export const featureFlagLocal = {
  additionalQuestion: {
    isActive: true,
  },
  bannerInfo: {
    isActive: true,
    message: 'dari pada bosen, baca blog Populix yuk',
    url: 'https://info.populix.co/report-blog',
  },
  facebookLogin: {
    isActive: true,
    url: 'https://info.populix.co/untuk-yang-login-dengan-facebook',
  },
  gamification: {
    isActive: true,
  },
  googleLogin: {
    isActive: true,
    url: '',
  },
  ktpValidation: {
    isActive: true,
  },
  otpPayoutValidation: {
    isActive: false,
  },
  studyCode: {
    isActive: true,
  },
};
