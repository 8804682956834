import React, { Component } from 'react';
import { Button } from '../../components';
import { connect } from 'react-redux';
import {
  toCurrency,
  toDecimal,
  adminFee,
  minimumPayout,
  normalPayout,
} from '../../helpers/generalHelpers';
class JumlahPenarikan extends Component {
  state = {
    formValidationFailed: false,
    formValidationMsg: '',
    uiValue: 0,
    value: 0,
  };

  _handleFormChange = (e) => {
    const { target } = e;
    this.setState({
      uiValue: toCurrency(toDecimal(target.value)),
      value: toDecimal(target.value),
    });
  };

  _handleSubmission = async (value, nextFunction) => {
    const { wallet } = this.props;
    if (value < minimumPayout || !value) {
      await this.setState({
        formValidationFailed: true,
        formValidationMsg: 'Minimum penarikan adalah Rp 15.000',
      });
      return setTimeout(() => {
        this.setState({ formValidationFailed: false, formValidationMsg: '' });
      }, 3500);
    } else if (value > wallet.saldo) {
      await this.setState({
        formValidationFailed: true,
        formValidationMsg: 'Saldo anda tidak cukup!',
      });
      setTimeout(() => {
        this.setState({ formValidationFailed: false, formValidationMsg: '' });
      }, 3500);
    } else {
      const payoutRequestMethod = {
        selectedMethod: {
          type: '',
        },
        step: 1,
        nominal: value,
      };
      nextFunction();
      sessionStorage.setItem('payoutRequestMethod', JSON.stringify(payoutRequestMethod));
    }
  };

  render() {
    const { nextStepFunction } = this.props;
    const { formValidationMsg, formValidationFailed, value, uiValue } = this.state;
    return (
      <div className='d-flex flex-column mt-3'>
        <div className='payout-request__jumlah-penarikan'>
          <label htmlFor='cash-withdraw--input' className='font-bold cash-withdraw__question'>
            Masukkan jumlah penarikan
          </label>
          <br />
          <div class='row'>
            <div class='mb-1' style={{ padding: '0.4rem 0rem 0rem' }}>
              <label>Rp</label>
            </div>
            <div class='col-sm'>
              <input
                type='text' // NOTE change the attribute via  props
                name='payoutNominal'
                className='form-control cash-withdraw__input modal__input'
                // TODO move to scss
                style={{
                  borderColor: formValidationFailed ? '#f14336' : '#ced4da',
                }}
                id='cash-withdraw--password'
                onChange={this._handleFormChange}
                value={uiValue}
                // defaultValue={value}
              />
              {/* TODO move to scss */}
              <small
                style={{
                  color: formValidationFailed ? '#f14336' : '#0d2433',
                }}
              >
                {formValidationMsg}
              </small>
              {value >= minimumPayout && value < normalPayout ? (
                <small className='text-color--warning'>
                  {`Penarikan dana di bawah Rp 50.000 akan dikenakan biaya admin sebesar Rp 5.000. Jumlah yang Anda terima sebesar Rp ${toCurrency(
                    value - adminFee,
                  )}`}
                </small>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className='stepper__footer stepper__footer--flex-end'>
          <Button
            label='Lanjut'
            type='primary'
            onClick={() => this._handleSubmission(value, nextStepFunction)}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    wallet: state.wallet,
  };
};
export default connect(mapStateToProps)(JumlahPenarikan);
