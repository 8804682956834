import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Button,
  PhoneVerification,
  SettingsBankAccount,
  Modal,
  PinConfirmation,
} from '../../components';
import { Transition } from 'react-spring/renderprops';
import { RupiahParser, adminFee, minimumPayout, normalPayout } from '../../helpers/generalHelpers';
import { withdrawSaldo } from '../../store/actions/saldoActions';
import PopCall from '../../services/PopCall';

const popCall = new PopCall();

class PayoutConfirmation extends Component {
  state = {
    selectedBank: {},
    disableBtn: false,
    loadingBtn: false,
    showModalOTP: false,
    otpFalseAttempt: 0,
    otpFalseMessage: '',
  };

  async componentDidMount() {
    const { wallet } = this.props;

    const storage = await JSON.parse(sessionStorage.getItem('payoutRequestMethod'));

    if (storage && storage.selectedMethod.type === 'Bank') {
      if (Array.isArray(wallet) && wallet.length !== 0) {
        let defaultBank = wallet.filter((w) => w.default === true);
        if (defaultBank.length === 0) {
          defaultBank.push(wallet[0]);
        }
        this.setState({
          selectedBank: {
            id: defaultBank && defaultBank[0] && defaultBank[0].id,
            accountNumber: defaultBank && defaultBank[0].accountNumber,
            bank: defaultBank && defaultBank[0].bank,
          },
        });
      } else {
        this.setState({ disableBtn: true });
      }
    }
  }

  handlePrevious = async () => {
    const storage = await JSON.parse(sessionStorage.getItem('payoutRequestMethod'));
    const payoutRequestMethod = {
      selectedMethod: {},
      step: 1,
      nominal: storage.nominal,
    };
    await sessionStorage.setItem('payoutRequestMethod', JSON.stringify(payoutRequestMethod));
    this.props.prevStepFunction();
  };

  handleNext = async (event) => {
    event.preventDefault();
    const storage = await JSON.parse(sessionStorage.getItem('payoutRequestMethod'));
    const { wallet, featureFlag } = this.props;

    if (
      featureFlag &&
      featureFlag.otpPayoutValidation &&
      featureFlag.otpPayoutValidation.isActive
    ) {
      let body = {
        payoutAmount: storage.nominal,
        payoutType: storage.selectedMethod.type,
      };
      if (storage.selectedMethod.type === 'Bank') {
        body = {
          ...body,
          paymentInfoId: wallet[0].id,
        };
      }
      try {
        const url = '/payout/otp/send';
        const response = await popCall.post(url, body);
        if (response) {
          this.setState({
            otpFalseAttempt: 0,
            otpFalseMessage: '',
            showModalOTP: true,
          });
        }
        return true;
      } catch (error) {
        this.props.history.push({
          pathname: '/payoutcompleted',
          state: { error: error },
        });
      } finally {
        this.setState({ disableBtn: true, loadingBtn: true });
      }
    } else {
      let payoutRequestMethod;
      if (storage.selectedMethod.type === 'Bank') {
        const { selectedBank } = this.state;
        payoutRequestMethod = {
          ...storage,
          step: 2,
          selectedMethod: {
            ...storage.selectedMethod,
            ...selectedBank,
          },
        };
      } else {
        const { profile } = this.props;
        payoutRequestMethod = {
          selectedMethod: { ...storage.selectedMethod, phone_number: profile.phone_number },
          step: 2,
          nominal: storage.nominal,
        };
      }
      await sessionStorage.setItem('payoutRequestMethod', JSON.stringify(payoutRequestMethod));
      await this._withdrawSaldo(
        parseInt(payoutRequestMethod.nominal),
        payoutRequestMethod.selectedMethod.type,
        payoutRequestMethod.selectedMethod.id,
      );
    }
  };

  _handlePayoutOTPSubmit = async (code) => {
    const { otpFalseAttempt } = this.state;
    const { wallet } = this.props;
    const storage = await JSON.parse(sessionStorage.getItem('payoutRequestMethod'));
    let body = {
      payoutAmount: storage.nominal,
      payoutType: storage.selectedMethod.type,
      code,
    };
    if (storage.selectedMethod.type === 'Bank') {
      body = {
        ...body,
        paymentInfoId: wallet[0].id,
      };
    }
    let countOtpFalse = otpFalseAttempt;
    this.setState({ otpFalseMessage: '' });

    const url = '/payout/otp/submit';
    try {
      const response = await popCall.post(url, body);
      if (response) {
        this.props.history.push({
          pathname: '/payoutcompleted',
        });
      }
    } catch (error) {
      if (otpFalseAttempt <= 1) {
        this.setState({
          otpFalseAttempt: (countOtpFalse += 1),
        });
        this.setState({ otpFalseMessage: 'Kode yang anda masukkan salah' });
      } else {
        this.props.history.push({
          pathname: '/payoutcompleted',
          state: { error: error },
        });
      }
    }
  };

  _withdrawSaldo = async (nominal, selectedMethodType, selectedMethodId) => {
    const withdraw = this.props.withdrawSaldo;
    try {
      this.setState({ disableBtn: true, loadingBtn: true });
      await withdraw(parseInt(nominal), selectedMethodType, selectedMethodId);
      this.setState({ disableBtn: false, loadingBtn: false });
      this.props.history.push({
        pathname: '/payoutcompleted',
      });
    } catch (error) {
      this.setState({ disableBtn: false, loadingBtn: false });
      this.props.history.push({
        pathname: '/payoutcompleted',
        state: { error: error },
      });
    }
  };

  _setSelectedBank = (id = null, accountNumber = null, bank = null) => {
    this.setState({
      selectedBank: {
        id,
        accountNumber,
        bank,
      },
      disableBtn: false,
    });
  };

  handleDonate = (amount) => async () => {
    try {
      this.setState({ disableBtn: true, loadingBtn: true });
      await popCall.post('/payout/donation', { amount });
      this.setState({ disableBtn: false, loadingBtn: false });
      this.props.history.push('/donateCompleted');
    } catch (err) {
      console.error(err);
    } finally {
      this.setState({ disableBtn: false, loadingBtn: false });
    }
  };

  render() {
    const payout = JSON.parse(sessionStorage.getItem('payoutRequestMethod'));
    const { selectedBank, disableBtn, loadingBtn, showModalOTP, otpFalseMessage } = this.state;

    if (payout.selectedMethod.type === 'donate') {
      return (
        <div className='payout-request d-flex flex-column mt-3'>
          <div className='payout-request__content pb-2'>
            <strong>Konfirmasi Donasi</strong>
          </div>
          <div>Total Donasi: {payout && RupiahParser(payout.nominal)}</div>
          <div className='stepper__footer '>
            <div className='col-sm-6'>
              <Button label='Kembali' type='primary' block onClick={this.handlePrevious} />
            </div>
            <div className='col-sm-6'>
              <Button
                label='Donasi'
                type='primary'
                block
                onClick={this.handleDonate(parseInt(payout.nominal))}
                disabled={disableBtn}
                loader={loadingBtn}
              />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className='payout-request d-flex flex-column mt-3'>
        <div className='payout-request__content pb-2'>Konfirmasi Penarikan</div>
        <div>Total Penarikan: {payout && RupiahParser(payout.nominal)}</div>
        <div
          style={{
            color: '#f14336',
          }}
        >
          {payout.nominal >= minimumPayout && payout.nominal < normalPayout
            ? `Biaya Administrasi: -${RupiahParser(adminFee)}`
            : ''}
        </div>
        <div
          style={{
            fontWeight: 'bold',
          }}
        >
          Total Transfer:&nbsp;
          {payout.nominal >= minimumPayout && payout.nominal < normalPayout
            ? payout && RupiahParser(payout.nominal - adminFee)
            : payout && RupiahParser(payout.nominal)}
        </div>
        {payout && payout.selectedMethod.type === 'Bank' && (
          <>
            <div className='mt-3'>
              <SettingsBankAccount
                fromPayoutRequest
                handleClickSelect={this._setSelectedBank}
                selectedBank={selectedBank && selectedBank.id}
              />
            </div>
          </>
        )}
        {payout && payout.selectedMethod.type !== 'Bank' && (
          <table className='table table-borderless mt-3' style={{ width: '60%' }}>
            <tbody>
              <tr>
                <td>Metode</td>
                <td>:</td>
                <td>
                  {payout && payout.selectedMethod && payout.selectedMethod.type === 'Gopay'
                    ? 'GoPay'
                    : 'OVO'}
                </td>
              </tr>
              <tr>
                <td>No. Hp</td>
                <td>:</td>
                <td>
                  {payout && payout.selectedMethod && payout.selectedMethod.number ? (
                    payout.selectedMethod.number
                  ) : (
                    <PhoneVerification />
                  )}
                </td>
              </tr>
              <tr>
                <td>Dana</td>
                <td>:</td>
                <td>
                  {payout.nominal >= minimumPayout && payout.nominal < normalPayout
                    ? payout && RupiahParser(payout.nominal - adminFee)
                    : payout && RupiahParser(payout.nominal)}
                </td>
              </tr>
            </tbody>
          </table>
        )}

        {/* </div> */}
        <div className='stepper__footer '>
          <div className='col-sm-6'>
            <Button label='Kembali' type='primary' block onClick={this.handlePrevious} />
          </div>
          <div className='col-sm-6'>
            <Button
              label='Tarik'
              type='primary'
              block
              onClick={this.handleNext}
              disabled={disableBtn}
              loader={loadingBtn}
            />
          </div>
        </div>

        <Transition
          native
          items={showModalOTP}
          from={{ transform: 'translate(0, -100%)' }}
          enter={{ transform: 'translate(0, 0)' }}
          leave={{ transform: 'translate(0, -100%)' }}
        >
          {(showPinVerif) => (style) =>
            showPinVerif && (
              <Modal classname='modal' show={showPinVerif} styles={style} animatedModal>
                <div className='modal-header'>
                  <button
                    type='button'
                    className='close'
                    onClick={() =>
                      this.setState({ showModalOTP: false, disableBtn: false, loadingBtn: false })
                    }
                  >
                    <span>&times;</span>
                  </button>
                </div>
                {/**NOTE We convert the ModalFormRekening to hooks or do this... */}
                <PinConfirmation
                  submitCode={this._handlePayoutOTPSubmit}
                  resendCode={this.handleNext}
                  closeModal={() =>
                    this.setState({ showModalOTP: false, disableBtn: false, loadingBtn: false })
                  }
                  isPayoutVerification={true}
                  otpFalseMessage={otpFalseMessage}
                />
              </Modal>
            )}
        </Transition>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile.userProfile,
    wallet: state.wallet.bankData ? state.wallet.bankData : state.wallet.bankObject,
    featureFlag: state.featureFlag,
  };
};

export default withRouter(connect(mapStateToProps, { withdrawSaldo })(PayoutConfirmation));
