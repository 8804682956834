import React, { Component } from 'react';
import { connect } from 'react-redux';
import dot from 'assets/img/dot.png';

class DashboardHeader extends Component {
	render() {
		const { isLoading, profile } = this.props;
		const { name } = profile;
		return (
			<div className="dashboard-header">
				{/* Welcome and Todo Section */}
				<h3 className="desktop-disabled">
					Hi, {name}.<br />
					Selamat datang :)
				</h3>

				{/* 3 titik */}
				<img className="img-3-dots" src={dot} />

				<h6 className="text-uppercase">Aktivitas yang bisa kamu lakukan:</h6>

				<div className="dashboard-activity">
					<h4 className="font-bold">Studi baru untuk kamu</h4>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		profile: state.profile.userProfile
	};
};
export default connect(mapStateToProps)(DashboardHeader);
