import React, { Component } from 'react';
import moment from 'moment';
import Auth from '@aws-amplify/auth';
import { connect } from 'react-redux';
import { Transition, animated } from 'react-spring/renderprops';
import studiMarketplaceXxxhd from 'assets/img/studi-marketplace-xxxhd.png';
import profesiIcon from 'assets/img/screening-tab-icons/profesi-icon.png';
import jamIcon from 'assets/img/screening-tab-icons/jam-icon.png';
import kalenderIcon from 'assets/img/screening-tab-icons/kalender-icon.png';
import popsad from 'assets/img/popcorn-sad.png';
import popshocked from 'assets/img/popcorn-kaget.png';
import downloadAndroid from 'assets/img/android-download.png';
import downloadIos from 'assets/img/ios-download.png';
import { FormSkeletons } from '../../Skeletons/FormSkeletons';
import { fetchStudy, fetchStudyByCode } from '../../../store/actions/studyActions';
import {
  updateRdxPhoneNumber,
  fetchUserProfileSuccess,
} from '../../../store/actions/profileActions';
import { resetActiveStudyState } from '../../../store/actions/activeStudyActions';
import {
  Button,
  Modal,
  ModalInput,
  PinConfirmation,
  ModalError,
  StudyReward,
} from '../../../components';
import PopCall from '../../../services/PopCall';
import populixLogo from 'assets/img/logo.png';
import Grid from '@material-ui/core/Grid';
import Cookies from 'js-cookie';
import { removeStoredData } from '../../../helpers/generalHelpers';

const popCall = new PopCall();
// TODO RAPIHIN

class StudyDetail extends Component {
  state = {
    loading: true,
    startStudy: false,
    redirectLoading: false,
    error: null,
    isEligible: true,
    errorMessage: '',
    showRequiredPhoneNumberModal: false,
    showModalHp: false,
    showModalVerification: false,
    postingVerification: false,
    validationFailed: false,
    validationMessage: '',
    validationProcessCompleted: false,
    phone_number: '',
    showErrorModal: false,
    errorMsgHead: '',
    errorMsgDetail: '',
    status: '',
  };
  async componentDidMount() {
    const { match } = this.props;
    // NOTE Maybe the reset should go to ActiveStudy instead?
    const resetStudyRdx = this.props.resetActiveStudyState;
    resetStudyRdx();
    // If user phone_number exists, set the process to completed
    if (this.props.profile.phone_number) {
      this.setState({ validationProcessCompleted: true });
    }

    const { params } = match;
    const query = new URLSearchParams(this.props.location.search);
    const studyCode = query.get('code');

    if (!studyCode) {
      await this._getStudy(params && params.id ? params.id : 0);
    } else {
      await this._getStudyByCode(studyCode);
    }

    // remove stored data
    removeStoredData();
  }

  _getStudy = async (id) => {
    const getStudy = this.props.fetchStudy;
    try {
      await getStudy(id);
    } catch (error) {
      const { response } = error;
      switch ((response && response.data.type) || response.status === 403) {
        case 'StudyFull':
          this.setState({
            isEligible: false,
            errorMessage: 'Studi ini sudah penuh',
            status: response.data.type,
          });
          break;
        case 'SubmissionExist':
          this.setState({
            isEligible: false,
            errorMessage: 'Kamu tidak dapat mengikuti studi ini lagi',
            status: response.data.type,
          });
          break;
        case 'StudyExpired':
          this.setState({
            isEligible: false,
            errorMessage: 'Studi ini sudah tidak berlaku',
            status: response.data.type,
          });
          break;
        case 'ProhibitedByCriteriaQuota':
          this.setState({
            isEligible: false,
            errorMessage: 'Studi ini sudah terpenuhi',
            status: response.data.type,
          });
          window.dataLayer.push({
            event: 'errorLoad',
            eventCategory: 'error state',
            eventAction: 'load',
            eventLabel: 'study full',
          });
          break;
        case 'NotSupportedDevice':
          this.setState({
            isEligible: false,
            errorMessage: 'Beda platform',
            status: response.data.type,
            deviceType: response.data.deviceType,
          });
          break;
        default:
          this.setState({
            isEligible: false,
            errorMessage: 'Kamu tidak dapat mengikuti studi ini',
            status: 'Error',
          });
          break;
      }
    } finally {
      this.setState({ loading: false });
    }
  };

  _getStudyByCode = async (studyCode) => {
    const getStudy = this.props.fetchStudyByCode;
    try {
      await getStudy(studyCode);
      const {
        study: { checkStatus },
      } = this.props;

      if (checkStatus !== 'OK') {
        let statusMessage = '';
        let status = '';

        if (checkStatus === 'FULL') {
          statusMessage = 'Maaf, studi sudah penuh.';
          status = checkStatus;
        } else if (checkStatus === 'TAKEN') {
          statusMessage = 'Maaf, studi sudah pernah diambil. Lihat Riwayat Studi.';
          status = checkStatus;
        } else if (checkStatus === 'EXPIRED') {
          statusMessage = 'Studi tidak ditemukan.';
          status = checkStatus;
        } else {
          statusMessage = 'Terdapat kesalahan. Harap coba kembali.';
          status = 'Error';
        }

        this.setState({
          isEligible: false,
          errorMessage: statusMessage,
          status,
        });
      }
    } catch (error) {
      const { response } = error;
      if (response.status === 404) {
        this.setState({
          isEligible: false,
          errorMessage: 'Studi tidak ditemukan.',
          status: 'Error',
        });
      } else {
        this.setState({
          isEligible: false,
          errorMessage: 'Terdapat kesalahan. Harap coba kembali.',
          status: 'Error',
        });
      }
    } finally {
      this.setState({ loading: false });
    }
  };

  _navigateToActiveStudy = async () => {
    const { study, profile } = this.props;
    const { validationProcessCompleted } = this.state;
    const { studyUrl, id, type } = study;
    const { groups, ktp_status } = profile;
    let sub = profile.sub;

    try {
      // validate ktp_status, if not approved cannot get study and show error modal
      if (ktp_status === 'REJECTED') {
        this.setState({
          showErrorModal: true,
          errorMsgHead: '',
          errorMsgDetail:
            'Silahkan hubungi Kami untuk validasi data KTP Anda agar bisa mengikuti studi ini.',
        });
        return;
      }
      // TODO : mechanism for ktp_status = 'EMPTY' -> upload & validate KTP

      // Using validationProcess state instead of phone_number
      if (
        validationProcessCompleted ||
        (groups && Array.isArray(groups) && groups.includes('admin'))
      ) {
        await this.setState({ redirectLoading: true });

        if (!sub) {
          const { attributes } = await Auth.currentUserInfo();
          sub = attributes.sub;
        }

        // Create new submission here
        const newSubmissionResult = await popCall.post(`/studies/${id}/submissions`);

        if (newSubmissionResult.data.status !== 'Jawaban Baru') {
          this.setState({
            showErrorModal: true,
            errorMsgHead: '',
            errorMsgDetail: 'Email Already Exist.',
            redirectLoading: false,
          });

          return;
        }

        if (!studyUrl && type === 'poplite') {
          return this.props.history.push(
            `/online-survey/${id}?sguid=${sub}&submissionid=${newSubmissionResult.data.id}`,
          );
        }

        if (study.isCintStudy) {
          window.location.href = studyUrl;
        } else {
          let queryParams = `sguid=${sub}&studyid=${id}&submissionid=${newSubmissionResult.data.id}&submissionId=${newSubmissionResult.data.id}`;

          // store submissionId to cookies
          Cookies.set('submissionId', newSubmissionResult.data.id, {
            domain: '.populix.co',
            secure: false,
          });
          Cookies.set('studyId', newSubmissionResult.data.StudyId, {
            domain: '.populix.co',
            secure: false,
          });
          Cookies.set('studyType', 'ONLINE', { domain: '.populix.co', secure: false });

          localStorage.setItem('SUBMISSION_ID', newSubmissionResult.data.id);
          localStorage.setItem('STUDY_ID', newSubmissionResult.data.StudyId);
          localStorage.setItem('STUDY_TYPE', 'ONLINE');

          if (studyUrl.includes('questionpro.com')) {
            queryParams = `custom1=${sub}&custom2=${id}&custom3=${newSubmissionResult.data.id}`;
            Cookies.set('isQuestionPro', 'true', { domain: '.populix.co', secure: false });
            localStorage.setItem('IS_QUESTIONPRO', 'true');
          } else {
            Cookies.remove('isQuestionPro', { domain: '.populix.co' });
            localStorage.removeItem('IS_QUESTIONPRO');
          }

          if (studyUrl.includes('?')) {
            return (window.location.href = `${studyUrl}&${queryParams}`);
          }

          return (window.location.href = `${studyUrl}?${queryParams}`);
        }
      } else {
        this.setState({ showRequiredPhoneNumberModal: true });
      }
    } catch (error) {
      this.setState({ error, redirectLoading: false });
    }
    // NOTE should you want to disable the back button, you can use:
    // window.location.replace = `${studyUrl}?sguid=${sub}`

    // history.push(`/activestudy/${id}`);
    // const createSubmission = await API.post(studySubmissionAPI, path, config);
    // if (createSubmission) {
    // // this.setState({ startStudy: true });
    // 	history.push(`/activestudy/${study_id}`, []);
    // }
  };

  _renderLoading = () => {
    return (
      <FormSkeletons native>
        {(props) => (
          <div className='study-item mt-3' style={{}}>
            <animated.div
              className='skeletons study-item-skeleton__icon mt-3'
              style={{ ...props }}
            />
            <div className='skeletons d-flex flex-column ml-1 mt-3' style={{ width: '85%' }}>
              <animated.div
                className='skeletons form_skeleton__children'
                style={{ ...props, width: '80%', height: 15 }}
              />
              <animated.div
                className='skeletons form_skeleton__children mt-2'
                style={{ ...props, width: '40%', height: 15 }}
              />
              <div className='skeletons d-flex flex-row flex-wrap mt-3'>
                <animated.div
                  className='skeletons form_skeleton__children'
                  style={{ ...props, width: '40%', height: 15 }}
                />
                <animated.div
                  className='skeletons form_skeleton__children ml-2'
                  style={{ ...props, width: '40%', height: 15 }}
                />
                <animated.div
                  className='skeletons form_skeleton__children mt-3'
                  style={{ ...props, width: '40%', height: 15 }}
                />
                <animated.div
                  className='skeletons form_skeleton__children ml-2 mt-3'
                  style={{ ...props, width: '40%', height: 15 }}
                />
              </div>
              <animated.div
                className='skeletons form_skeleton__children mt-4'
                style={{ ...props, width: '100%', height: 30 }}
              />
            </div>
          </div>
        )}
      </FormSkeletons>
    );
  };

  _getEndDate = () => {
    const { study } = this.props;
    const endDate = moment(study && study.endDate);

    const diffInDays = endDate.diff(moment(), 'days');
    const diffInHours = endDate.diff(moment(), 'hours');
    const diffInMinutes = endDate.diff(moment(), 'minutes');

    if (diffInDays > 365) {
      // This is logic just to handle the welcome survey (screening survey)
      return null;
    } else {
      if (diffInDays > 0 && diffInDays < 365) {
        return `${diffInDays} Hari Lagi`;
      }
      if (diffInHours >= 1 && diffInHours <= 24) {
        return `${diffInHours} Jam Lagi`;
      }
      if (diffInMinutes >= 0 && diffInMinutes <= 60) {
        return `${diffInMinutes} Menit Lagi`;
      }
    }
  };

  _renderCard = () => {
    const { study } = this.props;
    const { redirectLoading } = this.state;
    const studyEndTime = study && study.endDate && this._getEndDate();
    const reward = study && study.participantReward && Math.round(study.participantReward);
    return (
      <div className='study-item'>
        {' '}
        {/* TODO create study detail card */}
        <img className='study-icon' src={studiMarketplaceXxxhd} />
        <div style={{ borderLeft: '1px solid #EDEFF2' }} className='px-3'>
          <div className='study-header mb-3'>
            <h4 className='font-bold'>{study && study.title}</h4>
          </div>

          <h5>
            <span className='researcher-name font-semibold'>
              {study && study.companyName ? study.companyName : 'Populix'}
            </span>
          </h5>

          <p className='study-short-description text--dark-gray'>
            {study && study.companyDescription}
          </p>

          <div className='grid-4 study-details-and-reward mb-3'>
            {study.requiredParticipants < 1000000 && (
              <React.Fragment>
                <img className='icon' src={profesiIcon} />
                <p className='survey-header__value'>
                  <span>{study && study.requiredParticipants}</span> orang
                </p>
              </React.Fragment>
            )}

            <img className='icon' src={jamIcon} />
            <p className='survey-header__value'>
              <span>{study && (study.averageCompletionTime || study.maxAllowedTime)} menit</span>
            </p>

            <StudyReward reward={reward} type={study && study.rewardType} />

            {studyEndTime && (
              <React.Fragment>
                <img className='icon' src={kalenderIcon} />
                <p className='survey-header__value'>
                  <span>{studyEndTime}</span>
                </p>
              </React.Fragment>
            )}
          </div>
          <Button
            block
            label={redirectLoading ? 'Memproses' : 'Mulai Studi'}
            type='primary'
            onClick={this._navigateToActiveStudy}
            disabled={redirectLoading}
          />
          {/* <button
            className='btn btn-primary btn-block'
            type='button'
            onClick={this._navigateToActiveStudy}
            disabled={redirectLoading}
          >
            {redirectLoading ? 'Memproses' : 'Mulai Studi'}
          </button> */}
        </div>
      </div>
    );
  };

  _renderNotEligible = () => {
    const { profile } = this.props;
    const { errorMessage, status } = this.state;
    return (
      <div className='studi__not-eligible justify-content-center my-4'>
        {status === 'StudyFull' ||
        status === 'StudyExpired' ||
        status === 'ProhibitedByCriteriaQuota' ||
        status === 'FULL' ||
        status === 'EXPIRED' ? (
          <>
            <div className='studi__img-wrapper'>
              <img src={popshocked} className='img-fluid' alt='not-eligible' />
            </div>
            <h2 className='font-bold my-3 text-center'>Yah, kuota studi sudah habis...</h2>
            <h3 className='font-light text-center'>
              Tenang, masih ada studi lain menanti dan jangan lupa ikuti polling setiap hari ya!
            </h3>
          </>
        ) : (
          <>
            <div className='studi__img-wrapper'>
              <img src={popsad} className='img-fluid' alt='not-eligible' />
            </div>
            <h2 className='font-bold my-3 text-center'>Maaf {profile.name}</h2>
            <h3 className='font-light text-center'>{errorMessage}</h3>
          </>
        )}
        <Button
          label='Lihat studi lain'
          asNav
          navLink='/study'
          type='warning'
          style={{ color: 'white', marginTop: '1.5em' }}
        />
      </div>
    );
  };

  /* Render UI if the device unavailable */
  _renderDeviceNotCompatible = () => {
    const { profile } = this.props;
    const { errorMessage, deviceType } = this.state;
    const devices = deviceType.split(',') || [];

    var deviceMessage = '';

    if (devices.includes('android') && devices.includes('ios')) {
      deviceMessage = 'Studi ini hanya tersedia untuk Android & iOS';
    }

    if (devices.length <= 1) {
      deviceMessage =
        'Studi ini hanya berlaku di aplikasi ' + (devices[0] === 'android' ? 'Android' : 'iOS');
    }

    return (
      <div className='studi__not-eligible justify-content-center mt-4'>
        <div className='studi__img-wrapper'>
          <img src={popsad} className='img-fluid' alt='not-eligible' />
        </div>
        <h2 className='font-bold my-3 text-center'>Maaf {profile.name}</h2>
        <h3 className='font-light text-center'>{deviceMessage}</h3>
        <Grid
          container
          className='center'
          style={{ margin: '10px', marginTop: '25px', width: '50%' }}
          justify='center'
          alignItems='center'
        >
          {/* If device is Android, render Android download banner */}
          {devices.includes('android') && (
            <Grid item md xs='12'>
              <a
                href='https://play.google.com/store/apps/details?id=co.populix&hl=en'
                target='_blank'
              >
                <img
                  src={downloadAndroid}
                  className='img-fluid'
                  title='Download'
                  style={{
                    objectFit: 'contain',
                    display: 'block',
                    width: '250px',
                    margin: 'auto',
                    marginBottom: '15px',
                  }}
                  alt='Download Android'
                />
              </a>
            </Grid>
          )}
          {/* If device is iOS, render iOS download banner */}
          {/* {devices.includes('ios') && (
            <Grid item md xs='12'>
              <img
                src={downloadIos}
                className='img-fluid'
                style={{
                  objectFit: 'contain',
                  display: 'block',
                  width: '250px',
                  margin: 'auto',
                  marginBottom: '15px',
                }}
                title='Coming Soon'
                alt='Coming Soon - Downlaod iOS'
              />
            </Grid>
          )} */}
        </Grid>
        <Button
          label='Lihat studi lain'
          asNav
          navLink='/study'
          type='warning'
          style={{ color: 'white' }}
        />
      </div>
    );
  };

  _hideRequiredPhoneModal = () => {
    this.setState({
      showRequiredPhoneNumberModal: false,
    });
  };

  _onChangePhone = (val) => {
    this.setState({
      phone_number: val && val.toString(),
    });
  };

  _togglePhoneNumberModal = async () => {
    if (this.state.showRequiredPhoneNumberModal) {
      await this.setState({ showRequiredPhoneNumberModal: false });
    }
    await this.setState((prevState) => ({
      ...prevState,
      showModalHp: !prevState.showModalHp,
    }));
  };

  _cancelPhoneNumber = async () => {
    this.props.updateRdxPhoneNumber('');
    await this.setState({
      showModalHp: false,
    });
  };

  _cancelVerification = () => {
    this.setState({
      showModalVerification: false,
    });
  };
  // CHORE move this to service or helper or whatever
  _handleVerifikasi = async (e) => {
    const { profile } = this.props;
    const { phone_number } = this.state;
    const userAge = moment().diff(
      moment(
        profile &&
          profile.criteria &&
          profile.criteria.DateOfBirth &&
          profile.criteria.DateOfBirth[0],
        'DD-MM-YYYY',
      ),
      'years',
    );
    e.preventDefault();
    // NOTE might want to add formik
    if (phone_number && phone_number.length > 8 && phone_number.length < 15) {
      const phoneNumberPayload = `+62${phone_number}`;
      await this.setState({
        postingVerification: true,
        validationMessage: '',
        validationFailed: false,
      });
      const url = '/otp/send';
      try {
        if (userAge < 17) {
          await popCall.post(url, { phone_number: phoneNumberPayload });
          this.setState({
            showModalHp: false,
            showModalVerification: true,
            postingVerification: false,
          });
        } else {
          const putResponse = await popCall.put('/profile/participant', {
            phone_number: phoneNumberPayload,
          });
          if (putResponse && putResponse.data) {
            this.props.dispatch(
              fetchUserProfileSuccess({
                ...profile,
                phone_number: putResponse && putResponse.data && putResponse.data.phone_number,
              }),
            );
            this.setState({ showModalHp: false });
          }
        }
        // return true;
      } catch (error) {
        const { response } = error;
        this.setState({
          validationMessage: response.data.message,
          validationFailed: true,
          postingVerification: false,
        });
        // return false;
      }
    } else {
      return this.setState({
        validationMessage: 'Nomor HP harus lebih dari 8 angka',
        validationFailed: true,
      });
    }
  };

  // NOTE Might wanna convert this into service
  // with Promise pattern instead of returning plain object;
  _submitCode = async (code) => {
    const url = '/otp/verify';
    const phoneNumberPayload = `+62${this.state.phone_number}`;
    const body = {
      code,
      phone_number: phoneNumberPayload,
    };
    try {
      const responseVerif = await popCall.post(url, body);
      if (responseVerif && responseVerif.data) {
        this.props.fetchUserProfileSuccess(responseVerif.data);
        await this.setState({
          validationProcessCompleted: true,
        });
      }
      return responseVerif;
    } catch (error) {
      const { response } = error;
      console.error(error);
      return response.data;
    }
  };

  _renderPhoneInput = () => {
    const { profile } = this.props;
    const { postingVerification, validationMessage, validationFailed } = this.state;

    return (
      <Transition
        native
        items={this.state.showModalHp}
        from={{ transform: 'translate(0, -100%)' }}
        enter={{ transform: 'translate(0, 0)' }}
        leave={{ transform: 'translate(0, -100%)' }}
      >
        {(ShowInputHp) => (modalStyle) =>
          ShowInputHp && (
            <ModalInput
              animatedModal
              type='phone'
              style={modalStyle}
              title='Nomor HP'
              formLabelMain='Masukkan nomor HP Anda'
              submitLabel='Verifikasi'
              cancelLabel='Batal'
              value={this.state.phone_number}
              disabled={postingVerification}
              formValidationMsg={validationMessage || ''}
              formValidationFailed={validationFailed}
              //untuk sementara pake funx _savingCriteria, nanti bedain fungsi aja untuk validasi
              // handleSubmitForm={this._savingCriteria}
              handleSubmitForm={this._handleVerifikasi}
              handleCancelForm={this._cancelPhoneNumber}
              handleFormChange={this._onChangePhone}
              handleClose={this._cancelPhoneNumber}
            />
          )}
      </Transition>
    );
  };

  _renderInputCode = () => {
    const { showModalVerification } = this.state;
    return (
      <Transition
        native
        items={showModalVerification}
        from={{ transform: 'translate(0, -100%)' }}
        enter={{ transform: 'translate(0, 0)' }}
        leave={{ transform: 'translate(0, -100%)' }}
      >
        {(showPinVerif) => (style) =>
          showPinVerif && (
            <Modal classname='modal' show={showPinVerif} styles={style} animatedModal>
              <div className='modal-header'>
                <button type='button' className='close' onClick={this._cancelVerification}>
                  <span>&times;</span>
                </button>
              </div>
              <PinConfirmation
                submitCode={this._submitCode}
                resendCode={this._handleVerifikasi}
                closeModal={this._cancelVerification}
              />
            </Modal>
          )}
      </Transition>
    );
  };

  _renderRequiredPhoneModal = () => {
    const { showRequiredPhoneNumberModal } = this.state;
    return (
      <Transition
        native
        items={showRequiredPhoneNumberModal}
        from={{ transform: 'translate(0, -100%)' }}
        enter={{ transform: 'translate(0,0)' }}
        leave={{ transfrom: 'translate(0, -100%)' }}
      >
        {(showRequiredPhoneNumberModal) => (modalStyle) =>
          showRequiredPhoneNumberModal && (
            <Modal
              classname='modal'
              show={showRequiredPhoneNumberModal}
              animatedModal
              styles={modalStyle}
            >
              <div className='modal-header border-bottom py-4'>
                <img src={populixLogo} style={{ height: 32, width: 110 }} alt='populix' />
                <button type='button' className='close' onClick={this._hideRequiredPhoneModal}>
                  <span>&times;</span>
                </button>
              </div>
              <div>
                <div className='modal-body'>
                  <div className='my-2'>
                    <h3 className='mb-3'>{'Maaf, Kamu belum bisa mengambil studi ini'}</h3>
                    <h4 className='mb-3'>
                      Silahkan melakukan verifikasi Nomor Handphone terlebih dahulu.
                    </h4>
                  </div>
                </div>
                <div className='modal-footer modal-footer-type-2 float-right'>
                  <button
                    type='button'
                    className='btn btn-transparent btn--text text--light-blue modal-footer-btn'
                    onClick={this._togglePhoneNumberModal}
                  >
                    Verifikasi
                  </button>
                </div>
              </div>
            </Modal>
          )}
      </Transition>
    );
  };

  _renderErrorModal = () => {
    const { profile } = this.props;
    const { showErrorModal, errorMsgHead, errorMsgDetail } = this.state;
    return (
      <Transition
        native
        items={showErrorModal}
        from={{ transform: 'translate(0, -100%)' }}
        enter={{ transform: 'translate(0,0)' }}
        leave={{ transfrom: 'translate(0, -100%)' }}
      >
        {(showErrorModal) => (modalStyle) =>
          showErrorModal && (
            <ModalError
              animatedModal
              style={modalStyle}
              backButtonText='Tutup'
              errorMsgHead={errorMsgHead}
              errorMsgDetail={errorMsgDetail}
              dataObjects={{ name: `${profile.name} ${profile.family_name}` }}
              handleCloseFunction={this._hideErrorModal}
            />
          )}
      </Transition>
    );
  };

  _hideErrorModal = () => {
    this.setState({
      showErrorModal: false,
    });
  };

  render() {
    const {
      loading,
      isEligible,
      deviceType,
      showRequiredPhoneNumberModal,
      showModalHp,
      showModalVerification,
    } = this.state;

    if (loading) return this._renderLoading();
    return (
      <div className='studi'>
        {isEligible && this._renderCard()}
        {!isEligible && deviceType && this._renderDeviceNotCompatible()}
        {!isEligible && !deviceType && this._renderNotEligible()}
        {showRequiredPhoneNumberModal && this._renderRequiredPhoneModal()}
        {showModalHp && this._renderPhoneInput()}
        {showModalVerification && this._renderInputCode()}
        {this._renderErrorModal()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile.userProfile,
    study: state.study.studyObj,
  };
};

export default connect(mapStateToProps, {
  fetchStudy,
  fetchStudyByCode,
  resetActiveStudyState,
  updateRdxPhoneNumber,
  fetchUserProfileSuccess,
})(StudyDetail);
