import {
  UPDATE_AUTH,
  REGISTER,
  REGISTER_SUCCESS,
  REGISTER_FAILED,
  UNCONFIRMED_USER,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  SEND_EMAIL_FORGOT_PASSWORD_SUCCESS,
  USER_CONFIRMATION_SUCCESS,
  CLEAR_AUTH,
} from './actionTypes';

function updateAuth(isAuthenticated) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (typeof isAuthenticated !== 'boolean') {
        reject(`invalid type! expecting boolean type but received ${typeof isAuthenticated}`);
      }
      dispatch({
        type: UPDATE_AUTH,
        isAuthenticated,
      });
      resolve();
    });
  };
}

function updateConfirmationStatus() {
  return { type: USER_CONFIRMATION_SUCCESS };
}
function register() {
  return {
    type: REGISTER,
  };
}

function registerSuccess(email) {
  return {
    type: REGISTER_SUCCESS,
    username: email, //but email
  };
}

function registerFailed() {
  return {
    type: REGISTER_FAILED,
  };
}

function unconfirmedUser(email) {
  return {
    type: UNCONFIRMED_USER,
    username: email,
  };
}

function sendEmailForgotPasswordSuccess(email) {
  return {
    type: UNCONFIRMED_USER,
    username: email,
  };
}

function clearAuth() {
  return {
    type: CLEAR_AUTH,
  };
}

// function loginSuccess(userData){
//     return {
//         type: LOGIN_SUCCESS,
//         userProfile: userData,
//         isAuthenticated: true
//     }
// }

// function loginFailed(userData){
//     return {
//         type: LOGIN_FAILED,
//         userData: userData
//     }
// }

export {
  updateAuth,
  register,
  registerSuccess,
  registerFailed,
  unconfirmedUser,
  updateConfirmationStatus,
  // ,
  // loginSuccess,
  sendEmailForgotPasswordSuccess,
  clearAuth,
};
