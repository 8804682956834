import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Transition, animated } from 'react-spring/renderprops';
import illustration3 from 'assets/img/Illustration-03@3x.png';
import {
  showFailedNotification,
  showSuccessNotification,
  hideNotification,
  fetchUserProfileSuccess,
} from '../../store/actions/profileActions';

import PopCall from '../../services/PopCall';

const popCall = new PopCall();

class SettingsNotification extends Component {
  state = {
    emailNewStudyNotification: true,
    // hardcoded-crap
    loadingState: {
      updating_emailNewStudyNotification: false,
    },
  };

  componentDidMount() {
    const { profile } = this.props;
    if (profile && !profile.emailNewStudyNotification)
      this.setState({
        emailNewStudyNotification: profile.emailNewStudyNotification,
      });
  }

  _onChangeSettings = (e) => {
    const { profile } = this.props;
    const { email } = profile;
    const { target } = e;
    const { name, checked } = target;
    const dataAttrAppend = `updating_${name}`;
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          [name]: checked,
          loadingState: {
            ...prevState.loadingState,
            [dataAttrAppend]: true,
          },
        };
      },
      async () => {
        const { dispatch } = this.props;
        const { emailNewStudyNotification } = this.state;
        const pathSettings = `/profile/participant`;
        const dataSettings = {
          emailNewStudyNotification,
        };
        try {
          const changeSettingsValue = await popCall.put(pathSettings, dataSettings);

          if (changeSettingsValue && changeSettingsValue.data) {
            dispatch(fetchUserProfileSuccess(changeSettingsValue.data));
            dispatch(showSuccessNotification());
            this.setState((prevState) => {
              return {
                ...prevState,
                loadingState: {
                  ...prevState.loadingState,
                  [dataAttrAppend]: false,
                },
              };
            });
            setTimeout(() => {
              dispatch(hideNotification());
            }, 3000);
          }
        } catch (error) {
          dispatch(showFailedNotification());
          this.setState((prevState) => {
            return {
              ...prevState,
              loadingState: {
                ...prevState.loadingState,
                [dataAttrAppend]: false,
              },
            };
          });
          setTimeout(() => {
            dispatch(hideNotification());
          }, 3000);
        }
      },
    );
  };

  render() {
    const { loadingState, emailNewStudyNotification } = this.state;
    const { settings } = this.props;
    const { updating_emailNewStudyNotification } = loadingState;
    return (
      <div className='tab-pane fade show active' id='notification' role='tabpanel'>
        <div className='settings-grid'>
          {/* <div className='settings-header desktop-disabled pb-3'>
            <h3>Notifikasi</h3>
          </div> */}
          {/* Notification Settings */}
          <div className='notification-settings-form'>
            <h4 className='font-bold'>Notifikasi email</h4>
            <p>
              Populix dapat memberitahukan kamu jika ada informasi mengenai{' '}
              <strong className='text-highlight'>studi baru </strong>
              atau <strong className='text-highlight'>informasi terbaru</strong> dari Populix.
            </p>
            {/* Email Notification Toggle */}
            <label className='f-12'>
              Apakah kamu ingin menerima pemberitahuan via email saat ada studi baru ?
            </label>
            <div
              id='email_new_study_parent'
              className='notification-settings-toggle d-flex align-items-center'
            >
              <label className='switch'>
                <input
                  type='checkbox'
                  name='emailNewStudyNotification'
                  checked={emailNewStudyNotification}
                  onChange={this._onChangeSettings}
                />
                <span className='slider round' />
              </label>
              <Transition
                native
                items={updating_emailNewStudyNotification}
                from={{ transform: 'translate(-10px, 0)', opacity: 0 }}
                enter={{ transform: 'translate(0, 0)', opacity: 1 }}
                leave={{ transform: 'translate(-10px, 0)', opacity: 0 }}
              >
                {(showLabel) => (styleProps) =>
                  showLabel && (
                    <animated.label
                      style={{ ...styleProps }}
                      className='notification-settings__update-label ml-3'
                    >
                      Updating
                    </animated.label>
                  )}
              </Transition>
            </div>
          </div>
          {/* Notification Icon */}
          <div className='settings-icon mobile-disabled'>
            <img className='img-fluid' src={illustration3} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    settings: state.profile.userSettings,
    profile: state.profile.userProfile,
  };
};
export default connect(mapStateToProps)(SettingsNotification);
