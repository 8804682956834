import React, {Component} from 'react';

import StudyItem from '../StudyItem/StudyItem'

export default class StudyList extends Component{
    render() {
        return(
            <div className="study-list">
                <StudyItem />
            </div>
        )
    }
}