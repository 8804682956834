import React from 'react';
import PropTypes from 'prop-types';
import className from 'classnames';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Badge } from '../Badge/Badge';

export const BankListCard = (props) => {
  return (
    <div className='bank-account-list'>
      <div className='bank-account__detail'>
        <div className='bank-account__detail-user'>
          <p className='mb-0 text--elipsis'>{props.user}</p>
        </div>
        <div className='bank-account__detail-name'>
          <img
            className='img-fluid my-2'
            src={props.thumbnailUrl}
            alt={props.bank}
            width='50'
            height='50'
          />
        </div>
        <div className='bank-account__detail-id'>{props.accountNumber}</div>
      </div>
      <div className='bank-account__panel'>
        {(props.isDefault || props.isSelected) && (
          <div {...(props.isSelected ? { onClick: props.handleClickSelect } : undefined)}>
            <Badge status='OK' label={props.labelText} />
          </div>
        )}
        {!props.isDefault && props.labelText !== 'Terpilih' && (
          <a
            className='bank-account__panel-link'
            onClick={props.handleClickSelect || props.handleClickDefault}
          >
            {props.labelText}
          </a>
        )}
        {/* {labelDefault} */}
        {!props.hideEditDelete && (
          <div className='bank-account__panel-action'>
            <EditIcon className='bank-account__panel-item' onClick={props.handleClickEdit} />
            <DeleteIcon className='bank-account__panel-item' onClick={props.handleClickDelete} />
          </div>
        )}
      </div>
    </div>
  );
};

BankListCard.propTypes = {
  name: PropTypes.string,
  id: PropTypes.number.isRequired,
  user: PropTypes.string.isRequired,
  accountNumber: PropTypes.string.isRequired,
  bank: PropTypes.string.isRequired,
  isDefault: PropTypes.bool.isRequired,
  labelText: PropTypes.string,
  handleClickEdit: PropTypes.func,
  handleClickDelete: PropTypes.func,
  handleClickDefault: PropTypes.func,
  hideEditDelete: PropTypes.bool,
  isSelected: PropTypes.bool,
  thumbnailUrl: PropTypes.string,
};
