import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#FAFAFA',
    position: 'fixed',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    '&.MuiContainer-root': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  title: {
    fontSize: 60,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    letterSpacing: -0.5,
    color: '#333333',
    marginBottom: 20,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: 46,
    },
  },
  description: {
    color: '#333333',
    textAlign: 'center',
    fontSize: 16,
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.5,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: 14,
    },
  },
  mailText: {
    color: '#329BFF',
  },
}));

export default useStyles;
