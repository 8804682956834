import { SWITCH_USER } from "../actions/actionTypes";


export default function userReducer(state={}, action){
    switch (action.type) {
        case SWITCH_USER:
            return action.user;
        default:
            return state;
    }
}