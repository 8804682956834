import React, { useEffect, useState } from 'react';
import { Transition } from 'react-spring/renderprops';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import {
  Select,
  Input,
  MenuItem,
  Grid,
  FormControl,
  FormLabel,
  TextField,
  CssBaseline,
  Typography,
  FormHelperText,
  Checkbox,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { Button, Modal, ModalError } from '../../../components';
import popcornMagician from 'assets/img/popcorn-magic.png';
import { getDomiciles, StaticMonthlyExpenses, hideIntercom } from '../../../helpers/generalHelpers';
import PopCall from '../../../services/PopCall';
import { Prompt } from 'react-router-dom';

const popCall = new PopCall();
const staticMonthlyExpenses = StaticMonthlyExpenses();

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    marginBottom: 20,
    width: '95%',
  },
  containerModal: {
    paddingTop: 40,
    paddingBottom: 40,
    overflow: 'auto',
    maxHeight: '100vh',
  },
  mb30: {
    marginBottom: 30,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

const theme = createMuiTheme({
  palette: {
    primary: { main: '#3d9be9' },
    secondary: { main: '#f2bf5e' },
  },
  status: {
    danger: 'orange',
  },
  overrides: {
    MuiButton: {
      label: {
        color: 'white',
      },
    },
  },
});

const RenderAdditionalQuestion = ({
  additionalQuestionData,
  pipeAdditionalQuestionData,
  fnResetStudy,
  profile,
}) => {
  const classes = useStyles();
  const [valueAdditionalQuestion, setValueAdditionalQuestion] = useState({});
  const [cities, setCities] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);
  const [isShowModal, setShowModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [isLoadingButton, setLoadingButton] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [exclusiveOption, setExclusiveOption] = useState(false);
  const [additionalQuestions, setAdditionalQuestions] = useState([]);
  const [pipeAdditionalQuestions, setPipeAdditionalQuestions] = useState([]);
  const [selectedPipeAdditionalQuestions, setSelectedPipeAdditionalQuestions] = useState([]);
  const [isIncludedPipeQuestion, setIsIncludedPipeQuestion] = useState(false);

  useEffect(() => {
    if (additionalQuestionData && additionalQuestionData.length > 0) {
      setAdditionalQuestions(additionalQuestionData);
      countPage();
      const value = arrayToObject(additionalQuestionData);
      setValueAdditionalQuestion(value);
    }
    if (pipeAdditionalQuestionData && pipeAdditionalQuestionData.length > 0) {
      setPipeAdditionalQuestions(pipeAdditionalQuestionData);
    }
  }, [additionalQuestionData, pipeAdditionalQuestionData]);

  const _getDomiciles = async () => {
    const domicile = await getDomiciles();
    setCities(domicile);
  };

  const arrayToObject = (arrayAdditionalQuestions = [], arrayPipeAdditionalQuestions = []) => {
    // this function is for initialize state for the answer
    let dataToConvert = [];
    let objectResult = {};

    if (arrayAdditionalQuestions.length) {
      dataToConvert = arrayAdditionalQuestions;
    } else {
      dataToConvert = arrayPipeAdditionalQuestions;
    }

    objectResult = dataToConvert.reduce((obj, item) => {
      if (item.participantType === 'DETAILED_ADDRESS') {
        _getDomiciles();
      }
      if (item.participantType === 'INPUT_DATE') {
        obj[item.label] = [
          moment()
            .set('year', 2000)
            .format('DD-MM-YYYY')
            .toString(),
        ];
      } else {
        obj[item.label] = [];
      }
      return obj;
    }, {});

    return { ...valueAdditionalQuestion, ...objectResult };
  };

  const countPage = () => {
    let isThereBasicProfile = false;
    let isThereShortQuestion = false;
    let totalPage = 0;
    additionalQuestionData &&
      additionalQuestionData.forEach((question) => {
        if (question.tags === 'BasicProfile') {
          isThereBasicProfile = true;
        } else {
          isThereShortQuestion = true;
        }
      });
    if (isThereBasicProfile) {
      totalPage += 1;
      setPage(1);
    }
    if (isThereShortQuestion) {
      totalPage += 1;
    }
    setTotalPage(totalPage);
    if (!isThereBasicProfile && isThereShortQuestion) {
      setPage(2);
    }
  };

  const toggleModal = () => {
    if (page === totalPage) {
      setPage(1);
    }

    if (isShowModal) {
      hideIntercom(false);
    } else {
      hideIntercom(true);
    }

    setShowModal(!isShowModal);
  };

  const setPipeQuestions = (criteriaKey, value) => {
    let tmpPipeAdditionalQuestions = pipeAdditionalQuestions;
    let tmpSelectedPipeAdditionalQuestions = selectedPipeAdditionalQuestions;

    const data = tmpPipeAdditionalQuestions.find(
      (obj) =>
        obj &&
        obj.conditions &&
        obj.conditions[criteriaKey] &&
        obj.conditions[criteriaKey][0] &&
        obj.conditions[criteriaKey][0] === value,
    );

    const indexOfData = tmpPipeAdditionalQuestions.indexOf(data);

    if (data) {
      if (
        (tmpSelectedPipeAdditionalQuestions && !tmpSelectedPipeAdditionalQuestions.length) ||
        !tmpSelectedPipeAdditionalQuestions.includes(data)
      ) {
        tmpSelectedPipeAdditionalQuestions.push({ ...data, page: page + 1 });
        if (indexOfData > -1) {
          tmpPipeAdditionalQuestions.splice(indexOfData, 1);
        }
      }
    }

    setSelectedPipeAdditionalQuestions(tmpSelectedPipeAdditionalQuestions);
    setPipeAdditionalQuestions(tmpPipeAdditionalQuestions);
  };

  const handleNextPage = async () => {
    let errorVal = {};
    const payload = { ...valueAdditionalQuestion };
    shortQuestion.forEach((SQ) => {
      // Check Error
      if (SQ.label === 'DateOfBirth') {
        const date = moment(payload['DateOfBirth'][0], 'DD-MM-YYYY');
        const minDate = moment().subtract(100, 'year');
        const maxDate = moment().subtract(10, 'year');
        const range = moment(date, 'DD-MM-YYYY').isBetween(minDate, maxDate);
        if (!date.isValid() || !range) {
          errorVal = { ...errorVal, DateOfBirth: `Invalid date Format` };
        }
      } else {
        if ((payload[SQ.label] && payload[SQ.label].length === 0) || !payload[SQ.label][0]) {
          errorVal = { ...errorVal, [SQ.label]: 'jawaban tidak boleh kosong' };
        }
      }
    });
    setErrors(errorVal);

    if (Object.keys(errorVal).length === 0) {
      if (page < totalPage) {
        setPage(page + 1);
      } else {
        if (pipeAdditionalQuestions && pipeAdditionalQuestions.length && !isIncludedPipeQuestion) {
          for (const key in valueAdditionalQuestion) {
            valueAdditionalQuestion[key].forEach((value) => {
              setPipeQuestions(key, value);
            });
          }
          if (selectedPipeAdditionalQuestions && selectedPipeAdditionalQuestions.length) {
            const newAdditionalQuestions = [
              ...additionalQuestions,
              ...selectedPipeAdditionalQuestions,
            ];
            const value = arrayToObject([], selectedPipeAdditionalQuestions);
            setAdditionalQuestions(newAdditionalQuestions);
            setValueAdditionalQuestion(value);
            setTotalPage(totalPage + 1);
            setPage(page + 1);
            if (pipeAdditionalQuestions && !pipeAdditionalQuestions.length) {
              setIsIncludedPipeQuestion(true);
            }
            setSelectedPipeAdditionalQuestions([]);
          } else {
            submitAdditionalQuestion(payload);
          }
        } else {
          submitAdditionalQuestion(payload);
        }
      }
    }
  };

  const submitAdditionalQuestion = async (payload) => {
    try {
      setLoadingButton(true);
      Object.keys(payload).forEach((key) => {
        switch (key) {
          case 'Age':
            payload.DateOfBirth = payload[key];
            delete payload[key];
            break;
          default:
            break;
        }
      });
      await popCall.post('/participant/important-criteria', payload);
      await fnResetStudy();
      await toggleModal();
      setLoadingButton(false);
      setPage(1);
    } catch (error) {
      console.error(error);
      setLoadingButton(false);
      setShowErrorModal(true);
    }
  };

  let shortQuestion =
    (additionalQuestions && additionalQuestions.filter((bp) => bp.tags === 'BasicProfile')) || [];

  if (page === 2) {
    shortQuestion =
      additionalQuestions && additionalQuestions.filter((bp) => bp.tags !== 'BasicProfile');
  }

  if (page >= 3) {
    shortQuestion = additionalQuestions && additionalQuestions.filter((bp) => bp.page === page);
  }

  function _setSelectMulti(event, question) {
    let isExclusiveOption = '';
    for (const answer of question.options) {
      if (answer.value === event.target.value[event.target.value.length - 1]) {
        isExclusiveOption = answer;
      }
    }
    if (!isExclusiveOption.isExclusiveOption && !exclusiveOption) {
      setValueAdditionalQuestion({
        ...valueAdditionalQuestion,
        [event.target.name]: event.target.value,
      });
    } else {
      setValueAdditionalQuestion({
        ...valueAdditionalQuestion,
        [event.target.name]: [isExclusiveOption.value.toString()],
      });

      if (!exclusiveOption) {
        setExclusiveOption(true);
      } else {
        setExclusiveOption(false);
      }
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            flexWrap: 'wrap',
            marginBottom: '90px',
          }}
        >
          <img
            className='img-fluid mt-4'
            src={popcornMagician}
            alt='sad-popcorn'
            style={{ width: '40%', margin: '1em auto' }}
          />
          <h4 style={{ fontWeight: 'bold', textAlign: 'center', marginBottom: 10 }}>
            Popcorn mau tanya sesuatu nih
          </h4>
          <h4>isi dulu ya</h4>
          <Button label={'Selanjutnya'} type='primary' onClick={toggleModal} />
        </div>
        <Transition
          native
          items={isShowModal}
          from={{ transform: 'translate(0, -100%)' }}
          enter={{ transform: 'translate(0, 0)' }}
          leave={{ transform: 'translate(0, -100%)' }}
        >
          {(isShowModal) => (style) =>
            isShowModal && (
              <Modal classname='modal' show={isShowModal} styles={style} animatedModal>
                <>
                  <div className='modal-header'>
                    <button
                      type='button'
                      className='close'
                      onClick={() => {
                        let msg =
                          'Keluar PopCheck? Data yang sudah kamu isi tidak akan tersimpan ya.';
                        if (window.confirm(msg)) {
                          toggleModal();
                        }
                      }}
                    >
                      <Prompt
                        when={isShowModal}
                        message='Keluar PopCheck? Data yang sudah kamu isi tidak akan tersimpan ya.'
                      />
                      <span>&times;</span>
                    </button>
                  </div>
                  <Grid
                    container
                    direction='row'
                    justify='center'
                    alignItems='center'
                    className={classes.containerModal}
                  >
                    <Typography component='h1' variant='h4' align='center' className={classes.mb30}>
                      Pertanyaan Singkat
                    </Typography>
                    {shortQuestion &&
                      shortQuestion.map((question, idx) => {
                        if (question.participantType === 'INPUT_DATE') {
                          return (
                            <Grid item xs={12} key={idx}>
                              <FormControl component='fieldset' className={classes.formControl}>
                                <FormLabel component='legend'>{question.question}</FormLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                  <KeyboardDatePicker
                                    autoOk
                                    disableFuture
                                    format='DD/MM/YYYY'
                                    value={moment(
                                      valueAdditionalQuestion &&
                                        valueAdditionalQuestion[question.label] &&
                                        valueAdditionalQuestion[question.label][0],
                                      'DD-MM-YYYY',
                                    )}
                                    onChange={(event) => {
                                      setValueAdditionalQuestion({
                                        ...valueAdditionalQuestion,
                                        [question.label]: [
                                          moment(event)
                                            .format('DD-MM-YYYY')
                                            .toString(),
                                        ],
                                      });
                                    }}
                                    animateYearScrolling
                                    minDate={moment().subtract(100, 'year')}
                                    maxDate={moment().subtract(10, 'year')}
                                  />
                                </MuiPickersUtilsProvider>
                              </FormControl>
                            </Grid>
                          );
                        }

                        if (question.participantType === 'INPUT_NUMBER') {
                          if (question.label === 'MonthlyExpenses') {
                            const optionsMonthlyExpenses = staticMonthlyExpenses.options;
                            return (
                              <Grid item xs={12} key={idx}>
                                <FormControl component='fieldset' className={classes.formControl}>
                                  <FormLabel component='legend'>
                                    {question && question.question}
                                  </FormLabel>
                                  <Select
                                    name={question && question.label}
                                    labelId={`${question && question.label}-label`}
                                    id='jobstatus-select-outlined'
                                    value={
                                      (valueAdditionalQuestion &&
                                        valueAdditionalQuestion[question.label] &&
                                        valueAdditionalQuestion[question.label][0]) ||
                                      ''
                                    }
                                    onChange={(event) =>
                                      setValueAdditionalQuestion({
                                        ...valueAdditionalQuestion,
                                        [event.target.name]: [event.target.value],
                                      })
                                    }
                                    labelWidth={0}
                                    input={<Input fullWidth />}
                                  >
                                    {question &&
                                      optionsMonthlyExpenses.map((opt, idx) => {
                                        return (
                                          <MenuItem value={opt.value} key={idx}>
                                            {opt.key}
                                          </MenuItem>
                                        );
                                      })}
                                  </Select>
                                  <FormHelperText error={errors && errors[question.label] && true}>
                                    {(errors && errors[question.label]) || ''}
                                  </FormHelperText>
                                </FormControl>
                              </Grid>
                            );
                          }
                          return (
                            <Grid item xs={12} key={idx}>
                              <FormControl component='fieldset' className={classes.formControl}>
                                <TextField
                                  value={
                                    (valueAdditionalQuestion &&
                                      valueAdditionalQuestion[question.label]) || ['']
                                  }
                                  name={question && question.label}
                                  error={errors && errors[question.label] && true}
                                  label={question && question.question}
                                  id={`${question && question.label}-id`}
                                  helperText={(errors && errors[question.label]) || ''}
                                  onChange={(event) => {
                                    const value = event.target.value;
                                    const checkValue =
                                      value.match(/^[0-9]*$/) && value.charAt(0) !== '0'
                                        ? true
                                        : false;
                                    if (checkValue) {
                                      setValueAdditionalQuestion({
                                        ...valueAdditionalQuestion,
                                        [event.target.name]: [value],
                                      });
                                    }
                                  }}
                                  // type='number'
                                  style={{ paddingTop: 30 }}
                                />
                              </FormControl>
                            </Grid>
                          );
                        }
                        if (question.participantType === 'MULTI_SELECT') {
                          return (
                            <Grid item xs={12} key={idx}>
                              <FormControl component='fieldset' className={classes.formControl}>
                                <FormLabel component='legend'>
                                  {question && question.question}
                                </FormLabel>
                                <Select
                                  name={question && question.label}
                                  multiple
                                  labelId={`${question && question.label}-label`}
                                  id='jobstatus-select-outlined'
                                  value={
                                    (valueAdditionalQuestion &&
                                      valueAdditionalQuestion[question.label]) || ['']
                                  }
                                  onChange={(event) => _setSelectMulti(event, question)}
                                  labelWidth={0}
                                  renderValue={(selected) => selected.join(', ')}
                                  input={<Input fullWidth />}
                                >
                                  {question &&
                                    question.options.map((opt, idx) => {
                                      return (
                                        <MenuItem value={opt.value} key={idx}>
                                          <Checkbox
                                            color='primary'
                                            checked={
                                              valueAdditionalQuestion &&
                                              valueAdditionalQuestion[question.label].indexOf(
                                                opt.value,
                                              ) > -1
                                            }
                                          />
                                          {opt.value}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                                <FormHelperText error={errors && errors[question.label] && true}>
                                  {(errors && errors[question.label]) || ''}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                          );
                        }
                        if (
                          question.participantType === 'DETAILED_ADDRESS' ||
                          question.participantType === 'OPTION_CITY'
                        ) {
                          return (
                            <Grid item xs={12} key={idx}>
                              <FormControl component='fieldset' className={classes.formControl}>
                                <FormLabel component='legend'>
                                  {question && question.question}
                                </FormLabel>
                                <Autocomplete
                                  id='combo-box-cities'
                                  options={cities}
                                  getOptionLabel={(option) => {
                                    if (!option) return '';

                                    if (typeof option === 'string') {
                                      const selectedCity = cities.find(
                                        (city) => city.value === option,
                                      );
                                      return (selectedCity && selectedCity.label) || '';
                                    }

                                    return option.label || '';
                                  }}
                                  style={{ width: '100%' }}
                                  value={
                                    (valueAdditionalQuestion &&
                                      valueAdditionalQuestion[question.label] &&
                                      valueAdditionalQuestion[question.label][0]) ||
                                    ''
                                  }
                                  onChange={(event, selectedCity) => {
                                    setValueAdditionalQuestion({
                                      ...valueAdditionalQuestion,
                                      [question.label]:
                                        (selectedCity &&
                                          selectedCity.label && [
                                            ...selectedCity.label.split(', '),
                                            // array below means district, sub-district, and postal code.
                                            ...['', '', ''],
                                          ]) ||
                                        [],
                                    });
                                  }}
                                  renderInput={(params) => <TextField {...params} fullWidth />}
                                />
                                <FormHelperText error={errors && errors[question.label] && true}>
                                  {(errors && errors[question.label]) || ''}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                          );
                        }
                        return (
                          <Grid item xs={12} key={idx}>
                            <FormControl component='fieldset' className={classes.formControl}>
                              <FormLabel component='legend'>
                                {question && question.question}
                              </FormLabel>
                              <Select
                                name={question && question.label}
                                labelId={`${question && question.label}-label`}
                                id='jobstatus-select-outlined'
                                value={
                                  (valueAdditionalQuestion &&
                                    valueAdditionalQuestion[question.label] &&
                                    valueAdditionalQuestion[question.label][0]) ||
                                  ''
                                }
                                onChange={(event) =>
                                  setValueAdditionalQuestion({
                                    ...valueAdditionalQuestion,
                                    [event.target.name]: [event.target.value],
                                  })
                                }
                                labelWidth={0}
                                input={<Input fullWidth />}
                              >
                                {question &&
                                  question.options.map((opt, idx) => {
                                    return (
                                      <MenuItem value={opt} key={idx}>
                                        {opt}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                              <FormHelperText error={errors && errors[question.label] && true}>
                                {(errors && errors[question.label]) || ''}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        );
                      })}
                    <Button
                      label={'Selanjutnya'}
                      type='primary'
                      onClick={handleNextPage}
                      disabled={isLoadingButton}
                      loader={isLoadingButton}
                    />
                  </Grid>
                </>
              </Modal>
            )}
        </Transition>
        <Transition
          native
          items={showErrorModal}
          from={{ transform: 'translate(0, -100%)' }}
          enter={{ transform: 'translate(0, 0)' }}
          leave={{ transform: 'translate(0, -100%)' }}
        >
          {(showErrorModal) => (modalStyle) =>
            showErrorModal && (
              <ModalError
                handleCloseFunction={() => setShowErrorModal(false)}
                errorMsgHead='Telah terjadi kesalahan saat melakukan submit data'
                errorMsgDetail='Silahkan coba lagi atau hubungi customer service kami'
                dataObjects={{
                  name: profile && profile.name,
                  role: 'dialog',
                  tabIndex: '-1',
                }}
                animatedModal
                style={modalStyle}
                backButtonText='Tutup'
              />
            )}
        </Transition>
      </div>
    </ThemeProvider>
  );
};

export default RenderAdditionalQuestion;
