import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { SliderBtn } from '../../components';
class AvatarImageCropper extends Component {
  color = this.props.isBack ? '#ffffff' : 'rgba(148,148,148,1)';

  ifMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

  iconStyle = {
    display: 'inline-block',
    color: this.color,
    fill: 'currentcolor',
    height: 32,
    width: 32,
    userSelect: 'none'
  };

  textStyle = {
    color: this.color,
    fontSize: '18px'
  };

  rootStyle = {
    textAlign: 'center',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    overflow: 'hidden'
  };

  inputStyle = {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    opacity: 0,
    height: '100%',
    zIndex: 8,
    width: '100%',
    cursor: 'pointer'
  };

  previewStyle = {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 9,
    backgroundRepeat: 'no-repeat',
    cursor: 'move',
    backgroundPosition: '0% 0%',
    borderRadius: '50%'
  };

  cropStyle = {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)'
  };

  avatarStyle = {
    height: '100%',
    display: 'block',
    position: 'relative',
    borderRadius: '50%'
    // backgroundColor: this.props.isBack ? 'rgba(0,0,0,0.4)' : 'transparent'
  };

  sliderConStyle = {
    position: 'absolute',
    top: '15%',
    right: '-9rem',
    left: '6rem',
    zIndex: 9,
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: 'center'
  };

  sliderChildrenDiv = {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    maxWidth: '600px',
    width: '100%'
  };

  btnStyle = {
    display: 'inline-block',
    fontSize: '14px',
    fontWeight: 400,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    cursor: 'pointer',
    border: '1px solid transparent',
    borderRadius: '4px',
    margin: '5px'
  };

  cancelBtnStyle = {
    ...this.btnStyle,
    color: '#333',
    backgroundColor: '#fff',
    borderColor: '#ccc'
  };

  applyBtnStyle = {
    ...this.btnStyle,
    color: '#fff',
    backgroundColor: '#179dfe',
    borderColor: '#179dfe'
  };

  ele = null;

  filename = '';

  avatar2D = {
    width: 0,
    height: 0,
    ratio: 0
  };

  img2D = {
    width: 0,
    height: 0,
    ratio: 0
  };

  origin = {
    width: 0,
    height: 0
  };

  mainButtonWrapper = {
    height: 85,
    width: 83,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    position: 'absolute',
    left: '6.6666rem'
  };
  img = null;

  constructor(props) {
    super(props);
    this.state = {
      preview: null,
      x: 0,
      y: 0,
      relX: 0,
      relY: 0,
      sizeW: 0,
      sizeH: 0,
      errorMsg: ''
    };
  }

  componentDidMount() {
    this.ele = ReactDOM.findDOMNode(this);
    this.avatar2D.width = this.ele.offsetWidth;
    this.avatar2D.height = this.ele.offsetHeight;
    if (this.avatar2D.width < 200) {
      this.sliderChildrenDiv = {
        ...this.sliderChildrenDiv,
        flexDirection: 'column'
      };
    }
  }

  onDrop = evt => {
    const fileList = evt.target.files;
    const acceptedFiles = [];
    const maxsize = this.props.maxsize ? this.props.maxsize : 1024 * 1024 * 2;
    const file = fileList[0];
    if (!file) {
      return;
    }
    const ifImage =
      file.type.indexOf('png') >= 0 ||
      file.type.indexOf('jpg') >= 0 ||
      file.type.indexOf('jpeg') >= 0;

    if (ifImage && file.size <= maxsize) {
      // NOTE Need to implement clear() function to remove existing image
      this.props.clear();
      acceptedFiles.push(file);
      const src = window.URL.createObjectURL(file);
      const img = new Image();
      img.src = src;
      img.onload = () => {
        this.img = img;
        this.img2D.width = img.width;
        this.img2D.height = img.height;
        this.img2D.ratio = img.width / img.height;
        let sizeW =
          this.img2D.ratio >= 1
            ? this.avatar2D.height * this.img2D.ratio
            : this.avatar2D.width;
        sizeW = sizeW < this.avatar2D.width ? this.avatar2D.width : sizeW;
        const sizeH = sizeW / this.img2D.ratio;
        this.setState({
          sizeW: Math.ceil(sizeW),
          sizeH,
          errorMsg: ''
        });
        this.origin = {
          width: sizeW,
          height: sizeH
        };
      };

      file.preview = src;
    } else if (!ifImage) {
      if (this.props.errorHandler) {
        this.props.errorHandler('not_image');
        return;
      } else {
        this.setState({
          errorMsg: 'Please upload png/jpg/jpeg image'
        });
      }
    } else if (file.size > maxsize) {
      if (this.props.errorHandler) {
        this.props.errorHandler('maxsize');
        return;
      } else {
        this.setState({
          errorMsg: 'The size of image is too large'
        });
      }
    }

    if (acceptedFiles.length) {
      this.filename = acceptedFiles[0].name;
      this.setState({ preview: acceptedFiles[0].preview });

      if (this.props.onDrop) {
        this.props.onDrop(acceptedFiles[0]);
      }
    }
  };

  _onMouseDown = e => {
    if (this.ifMobile) {
      this.setState({
        x: e.touches[0].clientX - this.state.relX,
        y: e.touches[0].clientY - this.state.relY
      });
      document.addEventListener('touchmove', this._onMove);
      document.addEventListener('touchend', this._onMouseUp);
    } else {
      this.setState({
        x: e.clientX - this.state.relX,
        y: e.clientY - this.state.relY
      });
      document.addEventListener('mousemove', this._onMove);
      document.addEventListener('mouseup', this._onMouseUp);
    }

    e.preventDefault();
  };

  _onMove = e => {
    const x = this.ifMobile ? e.touches[0].clientX : e.clientX;
    const y = this.ifMobile ? e.touches[0].clientY : e.clientY;
    const relX = this.state.x - x;
    const relY = this.state.y - y;
    if (relX < this.state.sizeW - this.avatar2D.width && relX > 0) {
      this.setState({
        relX: -relX
      });
    }
    if (relY < this.state.sizeH - this.avatar2D.height && relY > 0) {
      this.setState({
        relY: -relY
      });
    }

    e.preventDefault();
  };

  _onMouseUp = e => {
    if (this.ifMobile) {
      document.removeEventListener('touchmove', this._onMove);
      document.removeEventListener('touchend', this._onMouseUp);
    } else {
      document.removeEventListener('mousemove', this._onMove);
      document.removeEventListener('mouseup', this._onMouseUp);
    }

    e.preventDefault();
  };

  _resize = val => {
    const sizeW = this.origin.width * (1 + val / 50);
    const sizeH = this.origin.height * (1 + val / 50);
    const avW = sizeW - this.avatar2D.width;
    const avH = sizeH - this.avatar2D.height;
    const relX = -this.state.relX > avW ? -avW : this.state.relX;
    const relY = -this.state.relY > avH ? -avH : this.state.relY;
    this.setState({
      sizeH,
      sizeW,
      relX,
      relY
    });
  };

  _apply = () => {
    const crop_canvas = document.createElement('canvas');
    crop_canvas.width = this.avatar2D.width;
    crop_canvas.height = this.avatar2D.height;
    const ratio = this.state.sizeW / this.img2D.width;
    crop_canvas
      .getContext('2d')
      .drawImage(
        this.img,
        -this.state.relX / ratio,
        -this.state.relY / ratio,
        this.img2D.width,
        this.img2D.height,
        0,
        0,
        this.state.sizeW,
        this.state.sizeH
      );
    this.props.apply(crop_canvas.toDataURL());
    this.setState({ preview: null });
  };

  _cancel = () => {
    if (
      this.ele.children[0] &&
      this.ele.children[0].children[1] &&
      this.ele.children[0].children[1].value
    ) {
      this.ele.children[0].children[1].value = '';
    }
    this.setState({
      preview: null
    });
    if (this.props.cancel) {
      this.props.cancel();
    }
  };

  render() {
    const { relX, relY, sizeW, sizeH } = this.state;
    const actions =
      (Array.isArray(this.props.actions) &&
        this.props.actions.map((ele, key) => {
          let res = null;
          switch (key) {
            case 0:
              res = React.cloneElement(ele, { onClick: this._cancel });
              break;
            case 1:
              res = React.cloneElement(ele, { onClick: this._apply });
              break;
            default:
              break;
          }
          return res;
        })) ||
      null;

    return (
      <React.Fragment>
        {!this.state.preview && (
          // THE HACKY WAY
          // Basically this 'mainButtonWrapper' is tailored specifically to move the 'Pilih foto' button
          // To the right side of the avatar container
          <div style={{ ...this.mainButtonWrapper }}>
            {' '}
            {this.state.errorMsg && (
              <p style={{ color: 'red' }}>{this.state.errorMsg}</p>
            )}
            <span className="btn btn-file text--light-blue">
              <input
                disabled={this.props.disabled}
                style={{
                  opacity: 0,
                  position: 'absolute',
                  width: '100%',
                  right: 0,
                  height: '28px',
                  zIndex: 10,
                  cursor: 'pointer'
                }}
                type="file"
                accept="image/*"
                onChange={e => {
                  this.onDrop(e);
                }}
              />
              {/* <span>
                <FontAwesomeIcon icon={faPlus} size="sm" />
              </span> */}
              Pilih foto
            </span>
            {/* <button
              type="button"
              className="btn popcorn-btn popcorn-btn--outline mt-4 profile-setting profile-setting__btn-upload--width popcorn-type popcorn-type__f3--bold"
            >

            </button> */}
          </div>
        )}

        <avatar-image
          class={this.props.className}
          style={{ ...this.avatarStyle, ...this.props.avatarStyle }}
        >
          <div style={{ ...this.rootStyle, ...this.props.rootStyle }}>
            {/* <div>
            {!this.props.noWaterMark && (
              <div>
                {this.props.icon ? (
                  this.props.icon
                ) : (
                  <svg viewBox="0 0 24 24" style={{ ...this.iconStyle, ...this.props.iconStyle }}>
                    <circle cx="12" cy="12" r="3.2" />
                    <path d="M9 2L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-3.17L15 2H9zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
                  </svg>
                )}
                <p style={{ ...this.textStyle, ...this.props.textStyle }}>
                  {this.props.text ? this.props.text : 'Upload photo'}
                </p>
              </div>
            )}

            <p style={{ color: 'red' }}>{this.state.errorMsg}</p>
          </div> */}
            {/* <input
            disabled={this.props.disabled}
            style={{ ...this.inputStyle }}
            type="file"
            accept="image/*"
            onChange={(e) => {
              this.onDrop(e);
            }}
          /> */}
            {this.state.preview && (
              <div>
                <div
                  onMouseDown={this._onMouseDown}
                  onTouchStart={this._onMouseDown}
                  style={{
                    ...this.previewStyle,
                    backgroundImage: `url(${this.state.preview})`,
                    backgroundSize: `${sizeW}px ${sizeH}px`,
                    backgroundPosition: `${relX}px ${relY}px`
                  }}
                />
              </div>
            )}
          </div>
          {this.state.preview && (
            <div
              style={{ ...this.sliderConStyle, ...this.props.sliderConStyle }}
            >
              <div
                style={{
                  ...this.sliderChildrenDiv,
                  ...this.props.sliderChildrenDiv
                }}
              >
                <div
                  style={{
                    height: '20px',
                    margin: '5px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    width: '100%'
                  }}
                >
                  <SliderBtn resize={this._resize} />
                </div>
                <div
                  name="action-con"
                  style={{ display: 'flex', minWidth: '100px' }}
                >
                  {actions || [
                    <button
                      type="button"
                      style={{
                        ...this.cancelBtnStyle,
                        ...this.props.cancelBtnStyle
                      }}
                      key={0}
                      onClick={this._cancel}
                    >
                      <svg
                        fill="#000000"
                        height="24"
                        viewBox="0 0 24 24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                        <path d="M0 0h24v24H0z" fill="none" />
                      </svg>
                    </button>,
                    <button
                      type="submit"
                      style={{
                        ...this.applyBtnStyle,
                        ...this.props.applyBtnStyle
                      }}
                      key={1}
                      onClick={this._apply}
                    >
                      <svg
                        fill="#ffffff"
                        height="24"
                        viewBox="0 0 24 24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
                      </svg>
                    </button>
                  ]}
                </div>
              </div>
            </div>
          )}
        </avatar-image>
      </React.Fragment>
    );
  }
}

export default AvatarImageCropper;
