import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Transition, animated } from 'react-spring/renderprops';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Popover, { ArrowContainer } from 'react-tiny-popover';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import congratulationIcon from 'assets/img/congratulation-icon.png';
import finishedIcon from 'assets/img/finish@2x.png';

import {
  ScreeningTypeMultiSelect,
  ScreeningTypeDate,
  ScreeningTypeSelect,
  FormSkeletons,
  ScreeningTypeBasic,
  ScreeningTypeRupiah,
  ModalError,
  ModalSuccess,
} from '../../components';

import defaultBullet from 'assets/img/favicon.png';
import { html, custom_tag, capitalizeFirstLetter, getQuestion } from '../../helpers/generalHelpers';
import { ScreeningTypeRangeSlider } from '../ScreeningTypes/ScreeningTypes';

import { fetchUserProfileSuccess, fetchUserScreening } from '../../store/actions/profileActions';
import { checkAuthDb } from '../../helpers/authHelpers';
import { setQuestion } from '../../store/actions/questionActions';
import PopCall from '../../services/PopCall';

const popCall = new PopCall();

const myInit = {
  headers: {},
};
class QuestionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      toPost: [],
      posting: false,
      error: null,
      show: false,
      loading: false,
      showModalError: false,
      showModalDone: false,
      totalScreeningQuestion: 0,
      dataSaved: false,
      questionList: [],
      criteria: null,
      isPopoverMonthlyExpenses: false,
      uiCriteria: null,
      // editValue: false
    };
  }
  async componentDidMount() {
    this.fetchUserScreening();
  }

  async componentDidUpdate(prevProps, prevState) {
    const { profile, questionTag } = this.props;
    const { email } = profile;
    if (prevProps.questionTag !== questionTag) {
      this.fetchQuestion();
    }
  }

  fetchUserScreening = async () => {
    const { criteria } = this.state;
    this.setState({ loading: true });
    try {
      const userAttributes = await checkAuthDb();
      if (userAttributes) {
        this.props.dispatch(fetchUserProfileSuccess(userAttributes));
        this.props.dispatch(fetchUserScreening(userAttributes.criteria));
      }
      await this.setState((prevState) => {
        return {
          criteria: prevState.criteria || userAttributes.criteria,
        };
      });
      this.fetchQuestion();
    } catch (error) {
      console.error('err', error);
      this.setState({ error: error, loading: false });
    }
  };

  fetchQuestion = async () => {
    await this.setState({ loading: true });
    const { is_loaded, question } = this.props.question;

    const { questionTag } = this.props;
    if (!is_loaded) {
      const response = await getQuestion();
      if (response) {
        const questionList = response.filter(
          (data) => data.tags === capitalizeFirstLetter(questionTag),
        );
        this.setState({ questionList, loading: false });
        //store question to redux
        this.props.dispatch(setQuestion(response));
      }
    } else {
      const questionList = question.filter(
        (data) => data.tags === capitalizeFirstLetter(questionTag),
      );
      this.setState({ questionList, loading: false });
    }
  };

  _setCriteriaState = (label, value) => {
    this.setState((prevState) => {
      return {
        criteria: { ...prevState.criteria, [label]: [value] },
      };
    });
  };

  selectOption = (e, label) => {
    const { target } = e;
    const { value } = target;
    this._setCriteriaState(label, value);
  };

  _selectOptionMulti = (e, label) => {
    const selectedObject = [...e];
    const values =
      Array.isArray(selectedObject) &&
      selectedObject.map((obj) => {
        return obj.value;
      });
    // const { criteria } = this.state;
    // const newVal = { ...criteria, [label]: values };
    // this.setState({ criteria: newVal });
    this.setState((prevState) => {
      return {
        criteria: { ...prevState.criteria, [label]: values },
      };
    });
  };

  _onChangeInputForm = (e, label) => {
    const { target } = e;
    const { value } = target;
    this._setCriteriaState(label, value);
  };

  _onChangeInputNumberForm = async (values, label) => {
    this._setCriteriaState(label, values);
  };

  _onChangeRangeSlider = (value, question_id, label, relationship, mandatory) => {
    // const { target } = e;
    // const { value } = target;
    this.setState((prevState) => {
      return {
        [`sliderStateVal_${question_id}`]: value,
        toPost: [
          ...prevState.toPost.filter((item) => {
            return item.question_id !== question_id;
          }),
          {
            question_id,
            label,
            relationship,
            mandatory,
            values: [`${value}`],
          },
        ],
      };
    });
  };

  _setRangeValue = (targetRange) => {
    targetRange.forEach((item) =>
      this.setState({
        [`sliderStateVal_${item.id}`]: parseInt(item.answers[0]),
      }),
    );
  };

  _toggleFineTune = (e, target_index) => {
    this.setState((prevState) => {
      return {
        [`editValue-${target_index}`]: prevState && !prevState[`editValue-${target_index}`],
      };
    });
  };

  _fineTune = (e, question_id, label, relationship, mandatory) => {
    const { target } = e;
    const { value } = target;
    this.setState((prevState) => {
      return {
        [`sliderStateVal_${question_id}`]: parseInt(value),
        toPost: [
          ...prevState.toPost.filter((item) => item.question_id !== question_id),
          {
            question_id,
            label,
            relationship,
            mandatory,
            values: [`${value}`],
          },
        ],
      };
    });
  };

  _renderLoading = () => {
    return (
      <FormSkeletons>
        {(props) => (
          <div className='skeletons form-skeleton__container d-flex flex-column'>
            <animated.div
              className='skeletons form-skeleton__children'
              style={{ ...props, width: '40%', height: 10 }}
            />
            <animated.div
              className='skeletons form-skeleton__children'
              style={{ ...props, width: '50%', height: 20 }}
            />
            <animated.div
              className='skeletons form-skeleton__children'
              style={{ ...props, width: '40%', height: 10 }}
            />
            <animated.div
              className='skeletons form-skeleton__children'
              style={{ ...props, width: '50%', height: 20 }}
            />
            <animated.div
              className='skeletons form-skeleton__children'
              style={{ ...props, width: '40%', height: 10 }}
            />
            <animated.div
              className='skeletons form-skeleton__children'
              style={{ ...props, width: '50%', height: 20 }}
            />
            <animated.div
              className='skeletons form-skeleton__children'
              style={{ ...props, width: '80%', height: 30 }}
            />
          </div>
        )}
      </FormSkeletons>
    );
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ posting: true });
    const { question } = this.props.question;
    const { questionList, criteria } = this.state;

    const totalAnswer = questionList.filter((question) => {
      return criteria && criteria[question.label];
    });

    const body = {
      criteria,
    };
    const url = '/profile/participant';
    try {
      await popCall.put(url, body);
      await this.setState({
        // show all screening done modal when the total answered is equal to the total screening question
        showModalDone: question.length === Object.keys(criteria).length,
        dataSaved: true,
      });
      setTimeout(() => {
        this.setState({ dataSaved: false }); // We're using this to hide the 'data tersimpan' text
      }, 3500);
      await this.setState((prevState) => {
        return {
          // This one is to show completed modal for individual screening
          show: !prevState.showModalDone && totalAnswer.length === questionList.length,
        };
      });
    } catch (error) {
      this.setState({
        error: error,
        showModalError: true,
      });
    } finally {
      this.setState({ posting: false });
    }
  };

  showModal = () => {
    this.setState({ show: true });
  };

  hideFinishedModal = (e) => {
    const { history, linkTo } = this.props;
    e.preventDefault();
    this.setState({ show: false }, () => {
      history.push(linkTo);
    });
  };

  _togglePopover = (name) => {
    this.setState((prevState) => {
      return { [name]: !prevState[name] };
    });
  };

  hideModal = (e) => {
    e.preventDefault();
    this.setState({ showModalDone: false, show: false });
  };

  hideErrorModal = (e) => {
    e.preventDefault();
    this.setState({ showModalError: false });
  };

  render() {
    const { profile, questionTag, category, linkTo } = this.props;
    const {
      data,
      posting,
      show,
      loading,
      showModalDone,
      showModalError,
      dataSaved,
      sliderStateVal,
      questionList,
      criteria,
      isPopoverMonthlyExpenses,
      uiCriteria,
    } = this.state;
    const { name, family_name } = profile;
    const studyIsNull = Array.isArray(questionList) && questionList.length === 0;
    if (loading) {
      return this._renderLoading();
    }
    return (
      <div className='tab-content'>
        <div className='tab-pane fade show active' id='tab-profesi' role='tabpanel'>
          <div className='body1'>
            <div>
              <div className='header-screening-cluster desktop-disabled'>
                <h3>
                  Screening &bull;&nbsp;
                  {category}
                </h3>
              </div>
              <form onSubmit={this.handleSubmit} className='pb-4'>
                <div className='screening-list'>
                  {Array.isArray(questionList) &&
                    questionList.map((question, index) => {
                      return (
                        <div className='prescreening-item grid-2' key={index}>
                          <span className='icon d-flex align-items-start justify-content-start'>
                            <img
                              src={defaultBullet}
                              alt='bullet'
                              style={{ width: 20, marginTop: '0.2rem' }}
                            />
                          </span>
                          <div>
                            <label className='prescreening__question' htmlFor={question.id}>
                              {question.question}
                            </label>
                            {question.label === 'MonthlyExpenses' && (
                              <Popover
                                isOpen={isPopoverMonthlyExpenses}
                                position={['bottom']}
                                padding={10}
                                onClickOutside={() =>
                                  this.setState({ isPopoverMonthlyExpenses: false })
                                }
                                content={({ position, targetRect, popoverRect }) => (
                                  <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                    position={position}
                                    targetRect={targetRect}
                                    popoverRect={popoverRect}
                                    arrowColor='#10496d'
                                    arrowSize={10}
                                  >
                                    <div
                                      className='row popover-background'
                                      onClick={() =>
                                        this._togglePopover('isPopoverMonthlyExpenses')
                                      }
                                    >
                                      <div className='mr-3'>
                                        <h5>Termasuk:</h5>
                                        <ul>
                                          <li>Makanan sehari-hari</li>
                                          <li>Listrik dan air</li>
                                          <li>Telpon / HP</li>
                                          <li>Gas / Minyak Tanah</li>
                                          <li>Sabun, Kosmetik</li>
                                          <li>Gaji pembantu</li>
                                          <li>Biaya sekolah anak</li>
                                          <li>Transportasi, Bensin</li>
                                          <li>Rokok</li>
                                          <li>Sewa bila dibayar bulanan</li>
                                          <li>Pungutan, premi asuransi yang dibayar bulanan</li>
                                        </ul>
                                      </div>

                                      <div>
                                        <h5>Tidak Termasuk: </h5>
                                        <ul>
                                          <li>Sewa bila dibayar tahunan</li>
                                          <li>Pembayaran cicilan</li>
                                          <li>Furnitur rumah tangga</li>
                                          <li>Peralatan rumah tangga</li>
                                          <li>Rekreasi</li>
                                          <li>Pengeluaran tidak rutin</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </ArrowContainer>
                                )}
                              >
                                <FontAwesomeIcon
                                  icon={faQuestionCircle}
                                  color='#b3b6b7'
                                  style={{ marginLeft: '5px' }}
                                  onMouseEnter={() =>
                                    this.setState({ isPopoverMonthlyExpenses: true })
                                  }
                                  onMouseLeave={() =>
                                    this.setState({ isPopoverMonthlyExpenses: false })
                                  }
                                />
                              </Popover>
                            )}
                            {/* {question.option_type === 1 && // option_type 1: text
                            this._renderTypeText(question)} */}
                            {question.optionType === 2 && (
                              <ScreeningTypeDate
                                questionData={question}
                                onChangeFunction={this.selectOption}
                                className='form-control prescreening__option'
                              /> // option type 2: input date
                            )}
                            {question.optionType === 3 && (
                              <ScreeningTypeSelect
                                questionData={question}
                                onChangeFunction={this.selectOption}
                                className='form-control prescreening__option'
                              /> // option_type 3: radio select
                            )}

                            {question.optionType === 'OPTION_SELECT' && (
                              <ScreeningTypeSelect
                                questionData={question}
                                value={criteria[question.label]}
                                onChangeFunction={this.selectOption}
                                className='form-control prescreening__option'
                              /> // option type 3: radio select
                            )}

                            {question.optionType === 4 && (
                              <ScreeningTypeSelect
                                questionData={question}
                                onChangeFunction={this.selectOption}
                                className='form-control prescreening__option'
                              /> // option_type 4: option select
                            )}
                            {question.optionType === 'MULTI_SELECT' && (
                              <ScreeningTypeMultiSelect
                                questionData={question}
                                value={criteria[question.label]}
                                onChangeFunction={this._selectOptionMulti}
                                classNameParent='form-control screening-multioption mb-2 py-0 pl-2 pr-1'
                                classNameComponent={{
                                  dropDownClassName:
                                    'screening-multioption__dropdown-indicator ml-3',
                                }}
                              /> // option_type 6: multi select
                            )}
                            {question.optionType === 'INPUT_NUMBER' &&
                              (question.label === 'MonthlyExpenses' ? (
                                <ScreeningTypeRupiah
                                  questionData={question}
                                  value={criteria[question.label]}
                                  onChangeFunction={this._onChangeInputNumberForm}
                                  className='form-control prescreening__option'
                                  placeholder='Jawab...'
                                  type='text'
                                />
                              ) : (
                                <ScreeningTypeBasic
                                  questionData={question}
                                  value={criteria[question.label]}
                                  onChangeFunction={this._onChangeInputForm}
                                  className='form-control prescreening__option'
                                  placeholder='Jawab...'
                                  type='number'
                                />
                              ))}
                            {question.optionType === 10 && (
                              <ScreeningTypeRangeSlider
                                onChangeFunction={this._onChangeRangeSlider}
                                questionData={question}
                                value={this.state[`sliderStateVal_${question.id}`] || 1000000}
                                toggleFineTuneFunc={(e) => this._toggleFineTune(e, question.id)}
                                fineTuneFunction={this._fineTune}
                                editValue={this.state[`editValue-${question.id}`]}
                                parentClassName='rangeslider-container'
                                valueClassName='prescreening__option mb-2'
                              />
                            )}
                          </div>
                        </div>
                      );
                    })}
                  {!studyIsNull && (
                    <div className='d-flex flex-row align-items-center'>
                      <button
                        style={{ position: 'relative' }}
                        type='submit'
                        className={`btn btn-primary mt-3
										${posting ? 'text--dark-gray' : 'text--white'} modal-btn`}
                        disabled={posting}
                      >
                        {posting ? 'Memproses' : 'Simpan'}
                      </button>
                      <Transition
                        native
                        items={dataSaved}
                        from={{
                          transform: 'translate(-15px, 0)',
                          opacity: 0,
                          position: 'absolute',
                        }}
                        enter={{
                          transform: 'translate(0, 0)',
                          opacity: 1,
                          position: 'relative',
                        }}
                        leave={{
                          transform: 'translate(-5px, 0)',
                          opacity: 0,
                          // position: 'absolute'
                        }}
                      >
                        {(showSavedNotif) => (styleProps) =>
                          showSavedNotif && (
                            <animated.label
                              className='text-color--primary font-bold ml-2 mb-0 mt-3'
                              style={{ ...styleProps }}
                            >
                              Data tersimpan
                            </animated.label>
                          )}
                      </Transition>
                    </div>
                  )}
                  <Transition
                    native
                    items={show}
                    from={{ transform: 'translate(0, -100%)' }}
                    enter={{ transform: 'translate(0,0)' }}
                    leave={{ transform: 'translate(0, -100%)' }}
                  >
                    {(showModal) => (modalStyle) =>
                      showModal && (
                        <ModalSuccess
                          animatedModal
                          style={modalStyle}
                          successMsgHead={`Selamat ${name} ${family_name}`}
                          successMsgDetail={`Kamu telah menyelesaikan screening dari kategori ${category}. Segera lengkapi kategori yang lain agar kamu bisa memperbesar peluangmu untuk mendapatkan studi lebih banyak.`}
                          handleClose={this.hideModal}
                          handleContinue={this.hideFinishedModal}
                          mainImage={congratulationIcon}
                        />
                        // <Modal
                        //   className="modal fade"
                        //   id="test-modal"
                        //   tabIndex="-1"
                        //   role="dialog"
                        //   show={this.state.show}
                        //   handleClose={this.hideModal}
                        //   nameData={first_name}
                        //   style={modalStyle}
                        //   category={category}
                        //   LinkTo={linkTo}
                        // />
                      )}
                  </Transition>

                  <Transition
                    native
                    items={showModalDone}
                    from={{ transform: 'translate(0, -100%)' }}
                    enter={{ transform: 'translate(0,0)' }}
                    leave={{ transform: 'translate(0, -100%)' }}
                  >
                    {(showModalDone) => (modalStyle) =>
                      showModalDone && (
                        <ModalSuccess
                          animatedModal
                          style={modalStyle}
                          successMsgHead={`Selamat ${name} ${family_name}`}
                          successMsgDetail={`Kamu sudah mengisi semua biodata kamu secara full. Sekarang tinggal menunggu untuk survei yang sesuai!`}
                          handleClose={this.hideModal}
                          handleContinue={this.hideModal}
                          mainImage={finishedIcon}
                        />
                        // <ModalFinished
                        //   className="modal fade"
                        //   id="test-modal"
                        //   tabIndex="-1"
                        //   role="dialog"
                        //   show={this.state.show}
                        //   handleClose={this.hideFinishedModal}
                        //   nameData={first_name}
                        //   style={modalStyle}
                        //   category={category}
                        //   LinkTo={linkTo}
                        // />
                      )}
                  </Transition>

                  <Transition
                    native
                    items={showModalError}
                    from={{ transform: 'translate(0, -100%)' }}
                    enter={{ transform: 'translate(0,0)' }}
                    leave={{ transform: 'translate(0, -100%)' }}
                  >
                    {(showModalError) => (modalStyle) =>
                      showModalError && (
                        <ModalError
                          animatedModal
                          backButtonText='Tutup'
                          dataObjects={{
                            name: `${name} ${family_name}`,
                          }}
                          errorMsgHead='Telah terjadi kesalahan saat menyimpan data kamu'
                          errorMsgDetail='Silahkan coba lagi atau hubungi customer service kami'
                          handleCloseFunction={this.hideErrorModal}
                          style={modalStyle}
                        />
                        // <ErrorModal
                        //   className="modal fade"
                        //   id="test-modal"
                        //   tabIndex="-1"
                        //   role="dialog"
                        //   show={this.state.show}
                        //   handleClose={this.hideErrorModal}
                        //   nameData={first_name}
                        //   style={modalStyle}
                        //   category={category}
                        //   LinkTo={linkTo}
                        // />
                      )}
                  </Transition>

                  <div
                    id='modal-backdrop'
                    className='modal-backdrop-transparent modal-transition'
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile.userProfile,
    question: state.question,
  };
};
export default connect(mapStateToProps)(QuestionList);

/* <select
className="form-control prescreening__option"
onChange={e => {
	this.selectOption(
		e,
		question.id,
		question.label,
		question.relationship
	);
}}
>
{question.options.map(option => (
	<option value={option.option_text} key={option.id}>
		{option.option_text}
	</option>
))}
</select> */
