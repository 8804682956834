import palette from '../palette';
import typography from '../typography';

export default {
  root: {
    ...typography.body1,
    borderBottom: `1px solid ${palette.divider}`,
  },
  head: {
    padding: '15px 10px 15px 20px',
    fontWeight: 600,
  },
};
