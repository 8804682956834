import React, { Component } from 'react';
import { BrowserRouter as Router, Route, NavLink, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
  SettingsAccount,
  SettingsBankAccount,
  SettingsNotification,
  SettingsPassword,
  SettingsProfile,
} from '../index';

const settingRoutes = [
  {
    label: 'Edit Profil',
    navLink: '/settings/profile',
  },
  {
    label: 'Kata sandi',
    navLink: '/settings/password',
  },
  {
    label: 'Notifikasi',
    navLink: '/settings/notification',
  },
  {
    label: 'Rekening bank',
    navLink: '/settings/bank-account',
  },
  // {
  //   label: 'Kontak',
  //   navLink: '/settings/account',
  // },
];
class Settings extends Component {
  render() {
    const { authProvider } = this.props;
    return (
      <Router>
        <div>
          <nav className='tab'>
            <div
              className='tab-nav nav nav-tabs justify-content-left no-wrap scroll-enabled no-scrollbar'
              id='content-tab'
              role='tablist'
            >
              {Array.isArray(settingRoutes) &&
                settingRoutes.map((route, key) => (
                  <NavLink
                    key={key}
                    exact
                    className={classNames('nav-item nav-link nav-link--flex', {
                      'display-none': authProvider === 'Facebook' && route.label === 'Kata sandi',
                    })}
                    to={route.navLink}
                  >
                    {route.label}
                  </NavLink>
                ))}
            </div>
          </nav>
          {/* Body without Floating Card */}
          <div className='body1'>
            <div className='tab-content'>
              <Route exact path='/settings/profile' component={SettingsProfile} />
              {authProvider !== 'Facebook' && (
                <Route exact path='/settings/password' component={SettingsPassword} />
              )}
              <Route exact path='/settings/notification' component={SettingsNotification} />
              <Route exact path='/settings/bank-account' component={SettingsBankAccount} />
              {/* <Route exact path='/settings/account' component={SettingsAccount} /> */}
            </div>
          </div>
        </div>
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authProvider: state.auth.authProvider,
  };
};

export default connect(mapStateToProps)(Settings);
