import React from 'react'
import useStyles from './styles'
import Header from './Header/Header'
import { Box, Container, Typography } from '@material-ui/core'
import PopcornSad from 'assets/images/popcorn-sad.png'

const Success = () => {
  const classes = useStyles()
  return (
    <Box className={classes.container}>
      <Header />
        <Container className={classes.content} maxWidth="lg" > 
          <img src={PopcornSad} alt="popcorn" height={250} width={250} />
          <Typography variant='h2' className={classes.title}>
            Kamu telah berhenti berlangganan
          </Typography>
          <Typography variant='body1' className={classes.description}>
            Kamu telah sukses berhenti berlangganan email dari Populix. Bila terjadi error dalam email langganan kami, 
            silakan hubungi <a href="mailto:support@populix.co" className={classes.mailText}>support@populix.co</a>.
          </Typography>
        </Container>
    </Box>
  )
}

export default Success