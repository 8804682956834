import React, { Component, Fragment } from 'react';
import { animated } from 'react-spring';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { BrowserRouter as Router, Route, NavLink } from 'react-router-dom';
import { FormSkeletons } from '../../components';

import { RupiahParser, FormatMonth } from '../../helpers/generalHelpers';
import PopCall from '../../services/PopCall';

const popCall = new PopCall();
class TransactionList extends Component {
  state = {
    loading: true,
    all_transactions: [],
    in_transactions: [],
    out_transactions: [],
    error: null,
  };

  async componentDidMount() {
    const { profile } = this.props;
    const { email } = profile;
    const url = `/participant/transactionhistory`;
    // const getSettings = {
    //   headers: {},
    // };
    try {
      const transactionList = await popCall.get(url); // I think getSettings is unnecessary for now
      const { data } = transactionList;
      const inTransactions = data.filter((filteredData) => filteredData.type === 'IN');
      const outTransactions = data.filter((filteredData) => filteredData.type === 'OUT');
      this.setState({
        all_transactions: data,
        in_transactions: inTransactions,
        out_transactions: outTransactions,
      });
    } catch (error) {
      this.setState({ error });
    } finally {
      this.setState({ loading: false });
    }
  }

  _renderLoading = () => {
    return (
      <FormSkeletons native>
        {(props) => (
          <div>
            <Fragment>
              <animated.div
                className='skeletons form-skeleton__children'
                style={{ ...props, width: '100%', height: 20 }}
              />
              <animated.div
                className='skeletons form-skeleton__children'
                style={{ ...props, width: '60%', height: 20 }}
              />
              <animated.div
                className='skeletons form-skeleton__children'
                style={{ ...props, width: '20%', height: 20 }}
              />
              <hr />
              <animated.div
                className='skeletons form-skeleton__children'
                style={{ ...props, width: '100%', height: 20 }}
              />
              <animated.div
                className='skeletons form-skeleton__children'
                style={{ ...props, width: '60%', height: 20 }}
              />
              <animated.div
                className='skeletons form-skeleton__children'
                style={{ ...props, width: '20%', height: 20 }}
              />
              <hr />
            </Fragment>
          </div>
        )}
      </FormSkeletons>
    );
  };

  render() {
    const { loading } = this.state;
    return (
      <Router>
        <div className='body-content'>
          <nav className='tab'>
            <div
              className='tab-nav nav nav-tabs justify-content-left'
              id='content-tab'
              role='tablist'
            >
              <NavLink
                className='nav-item nav-link'
                activeClassName='active'
                data-toggle='tab'
                to='/transactions/all'
                role='tab'
              >
                Seluruhnya
              </NavLink>
              <NavLink
                className='nav-item nav-link'
                data-toggle='tab'
                activeClassName='active'
                to='/transactions/income'
                role='tab'
              >
                Pemasukan
              </NavLink>
              <NavLink
                className='nav-item nav-link'
                data-toggle='tab'
                activeClassName='active'
                to='/transactions/withdrawal'
                role='tab'
              >
                Penarikan
              </NavLink>
            </div>
          </nav>

          <div className='tab-content'>
            <div className='tab-pane fade show active'>
              <div className='body1'>
                {/* Icon Swimming */}
                {/* <img className="img-lg" src={SwimmingIcon} /> */}

                {/* <FloatingCard /> */}

                {/* Balance History */}
                <div className='balance-list'>
                  <Route
                    exact
                    path='/transactions/all'
                    render={() =>
                      loading ? (
                        this._renderLoading()
                      ) : (
                        <All all_transactions={this.state.all_transactions} />
                      )
                    }
                  />
                  <Route
                    path='/transactions/income'
                    render={() =>
                      loading ? (
                        this._renderLoading()
                      ) : (
                        <Income in_transactions={this.state.in_transactions} />
                      )
                    }
                  />
                  <Route
                    path='/transactions/withdrawal'
                    render={() =>
                      loading ? (
                        this._renderLoading()
                      ) : (
                        <Withdrawal out_transactions={this.state.out_transactions} />
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Router>
    );
  }
}

function All({ all_transactions }) {
  return (
    <div>
      {Array.isArray(all_transactions) && all_transactions.length === 0 && (
        <h3>SAAT INI, BELUM ADA RIWAYAT SALDO YANG TERSEDIA BAGI KAMU</h3>
      )}
      {Array.isArray(all_transactions) &&
        all_transactions.map((transaction) => (
          <div className='balance-item' key={transaction.id}>
            <div className='balance__detail'>
              <h4 className='balance__title font-bold'>{transaction.remarks}</h4>
              <span className='balance__date font-semibold'>
                {FormatMonth(transaction.transactionDate)}
              </span>
              <span className='balance__time font-semibold'>
                Pukul {transaction.transactionTime}
              </span>
              <span
                className={classNames('balance__status', {
                  'status--pending px-2':
                    transaction.status === 'Pending' || transaction.status === 'On Process',
                  'status--success':
                    transaction.status === 'Success' ||
                    transaction.status === 'Approved' ||
                    transaction.status === 'Refund',
                  'status--rejected': transaction.status === 'Rejected',
                })}
              >
                {transaction.status === 'On Process' ? 'PENDING' : transaction.status}
              </span>
            </div>
            <h4 className='balance__value font-bold'>{RupiahParser(transaction.amount)}</h4>
          </div>
        ))}
    </div>
  );
}

function Income({ in_transactions }) {
  return (
    <div>
      {Array.isArray(in_transactions) && in_transactions.length === 0 && (
        <h3>SAAT INI, BELUM ADA RIWAYAT SALDO PEMASUKAN YANG TERSEDIA BAGI KAMU</h3>
      )}
      {Array.isArray(in_transactions) &&
        in_transactions.map((transaction) => (
          <div className='balance-item' key={transaction.id}>
            <div className='balance__detail'>
              <h4 className='balance__title font-bold'>{transaction.remarks}</h4>
              <span className='balance__date font-semibold'>
                {FormatMonth(transaction.transactionDate)}
              </span>
              <span className='balance__time font-semibold'>
                Pukul {transaction.transactionTime}
              </span>
              <span
                className={classNames('balance__status', {
                  'status--pending px-2': transaction.status === 'Pending',
                  'status--success':
                    transaction.status === 'Success' ||
                    transaction.status === 'Approved' ||
                    transaction.status === 'Refund',
                  'status--rejected': transaction.status === 'Rejected',
                })}
              >
                {transaction.status}
              </span>
            </div>

            <h4 className='balance__value font-bold'>{RupiahParser(transaction.amount)}</h4>
          </div>
        ))}
    </div>
  );
}

function Withdrawal({ out_transactions }) {
  return (
    <div>
      {Array.isArray(out_transactions) && out_transactions.length === 0 && (
        <h3>SAAT INI, BELUM ADA RIWAYAT SALDO PENARIKAN YANG TERSEDIA BAGI KAMU</h3>
      )}
      {Array.isArray(out_transactions) &&
        out_transactions.map((transaction) => (
          <div className='balance-item' key={transaction.id}>
            <div className='balance__detail'>
              <h4 className='balance__title font-bold'>{transaction.remarks}</h4>
              <span className='balance__date font-semibold'>
                {FormatMonth(transaction.transactionDate)}
              </span>
              <span className='balance__time font-semibold'>
                Pukul {transaction.transactionTime}
              </span>
              <span
                className={classNames('balance__status', {
                  'status--pending px-2':
                    transaction.status === 'Pending' || transaction.status === 'On Process',
                  'status--success':
                    transaction.status === 'Success' ||
                    transaction.status === 'Approved' ||
                    transaction.status === 'Refund',
                  'status--rejected': transaction.status === 'Rejected',
                })}
              >
                {transaction.status === 'On Process' ? 'PENDING' : transaction.status}
              </span>
            </div>
            <h4 className='balance__value font-bold'>{RupiahParser(transaction.amount)}</h4>
          </div>
        ))}
    </div>
  );
}

// Might want to be more detail on the state

const mapStateToProps = (state) => {
  return {
    profile: state.profile.userProfile,
  };
};

export default connect(mapStateToProps)(TransactionList);
