import React from 'react';
import { Modal, Button } from '@material-ui/core';
import useStyles from './styles';

const ConfirmLocationModal = ({
  isConfirmLocationModalOpen,
  onClose,
  onClickNext,
  onClickBack,
}) => {
  const classes = useStyles();

  return (
    <Modal open={isConfirmLocationModalOpen} onClose={onClose}>
      <div className={classes.modalConfirmLocation}>
        <h1 className={classes.caption}>Aktifkan Lokasi</h1>
        <p className={classes.description}>
          Segera memulai survei dengan mengaktifkan lokasimu. Kami menjamin seluruh kerahasiaan data
          dan informasi responden.
        </p>
        <Button
          type='submit'
          fullWidth
          variant='contained'
          color='primary'
          className={classes.submit}
          style={{ textTransform: 'none' }}
          onClick={onClickNext}
        >
          Ya, lanjutkan
        </Button>
        <Button
          fullWidth
          variant='text'
          color='primary'
          style={{ textTransform: 'none' }}
          onClick={onClickBack}
        >
          <span className={classes.primary}>Kembali</span>
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmLocationModal;
