import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: '#FBFBFB',
  },
  paper: {
    width: 360,
    minHeight: 640,
    backgroundColor: '#FBFBFB',
    padding: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  populixLogo: {
    width: 121,
    height: 32.54,
    marginRight: 'auto',
    marginBottom: 16,
  },
  populixLogoFooter: {
    width: 50,
    height: 50,
    marginRight: 20,
  },
  spinnerWrapper: {
    margin: '80px 0px 40px 0px',
    color: '#EE7824',
  },
  text: {
    fontWeight: 600,
  },
  descriptionText: {
    ...theme.typography.body2,
    fontSize: 10,
    fontWeight: 300,
    color: '#333333',
    textAlign: 'start',
    lineHeight: '18px',
  },
  traceIDText: {
    ...theme.typography.body2,
    fontSize: 10,
    fontWeight: 300,
    color: '#333333',
    textAlign: 'start',
    lineHeight: '18px',
    marginTop: 10,
    marginBottom: 10,
  },
  card: {
    width: 312,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 20,
    backgroundColor: '#FFFFFF',
    borderRadius: 20,
    border: '1px solid #E0E0E0',
    marginTop: 6,
    marginBottom: 16,
  },
  boldCaption: {
    color: '#333333',
    paddingBottom: 12,
    fontWeight: 600,
    fontSize: 12,
  },
  rewardFigure: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
  appSource: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    gap: 20,
  },
  appSourceLogo: {
    width: 125,
    height: 40,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  popcornImage: {
    width: 160,
    height: 149.71,
    marginBottom: 20,
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 'auto',
  },
  mainContentTextTitle: {
    fontWeight: 'bold',
    color: '#333333',
  },
  mainContentText: {
    ...theme.typography.subtitle2,
    color: '#4F4F4F',
    fontSize: 13,
    marginTop: 12,
    textAlign: 'center',
  },
  dividerLine: {
    color: '#E0E0E0',
    border: '1px solid',
    width: '100%',
  },
  referralCaption: {
    color: '#4F4F4F',
    fontSize: 10,
    fontWeight: 300,
    marginBottom: 8,
    marginRight: 'auto',
  },
  referralFigure: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
    alignItems: 'center',
    width: '100%',
  },
  referralCard: {
    padding: 8,
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 10,
    border: '1px solid #E0E0E0',
    width: '60%',
    justifyContent: 'center',
  },
  referralId: {
    fontSize: 18,
    fontWeight: 600,
  },
  button: {
    borderRadius: 100,
    backgroundColor: '#EE7824',
    padding: '8px 12px 8px 12px',
    border: 0,
  },
  buttonCaption: {
    fontSize: 12,
    fontWeight: '600',
    color: '#FFFFFF',
  },
}));

export default useStyles;
