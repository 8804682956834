import React, { Component } from 'react';
import { StaticTemplateSuccess } from '../../components';
import popcornMoney from 'assets/img/popcorn-money-2.png';

class DonateCompleted extends Component {
  componentDidMount() {
    sessionStorage.removeItem('payoutRequestMethod');
  }

  render() {
    return (
      <div className='lp-body-section App'>
        <div id='home--hero' className='lp-header lp-header--nobg'>
          <div className='lp-header--inner'>
            <StaticTemplateSuccess
              mainImage={popcornMoney}
              mainText='Donasi sudah diproses…'
              subText="Terima kasih atas donasi kamu"
              buttonText='Menu Utama'
              redirectURL='/study'
            />
          </div>
        </div>
      </div>
    )
  }
}

export default DonateCompleted;
