import { SET_FEATURE_FLAG, CLOSE_BANNER_INFO, CLOSE_BANNER_PROMO } from './actionTypes';

const setFeatureFlag = (value) => {
  return {
    type: SET_FEATURE_FLAG,
    payload: value,
  };
};

const closeBannerInfo = () => {
  return {
    type: CLOSE_BANNER_INFO,
  };
};

const closeBannerPromo = () => {
  return {
    type: CLOSE_BANNER_PROMO,
  };
};

export { setFeatureFlag, closeBannerInfo, closeBannerPromo };
