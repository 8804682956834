import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class LpFooter extends Component {
  render() {
    return (
      <div className='lp-footer'>
        <div className='footer--link'>
          <ul>
            <li className='link-item'>
              <Link to='/faq'>FAQ</Link>
            </li>
            <li className='link-item'>
              <Link to='/career'>Karir</Link>
            </li>
            <li className='link-item'>
              <Link to='/privacy-policy'>Kebijakan Privasi</Link>
            </li>
            <li className='link-item'>
              <Link to='https://info.populix.co/syarat-ketentuan'>Syarat &amp; Ketentuan</Link>
            </li>
            <li className='link-item'>
              <Link to='/contact-us'>Kontak</Link>
            </li>
          </ul>
        </div>
        <div className='footer--copyright d-flex flex-direction-row align-items-center'>
          <p className='copyright-text'>© 2018. All Right Reserved. Populix</p>
          <div className='mt-1'>
            <a
              href='https://www.instagram.com/populix.co/?hl=en'
              className='lp-footer__social-media-button mx-2'
              target='_blank'
              rel='noopener noreferrer'
            >
              <i className='fa fa-instagram fa-lg' style={{ color: 'black' }} />
            </a>
            <a
              href='https://www.linkedin.com/company/populix/'
              className='lp-footer__social-media-buttonfooter-social-media'
              target='_blank'
              rel='noopener noreferrer'
            >
              <i className='fa fa-linkedin fa-lg' style={{ color: 'black' }} />
            </a>
          </div>
        </div>
      </div>
    );
  }
}
