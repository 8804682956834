import {
  GET_STUDIES_FAILED,
  GET_STUDIES_SUCCESS,
  GET_STUDY_SUCCESS,
  GET_STUDY_FAILED,
  PURGE_PROFILE,
  PURGE,
} from '../actions/actionTypes';

const initialState = {
  studies: [],
  studyObj: {},
  error: null,
};

export default function reducers(state = initialState, actions) {
  const { payload, type } = actions;
  switch (type) {
    case GET_STUDIES_SUCCESS:
      return { ...state, studies: payload, studyObj: {}, error: null };
    case GET_STUDIES_FAILED:
      return { ...state, error: payload, studyObj: {}, studies: [] };
    case GET_STUDY_SUCCESS:
      return { ...state, studyObj: payload, error: null };
    case GET_STUDY_FAILED:
      return { ...state, error: payload, studyObj: {} };
    case PURGE:
      return { ...state, ...initialState };
    default:
      return state;
  }
}
