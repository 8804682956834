import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import {
  ValidateFileSize,
  AcceptedImageFile,
  ValidateImageFile,
} from '../../helpers/generalHelpers';

const UploaderKTP = (props) => {
  const { loading, onUpload, srcKTP, name } = props;
  const [fileSizeExeeced, setFileSizeExceeded] = useState(false);
  const [isNotImageFile, setIsNotImageFile] = useState(false);

  const _uploadFile = async (e) => {
    const { target } = e;
    const { files } = target;
    const reader = new FileReader();
    try {
      setFileSizeExceeded(false);
      setIsNotImageFile(false);
      await ValidateImageFile(files[0].type);
      await ValidateFileSize(files[0]);
      reader.readAsDataURL(files[0]);
      reader.onload = async () => {
        await onUpload(reader.result);
      };
    } catch (error) {
      if (error === 'File is not image') {
        console.error(error);
        setIsNotImageFile(true);
      } else {
        console.error('File size exceeded (maximum 10 MB).');
        setFileSizeExceeded(true);
      }
    }
  };

  return (
    <div
      id='prescreening'
      className='d-flex align-items-center justify-content-center'
      style={{ height: '40vh' }}
    >
      <div
        className='prescreening__ktp-height h-75'
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          border: '3px dashed #E0E0E0',
          width: '100%',
          margin: '5% 0',
        }}
      >
        {loading && <CircularProgress size={30} />}
        {!loading && (
          <div
            className='d-flex flex-column align-items-center justify-content-center'
            style={{ maxHeight: '100%' }}
          >
            {srcKTP && (
              <img
                src={srcKTP}
                alt={srcKTP ? `ktp-${name}` : null}
                style={{ maxHeight: '85%', maxWidth: '90%' }}
              />
            )}
            <input
              type='file'
              name='ktp_upload'
              id='ktp_upload'
              className='prescreening__ktp prescreening__ktp-height'
              accept={AcceptedImageFile}
              onChange={_uploadFile}
              style={{
                width: '100%',
              }}
            />
            <label htmlFor='ktp_upload'>Click to Browse File to Upload</label>
          </div>
        )}
      </div>
      {isNotImageFile && (
        <p style={{ color: 'red', textAlign: 'center', fontSize: '12px' }}>
          Maaf, File KTP yang diterima hanya file Gambar dengan format JPG atau PNG. Mohon periksa
          kembali.
        </p>
      )}
      {fileSizeExeeced && (
        <p style={{ color: 'red', textAlign: 'center', fontSize: '12px' }}>
          Maaf, ukuran file terlalu besar (maksimum file 10 MB).
        </p>
      )}
    </div>
  );
};

UploaderKTP.propTypes = {
  loading: PropTypes.bool,
  onUpload: PropTypes.func,
  srcKTP: PropTypes.string,
  name: PropTypes.string,
};

export default UploaderKTP;
