import React, { Suspense } from 'react';
import { animated } from 'react-spring/renderprops';

export const PreScreeningWrappers = ({ children, prescreeningId, style, className }) => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <animated.div
        id={`prescreening-${prescreeningId}`}
        className={`prescreening-item ${className}`}
        style={{ ...style }}
      >
        {children}
      </animated.div>
    </Suspense>
  );
};
