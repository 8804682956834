import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: '#FFFFFF',
    height: 100,
    display: 'flex',
    justifyContent: 'center'
  },
  toolbar: {
    paddingLeft: 80,
    paddingRight: 80,
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    }
  }
}))

export default useStyles