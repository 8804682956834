import React, { Component } from 'react';
import Auth from '@aws-amplify/auth';
import { connect } from 'react-redux';
import {
  showFailedNotification,
  showSuccessNotification,
  hideNotification,
} from '../../store/actions/profileActions';

class SettingsPassword extends Component {
  state = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
    showPassword: {
      oldPwd: false,
      newPwd: false,
      confirmPwd: false,
    },
  };

  _toggleShowPassword = (target) => {
    this.setState((prevState) => {
      return {
        showPassword: {
          ...prevState.showPassword,
          [target]: !prevState.showPassword[target],
        },
      };
    });
  };

  _onChangeForm = (e) => {
    const { target } = e;
    const { value, name } = target;
    this.setState({
      [name]: value,
    });
  };

  _onSubmit = () => {
    const { dispatch } = this.props;
    const { oldPassword, newPassword, confirmNewPassword } = this.state;
    const inValid = /\s/;

    if (newPassword !== confirmNewPassword) {
      dispatch(showFailedNotification('Password yang anda masukan tidak sama'));
      setTimeout(() => {
        dispatch(hideNotification());
      }, 2500);
    }

    if (inValid.test(newPassword)) {
      dispatch(showFailedNotification('Password yang anda masukan tidak boleh ada spasi'));
      setTimeout(() => {
        dispatch(hideNotification());
      }, 2500);
    }
    Auth.currentAuthenticatedUser()
      .then(async (user) => {
        const authChangePassword = await Auth.changePassword(user, oldPassword, newPassword);
        if (authChangePassword) {
          dispatch(showSuccessNotification());
          setTimeout(() => {
            dispatch(hideNotification());
          }, 2500);
        }
      })
      .catch((error) => {
        dispatch(showFailedNotification('Error, gagal merubah password'));
        setTimeout(() => {
          dispatch(hideNotification());
        }, 2500);
      });
  };

  render() {
    const { showPassword } = this.state;
    return (
      <div>
        <div
          className='tab-pane fade show active'
          id='password'
          role='tabpanel'
          aria-labelledby='nav-tab-password'
        >
          <div className='settings-grid'>
            {/* Password Settings Form */}
            <div className='password-settings-form'>
              {/* Old Password */}
              <label htmlFor='password-old' className='font-bold'>
                Kata sandi lama
              </label>
              <div className='input-group mb-3'>
                <input
                  type={showPassword.oldPwd ? 'text' : 'password'}
                  name='oldPassword'
                  onChange={this._onChangeForm}
                  className='form-control form-password settings__input'
                />
                <div className='input-group-append'>
                  <span className='input-group-text'>
                    <i
                      onClick={() => this._toggleShowPassword('oldPwd')}
                      className={`form-control-feedback fa ${
                        showPassword.oldPwd ? 'fa-eye' : 'fa-eye-slash'
                      } form-icon-eye`}
                    />
                  </span>
                </div>
              </div>
              {/* New Password */}
              <label htmlFor='password-new' className='font-bold'>
                Kata sandi baru
              </label>
              <div className='input-group mb-3'>
                <input
                  type={showPassword.newPwd ? 'text' : 'password'}
                  name='newPassword'
                  onChange={this._onChangeForm}
                  className='form-control form-password settings__input'
                />
                <div className='input-group-append'>
                  <span className='input-group-text'>
                    <i
                      onClick={() => this._toggleShowPassword('newPwd')}
                      className={`form-control-feedback fa ${
                        showPassword.newPwd ? 'fa-eye' : 'fa-eye-slash'
                      } form-icon-eye`}
                    />
                  </span>
                </div>
              </div>
              {/* Confirm New Password*/}
              <label htmlFor='password-confirm' className='font-bold'>
                Konfirmasi kata sandi baru
              </label>
              <div className='input-group mb-3'>
                <input
                  type={showPassword.confirmPwd ? 'text' : 'password'}
                  name='confirmNewPassword'
                  onChange={this._onChangeForm}
                  className='form-control form-password settings__input'
                />
                <div className='input-group-append'>
                  <span className='input-group-text'>
                    <i
                      onClick={() => this._toggleShowPassword('confirmPwd')}
                      className={`form-control-feedback fa ${
                        showPassword.confirmPwd ? 'fa-eye' : 'fa-eye-slash'
                      } form-icon-eye`}
                    />
                  </span>
                </div>
              </div>
              {/* Button Save */}
              <button className='btn btn-primary' onClick={this._onSubmit}>
                Simpan
              </button>
              <p className='f-12 pt-2'>
                Lupa kata sandi?
                <a href='/forgot-password' className='text--light-blue font-bold ml-1'>
                  Atur ulang kata sandi via email
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(SettingsPassword);
