import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Transition, animated } from 'react-spring/renderprops';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import populixLogo from 'assets/img/logo.png';
import { getUserSaldo, withdrawSaldo } from '../../store/actions/saldoActions';
import { FormSkeletons, ModalInput, ModalError, ModalPrompt, ProtectedBtn } from '../../components';
import { RupiahParser, toCurrency, toDecimal } from '../../helpers/generalHelpers';
// import profilePicture from "assets/avatar/2.jpg";
const defaultImage = require('../../assets/img/default-img-02.png');

class SidebarNav extends Component {
  state = {
    showTarikSaldoModal: false,
    showConfirmationModal: false,
    payoutNominal: 0,
    uiPayoutNominal: 0,
    password: '',
    showPassword: false,
    showCongratulationModal: false,
    showErrorModal: false,
    showNoBankAccountModal: false,
    validationMessage: '',
    formValidationFailed: false,
    uiStates: {
      loadingSaldo: false,
      postingPayout: false,
    },
    error: null,
  };

  async componentDidMount() {
    const { profile } = this.props;
    const getSaldo = this.props.getUserSaldo;
    const { email } = profile;
    this.setState((prevState) => {
      return {
        uiStates: {
          ...prevState.uiStates,
          loadingSaldo: true,
        },
      };
    });
    try {
      await getSaldo();
    } catch (error) {
      this.setState({
        error: error,
      });
    } finally {
      this.setState((prevState) => {
        return {
          uiStates: {
            ...prevState.uiStates,
            loadingSaldo: false,
          },
        };
      });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const { postingPayoutStatus } = this.props.wallet;
    if (prevProps.wallet.postingPayoutStatus !== postingPayoutStatus) {
      if (postingPayoutStatus === 'success') {
        this.setState({
          uiStates: {
            ...prevState.uiStates,
            loadingSaldo: true,
          },
        });
        try {
          await this.props.getUserSaldo();
        } catch (error) {
          this.setState({ error: error });
        } finally {
          this.setState({
            uiStates: {
              ...prevState.uiStates,
              loadingSaldo: false,
            },
          });
        }
      }
    }
  }

  _showModal = () => {
    this.setState({ showTarikSaldoModal: true });
  };

  _navigateToPayoutRequest = () => {
    this.props.history.push('/payoutrequest');
  };

  _handleOnPressTarikDana = () => {
    const { wallet } = this.props;
    const { payoutNominal } = this.state;
    const { saldo } = wallet;
    if (payoutNominal < 50000) {
      this.setState(
        {
          validationMessage: 'Minimum jumlah penarikan adalah Rp50.000',
          formValidationFailed: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              validationMessage: '',
              formValidationFailed: false,
            });
          }, 2500);
        },
      );
    } else if (payoutNominal > saldo) {
      this.setState(
        {
          formValidationFailed: true,
          validationMessage: 'Saldo tidak cukup!',
        },
        () => {
          setTimeout(() => {
            this.setState({
              formValidationFailed: false,
              validationMessage: '',
            });
          }, 3000);
        },
      );
    } else {
      this.setState({
        showTarikSaldoModal: false,
        showConfirmationModal: true,
      });
    }
  };

  _handleOnPressBatal = () => {
    this.setState({
      showTarikSaldoModal: true,
      showConfirmationModal: false,
    });
  };

  _handlePayoutChange = (e) => {
    const { target } = e;
    const { value } = target;
    // This one is actually specific to set the payoutNominal state
    // and with that in mind, I think it's safe to add this currency helper to convert the
    // integer value to string
    this.setState((prevState) => {
      return {
        ...prevState,
        uiPayoutNominal: toCurrency(toDecimal(value)),
        payoutNominal: toDecimal(value),
      };
    });
  };

  _onSubmitTarikSaldo = async () => {
    const { profile, wallet } = this.props;
    const { bankData } = wallet;
    const withdraw = this.props.withdrawSaldo;
    const { payoutNominal } = this.state;
    const { email } = profile;
    const defaultBankData = bankData.filter((bank) => bank.default === true);
    if (defaultBankData.length === 0) {
      return this.setState({ showNoBankAccountModal: true });
    }
    this.setState((prevState) => {
      return {
        uiStates: {
          ...prevState.uiStates,
          postingPayout: true,
        },
      };
    });
    try {
      await withdraw(payoutNominal, email, defaultBankData[0] && defaultBankData[0].id);
      this.setState({
        showCongratulationModal: true,
        // uiStates: {
        //   ...prevState.uiStates,
        //   postingPayout: false
        // }
      });
      await this.props.getUserSaldo();
    } catch (error) {
      // Do something
      this.setState({ showErrorModal: true });
    } finally {
      this.setState((prevState) => {
        return {
          showConfirmationModal: false,
          uiStates: {
            ...prevState.uiStates,
            postingPayout: false,
          },
        };
      });
      // Do something
    }
  };

  _handleMoreWithdrawal = () => {
    this.setState({
      showTarikSaldoModal: true,
      showCongratulationModal: false,
    });
  };

  _toggleShowPassword = () => {
    this.setState((prevState) => {
      return {
        showPassword: !prevState.showPassword,
      };
    });
  };

  _hideModal = () => {
    this.setState({
      showTarikSaldoModal: false,
      showConfirmationModal: false,
      showCongratulationModal: false,
      showNoBankAccountModal: false,
      showErrorModal: false,
    });
  };

  _renderLoading = () => {
    return (
      <FormSkeletons native>
        {(props) => (
          <Fragment>
            <animated.div
              className='skeletons form-skeleton__children'
              style={{ ...props, height: 15 }}
            />
            <animated.div
              className='skeletons form-skeleton__children mb-2'
              style={{ ...props, height: 10, width: '50%' }}
            />
            <animated.div
              className='skeletons form-skeleton__children mb-2'
              style={{ ...props, height: 10, width: '100%' }}
            />
            <animated.div
              className='skeletons form-skeleton__children mb-2'
              style={{ ...props, height: 8, width: '45%' }}
            />
          </Fragment>
        )}
      </FormSkeletons>
    );
  };

  _renderLoadingProfilePicture = () => {
    return (
      <FormSkeletons native>
        {(props) => (
          <div className='d-flex'>
            <animated.div
              className='skeletons form-skeleton__profile-picture sidebar-img-profile mb-0'
              style={{ ...props, zIndex: 999 }}
            />
          </div>
        )}
      </FormSkeletons>
    );
  };

  render() {
    const { wallet, profile, location } = this.props;
    const { uiStates, uiPayoutNominal } = this.state;
    const { loadingSaldo, postingPayout } = uiStates;
    const { saldo } = wallet;
    const errorGetSaldo = wallet.error;
    const { name, family_name, code, picture } = profile;
    const pathName = location && location.pathname && location.pathname.split('/')[1];
    return (
      // sidebar for desktop
      <div className='sidebar mobile-disabled'>
        <div className='sidebar-header row'>
          <Link to='/study'>
            <img className='img-fluid' src={populixLogo} alt='populix logo' />
          </Link>
        </div>

        <div className='sidebar-profile'>
          {loadingSaldo && this._renderLoadingProfilePicture()}
          {!loadingSaldo && (
            <img
              className='sidebar-img-profile'
              src={picture ? `https://${picture}?${Math.random()}` : defaultImage}
              alt='profile pic'
            />
          )}
          <div className='sidebar-profile-content'>
            {loadingSaldo && this._renderLoading()}
            {!loadingSaldo && (
              <Fragment>
                <h6 style={{ color: '#000000', fontWeight: 'bold' }}>{code}</h6>
                <h4 className='font-bold sidebar-profile-content__name-container'>
                  {name}
                  &nbsp;
                  {family_name}
                </h4>
                <h6 className='f-13 text-uppercase'>Saldo Tersedia</h6>
                {!errorGetSaldo && (
                  <span className='profile-balance font-bold'>{RupiahParser(saldo)}</span>
                )}
                {errorGetSaldo && (
                  <span className='font-bold'>
                    Terjadi kesalahan dalam mengambil data
                    <span role='img' aria-label='sadface'>
                      😟
                    </span>
                    , mohon coba lagi
                  </span>
                )}
                <ul className='nav'>
                  <li className='nav-item'>
                    <button
                      type='button'
                      className='btn--text text--light-blue f-13 pl-0'
                      onClick={this._navigateToPayoutRequest}
                    >
                      Tarik Dana
                    </button>
                  </li>
                </ul>
              </Fragment>
            )}
          </div>
        </div>

        <div className='sidebar-nav'>
          <ul className='navbar-nav nav'>
            {/* <li className='nav-item'>
              <ProtectedBtn linkTo='/study' className='nav-link link'>
                Dashboard
              </ProtectedBtn>
            </li> */}
            <li className='nav-item'>
              <ProtectedBtn
                linkTo='/study'
                className={`nav-link link ${pathName === 'study' ? 'active' : ''}`}
              >
                Lihat Studi
              </ProtectedBtn>
            </li>
            {/* <li className='nav-item'>
              <ProtectedBtn
                linkTo='/screening/profesi'
                className={`nav-link link ${pathName === 'screening' ? 'active' : ''}`}
              >
                Screening
              </ProtectedBtn>
            </li> */}
          </ul>
        </div>

        {/* Cash Withdrawal Modal */}
        <Transition
          native
          items={this.state.showTarikSaldoModal}
          from={{ transform: 'translate(0, -100%)' }}
          enter={{ transform: 'translate(0, 0)' }}
          leave={{ transform: 'translate(0, -100%)' }}
        >
          {(showWithdrawalModal) => (modalStyle) =>
            showWithdrawalModal && (
              <ModalInput
                animatedModal
                style={modalStyle}
                formLabelMain='Masukan Jumlah Penarikan (Rp)'
                formValidationMsg={
                  this.state.validationMessage || 'Minimum jumlah penarikan adalah Rp 50.000'
                }
                formValidationFailed={this.state.formValidationFailed}
                handleSubmitForm={this._handleOnPressTarikDana}
                submitLabel='Tarik dana'
                handleFormChange={this._handlePayoutChange}
                handleClose={this._hideModal}
                value={uiPayoutNominal}
              />
            )}
        </Transition>
        <ModalPrompt
          handleClose={this._hideModal}
          handleConfirmation={this._onSubmitTarikSaldo}
          processingData={postingPayout}
          mainPromptMsg={`Kamu melakukan penarikan dana sebesar ${RupiahParser(
            this.state.payoutNominal,
          )} ?`}
          extraPromptMsg='Konfirmasi penarikan dapat memakan waktu 1 sampai 3 hari'
          show={this.state.showConfirmationModal}
          mainLoadingMsg='Memproses Penarikan Saldo'
          extraLoadingMsg='Harap tunggu, penarikan saldo kamu sedang kami proses'
        />
        <ModalSuccess
          amountToWithdraw={this.state.payoutNominal}
          name={`${name} ${family_name}`}
          show={this.state.showCongratulationModal}
          handleBackFunction={this._handleMoreWithdrawal}
          handleCloseFunction={this._hideModal}
        />
        <Transition
          native
          items={this.state.showErrorModal}
          from={{ transform: 'translate(0, -100%)' }}
          enter={{ transform: 'translate(0, 0)' }}
          leave={{ transform: 'translate(0, -100%)' }}
        >
          {(showErrorModal) => (styleProps) =>
            showErrorModal && (
              <ModalError
                animatedModal
                dataObjects={{ name: `${name} ${family_name}` }}
                handleCloseFunction={this._hideModal}
                style={styleProps}
                errorMsgHead='Telah terjadi kesalahan saat melakukan penarikan'
                errorMsgDetail='Silahkan coba lagi atau hubungi customer service kami'
              />
            )}
        </Transition>
        <Transition
          native
          items={this.state.showNoBankAccountModal}
          from={{ transform: 'translate(0, -100%)' }}
          enter={{ transform: 'translate(0, 0)' }}
          leave={{ transform: 'translate(0, -100%)' }}
        >
          {(showNoBankAccountModal) => (styleProps) =>
            showNoBankAccountModal && (
              <ModalError
                animatedModal
                dataObjects={{ name: `${name} ${family_name}` }}
                handleCloseFunction={this._hideModal}
                style={styleProps}
                errorMsgHead='Kamu belum mengisi nomor rekening kamu'
                errorMsgDetail='Mohon mengisi informasi bank kamu pada menu settings'
              />
            )}
        </Transition>
        {/* Modal Backdrop */}
        <div id='modal-backdrop' className='modal-backdrop-transparent modal-transition' />
      </div>
    );
  }
}

// NOTE Masih niche, jadi gue keep dulu
const ModalSuccess = ({
  name,
  amountToWithdraw,
  show,
  handleBackFunction,
  handleCloseFunction,
}) => {
  return (
    <div className={`${show ? 'modal display-block' : 'modal display-none'}`}>
      <section className='modal-main'>
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header border-bottom py-4'>
              <img src={populixLogo} style={{ height: 32, width: 110 }} alt='populix' />
            </div>
            <div>
              <div className='modal-body'>
                <div className='d-flex justify-content-between align-items-center'>
                  <h4 className='m-0'>Selamat, {name} 👋</h4>
                  <span>
                    <FontAwesomeIcon icon={faCheckCircle} color='#FAAF40' size='3x' />
                  </span>
                </div>
                <div className='my-2'>
                  <h3 className='mb-3'>Penarikan Sukses !</h3>
                  <h4 className='mb-3'>
                    Dana sebesar{' '}
                    <span style={{ fontWeight: 'bold' }}>{RupiahParser(amountToWithdraw)}</span>{' '}
                    akan segera di transfer ke rekening kamu dalam 3 hari kerja
                  </h4>
                </div>
              </div>
              <div className='modal-footer modal-footer-type-2 float-right'>
                {/* <button
                  type="button"
                  className="btn btn-primary modal-footer-btn"
                  onClick={handleBackFunction}
                >
                  Tarik lagi
                </button> */}
                <button
                  type='button'
                  className='btn btn-transparent btn--text text--light-blue modal-footer-btn'
                  onClick={handleCloseFunction}
                >
                  Tutup
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.profile.userProfile,
    wallet: state.wallet,
  };
};

export default withRouter(connect(mapStateToProps, { getUserSaldo, withdrawSaldo })(SidebarNav));

/* <div className="cash-withdraw--form">
	<div className="form-group">
		<label
			htmlFor="cash-withdraw--input"
			className="font-bold cash-withdraw__question"
		>
			Kata sandi
                    </label>

		<div className="input-group mb-3">
			<input
				type={showPassword ? 'text' : 'password'}
				className="form-control cash-withdraw__input modal__input"
				id="cash-withdraw-validate--password"
				name="password"
				placeholder="Masukkan kata sandi kamu"
				onChange={handleFormChange}
			/>
			<div className="input-group-append">
				<span
					style={{
						position: 'relative',
						top: 7,
						right: 25,
						zIndex: 999
					}}
				>
					{' '}
					<i
						onClick={togglePasswordHandler}
						className={`form-control-feedback fas ${
							showPassword ? 'fa-eye' : 'fa-eye-slash'
							} form-icon-eye`}
						style={{ color: '#D7DADB' }}
					/>
				</span>
			</div>
		</div>

		<small>
			Kata sandi diperlukan untuk mengkonfirmasi ketika kamu
			ingin melakukan penarikan dana.
                    </small>
	</div>
</div>
*/
