const SWITCH_USER = 'SWITCH_USER';
const UPDATE_PROFILE = 'UPDATE_PROFILE';
const DELETE_PROFILE = 'DELETE_PROFILE';

// For Login Page
const UPDATE_AUTH = 'UPDATE_AUTH';

// Registration Action Types
const LOGIN = 'LOGIN';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_FAILED = 'LOGIN_FAILED';

// Registration Action Types
const REGISTER = 'REGISTER';
const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
const REGISTER_FAILED = 'REGISTER_FAILED';

const UNCONFIRMED_USER = 'UNCONFIRMED_USER';
const USER_CONFIRMATION_SUCCESS = 'USER_CONFIRMATION_SUCCESS';
// Profile Action Types
const INSERT_USER_PROFILE = 'INSERT_USER_PROFILE';
const INSERT_USER_PROFILE_SUCCESS = 'INSERT_USER_PROFILE_SUCCESS';
const INSERT_USER_PROFILE_FAILED = 'INSERT_USER_PROFILE_FAILED';

const FETCH_USER_PROFILE = 'FETCH_USER_PROFILE';
const FETCH_USER_PROFILE_SUCCESS = 'FETCH_USER_PROFILE_SUCCESS';
const FETCH_USER_PROFILE_FAILED = 'FETCH_USER_PROFILE_FAILED';

const UPDATE_NEW_MEMBER_STATUS = 'UPDATE_NEW_MEMBER_STATUS';
const UPDATE_KTP_MEMBER_STATUS = 'UPDATE_KTP_MEMBER_STATUS';
const PURGE_PROFILE = 'PURGE_PROFILE';

const SEND_EMAIL_FORGOT_PASSWORD = 'SEND_EMAIL_FORGOT_PASSWORD';
const SEND_EMAIL_FORGOT_PASSWORD_SUCCESS = 'SEND_EMAIL_FORGOT_PASSWORD_SUCCESS';
const SEND_EMAIL_FORGOT_PASSWORD_FAILED = 'SEND_EMAIL_FORGOT_PASSWORD_FAILED';

// NOTE: The purpose of these action consts is to update
// the `loading` state of our profile setting when user is submitting
// their new data.
const SHOW_SETTINGS_SUCCESS_NOTIFICATION = 'SHOW_SETTINGS_SUCCESS_NOTIFICATION';
const SHOW_SETTINGS_FAILED_NOTIFICATION = 'SHOW_SETTINGS_FAILED_NOTIFICATION';
const HIDE_SETTINGS_NOTIFICATION = 'HIDE_SETTINGS_NOTIFICATION';

const UPDATE_NOTIFICATION_STATUS = 'UPDATE_NOTIFICATION_STATUS';

const GET_STUDIES = 'GET_STUDIES';
const GET_STUDIES_SUCCESS = 'GET_STUDIES_SUCCESS';
const GET_STUDIES_FAILED = 'GET_STUDIES_FAILED';

const GET_STUDY = 'GET_STUDY';
const GET_STUDY_SUCCESS = 'GET_STUDY_SUCCESS';
const GET_STUDY_FAILED = 'GET_STUDY_FAILED';

const GET_SALDO = 'GET_SALDO';
const GET_SALDO_SUCCESS = 'GET_SALDO_SUCCESS';
const GET_SALDO_FAILED = 'GET_SALDO_FAILED';

const SET_QUESTION = 'SET_QUESTION';

const POST_PAYOUT_REQUEST = 'POST_PAYOUT_REQUEST';
const POST_PAYOUT_REQUEST_SUCCESS = 'POST_PAYOUT_REQUEST_SUCCESS';
const POST_PAYOUT_REQUEST_FAILED = 'POST_PAYOUT_REQUEST_FAILED';

const GET_BANK = 'GET_BANK';
const GET_BANK_SUCCESS = 'GET_BANK_SUCCESS';
const GET_BANK_FAILED = 'GET_BANK_FAILED';

const UPDATE_PHONE_NUMBER = 'UPDATE_PHONE_NUMBER';

const STUDY_STARTED = 'STUDY_STARTED';
const STUDY_STOPPED = 'STUDY_STOPPED';
const PROTECTED_BTN_PRESSED = 'PROTECTED_BTN_PRESSED';
const CLOSE_MODAL_PROMPT = 'CLOSE_MODAL_PROMPT';
const RESET_ACTIVE_STUDY_STATE = 'RESET_ACTIVE_STUDY_STATE';

const CLEAR_AUTH = 'CLEAR_AUTH';

const GET_BANK_DATA = 'GET_BANK_DATA';
const GET_BANK_DATA_SUCCESS = 'GET_BANK_DATA_SUCCESS';
const GET_BANK_DATA_FAILED = 'GET_BANK_DATA_FAILED';

const PURGE = 'persist/PURGE';

const UPDATE_PRESCREENING = 'UPDATE_PRESCREENING';
const UPDATE_KTP = 'UPDATE_KTP';
const INCREMENT_PERCENTAGE_VALUE = 'INCREMENT_PERCENTAGE_VALUE';
const INCREMENT_VALIDATION_COUNTER = 'INCREMENT_VALIDATION_COUNTER';
const UPDATE_VALIDATION_RESULT = 'UPDATE_VALIDATION_RESULT';

const SET_FEATURE_FLAG = 'SET_FEATURE_FLAG';
const CLOSE_BANNER_PROMO = 'CLOSE_BANNER_PROMO';
const CLOSE_BANNER_INFO = 'CLOSE_BANNER_INFO';

export {
  SWITCH_USER,
  UPDATE_PROFILE,
  DELETE_PROFILE,
  UPDATE_AUTH,
  REGISTER,
  REGISTER_SUCCESS,
  REGISTER_FAILED,
  UNCONFIRMED_USER,
  USER_CONFIRMATION_SUCCESS,
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  INSERT_USER_PROFILE,
  INSERT_USER_PROFILE_SUCCESS,
  INSERT_USER_PROFILE_FAILED,
  FETCH_USER_PROFILE,
  FETCH_USER_PROFILE_SUCCESS,
  FETCH_USER_PROFILE_FAILED,
  UPDATE_NEW_MEMBER_STATUS,
  PURGE_PROFILE,
  SEND_EMAIL_FORGOT_PASSWORD,
  SEND_EMAIL_FORGOT_PASSWORD_SUCCESS,
  SEND_EMAIL_FORGOT_PASSWORD_FAILED,
  UPDATE_KTP_MEMBER_STATUS,
  SHOW_SETTINGS_SUCCESS_NOTIFICATION,
  SHOW_SETTINGS_FAILED_NOTIFICATION,
  HIDE_SETTINGS_NOTIFICATION,
  UPDATE_NOTIFICATION_STATUS,
  GET_STUDIES,
  GET_STUDIES_SUCCESS,
  GET_STUDIES_FAILED,
  GET_STUDY,
  GET_STUDY_SUCCESS,
  GET_STUDY_FAILED,
  GET_SALDO,
  GET_SALDO_SUCCESS,
  GET_SALDO_FAILED,
  SET_QUESTION,
  POST_PAYOUT_REQUEST,
  POST_PAYOUT_REQUEST_SUCCESS,
  POST_PAYOUT_REQUEST_FAILED,
  GET_BANK,
  GET_BANK_SUCCESS,
  GET_BANK_FAILED,
  UPDATE_PHONE_NUMBER,
  STUDY_STARTED,
  STUDY_STOPPED,
  PROTECTED_BTN_PRESSED,
  CLOSE_MODAL_PROMPT,
  RESET_ACTIVE_STUDY_STATE,
  CLEAR_AUTH,
  GET_BANK_DATA,
  GET_BANK_DATA_SUCCESS,
  GET_BANK_DATA_FAILED,
  PURGE,
  UPDATE_PRESCREENING,
  UPDATE_KTP,
  INCREMENT_PERCENTAGE_VALUE,
  SET_FEATURE_FLAG,
  CLOSE_BANNER_PROMO,
  CLOSE_BANNER_INFO,
  INCREMENT_VALIDATION_COUNTER,
  UPDATE_VALIDATION_RESULT,
};
