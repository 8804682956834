import { AppBar, Toolbar } from '@material-ui/core';
import React from 'react';
import useStyles from './styles';
import Logo from 'assets/img/logo.png';

const Header = () => {
  const classes = useStyles();
  return (
    <AppBar className={classes.appBar} elevation={0}>
      <Toolbar className={classes.toolbar}>
        <img src={Logo} alt='populix' height={48} width={170} />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
