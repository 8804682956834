import React from 'react';

class AvatarImageCropperWrapper extends React.Component {
  static defaultProps = {
    disabled: false,
    imageSrc: ''
  };

  apply = dataURL => {
    this.props.callback(dataURL);
  };

  render() {
    const { callback, disabled, imageSrc, style, clear, ...rest } = this.props;
    return (
      <div
        {...rest}
        style={{ ...style, backgroundImage: `url("${imageSrc}")` }}
        disabled={disabled}
      >
        {this.props.rendercomponent}
        {/* <AvatarImageCropper apply={this.apply} isBack maxSize={1000000} /> */}
      </div>
    );
  }
}

export default AvatarImageCropperWrapper;
