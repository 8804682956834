import { combineReducers } from 'redux';
import userReducer from './userReducer';
import profileReducer from './profileReducer';
import authReducer from './authReducer';
import studyReducer from './studyReducer';
import walletReducer from './walletReducer';
import questionReducer from './questionReducer';
import activeStudyReducer from './activeStudyReducer';
import preScreeningReducer from './prescreeningReducer';
import featureFlagReducer from './featureFlagReducer';

export default combineReducers({
  user: userReducer,
  profile: profileReducer,
  auth: authReducer,
  study: studyReducer,
  wallet: walletReducer,
  activeStudy: activeStudyReducer,
  question: questionReducer,
  prescreening: preScreeningReducer,
  featureFlag: featureFlagReducer,
});
