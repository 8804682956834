import React, { useState } from 'react';
import popcornHappyNew from 'assets/img/popcorn-happy-new.png';
import popcornFail from 'assets/img/popcorn-fail-new.png';
import populixLogo from 'assets/img/logo.png';
import playStoreLogo from 'assets/img/google-play-logo.png';
import appStoreLogo from 'assets/img/app-store-logo.png';
import gadgetReward from 'assets/img/gadget-reward.png';
import useStyles from './ThankyouStudyStyles';
import { Toast } from '../Toast/Toast';
import { errorPopup } from '../../helpers/guestHelpers';

const ThankyouStudy = ({ studyStatus, studyType, participantData, traceID }) => {
  const classes = useStyles();
  const { name, phoneNumber } = participantData || {};
  const [showToast, setShowToast] = useState(false);

  const referralCode = 'ENT2023';

  const copyToClipboard = () => {
    navigator.clipboard.writeText(referralCode).then(() => {
      setShowToast(true);
    });
  };

  const redirectToAppSource = (url) => {
    window.open(url);
  };

  const _renderGeneralThankyou = () => {
    return (
      <div className={classes.mainContent}>
        <img src={popcornHappyNew} alt='popcorn-happy' className={classes.popcornImage} />
        <div className={classes.mainContentTextTitle}>Terima kasih telah mengisi survei ini!</div>
        <div className={classes.mainContentText}>
          {studyType === 'OFFLINE' &&
            `Jawaban survei kamu telah kami terima, ${name} (${phoneNumber}).`}
          {studyType === 'ONLINE' &&
            'Kami sedang melakukan pengecekan hasil survei kamu. Tim kami akan menilai kejujuran, konsistensi serta kesungguhan kamu dalam mengisi survei.'}
        </div>
        <div className={classes.mainContentText}>
          {studyType === 'ONLINE' &&
            'Kamu tetap dapat mengisi PopPoll setiap hari untuk mendapatkan tambahan poin.'}
        </div>
      </div>
    );
  };

  const _renderOverquotaThankyou = () => {
    return (
      <div className={classes.mainContent}>
        <img src={popcornFail} alt='popcorn-sad' className={classes.popcornImage} />
        <div className={classes.mainContentTextTitle}>Kuota survei habis</div>
        <div className={classes.mainContentText}>
          Tenang, {name && phoneNumber && `${name} (${phoneNumber}),`} kamu masih bisa isi survey
          lainnya di Populix. Download sekarang!
        </div>
      </div>
    );
  };

  const _renderErrorState = () => {
    return (
      <div className={classes.mainContent}>
        <img src={popcornFail} alt='popcorn-sad' className={classes.popcornImage} />
        <div className={classes.mainContentTextTitle}>{errorPopup[studyStatus].title}</div>
        <div className={classes.mainContentText}>{errorPopup[studyStatus].description}</div>
      </div>
    );
  };

  const _handleRenderMessageState = () => {
    if (studyStatus === 'OVERQUOTA' || studyStatus === 'STUDY_FULL') {
      return _renderOverquotaThankyou();
    }

    if (errorPopup[studyStatus] && studyStatus !== 'SUBMISSION_ON_REVIEW') {
      return _renderErrorState();
    }

    return _renderGeneralThankyou();
  };

  const _renderTemplateThankyou = () => {
    return (
      <>
        <Toast
          show={showToast}
          title={'Kode Berhasil Disalin'}
          category='success'
          onClose={() => setShowToast(false)}
        />
        <div className={classes.root}>
          <div className={classes.paper}>
            <img src={populixLogo} alt='populix' className={classes.populixLogo} />
            {_handleRenderMessageState()}
            {traceID && <div className={classes.traceIDText}>TraceID: {traceID}</div>}
            {studyType === 'OFFLINE' && (
              <>
                <div className={classes.card}>
                  <div className={classes.rewardFigure}>
                    <img src={gadgetReward} alt='play-store' width={80} height={76} />
                    <div>
                      <span className={classes.boldCaption}>Reward menarik menantimu!</span>
                      <div className={classes.descriptionText}>
                        Isi survei, nikmati aktivitas seru, dan tukar poin untuk reward favoritmu di
                        Aplikasi Populix!
                      </div>
                    </div>
                  </div>
                  <hr className={classes.dividerLine} />
                  <span className={classes.referralCaption}>
                    Daftar dan gunakan kode referral ini untuk extra poin.
                  </span>
                  <div className={classes.referralFigure}>
                    <div className={classes.referralCard}>
                      <span className={classes.referralId}>{referralCode}</span>
                    </div>
                    <button className={classes.button} onClick={copyToClipboard}>
                      <div className={classes.buttonCaption}>Salin Kode</div>
                    </button>
                  </div>
                </div>
                <span className={classes.boldCaption}>Download Populix Sekarang!</span>
                <div className={classes.appSource}>
                  <img
                    src={playStoreLogo}
                    alt='play-store'
                    className={classes.appSourceLogo}
                    onClick={() => redirectToAppSource('https://populix.onelink.me/iAix/ent')}
                  />
                  <img
                    src={appStoreLogo}
                    alt='app-store'
                    className={classes.appSourceLogo}
                    onClick={() => redirectToAppSource('https://populix.onelink.me/iAix/ent')}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  };

  return _renderTemplateThankyou();
};

export default ThankyouStudy;
