import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';

import LoginTop from 'assets/images/wave-right-up.svg';
import LoginBottom from 'assets/images/wave-left-bottom.svg';
import LoginImage from 'assets/images/image-login.png';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
    width: '100vw',
    backgroundImage: 'linear-gradient(84.76deg, #EE7824 -2.43%, #FFB808 101.57%)',

    display: 'flex',
    '@media all and (-ms-high-contrast:none)': {
      height: 0,
    },
  },
  content: {
    flexGrow: 1,
    width: '100%',
    height: '100%',
  },
  center: {
    position: 'fixed',
    display: 'flex',
    overflow: 'scroll',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    zIndex: 1500,
  },
  loginTop: {
    position: 'fixed',
    right: 0,
    zIndex: 1000,
  },
  loginBottom: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    zIndex: 1000,
  },
  root: {
    height: '100%',
    padding: theme.spacing(2),
    margin: '70px 0px',
  },
  smallCard: {
    width: 500,
    margin: 8,
    overflow: 'visible',
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
      width: '50%',
    },
    [theme.breakpoints.down('md')]: {
      width: 'unset',
    },
  },
  cardMedia: {
    display: 'flex',
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    flexDirection: 'column',
    borderTopLeftRadius: 28,
    borderBottomLeftRadius: 28,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  cardContent: {
    padding: theme.spacing(4, 3, 3, 3),
    alignItems: 'center',
  },
  card: {
    width: theme.breakpoints.values.md + 24,
    maxWidth: '100%',
    overflow: 'visible',
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
      width: '50%',
    },
    [theme.breakpoints.down('md')]: {
      width: 'unset',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(3),
    },
  },
  cardWrapper: {
    paddingBottom: theme.spacing(2),
  },
}));

function Auth({ children, withImage }) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.container}>
        <div className={classes.content}>
          <div className={classes.loginTop}>
            <img src={LoginTop} alt='wave-top' />
          </div>
          <div className={classes.center}>
            <div className={classes.root}>
              <div className={classes.cardWrapper}>
                <Card className={withImage ? classes.card : classes.smallCard}>
                  {withImage && (
                    <CardMedia className={classes.cardMedia} image={LoginImage} title='Cover' />
                  )}
                  <CardContent className={classes.cardContent}>{children}</CardContent>
                </Card>
              </div>
            </div>
          </div>
          <div className={classes.loginBottom}>
            <img src={LoginBottom} alt='wave-bottom' />
          </div>
        </div>
      </div>
    </>
  );
}

Auth.propTypes = {
  children: PropTypes.node,
  withImage: PropTypes.bool,
};

export default Auth;
