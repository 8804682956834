import React, { Component } from 'react';
import uangIcon from 'assets/img/screening-tab-icons/uang-icon.png';
import ticketIcon from 'assets/img/ticket-icon.png';
import PropTypes from 'prop-types';
import { RupiahParser } from '../../helpers/generalHelpers';

class StudyReward extends Component {
  render() {
    const { reward, type } = this.props;

    let rewardObject;
    switch (type) {
      case 'cash':
        rewardObject = { label: RupiahParser(reward), icon: uangIcon };
        break;
      case 'ticket':
        rewardObject = { label: `${reward} Tiket`, icon: ticketIcon };
        break;
      default:
        rewardObject = { label: '', icon: uangIcon };
        break;
    }

    return (
      <>
        {type && (
          <>
            <img className='icon' src={rewardObject.icon} title='Reward' alt='reward' />
            <p className='survey-header__value'>
              <span>Reward Dasar: {rewardObject.label}</span>
            </p>
          </>
        )}
      </>
    );
  }
}

StudyReward.propTypes = {
  reward: PropTypes.number,
  type: PropTypes.string,
};

export default StudyReward;
