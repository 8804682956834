import React from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import AsyncSelect from 'react-select/lib/Async';
import Slider from 'react-rangeslider';
import { RupiahParser } from '../../helpers/generalHelpers';
import NumberFormat from 'react-number-format';

export const ScreeningTypeSelect = ({ id, className, questionData, onChangeFunction, value }) => {
  const sortedOptions =
    Array.isArray(questionData.options) &&
    [...questionData.options].sort((prevVal, nextVal) => {
      if (prevVal.option_text < nextVal.option_text) return -1;
      if (prevVal.option_text > nextVal.option_text) return 1;
      return 0;
    });
  return (
    <select
      // className={"form-control prescreening__option"}
      id={id}
      className={className}
      onChange={(e) => onChangeFunction(e, questionData.label)}
      defaultValue={(value && value[0]) || 'pilih_placeholder'}
    >
      <option disabled value='pilih_placeholder'>
        Pilih
      </option>
      {Array.isArray(sortedOptions) &&
        sortedOptions.map((option, index) => (
          <option id={option} key={index} value={option}>
            {option}
          </option>
        ))}
    </select>
  );
};

export const ScreeningTypeMonthlyExpenses = ({
  className,
  onChangeFunction,
  value,
  questionData,
}) => {
  return (
    <select
      id='monthly-expenses'
      className={className}
      onChange={(e) => onChangeFunction(e, questionData.label)}
      defaultValue={(value && value[0]) || 'pilih_placeholder'}
    >
      <option disabled value='pilih_placeholder'>
        Pilih
      </option>
      {Array.isArray(questionData.options) &&
        questionData.options.map((option, index) => (
          <option id={option.value} key={index} value={option.value}>
            {option.key}
          </option>
        ))}
    </select>
  );
};

export const ScreeningTypeDate = ({
  className,
  questionData,
  onChangeFunction,
  dateState,
  maxDate,
  minDate,
}) => {
  return (
    <DatePicker
      id='date-of-birth'
      openToDate={dateState || new Date(new Date().setFullYear(2000))}
      selected={dateState}
      dateFormat='dd-MM-yyyy'
      showYearDropdown
      showMonthDropdown
      dropdownMode='select'
      minDate={minDate}
      maxDate={maxDate}
      className={className}
      placeholderText='dd-mm-yyyy'
      onChange={(val) => onChangeFunction(val, questionData.label)}
      popperModifiers={{
        preventOverflow: { enabled: true },
      }}
    />
  );
};

export const ScreeningTypeMultiSelect = ({
  classNameParent,
  classNameComponent,
  questionData,
  onChangeFunction,
  value,
}) => {
  return (
    <Select
      isMulti
      value={
        Array.isArray(value) &&
        value.map((answer) => {
          return { label: answer, value: answer };
        })
      }
      options={questionData.options.map((option) => {
        return {
          ...option,
          label: option,
          value: option,
        };
      })}
      onChange={(e) => onChangeFunction(e, questionData.label)}
      styles={customStylesMultiselect}
      // className="form-control screening-multioption mb-2 p-0"
      className={classNameParent}
      // TODO find better ways to pass the className
      components={{
        DropdownIndicator: () => <div className={classNameComponent.dropDownClassName} />,
      }}
    />
  );
};

export const ScreeningTypeGenderOption = ({ className, questionData, onChangeFunction, value }) => {
  // Maybe its a good time to introduce hooks and context. So this components
  // can be independent from its parent(?)
  return (
    Array.isArray(questionData.options) &&
    questionData.options.map((option, key) => (
      <div key={key} className='col-6 p-1'>
        <input
          id={option}
          type='radio'
          name='gender'
          value={option}
          onChange={(e) => onChangeFunction(e, questionData.label)}
        />
        <label htmlFor={option} className='font-bold'>
          <img
            src={require(option === 'Laki-Laki'
              ? '../../assets/img/man.png'
              : '../../assets/img/women.png')}
            alt='gender-opt'
          />
          <br />
          {option}
        </label>
      </div>
    ))
  );
};

export const ScreeningTypeSearchableSelect = ({
  classNameParent,
  classNameComponent,
  defaultData,
  onChangeFunction,
  value,
  questionData,
  singleValueColor,
}) => {
  return (
    <Select
      isSearchable
      onChange={(e) => onChangeFunction(e, questionData.label)}
      options={defaultData}
      styles={customStylesMultiselect}
      value={
        Array.isArray(value) &&
        value[0] !== '' &&
        value.map((answer) => {
          return { label: answer, value: answer, singleValueColor };
        })
      }
      // className="form-control screening-multioption mb-2 p-0"
      className={classNameParent}
      // TODO find better ways to pass the className
      components={{
        DropdownIndicator: () => (
          <div className={classNameComponent && classNameComponent.dropDownClassName} />
        ),
        // SingleValue: () => <div style={{ color: '#000000' }}></div>
      }}
    />
  );
};

export const ScreeningTypeBasic = ({
  id,
  className,
  questionData,
  onChangeFunction,
  type,
  placeholder,
  value,
}) => {
  return (
    <input
      id={id}
      type={type || 'text'}
      onChange={(e) => onChangeFunction(e, questionData.label)}
      className={className}
      placeholder={placeholder}
      value={(value && value[0]) || ''}
    />
  );
};

export const ScreeningTypeRupiah = ({
  className,
  questionData,
  onChangeFunction,
  type,
  placeholder,
  value,
  getInputRef,
}) => {
  return (
    <NumberFormat
      value={(value && value[0] && value[0].toString()) || ''}
      className={className}
      placeholder={placeholder}
      decimalScale={0}
      onValueChange={(values) => onChangeFunction(values.floatValue, questionData.label)}
      displayType={'input'}
      thousandSeparator={'.'}
      decimalSeparator={','}
      prefix={'Rp '}
      getInputRef={getInputRef}
    />
  );
};

export const ScreeningTypeRangeSlider = ({
  parentClassName,
  valueClassName,
  questionData,
  onChangeFunction,
  value,
  editValue,
  fineTuneFunction,
  toggleFineTuneFunc,
}) => {
  return (
    <div className={parentClassName}>
      <Slider
        min={0}
        max={100000000}
        value={value}
        step={10000}
        tooltip={false}
        // label={}
        onChange={(val) =>
          onChangeFunction(
            val,
            questionData.id,
            questionData.label,
            questionData.relationship,
            questionData.mandatory,
          )
        }
      />
      <div className={valueClassName} onClickCapture={!editValue ? toggleFineTuneFunc : () => {}}>
        {!editValue && RupiahParser(value)}{' '}
        {editValue && (
          <React.Fragment>
            <input
              className={valueClassName}
              type='number'
              style={{ width: '80%' }}
              value={value}
              onChange={(e) =>
                fineTuneFunction(
                  e,
                  questionData.id,
                  questionData.label,
                  questionData.relationship,
                  questionData.mandatory,
                )
              }
            />
            <button onClick={toggleFineTuneFunc} className='btn btn-primary btn-sm mt-1 p-1'>
              Ubah
            </button>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export const ScreeningTypeSelectNew = ({ className, questionData, onChangeFunction, value }) => {
  return (
    <ul>
      {Array.isArray(questionData.options) &&
        questionData.options.map((opt, index) => (
          <li
            key={index}
            className={`${className} ${value && value[0] === opt && 'selected'}`}
            onClick={(e) =>
              onChangeFunction({ target: { ...e.target, value: opt } }, questionData.label)
            }
          >
            {opt}
          </li>
        ))}
    </ul>
  );
};

export const ScreeningTypeSearchableSelectNew = ({
  classNameParent,
  classNameComponent,
  defaultData,
  onChangeFunction,
  value,
  questionData,
  singleValueColor,
}) => {
  return (
    <Select
      isSearchable
      onChange={(e) => onChangeFunction(e, questionData.label)}
      options={defaultData}
      styles={customStylesMultiselectNew}
      value={
        Array.isArray(value) &&
        value[0] !== '' &&
        value.map((answer) => {
          return { label: answer, value: answer, singleValueColor };
        })
      }
      placeholder='Cari Kota'
      // className="form-control screening-multioption mb-2 p-0"
      className={classNameParent}
      // TODO find better ways to pass the className
      components={{
        IndicatorSeparator: () => <div />,
        DropdownIndicator: () => <div className='fa fa-search mr-2' />,
        // DropdownIndicator: () => (
        //   <div className={classNameComponent && classNameComponent.dropDownClassName} />
        // ),
        // SingleValue: () => <div style={{ color: '#000000' }}></div>
      }}
    />
  );
};

const customStylesMultiselect = {
  // container: () => ({
  // 	alignItems: 'center',
  // 	backgroundColor: 'hsl(0,0%,100%)',
  // 	borderColor: 'transparent',
  // 	borderRadius: 4,
  // 	borderStyle: 'solid',
  // 	borderWidth: 1,
  // 	cursor: 'default',
  // 	display: 'flex',
  // 	flexWrap: 'wrap',
  // 	justifyContent: 'space-between',
  // 	minHeight: 38,
  // 	outline: 0,
  // 	position: 'relative',
  // 	transition: 'all 100ms',
  // 	boxSizing: 'border-box'
  // }),
  control: (base) => ({
    // ...base,
    // borderColor: 'transparent',
    // border: 0
    alignItems: 'center',
    backgroundColor: 'hsl(0,0%,100%)',
    borderColor: 'transparent',
    borderRadius: 4,
    borderStyle: 'solid',
    borderWidth: 1,
    cursor: 'default',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    minHeight: 38,
    outline: 0,
    position: 'relative',
    transition: 'all 100ms',
    boxSizing: 'border-box',
  }),
  valueContainer: (base) => ({ ...base, padding: 0 }),
  // indicatorsContainer: () => ({})
  indicatorSeparator: () => ({
    display: 'none',
    // alignSelf: 'stretch',
    // backgroundColor: 'hsl(0,0%,80%)',
    // marginBottom: 8,
    // marginTop: 8,
    // width: 1,
    // marginLeft: 10,
    // marginRight: 10,
    // boxSizing: 'border-box'
  }),
  dropdownIndicator: () => ({
    width: 0,
    height: 0,
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderTop: '5px solid #179dfe',
  }),
  clearIndicator: () => ({
    display: 'none',
  }),
  placeholder: () => ({
    color: '#179dfe',
    // fontWeight: 'bold'
  }),
  singleValue: (provided, { data }) => ({
    ...provided,
    color: data.singleValueColor || '#179dfe',
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: 'white',
    backgroundColor: '#179dfe',
    fontWeight: '100',
  }),
  multiValueRemove: (base) => ({
    ...base,
    color: 'white',
    backgroundColor: '#179dfe',
  }),
};

const customStylesMultiselectNew = {
  container: (base) => ({
    ...base,
    width: '100%',
    // borderColor: 'transparent',
    // border: 0
    // alignItems: 'center',
    // backgroundColor: 'hsl(0,0%,100%)',
    // borderColor: 'transparent',
    // borderRadius: 4,
    // borderStyle: 'solid',
    // borderWidth: 1,
    // cursor: 'default',
    // display: 'flex',
    // flexWrap: 'wrap',
    // justifyContent: 'space-between',
    // minHeight: 38,
    // outline: 0,
    // position: 'relative',
    // transition: 'all 100ms',
    // boxSizing: 'border-box',
  }),
};

{
  /* <div className="screening-multioption__dropdown-indicator" /> */
}
