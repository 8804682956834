import axios from 'axios';
import Cookies from 'js-cookie';

const months_idn = [
  'Januari',
  'Februari',
  'Maret',
  'April',
  'Mei',
  'Juni',
  'Juli',
  'Agustus',
  'September',
  'Oktober',
  'November',
  'Desember',
];

export const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  navigator.userAgent,
);

export const DateFormatter = (targetDate) => {
  let dateObj;
  // it will force any non-date instance to date
  if (!(targetDate instanceof Date)) {
    dateObj = new Date(targetDate);
  } else {
    dateObj = targetDate;
  }
  const dd = dateObj && dateObj.getDate();
  const MM = dateObj && dateObj.getMonth() + 1;
  const yyyy = dateObj && dateObj.getFullYear();
  if (!(dd || MM || yyyy)) {
    return null;
  }
  return `${dd < 10 ? '0' + dd : dd}-${MM < 10 ? '0' + MM : MM}-${yyyy}`;
};

export const DateDeFormatter = (targetDate) => {
  const splitedDate = targetDate && targetDate.split('-');
  // returns mm/dd/yyyy
  if (!splitedDate) {
    return new Date();
  }
  return `${splitedDate[1]}/${splitedDate[0]}/${splitedDate[2]}`;
};

export const StringToDate = (date) => {
  const dateSplitted = date && date.toString().split('-');
  if (dateSplitted && dateSplitted.length > 1) {
    const newDate = new Date(`${dateSplitted[2]}/${dateSplitted[1]}/${dateSplitted[0]}`);
    return newDate;
  } else {
    return date;
  }
};

export const dataLayerHandlers = (dataLayer = {}) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: dataLayer.event,
    eventCategory: dataLayer.eventCategory,
    eventAction: dataLayer.eventAction,
    eventLabel: dataLayer.eventLabel,
  });
};

export const StaticMonthlyExpenses = () => {
  const questionData = {
    label: 'MonthlyExpenses',
    options: [
      { key: '> Rp 20.000.000,-', value: 23000000 },
      { key: 'Rp 15.000.001 – Rp 20.000.000,-', value: 18000000 },
      { key: 'Rp 10.000.001 – Rp 15.000.000,-', value: 13000000 },
      { key: 'Rp 9.000.001 – Rp 10.000.000,-', value: 9500000 },
      { key: 'Rp 8.000.001 – Rp 9.000.000,-', value: 8500000 },
      { key: 'Rp 7.000.001 – Rp 8.000.000,-', value: 7500000 },
      { key: 'Rp 6.000.001 – Rp 7.000.000,-', value: 6500000 },
      { key: 'Rp 5.000.001 – Rp 6.000.000,-', value: 5500000 },
      { key: 'Rp 4.000.001 – Rp 5.000.000,-', value: 4500000 },
      { key: 'Rp 3.000.001 – Rp 4.000.000,-', value: 3500000 },
      { key: 'Rp 2.700.001 – Rp 3.000.000,-', value: 2850000 },
      { key: 'Rp 2.000.001 – Rp 2.700.000,-', value: 2350000 },
      { key: 'Rp 1.750.001 – Rp 2.000.000,-', value: 1850000 },
      { key: 'Rp 1.500.001 – Rp 1.750.000,-', value: 1600000 },
      { key: 'Rp 1.300.001 – Rp 1.500.000,-', value: 1400000 },
      { key: 'Rp 900.001 – Rp 1.300.000,-', value: 1000000 },
      { key: 'Rp 750.001 – Rp 900.000,-', value: 850000 },
      { key: '< Rp 750.000,-', value: 700000 },
    ],
    bullet_image_url: 'https://populix-public.s3.amazonaws.com/money.png',
  };

  return questionData;
};

export const FormatMonth = (targetDate) => {
  const splitDate = targetDate && targetDate.split('-');
  if (!splitDate) {
    return new Date();
  }
  const parsedMonth = parseInt(splitDate && splitDate[1]);

  return `${splitDate[0]} ${months_idn[parsedMonth - 1]} ${splitDate[2]}`;
};
// TODO Might want to merge this with DateFormatter
export const DateFormatPretty = (targetDate) => {
  let dateObj;
  if (!(targetDate instanceof Date)) {
    dateObj = new Date(targetDate);
  } else {
    dateObj = targetDate;
  }
  // const dateObj = new Date(targetDate);
  const day = dateObj && dateObj.getDate();
  const monthIndex = dateObj && dateObj.getMonth();
  const fullYear = dateObj && dateObj.getFullYear();

  return `${day < 10 ? '0' + day : day} ${months_idn[monthIndex]} ${fullYear}`;
};

export const PrettifyTimer = (min, second) => {
  return `${min < 10 ? '0' + min : min}:${second < 10 ? '0' + second : second}`;
};

export const RupiahParser = (number) => {
  // we are going to allow number with string value
  if (number === 0 || isNaN(number) || (!number && typeof number !== 'number')) {
    return '-';
  }
  const regx = /(\d)(?=(\d\d\d)+(?!\d))/g;
  const numToStr = typeof number !== 'string' ? number.toString() : number;
  const prefixRp = 'Rp ';
  return prefixRp + numToStr.replace(regx, '$1.').trim();
  // TODO implement the parser
};

export const ValidateFileSize = (file) => {
  return new Promise((resolve, reject) => {
    if (file.size < 10000000) {
      resolve();
    } else {
      reject('File size is exceeding 10 MB');
      // throw new Error("File size is exceeding 2mb");
    }
  });
};

export const ValidateImageFile = (type) => {
  return new Promise((resolve, reject) => {
    if (type === 'image/png' || type === 'image/jpeg' || type === 'image/jpg') {
      resolve();
    } else {
      reject('File is not image');
      // throw new Error("File size is exceeding 2mb");
    }
  });
};

export const ValidateBirthYear = (date) => {
  // The format returned from our date picker is 'dd-mm-yyyy'.
  // It has a '-' delimiter which not recognize by js Date() object.
  // That is why we have to deformat it back to the 'dd/mm/yyyy'
  // format (with /) so JS can recognize it.
  // It will be better if we check for '-' first before deformatting.
  // Might do that later 🤷🏻‍
  const deFormattedDate = DateDeFormatter(date);
  return new Promise((resolve, reject) => {
    if (new Date(deFormattedDate).getFullYear() > 2009) {
      reject('Invalid Date of Birth');
    } else {
      resolve(true);
    }
  });
};

export const toCurrency = (number) => {
  // When we erase the whole value, since its a string, it will return a NaN.
  // this condition will handle the NaN by resetting the value to 0
  if (isNaN(number)) {
    return 0;
  }
  const numberRounded = Math.round(number * 100) / 100;
  return ''.concat((numberRounded + '').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$&.'));
};

export const toDecimal = (number) => {
  return parseFloat(number.replace(/\D+/g, ''));
};

export const capitalizeFirstLetter = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const getQuestion = async () => {
  try {
    // fetch from new static question json in s3
    const question = await axios.get(`${process.env.REACT_APP_CDN}/participant-question.json`);
    if (question && question.data) {
      return question.data;
    }
    return null;
  } catch (error) {
    console.warn('error getQuestion', error);
    throw error;
  }
};

export const hideIntercom = (isHide) => {
  if (!!window.Intercom) {
    window.Intercom('update', {
      hide_default_launcher: isHide,
    });
  }
};

export const parseDomicile = async (domiciles) => {
  try {
    const result = [];

    if (domiciles) {
      for (const key in domiciles) {
        const value = {
          label: `${key}, ${domiciles[key]}`,
          value: `${key}`,
        };

        result.push(value);
      }

      result.sort((a, b) => (a.value > b.value ? 1 : -1));
    }

    return result;
  } catch (err) {
    throw new Error(err);
  }
};

export const getDomiciles = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_CDN}/citiesWithProvinceV2.json`);

    return parseDomicile(response.data);
  } catch (err) {
    throw new Error('Failed to fetch domicile');
  }
};

export const PRESCREENING_QUESTION_LABEL = {
  GENDER: 'Gender',
  DOB: 'DateOfBirth',
  JOB_STATUS: 'JobStatus',
  DOMICILE: 'Domicile',
  MONTHLY_EXPENSES: 'MonthlyExpenses',
};

export const AcceptedImageFile = 'image/png, image/jpeg, image/jpg';
export const EmailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const adminFee = 5000;
export const minimumPayout = 15000;
export const normalPayout = 50000;

export const keyPreScreening = [
  'Gender',
  'Domicile',
  'JobStatus',
  'MonthlyExpensesMin',
  'MonthlyExpensesMax',
  'MonthlyExpenses',
  'CookingFuel',
  'DrinkingWater',
  'HouseholdSize',
  'Age',
  'DateOfBirth',
];

export const getSubmissionIdFromUrl = (url) => {
  try {
    if (url) {
      const parseURL = new URL(url);

      const submissionId =
        parseURL.searchParams.get('submissionId') || parseURL.searchParams.get('submissionid');

      return submissionId;
    }

    return null;
  } catch {
    return null;
  }
};

const removeExtraQuestionMarks = (url) => {
  // Use a regular expression to replace consecutive question marks with a single question mark
  return url.replace(/(\?{2,})/g, '?');
};

// TODO : temporary solution
export const urlGetParams = (url) => {
  const sanitizeURL = removeExtraQuestionMarks(url);
  try {
    const queryParams = {};

    if (sanitizeURL) {
      const parseURL = new URL(sanitizeURL);

      parseURL.searchParams.forEach((value, key) => {
        queryParams[key] = value;
      });

      const splitStudyName = queryParams.studyname ? queryParams.studyname.split('=')[1] : null;

      return {
        studyId: splitStudyName || queryParams.studyId,
        ...queryParams,
      };
    }

    return queryParams;
  } catch {
    return {};
  }
};

export const validateJSON = (jsonStr) => {
  try {
    JSON.parse(jsonStr);
    return true;
  } catch (error) {
    return false;
  }
};

export const getWebhookStatus = (url) => {
  if (!url || url.trim() === '') {
    return '';
  }

  try {
    const sanitizeURL = encodeURI(url);
    const urlToParse = new URL(sanitizeURL);

    const urlPath = urlToParse.pathname;
    const pathComponents = urlPath.split('/');
    const webhookStatus = pathComponents[pathComponents.length - 1];

    return webhookStatus;
  } catch (error) {
    return '';
  }
};

export const parseStudyHistoryStatus = (status) => {
  switch (status) {
    case 'Disetujui':
      return 'Disetujui';
    case 'Butuh Review':
      return 'Diproses';
    case 'Ditolak':
      return 'Ditolak';
    default:
      return status;
  }
};

export const removeStoredData = () => {
  // Remove cookies
  Cookies.remove('submissionId');
  Cookies.remove('studyId');
  Cookies.remove('studyType');
  Cookies.remove('isQuestionPro');

  // Remove cookies from domain .populix.co
  Cookies.remove('submissionId', { domain: '.populix.co' });
  Cookies.remove('studyId', { domain: '.populix.co' });
  Cookies.remove('studyType', { domain: '.populix.co' });
  Cookies.remove('isQuestionPro', { domain: '.populix.co' });

  // Clear localStorage
  localStorage.removeItem('SUBMISSION_ID');
  localStorage.removeItem('STUDY_ID');
  localStorage.removeItem('STUDY_TYPE');
  localStorage.removeItem('IS_QUESTIONPRO');
};

export const redirectToWebOfflineParticipatV2 = (currentURL) => {
  const { origin, pathname, search } = new URL(currentURL);
  const v2URL = origin + '/v2' + pathname + search;
  window.location.href = v2URL;
};
