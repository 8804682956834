import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
// import { Button } from '../../components';
export const PopStepper = ({ steps, stepperContext }) => {
  const [activeStep, setActiveStep] = useState(0);
  // NOTE might want to use context for these

  useEffect(() => {
    //check if sessionStorage has payoutRequestMethod object.
    if (sessionStorage.getItem('payoutRequestMethod')) {
      // user JSON parse so we can using object that we save.
      const storage = JSON.parse(sessionStorage.getItem('payoutRequestMethod'))
      setActiveStep(storage.step || 0)
    }
  });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handlePrevious = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const handleReset = () => {
  //   setActiveStep(0);
  // };

  const getStepperContext = (stepIndex) => {
    const Component = stepperContext[stepIndex];
    return <Component nextStepFunction={handleNext} prevStepFunction={handlePrevious} />;
  };

  return (
    <div className='stepper__root'>
      <Stepper activeStep={activeStep} alternativeLabel>
        {Array.isArray(steps) &&
          steps.map((step, key) => (
            <Step key={key}>
              <StepLabel>{step.label || step}</StepLabel>
            </Step>
          ))}
      </Stepper>
      <div className='stepper__context'>{getStepperContext(activeStep)}</div>
      {/* <div className='stepper__footer'>
        {activeStep > 0 && <Button label='Kembali' type='primary' onClick={handlePrevious} />}
        {activeStep !== steps.length - 1 && (
          <Button label='Lanjut' type='primary' onClick={handleNext} />
        )}
        {activeStep === steps.length - 1 && (
          <Button label='Selesai' type='primary' onClick={() => {}} />
        )}
      </div> */}
    </div>
  );
};

PopStepper.propTypes = {
  steps: PropTypes.array.isRequired,
};
