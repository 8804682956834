import {
  // LOGIN_SUCCESS,
  // LOGIN_FAILED,
  FETCH_USER_PROFILE_SUCCESS,
  UPDATE_NEW_MEMBER_STATUS,
  SHOW_SETTINGS_SUCCESS_NOTIFICATION,
  SHOW_SETTINGS_FAILED_NOTIFICATION,
  HIDE_SETTINGS_NOTIFICATION,
  UPDATE_NOTIFICATION_STATUS,
  UPDATE_PHONE_NUMBER,
  UPDATE_KTP_MEMBER_STATUS,
} from './actionTypes';

function fetchUserProfileSuccess(userData) {
  // NOTE Might want to convert the user data here
  // so you dont have to declare any conversion variables anymore.

  const convertedUserData = userData.is_first_login
    ? {
        ...userData,
        is_first_login: userData.is_first_login === 1,
      }
    : userData;

  return (dispatch) => {
    dispatch({
      type: FETCH_USER_PROFILE_SUCCESS,
      convertedUserData,
      authProvider: userData.identities && JSON.parse(userData.identities)[0].providerName,
    });
  };
}

function fetchUserScreening(screeningData) {
  return {
    type: 'FETCH_USER_SCREENING_SUCCESS',
    screeningData,
  };
}

function fetchUserSettingsSuccess(userSettings) {
  return {
    type: 'FETCH_USER_SETTINGS_SUCCESS',
    userSettings,
  };
}

function updateNewMemberStatus(booleanStatus) {
  return {
    type: UPDATE_NEW_MEMBER_STATUS,
    payload: booleanStatus,
  };
}

function updateRdxPhoneNumber(newNumber) {
  // To update the user phone number locally
  return {
    type: UPDATE_PHONE_NUMBER,
    payload: newNumber,
  };
}

function updateNotificationSettingStatus(field_name, value) {
  return {
    type: UPDATE_NOTIFICATION_STATUS,
    fieldName: field_name,
    payload: value,
  };
}

function updateKTPStatus(booleanStatus) {
  return {
    type: UPDATE_KTP_MEMBER_STATUS,
    payload: booleanStatus,
  };
}

function showSuccessNotification() {
  return {
    type: SHOW_SETTINGS_SUCCESS_NOTIFICATION,
  };
}

function showFailedNotification(message = 'Gagal mengubah pengaturan, silahkan coba lagi') {
  return {
    type: SHOW_SETTINGS_FAILED_NOTIFICATION,
    payload: message,
  };
}

function hideNotification() {
  return {
    type: HIDE_SETTINGS_NOTIFICATION,
  };
}

export {
  fetchUserProfileSuccess,
  updateNewMemberStatus,
  showSuccessNotification,
  showFailedNotification,
  hideNotification,
  updateNotificationSettingStatus,
  updateRdxPhoneNumber,
  updateKTPStatus,
  fetchUserSettingsSuccess,
  fetchUserScreening,
};
