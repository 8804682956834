import {
  GET_SALDO,
  GET_SALDO_SUCCESS,
  GET_SALDO_FAILED,
  POST_PAYOUT_REQUEST,
  POST_PAYOUT_REQUEST_SUCCESS,
  POST_PAYOUT_REQUEST_FAILED,
  GET_BANK,
  GET_BANK_SUCCESS,
  GET_BANK_FAILED,
  GET_BANK_DATA,
  GET_BANK_DATA_FAILED,
  GET_BANK_DATA_SUCCESS,
} from './actionTypes';
import PopCall from '../../services/PopCall';

const popCall = new PopCall();

export function getUserSaldo() {
  // const walletPath = `/users/${email}/wallet`;
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: GET_SALDO });
      try {
        const balanceObject = await popCall.get('/participant/balances');
        dispatch({ type: GET_SALDO_SUCCESS, payload: balanceObject.data });
        resolve(balanceObject);
      } catch (error) {
        dispatch({ type: GET_SALDO_FAILED, payload: error });
        reject(error);
      }
    });
  };
}

export function withdrawSaldo(amountToWithdraw, payoutType, participant_acc_id) {
  const objectToSend = {
    paymentInfoId: participant_acc_id,
    payoutAmount: parseFloat(amountToWithdraw),
    payoutType,
  };
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: POST_PAYOUT_REQUEST });
      try {
        const data = await popCall.post('/payout', objectToSend);
        dispatch({ type: POST_PAYOUT_REQUEST_SUCCESS, payload: data });
        resolve(data);
      } catch (error) {
        dispatch({ type: POST_PAYOUT_REQUEST_FAILED, payload: error });
        reject(error);
      }
    });
  };
}
// NOTE convert this to get by id,
// for editing existing data.
export function getBankInformation() {
  const path = `/paymentinfo`;
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: GET_BANK });
      try {
        const request = await popCall.get(path);
        dispatch({ type: GET_BANK_SUCCESS, payload: request.data });
        resolve(request.data);
      } catch (error) {
        dispatch({ type: GET_BANK_FAILED, payload: error });
        reject(error);
      }
    });
  };
}

export function getBankData() {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: GET_BANK_DATA });
      try {
        const request = await popCall.get('/paymentinfo');
        const sortedData = [...request.data].sort((x, y) => (x.default > y.default ? -1 : 1));
        dispatch({ type: GET_BANK_DATA_SUCCESS, payload: sortedData });
        resolve(request.data);
      } catch (error) {
        dispatch({ type: GET_BANK_DATA_FAILED, payload: error });
        reject(error);
      }
    });
  };
}
