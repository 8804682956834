import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import {
  protectedBtnPressed,
  resetActiveStudyState
} from '../../store/actions/activeStudyActions';
class ProtectedBtn extends Component {
  _onButtonPressed = () => {
    const { is_study_active, history, linkTo } = this.props;
    const protected_btn_pressed = this.props.protectedBtnPressed;
    if (is_study_active) {
      protected_btn_pressed(linkTo);
    } else {
      history.push(linkTo);
    }
  };

  render() {
    const { children, className } = this.props;
    return (
      <a
        onClick={this._onButtonPressed}
        style={{ cursor: 'pointer' }}
        className={className}
      >
        {children}
      </a>
    );
  }
}

const mapStateToProps = state => {
  return {
    is_study_active: state.activeStudy.is_study_active,
    protected_btn_pressed: state.activeStudy.protected_btn_pressed
  };
};
export default withRouter(
  connect(
    mapStateToProps,
    { protectedBtnPressed, resetActiveStudyState }
  )(ProtectedBtn)
);
