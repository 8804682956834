import React from 'react';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { animated, Transition } from 'react-spring/renderprops';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import ReactCodeInput from 'react-code-input';
import Countdown from 'react-countdown-now';
import populixLogo from 'assets/img/logo.png';
import default_success_image from 'assets/img/selesai-studi.svg';

export const ModalError = ({
  show,
  dataObjects,
  errorMsgHead,
  errorMsgDetail,
  backButtonText,
  animatedModal,
  handleCloseFunction,
  style,
  noMessageHeader,
  hideBackButton,
}) => {
  const ContainerTag = animatedModal ? animated.div : 'div';

  const classes = classNames('modal', {
    'display-block': animatedModal || (show && show === true),
    'display-none': show && show === false,
  });

  return (
    <ContainerTag className={classes} style={{ ...style }}>
      <section className='modal-main'>
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header border-bottom py-4'>
              <img src={populixLogo} style={{ height: 32, width: 110 }} alt='populix' />
              {!hideBackButton && (
                <button type='button' className='close' onClick={handleCloseFunction}>
                  <span>&times;</span>
                </button>
              )}
            </div>
            <div>
              <div className='modal-body'>
                {!noMessageHeader && (
                  <div className='d-flex justify-content-between align-items-center'>
                    <h4 className='m-0'>
                      {/* NOTE Might Want to use safer approach */}
                      Maaf, {dataObjects && dataObjects.name} 😞
                    </h4>
                    {/* <span onClick={handleCloseFunction}>
                    <FontAwesomeIcon icon={faTimesCircle} color='#f14336' size='2x' />
                  </span> */}
                  </div>
                )}
                <div className='my-2'>
                  <h3 className='mb-3'>{errorMsgHead}</h3>
                  <h4 className='mb-3'>{errorMsgDetail}</h4>
                </div>
              </div>
              {!hideBackButton && (
                <div className='modal-footer modal-footer-type-2 float-right'>
                  <button
                    id='btn_modal-error'
                    type='button'
                    className='btn btn-transparent btn--text text--light-blue modal-footer-btn'
                    onClick={handleCloseFunction}
                  >
                    {backButtonText || 'Kembali ke Beranda'}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </ContainerTag>
  );
};

export const ModalSuccess = ({
  show,
  dataObjects,
  successMsgHead,
  successMsgDetail,
  buttonText,
  handleClose,
  handleContinue,
  mainImage,
  animatedModal,
  style,
}) => {
  const ContainerTag = animatedModal ? animated.div : 'div';

  const classes = classNames('modal', {
    'display-block': animatedModal || (show && show === true),
    'display-none': show && show === false,
  });

  return (
    <ContainerTag className={classes} style={{ ...style }}>
      <section className='modal-main'>
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <button type='button' className='close' onClick={handleClose}>
                <span>&times;</span>
              </button>
            </div>
            <div className='modal-body text-center'>
              <img className='img-lg' src={mainImage || default_success_image} alt='congratulate' />
              <h4 className='font-bold mt-4'>{successMsgHead}</h4>
              <p>{successMsgDetail}</p>
            </div>
            <div className='modal-footer modal-footer-type-1'>
              <div
                onClick={handleContinue}
                role='button'
                // to={LinkTo}
                className='btn btn-primary text--white modal-footer-btn'
              >
                {buttonText || 'Selanjutnya'}
              </div>
            </div>
          </div>
        </div>
      </section>
    </ContainerTag>
  );
};

export const ModalPrompt = ({
  handleClose,
  handleConfirmation,
  style,
  processingData,
  animatedModal,
  show,
  mainPromptMsg,
  extraPromptMsg,
  mainLoadingMsg,
  extraLoadingMsg,
}) => {
  // NOTE Might wanna make these more dynamic
  // Also, think about the unused transition if we're not using animatedModal
  const ContainerTag = animated.div;
  const ChildTagParagraph = animated.p;
  const ChildTagH4 = animated.h4;
  const ChildTagBtn = animated.button;

  const classes = classNames('modal', {
    'display-block': animatedModal || (show && show === true),
    'display-none': show && show === false,
  });

  return (
    <ContainerTag className={classes} style={{ ...style }}>
      <section className='modal-main'>
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              {!processingData && (
                <button type='button' className='close' onClick={handleClose}>
                  <span>&times;</span>
                </button>
              )}
            </div>
            <div className='modal-body'>
              <Transition
                native
                items={processingData}
                from={{
                  transform: 'translate(0, -10px)',
                  opacity: 0,
                  position: 'absolute',
                }}
                enter={{
                  transform: 'translate(0, 0)',
                  opacity: 1,
                  position: 'relative',
                }}
                leave={{
                  transform: 'translate(0, -10px)',
                  opacity: 0,
                  position: 'absolute',
                }}
              >
                {(changeLoadingStatus) => (style) =>
                  !changeLoadingStatus ? (
                    <ChildTagH4 // NOTE might wanna make this more dynamic
                      style={{ ...style }}
                      className='font-bold mt-4'
                    >
                      {mainPromptMsg}
                    </ChildTagH4>
                  ) : (
                    <ChildTagH4 style={{ ...style }} className='font-bold mt-4'>
                      {mainLoadingMsg}
                    </ChildTagH4>
                  )}
              </Transition>
              <Transition
                native
                items={processingData}
                from={{
                  transform: 'translate(0, -15px)',
                  opacity: 0,
                  position: 'absolute',
                }}
                enter={{
                  transform: 'translate(0, 0)',
                  opacity: 1,
                  position: 'relative',
                }}
                leave={{
                  transform: 'translate(0, -15px)',
                  opacity: 0,
                  position: 'absolute',
                }}
              >
                {(processing) => (styleProps) =>
                  !processing ? (
                    <ChildTagParagraph style={{ ...styleProps }}>
                      {extraPromptMsg}
                    </ChildTagParagraph>
                  ) : (
                    <ChildTagParagraph
                      className='text-color--warning font-bold'
                      style={{ ...styleProps }}
                    >
                      {extraLoadingMsg}
                    </ChildTagParagraph>
                  )}
              </Transition>
            </div>
            <div className='modal-footer modal-footer-type-2'>
              <button
                onClick={handleClose}
                type='button'
                disabled={processingData}
                // to={LinkTo}
                className='btn btn-primary text--white modal-footer-btn'
              >
                Tidak
              </button>

              <Transition
                native
                items={processingData}
                from={{
                  transform: 'translate(-15px, 0)',
                  opacity: 0,
                  position: 'absolute',
                }}
                enter={{
                  transform: 'translate(0, 0)',
                  opacity: 1,
                  position: 'relative',
                }}
                leave={{
                  transform: 'translate(-15px, 0)',
                  opacity: 0,
                  position: 'absolute',
                }}
              >
                {(processing) => (styleProps) =>
                  !processing ? (
                    <ChildTagBtn
                      type='button'
                      onClick={handleConfirmation}
                      className='btn btn-primary btn-secondary text--white modal-footer-btn'
                    >
                      Iya
                    </ChildTagBtn>
                  ) : (
                    <ChildTagH4 style={{ ...styleProps }} className='text-color--warning font-bold'>
                      Memproses
                    </ChildTagH4>
                  )}
              </Transition>
            </div>
          </div>
        </div>
      </section>
    </ContainerTag>
  );
};

export const ModalInput = ({
  handleClose,
  show,
  handleSubmitForm,
  handleFormChange,
  formLabelMain,
  formValidationMsg,
  formValidationFailed,
  animatedModal,
  value,
  style,
  title,
  submitLabel,
  dangerLabel, //added danger label for warning button
  cancelLabel,
  handleCancelForm,
  type,
  disabled,
  handleCountDown,
  countDownStart,
  changePhoneNumber,
}) => {
  const ContainerTag = animatedModal ? animated.div : 'div';
  const classes = classNames('modal', {
    'display-block': animatedModal || (show && show === true),
    'display-none': show && show === false,
  });
  return (
    <ContainerTag className={classes} style={{ ...style }}>
      <section className='modal-main'>
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              {changePhoneNumber ? (
                <img src={populixLogo} style={{ height: 32, width: 110 }} alt='populix' />
              ) : (
                <>{title || ''}</>
              )}
              <button type='button' className='close' onClick={handleClose}>
                <span>&times;</span>
              </button>
            </div>
            <form onSubmit={handleSubmitForm}>
              <div className='modal-body'>
                <div className='cash-withdraw--form'>
                  <div className='form-group'>
                    {changePhoneNumber ? (
                      <>
                        <h3 className='mb-3'>Maaf, Kamu tidak bisa mengganti Nomor Handphone</h3>
                        <h4 className='mb-3'>
                          Silahkan hubungi tim support kami untuk melakukan penggantian Nomor
                          Handphone.
                        </h4>
                      </>
                    ) : (
                      <>
                        {/* label for payout request */}
                        {formLabelMain && (
                          <label
                            htmlFor='cash-withdraw--input'
                            className='font-bold cash-withdraw__question'
                          >
                            {formLabelMain}
                          </label>
                        )}
                        {type === 'phone' && (
                          <NumberFormat
                            className='form-control cash-withdraw__input modal__input'
                            displayType={'input'}
                            placeholder={'+62'}
                            format='+62 ### #### #### ###'
                            value={value || ''}
                            onValueChange={(values) => handleFormChange(values.floatValue)}
                            isAllowed={(values) => {
                              const { value, floatValue } = values;

                              if (value.charAt(0) === '0') {
                                return false;
                              } else {
                                return true;
                              }
                            }}
                          />
                        )}
                        {type === 'verification' && (
                          <React.Fragment>
                            <ReactCodeInput
                              className='code-input mb-3 mt-2'
                              type='number'
                              disabled={disabled}
                              isValid={!formValidationFailed}
                              inputStyleInvalid={{ border: '1px solid red' }}
                              fields={6}
                              value={value}
                              onChange={(values) => handleFormChange(values)}
                            />
                            <div style={{ width: '100%', textAlign: 'center' }}>
                              {countDownStart ? (
                                <small
                                  style={{
                                    color: formValidationFailed ? '#f14336' : '#0d2433',
                                  }}
                                >
                                  <Countdown
                                    date={Date.now() + 40000}
                                    renderer={(props) => (
                                      <div>{`Mohon menunggu ${props.seconds} detik untuk mengirim ulang`}</div>
                                    )}
                                    onComplete={handleCountDown}
                                  />
                                </small>
                              ) : (
                                <React.Fragment>
                                  <p>Tidak menerima Kode? </p>
                                  <button
                                    type='button'
                                    onClick={handleSubmitForm}
                                    className='btn btn-primary f-13 m-0'
                                    disabled={disabled}
                                  >
                                    Kirim Ulang
                                  </button>
                                </React.Fragment>
                              )}
                              <small
                                style={{
                                  color: formValidationFailed ? '#f14336' : '#0d2433',
                                }}
                              >
                                {formValidationMsg}
                              </small>
                            </div>
                          </React.Fragment>
                        )}
                      </>
                    )}
                    {/* add exception for type checking */}
                    {type !== 'phone' && type !== 'verification' && type !== 'deleteAccount' && (
                      <input
                        type='text' // NOTE change the attribute via  props
                        name='payoutNominal'
                        className='form-control cash-withdraw__input modal__input'
                        // TODO move to scss
                        style={{
                          borderColor: formValidationFailed ? '#f14336' : '#ced4da',
                        }}
                        id='cash-withdraw--password'
                        onChange={handleFormChange}
                        value={value}
                        // defaultValue={value}
                      />
                    )}
                    {/* info for account deletion */}
                    {type === 'deleteAccount' && (
                      <div>
                        <label>
                          Menghapus akun berarti menghilangkan semua akses dan kesempatanmu untuk
                          berpartisipasi di Populix secara permanen.
                        </label>
                        <label>
                          Jika Anda menghapus akun, maka email dan nomor handphone yang terdaftar
                          untuk akun ini tidak dapat kembali digunakan.
                        </label>
                      </div>
                    )}

                    {/* TODO move to scss */}
                    {type !== 'verification' && (
                      <small
                        style={{
                          color: formValidationFailed ? '#f14336' : '#0d2433',
                        }}
                      >
                        {formValidationMsg}
                      </small>
                    )}
                  </div>
                </div>
              </div>
              <div className='modal-footer modal-footer-type-2'>
                {changePhoneNumber ? (
                  <>
                    <button
                      type='button'
                      className='btn btn-transparent btn--text text--light-blue modal-footer-btn'
                      onClick={handleClose}
                    >
                      Okay
                    </button>
                  </>
                ) : (
                  <>
                    {cancelLabel && (
                      <button
                        type='button'
                        onClick={handleCancelForm}
                        className='btn btn-secondary modal-footer-btn f-13'
                        disabled={disabled}
                      >
                        {cancelLabel || ''}
                      </button>
                    )}
                    {submitLabel && (
                      <button
                        type='submit'
                        className='btn btn-primary modal-footer-btn f-13'
                        disabled={disabled}
                      >
                        {submitLabel || ''}
                      </button>
                    )}
                    {/* red button */}
                    {dangerLabel && (
                      <button
                        style={{ padding: '0.75rem 1.12rem 0.87rem' }}
                        type='submit'
                        className='btn btn-danger modal-footer-btn f-13'
                        disabled={disabled}
                      >
                        {dangerLabel || ''}
                      </button>
                    )}
                  </>
                )}
              </div>
            </form>
          </div>
        </div>
      </section>
    </ContainerTag>
  );
};

// Use Hooks
export const ModalFormRekening = ({
  handleClose,
  show,
  handleSubmitForm,
  handleChangeForm,
  animatedModal,
  style,
  accountName,
  accountNumber,
  bank,
  isNewRekening,
  formValidation,
  bankList,
}) => {
  const ContainerTag = animatedModal ? animated.div : 'div';
  const classes = classNames('modal', {
    'display-block': animatedModal || (show && show === true),
    'display-none': show && show === false,
  });
  const { accountNameForm_invalid, bankForm_invalid, accountNumberForm_invalid } = formValidation;
  const customDropdownStyle = {
    // Change react-select's dropdown default, focus, and hover state to match bootstrap's text input
    control: (provided, state) => ({
      ...provided,
      borderColor: bankForm_invalid ? '#f14336' : '#e6e8ea',
      color: '#495057',
      backgroundColor: !state.isFocused || '#fff',
      outline: !state.isFocused || 0,
      boxShadow: !state.isFocused || '0 0 0 0.2rem rgba(0, 123, 255, 0.25)',
      '&:hover': {
        borderColor: '#e6e8ea',
      },
    }),
  };

  return (
    <ContainerTag className={classes} style={{ ...style }}>
      <section className='modal-main'>
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <button type='button' className='close' onClick={handleClose}>
                <span>&times;</span>
              </button>
            </div>
            <form>
              <div className='modal-body'>
                <div className='form-group'>
                  <label className='settings__question' htmlFor='bank-account-name'>
                    Nama pemilik rekening
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='bank-account-name'
                    name='accountName'
                    onChange={handleChangeForm}
                    value={accountName || ''}
                    style={{
                      borderColor: accountNameForm_invalid ? '#f14336' : '#e6e8ea',
                    }}
                  />
                  <p
                    className='f-12 my-1'
                    style={{ color: accountNameForm_invalid ? '#f14336' : '#212529' }}
                  >
                    {accountNameForm_invalid
                      ? 'Nama tidak boleh kosong'
                      : 'Nama yang tertera harus sesuai dengan yang terdaftar di Bank'}
                  </p>
                  <label className='settings__question' htmlFor='bank-account-bank-name'>
                    Nama bank
                  </label>
                  {/* <input
                    type='text'
                    className='form-control settings__input'
                    id='bank-account-bank-name'
                    name='bank'
                    onChange={handleChangeForm}
                    value={bank || ''}
                    style={{
                      borderColor: bankForm_invalid ? '#f14336' : '#e6e8ea',
                    }}
                  /> */}
                  <Select
                    isSearchable
                    onChange={handleChangeForm}
                    options={bankList}
                    value={bankList.filter((bankData) => bankData.value === bank)[0]}
                    className='mb-2'
                    styles={customDropdownStyle}
                  />
                  {bankForm_invalid && (
                    <p className='f-12 my-1' style={{ color: '#f14336' }}>
                      Nama Bank tidak boleh kosong
                    </p>
                  )}
                  <label className='settings__question' htmlFor='bank-account-number'>
                    No. rekening
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='bank-account-number'
                    name='accountNumber'
                    onChange={handleChangeForm}
                    value={accountNumber || ''}
                    style={{
                      borderColor: accountNumberForm_invalid ? '#f14336' : '#e6e8ea',
                    }}
                  />
                  {accountNumberForm_invalid && (
                    <p className='f-12 my-1' style={{ color: '#f14336' }}>
                      Nomor rekening tidak boleh kosong
                    </p>
                  )}
                  <button
                    type='button'
                    className='btn btn-primary modal-btn btn-rekening'
                    onClick={handleSubmitForm}
                  >
                    {isNewRekening ? 'Tambah Rekening' : 'Ubah Rekening'}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </ContainerTag>
  );
};

export const Modal = ({ children, styles, classname, animatedModal, show }) => {
  const ContainerTag = animatedModal ? animated.div : 'div';
  const classes = classNames(classname, {
    'display-block': animatedModal || (show && show === true),
    'display-none': show && show === false,
  });
  return (
    <ContainerTag className={classes} style={{ ...styles }}>
      <section className='modal-main'>
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>{children}</div>
        </div>
      </section>
    </ContainerTag>
  );
};

ModalError.propTypes = {
  show: PropTypes.bool,
  handleCloseFunction: PropTypes.func.isRequired,
  errorMsgHead: PropTypes.string.isRequired, // The main error message
  errorMsgDetail: PropTypes.string, // Extra message if any
  backButtonText: PropTypes.string, // button text, by default it will show 'Kembali ke Beranda'
  animatedModal: PropTypes.bool, // Use animation or Not, If using animation, you'll have to implement <Transition /> in its container
  dataObjects: PropTypes.object, // Extra object, might not need this
  style: PropTypes.object, // You can use this to pass the style from react-spring animation or to override some styling
};

// NOTE description is similar to ModalError
ModalSuccess.propTypes = {
  show: PropTypes.bool,
  dataObjects: PropTypes.object,
  successMsgHead: PropTypes.string.isRequired,
  successMsgDetail: PropTypes.string,
  buttonText: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  handleContinue: PropTypes.func.isRequired,
  mainImage: PropTypes.string,
  animatedModal: PropTypes.bool,
  style: PropTypes.object,
};

ModalPrompt.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleConfirmation: PropTypes.func.isRequired,
  style: PropTypes.object,
  processingData: PropTypes.bool,
  animatedModal: PropTypes.bool,
  show: PropTypes.bool,
  mainPromptMsg: PropTypes.string.isRequired,
  extraPromptMsg: PropTypes.string,
  mainLoadingMsg: PropTypes.string.isRequired,
  extraLoadingMsg: PropTypes.string,
};

Modal.propTypes = {
  children: PropTypes.object,
  classname: PropTypes.string,
  animatedModal: PropTypes.bool,
  styles: PropTypes.object,
  show: PropTypes.bool,
};
