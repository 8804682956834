import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import logo from 'assets/img/study-with-code-icon.png';
import { Transition, animated } from 'react-spring/renderprops';
import { Button } from '../';
import { StudyItem } from '../../components';

const styles = {
  colorPrimary: {
    backgroundColor: '#d9eff9',
  },
  barColorPrimary: {
    backgroundColor: '#007bff66',
  },
  main: {
    height: 20,
    marginBottom: 16,
  },
};

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
    maxWidth: 350,
  },
}));

function MouseOverPopover() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup='true'
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <InfoOutlinedIcon style={{ fontSize: 15 }} />
      </Typography>
      <Popover
        id='mouse-over-popover'
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography color='inherit'>
          <strong>Apa Itu Kode Studi?</strong>
        </Typography>
        <Typography color='inherit'>
          Kode studi adalah kode unik untuk mengakses suatu studi dan hanya dapat digunakan oleh
          partisipan yang memiliki kode unik tersebut
        </Typography>
        <Typography color='inherit'>
          *Mau tau lebih lanjut bagaimana cara menggunakan fitur kode studi? Ikutin terus update
          dari @populix.co di Instagram ya!
        </Typography>
      </Popover>
    </div>
  );
}
class SearchByCode extends Component {
  state = {
    code: '',
  };

  componentDidUpdate() {}

  _onChangeStudyCode = (e) => {
    const { target } = e;
    const { value } = target;
    this.setState({
      code: value.toUpperCase(),
    });
  };

  render() {
    const { code } = this.state;
    const { onCheckStudy, navigateToDetail, study, status } = this.props;

    const isError =
      status === 'FULL' ||
      status === 'TAKEN' ||
      status === 'NOT_FOUND' ||
      status === 'ERROR' ||
      status === 'EXPIRED';
    let statusMessage = '';

    if (status === 'FULL') {
      statusMessage = 'Maaf, studi sudah penuh.';
    } else if (status === 'TAKEN') {
      statusMessage = 'Maaf, studi sudah pernah diambil. Lihat Riwayat Studi.';
    } else if (status === 'NOT_FOUND' || status === 'EXPIRED') {
      statusMessage = 'Studi tidak ditemukan.';
    } else if (status === 'ERROR') {
      statusMessage = 'Terdapat kesalahan. Harap coba kembali.';
    }

    return (
      <>
        <div className='study-item'>
          <img className='study-icon' src={logo} alt='study-icon' />
          <div>
            <div className='study-header mb-3 d-flex align-items-center'>
              <h4 className='font-bold mb-0 mr-1'>Kamu Punya Kode Studi ?</h4>
              <MouseOverPopover />
            </div>
            <form>
              <div className='form-group'>
                <input
                  name='studyCode'
                  type='text'
                  className='form-control settings__input'
                  id='studyCode'
                  onChange={this._onChangeStudyCode}
                  placeholder='Masukkan kode studi disini'
                  style={{
                    marginBottom: 2,
                  }}
                />
                {isError && (
                  <label
                    className={
                      status === 'FULL' || status === 'TAKEN'
                        ? 'text-color--primary'
                        : 'text-color--danger'
                    }
                    style={{ borderBottom: 0 }}
                  >
                    {statusMessage}
                  </label>
                )}
              </div>
            </form>
          </div>
          <Button
            className='btn btn-block btn--orange'
            style={{ marginBottom: isError ? 48 : 18 }}
            onClick={() => {
              onCheckStudy(code);
            }}
            type='warning'
            label='Cek Studi'
          />
        </div>
        {status === 'OK' && (
          <Transition
            native
            items={true}
            from={{
              transform: 'translate(0, -25px)',
              opacity: 0,
              position: 'absoluste',
            }}
            enter={{
              transform: 'translate(0, 0)',
              opacity: 1,
              position: 'relative',
            }}
            leave={{
              transform: 'translate(0, -25px)',
              opacity: 0,
            }}
          >
            {() => (styleProps) => (
              <animated.h5
                style={{ ...styleProps }}
                // className='text-color--warning font-bold mb-0 ml-4'
              >
                <StudyItem
                  data={study}
                  buttonFunction={(studyId) => {
                    navigateToDetail(studyId, code);
                  }}
                />
              </animated.h5>
            )}
          </Transition>
        )}
      </>
    );
  }
}

SearchByCode.propTypes = {
  onCheckStudy: PropTypes.func,
};

export default withStyles(styles)(SearchByCode);
