import React, { Component } from 'react';
import { Button } from '../../components';
import { connect } from 'react-redux';

class MetodePenarikan extends Component {
  state = {
    selectedMethod: {
      type: '',
    },
    showModal: false,
  };

  handleChange = (e) => {
    const { value } = e.target;
    const { selectedMethod } = this.state;
    const { type } = selectedMethod;

    // insert logic checking here. like checking Hp and showing popup
    if (value === type) {
      this.setState({ selectedMethod: { type: '' } });
    } else {
      this.setState({ selectedMethod: { type: value } });
    }
  };

  handleNext = async () => {
    const { selectedMethod } = this.state;
    if (selectedMethod) {
      const storage = await JSON.parse(sessionStorage.getItem('payoutRequestMethod'));
      const payoutRequestMethod = {
        selectedMethod,
        step: 2,
        nominal: storage.nominal,
      };
      await sessionStorage.setItem('payoutRequestMethod', JSON.stringify(payoutRequestMethod));
      this.props.nextStepFunction();
    }
  };

  handlePrevious = async () => {
    await sessionStorage.removeItem('payoutRequestMethod');
    this.props.prevStepFunction();
  };

  render() {
    const { featureFlag } = this.props;
    const { selectedMethod } = this.state;
    const { type } = selectedMethod;
    return (
      <div className='payout-request d-flex flex-column mt-3'>
        <div className='payout-request__content'>Metode Penarikan</div>
        <div className='mt-4'>
          {featureFlag &&
            featureFlag.payoutOptions &&
            featureFlag.payoutOptions.payoutMethod &&
            featureFlag.payoutOptions.payoutMethod.bankTransfer && (
              <label className='radio-container mb-3'>
                <input
                  type='checkbox'
                  value='Bank'
                  checked={type === 'Bank'}
                  onChange={this.handleChange}
                />
                Bank Transfer
                <span className='checkmark'></span>
              </label>
            )}
          {featureFlag &&
            featureFlag.payoutOptions &&
            featureFlag.payoutOptions.payoutMethod &&
            featureFlag.payoutOptions.payoutMethod.gopay && (
              <label className='radio-container mb-3'>
                <input
                  type='checkbox'
                  value='Gopay'
                  checked={type === 'Gopay'}
                  onChange={this.handleChange}
                />
                GoPay
                <span className='checkmark'></span>
              </label>
            )}
          {featureFlag &&
            featureFlag.payoutOptions &&
            featureFlag.payoutOptions.payoutMethod &&
            featureFlag.payoutOptions.payoutMethod.ovo && (
              <label className='radio-container mb-3'>
                <input
                  type='checkbox'
                  value='Ovo'
                  checked={type === 'Ovo'}
                  onChange={this.handleChange}
                />
                OVO
                <span className='checkmark'></span>
              </label>
            )}
        </div>
        <div className='stepper__footer'>
          <div className='col-sm-6'>
            <Button label='Kembali' type='primary' onClick={this.handlePrevious} block />
          </div>
          <div className='col-sm-6'>
            <Button
              label='Lanjut'
              type='primary'
              block
              disabled={!selectedMethod.type}
              onClick={this.handleNext}
            />
          </div>
        </div>
        {/* <Transition
          native
          items={showModal}
          from={{ transform: 'translate(0, -100%)' }}
          enter={{ transform: 'translate(0, 0)' }}
          leave={{ transform: 'translate(0, -100%)' }}
        >
          {(showBanks) => (style) =>
            showBanks && (
              <Modal classname='modal' show={showBanks} styles={style} animatedModal>
                <div className='modal-header'>
                  <p>Pilih Rekening bank anda</p>
                  <button type='button' className='close' onClick={this._togglePopup}>
                    <span>&times;</span>
                  </button>
                </div>
              </Modal>
            )}
        </Transition> */}
        {/* <Transition
          native
          items={showModalAdd}
          from={{ transform: 'translate(-100%, 0)' }}
          enter={{ transform: 'translate(0, 0)' }}
          leave={{ transform: 'translate(-100%, 0)' }}
        >
          {(showNewRekening) => (styleX) =>
            showNewRekening && (
              <ModalFormRekening
                show={showNewRekening}
                animatedModal
                style={styleX}
                isNewRekening={true}
                formValidation={formValidation}
                accountName={accountName}
                accountNumber={accountNumber}
                bank={bank}
                handleClose={this._toggleNewRekening}
              />
            )}
        </Transition> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  bankList: state.wallet.bankObject,
  featureFlag: state.featureFlag,
});

export default connect(mapStateToProps, {})(MetodePenarikan);
