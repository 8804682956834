import React, { Component } from 'react';
import { StudyItem } from '../../../components';
import { connect } from 'react-redux';
import dot from 'assets/img/dot.png';
import noDataIcon from 'assets/img/berenang-icon.png';
import { fetchStudyHistories } from '../../../store/actions/studyActions';
import { StudyHistory } from '../../../components';
class StudyHistoryMain extends Component {
  state = {
    historyData: [],
    loading: true,
    error: null
  };

  async componentDidMount() {
    const { profile } = this.props;
    const { email } = profile;
    try {
      const studyHistories = await fetchStudyHistories(email);
      this.setState({ historyData: studyHistories });
    } catch (error) {
      this.setState({ error });
    } finally {
      this.setState({ loading: false });
    }
    // setTimeout(() => {
    //   this.setState({ loading: false });
    // }, 2500);
  }

  _renderStudyLoading = () => {
    // todo, might need to refactor these
    return (
      <div>
        <StudyItem loading={true} />
        <StudyItem loading={true} />
        <StudyItem loading={true} />
      </div>
    );
  };

  _renderEmpty = () => {
    return (
      <div className="study-list__empty-container">
        <img className="img-3-dots" src={dot} alt="dot" />
        <h6 className="text-uppercase">
          Saat ini, belum ada riwayat studi yang tersedia bagi kamu
        </h6>
        {/** TODO move this to scss */}
        <div
          className="study-list__empty-image-container"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'center'
          }}
        >
          <img
            className="img-fluid mt-4"
            src={noDataIcon}
            alt="swim"
            style={{ width: '65%' }}
          />
        </div>
      </div>
    );
  };

  render() {
    const { historyData, loading } = this.state;

    return (
      <div className="study-history__main">
        {loading && this._renderStudyLoading()}

        {!loading &&
          Array.isArray(historyData) &&
          historyData.length === 0 &&
          this._renderEmpty()}
        {!loading &&
          Array.isArray(historyData) &&
          historyData.map((history, key) => (
            <StudyHistory key={key} historyData={history} />
          ))}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    profile: state.profile.userProfile
  };
};
export default connect(mapStateToProps)(StudyHistoryMain);
