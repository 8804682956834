class BrowserNavigator {
  constructor() {
    const navigator = window.navigator;
    this.navigator = navigator;
    this.user_agent = navigator.userAgent;
    this.app_version = navigator.appVersion;
  }

  // For further development
  // get getUserBrowser() {
  //   let browser;
  //   const known_browser = [
  //     'Firefox',
  //     'Chrome',
  //     // 'Safari',
  //     'Edge',
  //     'MSIE',
  //     'Netscape'
  //   ];

  //   for (let i = 0; i < known_browser.length; i++) {
  //     if (this.user_agent.indexOf(known_browser[i]) !== -1) {
  //       browser = known_browser[i];
  //     }
  //   }
  //   // }

  get operatingSystem() {
    const iOS = /iPad|iPhone|iPod/.test(this.user_agent);
    const android = /android|Android/.test(this.user_agent);
    const macintosh = /Macintosh/.test(this.user_agent);
    const windows = /Win64|Win32/.test(this.user_agent);

    if (iOS) {
      return 'IOS';
    }
    if (android) {
      return 'ANDROID';
    }
    if (macintosh) {
      return 'MAC_OS';
    }
    if (windows) {
      return 'WINDOWS';
    }
    return 'Unknown OS';
  }

  //   return browser;
  // }
}

export default BrowserNavigator;
