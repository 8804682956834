import { Keyframes } from 'react-spring/renderprops';

export const AnimatedLoginButton = Keyframes.Spring(async (next) => {
  while (true) {
    await next({
      from: { backgroundColor: '#179DFE' },
      to: { backgroundColor: '#edeff2', color: 'black' },
    });
  }
});
