import React from 'react';

const SpinnerBasic = (color, size) => {
	return <div style={{ color, fontSize: size }}>aaa</div>;
};

const SpinnerRipple = (color, size) => {
	return (
		<div className="spinner-ripple__container">
			<div className="spinner-ripple__child" />
			<div className="spinner-ripple__child" />
		</div>
	);
};

const SpinnerRoller = (color, size) => {
	return (
		<div className="spinner-roller__container">
			<div className="spinner-roller__child" />
			<div className="spinner-roller__child" />
			<div className="spinner-roller__child" />
			<div className="spinner-roller__child" />
			<div className="spinner-roller__child" />
			<div className="spinner-roller__child" />
			<div className="spinner-roller__child" />
		</div>
	);
};

export default ({ type, color, size }) => {
	switch (type) {
		case 'basic':
			return SpinnerBasic(color, size);
		case 'ripple':
			return SpinnerRipple(color, size);
		case 'roller':
			return SpinnerRoller(color, size);
		default:
			return SpinnerBasic(color, size);
	}
};
