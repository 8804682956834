import React, { Component } from 'react';
import { Route, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateAuth } from 'store/actions/authActions';
import { fetchUserProfileSuccess } from 'store/actions/profileActions';
import { persistor } from 'store/store';
import { checkAuthDb } from '../../helpers/authHelpers';

class PrivateRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
  }
  componentDidMount() {
    this.authenticate();
    this.unlisten = this.props.history.listen(async () => {
      try {
        await checkAuthDb();
      } catch (error) {
        if (this.state.isAuthenticated) this.setState({ loaded: false });
      }
      // 👇🏻 I dont know what this is.....but I move it above 👆🏻
      // Auth.currentAuthenticatedUser()
      //   // .then(user => user)
      //   .catch(() => {
      //    if (this.state.isAuthenticated) this.setState({ loaded: false });
      //   });
    });
  }

  componentWillUnmount() {
    // this.unlisten();
    // this.setState({ loaded: true, isAuthenticated: false })
  }

  async authenticate() {
    const { location, history } = this.props;
    // No need to check for flag
    // const fromEmail = location.search.search('from_ext=email_redir') !== -1;
    try {
      const currentAuthenticatedData = await checkAuthDb();
      const redir_target = sessionStorage.getItem('redir_target');

      if (redir_target) {
        const search = window.location.search;
        const url = new URLSearchParams(search);

        const fbCallback = url.has('callback');

        if (fbCallback) {
          sessionStorage.removeItem('redir_target');
          history.push(redir_target);
        }

        // Check if sessionStorage with key redir_target exist
        sessionStorage.removeItem('redir_target');
      }
      const newAuthData = { ...currentAuthenticatedData };

      // send userId(db participant sub) to dataLayer
      if (process.env.NODE_ENV === 'production') {
        window.dataLayer.push({ userId: newAuthData.sub });
      }
      await this.props.updateAuth(true);
      // rehydrate attributes on checking
      this.props.fetchUserProfileSuccess(newAuthData);
      this.setState({ loaded: true });
    } catch (error) {
      if (error === 'not authenticated') {
        // Save the route to the sessionStorage to be consumed later.
        // This should happen only if user is trying to access PrivateRoute directly
        // while unauthenticated
        sessionStorage.setItem('redir_target', location.pathname + location.search);
        // we're saving location.search to get extra params such as surveygizmo's sguid
      }
      this.setState({ loaded: true });
      persistor.purge();
      await localStorage.clear();
    }
  }

  render() {
    const { component: Component, isAuthenticated, authProvider, ...rest } = this.props;
    const { loaded } = this.state;

    if (!loaded) {
      return null;
    }
    return (
      <Route
        {...rest}
        render={(props) => {
          return isAuthenticated || authProvider ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: '/login',
              }}
            />
          );
        }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    authProvider: state.auth.authProvider,
  };
};
export default connect(mapStateToProps, { updateAuth, fetchUserProfileSuccess })(
  withRouter(PrivateRoute),
);
