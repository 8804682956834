import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';
import populixLogo from 'assets/img/logo.png';
import { checkAuthStatus } from '../../helpers/authHelpers';
import { updateAuth } from '../../store/actions/authActions';

class LpTopNav extends Component {
  async componentDidMount() {
    const { dispatch } = this.props;
    const isUserAuthenticated = await checkAuthStatus();
    if (isUserAuthenticated) {
      dispatch(updateAuth(true));
    }
  }

  render() {
    const { isAuthenticated } = this.props;
    return (
      <div>
        <nav
          id='lp-menu--desktop'
          className='lp-navbar navbar navbar-expand-lg navbar-light lp-mobile-disabled lp-tablet-disabled'
        >
          <Link className='navbar-brand' to='/'>
            <img className='img-fluid' src={populixLogo} alt='populix' />
          </Link>
          <div className='navbar-collapse collapse' id='navbar'>
            <ul className='navbar-nav ml-auto'>
              <li className='nav-item'>
                <a className='nav-link' href='https://researcher.populix.co/'>
                  Researcher
                </a>
              </li>
              <li className='nav-item'>
                <Link className='nav-link' to='/about'>
                  Tentang Kami
                </Link>
              </li>
              <li className='nav-item'>
                <Link className='nav-link' to='/contact-us'>
                  Kontak
                </Link>
              </li>
              {!isAuthenticated ? (
                <li className='nav-item nav-btn-link' id='navbar-btn__login'>
                  <Link className='nav-link' to='/login'>
                    Masuk
                  </Link>
                </li>
              ) : (
                <li className='nav-item nav-btn-link' id='navbar-btn__dashboard'>
                  <Link className='nav-link' to='/study'>
                    Study
                  </Link>
                </li>
              )}
              {!isAuthenticated ? (
                <li className='nav-item nav-btn-link' id='navbar-btn__signup'>
                  <Link className='nav-link' to='/register'>
                    Daftar
                  </Link>
                </li>
              ) : (
                // TODO Change this to use button instead
                <li className='nav-item nav-btn-link' id='navbar-btn__logout'>
                  <Link className='nav-link' to='/'>
                    Logout
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </nav>

        <div className='lp-navbar-wrapper lp-desktop-disabled'>
          <div className='lp-left'>
            <Link className='navbar-brand' to='/'>
              <img className='img-fluid' src={populixLogo} alt='populix' />
            </Link>
          </div>
          <Menu id='lp-menu--tablet-mobile' className='lp-right'>
            <li className='nav-item'>
              <a href='https://researcher.populix.co/' className='nav-link'>
                Researcher
              </a>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to='/about'>
                Tentang Kami
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to='/contact-us'>
                Hubungi Kami
              </Link>
            </li>
            {!isAuthenticated ? (
              <li className='nav-item nav-btn-link'>
                <Link to='/login' id='navbar-btn__login' className='nav-link'>
                  Masuk
                </Link>
              </li>
            ) : (
              <li className='nav-item nav-btn-link' id='navbar-btn__dashboard'>
                <Link to='/study' className='nav-link'>
                  Lihat Studi
                </Link>
              </li>
            )}
            {!isAuthenticated ? (
              <li className='nav-item nav-btn-link'>
                <Link to='/register' id='navbar-btn__signup' className='nav-link'>
                  Daftar
                </Link>
              </li>
            ) : (
              // TODO Change this to use button instead
              <li className='nav-item nav-btn-link'>
                <Link id='navbar-btn__logout' className='nav-link' to='/' onClick={this._logout}>
                  Logout
                </Link>
              </li>
            )}
          </Menu>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};
export default connect(mapStateToProps)(LpTopNav);
