import {
  GET_SALDO_SUCCESS,
  GET_BANK_SUCCESS,
  GET_SALDO_FAILED,
  POST_PAYOUT_REQUEST_SUCCESS,
  POST_PAYOUT_REQUEST_FAILED,
  GET_BANK_DATA_SUCCESS,
  PURGE,
} from '../actions/actionTypes';

const status_enum = {
  success: 'success',
  failed: 'failed',
};
const initialState = {
  saldo: 0,
  bankObject: null,
  bankData: [],
  error: null,
  postingPayoutStatus: '',
};

export default function reducers(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case GET_SALDO_SUCCESS:
      return { ...state, saldo: payload, loadingSaldo: false };
    case GET_SALDO_FAILED:
      return { ...state, error: payload };
    case GET_BANK_SUCCESS:
      return { ...state, bankObject: payload };
    case POST_PAYOUT_REQUEST_SUCCESS:
      return {
        ...state,
        postingPayoutStatus: payload.message || status_enum.success,
      };
    case GET_BANK_DATA_SUCCESS:
      return {
        ...state,
        bankData: payload,
      };
    case POST_PAYOUT_REQUEST_FAILED:
      return {
        ...state,
        postingPayoutStatus: payload.message || status_enum.failed,
      };
    case PURGE:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
