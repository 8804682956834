import React, { Component } from 'react';

import profesiIcon from 'assets/img/screening-tab-icons/profesi-icon.png';
import pendidikanIcon from 'assets/img/screening-tab-icons/pendidikan-icon.png';
import opiniPolitikIcon from 'assets/img/screening-tab-icons/opini-politik-icon.png';
import hubunganKeluargaIcon from 'assets/img/screening-tab-icons/hubungan-keluarga-icon.png';
import statusSosialIcon from 'assets/img/screening-tab-icons/status-sosial-icon.png';
import hobiIcon from 'assets/img/screening-tab-icons/hobi-icon.png';
import studiKualitatifIcon from 'assets/img/screening-tab-icons/studi-kualitatif-icon.png';
import { NavLink } from 'react-router-dom';

export default class StudyNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigation: [
        {
          category: 'Profesi',
          icon: profesiIcon,
          link: '/screening/profesi'
        },
        {
          category: 'Pendidikan',
          icon: pendidikanIcon,
          link: '/screening/education'
        },
        // {
        // 	category: 'Opini Politik',
        // 	icon: opiniPolitikIcon,
        // 	link: '/screening/politics'
        // },
        {
          category: 'Hubungan Keluarga',
          icon: hubunganKeluargaIcon,
          link: '/screening/family'
        },
        {
          category: 'Status Sosial',
          icon: statusSosialIcon,
          link: '/screening/social'
        },
        {
          category: 'Lainnya',
          icon: hobiIcon,
          link: '/screening/others'
        }
        // {
        //   "category": "Studi Kualitatif",
        //   "icon": studiKualitatifIcon,
        //   "link": "/screening/study"
        // },
      ]
    };
  }

  render() {
    return (
      <nav className="tab mobile-disabled">
        <div
          className="tab-nav nav nav-tabs justify-content-left"
          id="content-tab"
          role="tablist"
        >
          {this.state.navigation.map((category, key) => (
            <NavLink className="nav-item nav-link" to={category.link} key={key}>
              <img
                alt={category.category}
                className="icon"
                src={category.icon}
              />
              {category.category}
            </NavLink>
          ))}
        </div>
      </nav>
    );
  }
}
