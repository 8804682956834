import React from 'react';
import { Modal, Button } from '@material-ui/core';
import useStyles from './styles';

const AlertLocationModal = ({ isAlertLocationModalOpen, onClose, onClickSubmit }) => {
  const classes = useStyles();

  return (
    <Modal open={isAlertLocationModalOpen} onClose={onClose}>
      <div className={classes.modalConfirmLocation}>
        <h1 className={classes.caption}>Lokasi Belum Aktif</h1>
        <p className={classes.description}>
          Untuk memulai, pastikan kamu mengaktifkan pengaturan lokasi di browser atau aplikasi kamu.
          Setelah diaktifkan, refresh halaman ini untuk memulai survei.
        </p>
        <Button
          type='submit'
          fullWidth
          variant='contained'
          color='primary'
          className={classes.submit}
          style={{ textTransform: 'none' }}
          onClick={onClickSubmit}
        >
          Oke
        </Button>
      </div>
    </Modal>
  );
};

export default AlertLocationModal;
