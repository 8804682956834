import React, { Component } from 'react';
import { animated } from 'react-spring';
import moment from 'moment';

import studiMarketplaceXxxhd from 'assets/img/studi-marketplace-xxxhd.png';
import profesiIcon from 'assets/img/screening-tab-icons/profesi-icon.png';
import jamIcon from 'assets/img/screening-tab-icons/jam-icon.png';
import kalenderIcon from 'assets/img/screening-tab-icons/kalender-icon.png';
import { FormSkeletons } from '../../Skeletons/FormSkeletons';
import { Button, StudyReward } from '../../../components';

export default class StudyItem extends Component {
  _getEndDate = () => {
    const { data } = this.props;
    const endDate = moment(data && data.endDate);
    const dateNow = new Date();
    const currentDate = new Date(dateNow.getTime() + dateNow.getTimezoneOffset() * 60000);

    const diffInDays = endDate.diff(currentDate, 'days');
    const diffInHours = endDate.diff(currentDate, 'hours');
    const diffInMinutes = endDate.diff(currentDate, 'minutes');

    if (diffInDays > 365) {
      // This is logic just to handle the welcome survey (screening survey)
      return null;
    } else {
      if (diffInDays > 0 && diffInDays < 365) {
        return `${diffInDays} Hari Lagi`;
      }
      if (diffInHours >= 1 && diffInHours <= 24) {
        return `${diffInHours} Jam Lagi`;
      }
      if (diffInMinutes >= 0 && diffInMinutes <= 60) {
        return `${diffInMinutes} Menit Lagi`;
      }
    }
  };

  _renderLoading = (styleProps) => {
    return (
      <div className='study-item align-items-center'>
        <animated.div
          className='study-icon skeletons study-item-skeleton__icon'
          style={{ ...styleProps }}
        />
        <div>
          <animated.div
            className='study-header mb-2 skeletons form-skeleton__children'
            style={{ height: 25, ...styleProps }}
          />

          <animated.div
            className='skeletons form-skeleton__children'
            style={{ height: 10, ...styleProps }}
          />
          <animated.div
            className='skeletons form-skeleton__children mb-2'
            style={{ height: 10, ...styleProps }}
          />
          <animated.div
            className='skeletons form-skeleton__children'
            style={{ height: 10, ...styleProps }}
          />
        </div>
      </div>
    );
  };

  render() {
    const { loading, buttonFunction, data, userGrade, fflag } = this.props;
    const reward = data && data.participantReward && Math.round(data.participantReward);
    const studyEndTime = data && data.endDate && this._getEndDate();

    const studyActive = fflag
      ? data && ['ACTIVE', 'ON_GOING'].includes(data.status) && userGrade !== 'E'
      : data && ['ACTIVE', 'ON_GOING'].includes(data.status);

    if (loading) {
      return <FormSkeletons native>{(props) => this._renderLoading(props)}</FormSkeletons>;
    }
    return (
      <div className='study-item'>
        <img className='study-icon' src={studiMarketplaceXxxhd} />

        <div>
          <div className='study-header mb-3'>
            <h4 className='font-bold'>{data && data.title}</h4>
          </div>

          <h5>
            <span className='researcher-name font-semibold'>{data && data.companyName}</span>
          </h5>

          <p className='study-short-description text--dark-gray'>{data && data.companyField}</p>

          <div className='grid-4 study-details-and-reward'>
            <React.Fragment>
              <img
                className='icon'
                src={profesiIcon}
                title='Jumlah Partisipan'
                alt='jumlah partisipan'
              />
              <p className='survey-header__value'>
                {data.requiredParticipants < 10000
                  ? `${data.requiredParticipants} orang`
                  : 'Unlimited'}
              </p>
            </React.Fragment>
            <img className='icon' src={jamIcon} title='Lama Studi' alt='lama studi' />
            <p className='survey-header__value'>
              <span>{data && data.averageCompletionTime} menit</span>
            </p>
            <StudyReward reward={reward} type={data && data.rewardType} />
            {studyEndTime && (
              <React.Fragment>
                <img className='icon' src={kalenderIcon} title='Masa Aktif' alt='masa aktif' />
                <p className='survey-header__value'>
                  <span>{studyEndTime}</span>
                </p>
              </React.Fragment>
            )}
          </div>
        </div>
        <Button
          label={studyActive ? 'Ambil Studi' : ' Studi Penuh'}
          type='primary'
          classNames={studyActive ? '' : 'btn-primary--disabled'}
          onClick={() => buttonFunction(data && data.studyId)}
          block
          disabled={studyActive ? false : true}
        />
      </div>
    );
  }
}
