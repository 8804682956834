import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  modalConfirmLocation: {
    position: 'fixed',
    backgroundColor: '#FFFFFF',
    padding: 20,
    borderRadius: 16,
    top: '50%',
    left: '50%',
    width: 320,
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  caption: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#000000',
    marginBottom: 16,
  },
  description: {
    fontSize: 16,
    fontWeight: 300,
    color: '#000000',
  },
  submit: {
    margin: theme.spacing(0, 0, 2),
  },
  primary: {
    color: '#3d9be9',
  },
}));

export default useStyles;
