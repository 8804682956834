import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Spring, animated } from 'react-spring/renderprops';
import { FormSkeletons } from '../../components';
import defaultImage from '../../assets/img/favicon.png';
import PopCall from '../../services/PopCall';

const popCall = new PopCall();
class FloatingCard extends Component {
  state = {
    showMore: false,
    fetchData: false,
    screening: [],
  };

  componentDidMount = async () => {
    // implement GET Screening later
    this.setState({ fetchData: true });
    try {
      const screeningData = await popCall.get('/participant/status');
      this.setState({ screening: screeningData });
    } catch (error) {
      console.error('we have an error', error);
    } finally {
      this.setState({ fetchData: false });
    }
  };

  // This is a less brittle solution than our previous attempt.
  // And, its also pure;
  _determineLabel = (target) => {
    switch (target) {
      case 'BasicProfile':
        return 'Pre Screening';
      case 'Education':
        return 'Pendidikan';
      case 'Hobby':
        return 'Hobi';
      case 'PoliticsOpinion':
        return 'Opini Politik';
      case 'Profesi':
        return 'Profesi';
      case 'Family':
        return 'Hubungan Keluarga';
      case 'Others':
        return 'Lainnya';
      case 'SocialStatus':
        return 'Status Sosial';
      default:
        return 'undefined';
    }
  };

  _toggleShowMore = () => {
    this.setState((prevState) => {
      return {
        showMore: !prevState.showMore,
      };
    });
  };

  // TODO Convert this to component
  _renderBar = (fillWidth) => {
    return (
      <div className='progress'>
        <Spring
          from={{ width: '0%' }}
          to={{ width: fillWidth }}
          config={{ friction: 10, clamp: true }}
        >
          {(styleProps) => (
            <animated.div className='progress-bar' role='progressbar' style={{ ...styleProps }} />
          )}
        </Spring>
      </div>
    );
  };

  _renderList = (data, key, label) => {
    const progress = (data.answered / data.question_length) * 100;
    return (
      <Fragment key={key}>
        <div className='progress-screening-item'>
          <img
            src={(data.image !== 'icon' && data.image) || defaultImage}
            alt='icon'
            className='small-icon'
          />
          <span className='ml-2'>{label}</span>
        </div>
        {this._renderBar(`${progress}%`)}
        <Spring
          from={{ number: 0 }}
          to={{ number: progress }}
          config={{ delay: 200, friction: 22, clamp: true }}
        >
          {(props) => (
            <animated.p className='f-12'>
              <span className='font-bold'>{Math.floor(props.number)}%</span> pertanyaan screening
              terjawab
            </animated.p>
          )}
        </Spring>
      </Fragment>
    );
  };

  _renderLoading = () => {
    return (
      <FormSkeletons native>
        {(props) => (
          <div className='skeletons form-skeleton__container d-flex flex-column mt-3'>
            <animated.div
              className='form-skeleton__children'
              style={{ ...props, width: '50%', height: 8 }}
            />
            <animated.div
              className='form-skeleton__children mt-2'
              style={{ ...props, width: '100%', height: 10 }}
            />
            <animated.div
              className='form-skeleton__children mt-2'
              style={{ ...props, width: '70%', height: 5 }}
            />
            <animated.div
              className='form-skeleton__children mt-2'
              style={{ ...props, width: '20%', height: 5 }}
            />
          </div>
        )}
      </FormSkeletons>
    );
  };

  render() {
    const { showMore, screening, fetchData } = this.state;
    return (
      <div className='floating-card mobile-disabled'>
        <a href='/contact-us' target='_blank' rel='noopener' className='text--light-blue font-bold'>
          Butuh bantuan ?
        </a>

        <div className='progress-card'>
          {/* {this._renderBar('50%')} */}
          {/* <p className="f-12">
						<span className="font-bold">50%</span> pertanyaan screening yang
						telah kamu jawab dari total screening yang ada
					</p> */}
          <h6 className='f-13 text-uppercase font-semibold'>Pertanyaan Screening kamu</h6>
          <div className='progress-screening-list'>
            {fetchData && this._renderLoading()}
            {!fetchData &&
              Array.isArray(screening) &&
              screening.map((screening, key) => {
                // do tag matching here
                const label = this._determineLabel(screening.tag_name);
                return this._renderList(screening, key, label);
              })}
            {/* <div className="progress-screening-item">
							<img />
							<span>Kesehatan</span>
						</div>
						<div className="progress-screening-item">
							<img />
							<span>Makanan</span>
						</div> */}
          </div>
          <div
            className='text--light-blue font-semibold'
            // onClick={this._navigateToScreening}
          >
            <Link to='/screening/profesi'>Lihat semua</Link>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile.userProfile,
  };
};

export default connect(mapStateToProps)(FloatingCard);
