import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

const APP_ID = 'sgfj93ly';

const IntercomWidget = ({ profile, isAuthenticated }) => {
  const renderWidgetVisitor = () => {
    return (
      <script>
        {`
          window.Intercom('boot', {
            app_id: '${APP_ID}'
          });
        `}
      </script>
    );
  };

  const renderWidgetLoggedIn = () => {
    const { email, name, family_name } = profile;

    return (
      <script>
        {`
          window.Intercom('boot', {
            app_id: '${APP_ID}',
            email: '${email}',
            name: '${name} ${family_name}',
          });
        `}
      </script>
    );
  };

  return (
    <Helmet>
      <script>
        {`
        (function(){
          var w=window;
          var ic=w.Intercom;
          if(typeof ic==="function"){
            ic('reattach_activator');ic('update',w.intercomSettings);
          }else{
            var d=document;
            var i=function(){
              i.c(arguments);
            };
            i.q=[];
            i.c=function(args){
              i.q.push(args);
            };
            w.Intercom=i;
            var l=function(){
              var s=d.createElement('script');
              s.type='text/javascript';
              s.async=true;
              s.src='https://widget.intercom.io/widget/${APP_ID}';
              var x=d.getElementsByTagName('script')[0];
              x.parentNode.insertBefore(s,x);
            };
            if(w.attachEvent){
              w.attachEvent('onload',l);
            }else{
              w.addEventListener('load',l,false);
            }
          }
        })();
        `}
      </script>

      {isAuthenticated && renderWidgetLoggedIn()}
      {!isAuthenticated && renderWidgetVisitor()}

      <script>
        {`
          window.intercomSettings = {
            app_id: '${APP_ID}',
            alignment: 'right',
            horizontal_padding: 20,
            vertical_padding: 20
          };
        `}
      </script>
    </Helmet>
  );
};

const mapStateToProps = (state) => ({
  featureFlag: state.featureFlag,
  profile: state.profile.userProfile,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {})(IntercomWidget);
