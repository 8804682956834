import {
  PURGE,
  UPDATE_PRESCREENING,
  UPDATE_KTP,
  INCREMENT_PERCENTAGE_VALUE,
  INCREMENT_VALIDATION_COUNTER,
  UPDATE_VALIDATION_RESULT,
} from '../actions/actionTypes';

const initialState = {
  prescreeningData: {
    // Gender: [],
    // DateOfBirth: [],
    // JobStatus: [],
    // Domicile: [],
    // MonthlyExpenses: [],
    // referralCode: '',
    // ktp_obj: {
    //   image_base_64: '',
    // },
  },
  percentageValue: 0,
  validationCounter: 0,
  validationResult: null,
};

export default function preScreeningReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_PRESCREENING:
      return {
        ...state,
        prescreeningData: {
          ...state.prescreeningData,
          [action.label]: [action.payload],
        },
      };
    case UPDATE_KTP: {
      return {
        ...state,
        prescreeningData: {
          ...state.prescreeningData,
          ktp_obj: {
            image_base_64: action.payload,
          },
        },
      };
    }
    case INCREMENT_PERCENTAGE_VALUE: {
      return {
        ...state,
        percentageValue: state.percentageValue + action.payload,
      };
    }
    case INCREMENT_VALIDATION_COUNTER: {
      return {
        ...state,
        validationCounter: (state.validationCounter += 1),
      };
    }
    case UPDATE_VALIDATION_RESULT: {
      return {
        ...state,
        validationResult: action.payload,
      };
    }
    case PURGE: {
      return {
        ...state,
        ...initialState,
      };
    }
    default:
      return state;
  }
}

function updatePreScreening(label, value) {
  return (dispatch) => {
    dispatch({ type: UPDATE_PRESCREENING, payload: value, label });
  };
}

function updateImage(value) {
  return (dispatch) => {
    dispatch({ type: UPDATE_KTP, payload: value });
  };
}

function updatePercentage(label, value, prescreeningObj) {
  // // if key does not exist...
  const isKeyExist = Object.keys(prescreeningObj).includes(label);
  // Increment the percentage value
  return (dispatch) => {
    if (!isKeyExist || label === 'ktp_obj') {
      dispatch({ type: INCREMENT_PERCENTAGE_VALUE, payload: value });
    }
  };
}

function incrementValidationCounter() {
  return (dispatch) => {
    dispatch({ type: INCREMENT_VALIDATION_COUNTER });
  };
}

function updateValidationResult(result) {
  return (dispatch) => {
    dispatch({ type: UPDATE_VALIDATION_RESULT, payload: result });
  };
}

export {
  updatePreScreening,
  updateImage,
  updatePercentage,
  incrementValidationCounter,
  updateValidationResult,
};
