import {
  SET_FEATURE_FLAG,
  CLOSE_BANNER_INFO,
  CLOSE_BANNER_PROMO,
  PURGE,
} from '../actions/actionTypes';

const initialState = {};

export default function reducers(state = initialState, actions) {
  const { payload, type } = actions;
  switch (type) {
    case SET_FEATURE_FLAG:
      return { ...state, ...payload };
    case CLOSE_BANNER_INFO:
      return { ...state, bannerInfo: null };
    case CLOSE_BANNER_PROMO:
      return { ...state, bannerPromo: null };
    default:
      return state;
  }
}
