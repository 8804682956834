import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import dot from 'assets/img/dot.png';
import noDataIcon from 'assets/img/berenang-icon.png';
import { StudyItem } from '../../../components';
import { Transition } from 'react-spring/renderprops';
import { fetchStudies } from '../../../store/actions/studyActions';
import { DailyQuestion, SearchByCode, Modal } from '../../../components';
import PopCall from '../../../services/PopCall';
import debounce from 'lodash.debounce';
import populixLogo from 'assets/img/logo.png';
import popcornUfo from 'assets/img/popcorn-ufo.png';
import RenderAdditionalQuestion from './AdditionalQuestion';
import { remoteConfig } from 'firebase';

const popCall = new PopCall();

class StudyItemLayout extends Component {
  state = {
    study: [],
    loading: true,
    dailyQuestion: null,
    isPosting: false,
    studyByCode: {},
    studyByCodeStatus: '',
    additionalQuestion: [],
    pipeAdditionalQuestion: [],
    modalKtpValidation: {
      isOpen: false,
      message: '',
    },
    isNewPollingFeatureActive: false,
  };

  componentDidMount() {
    this._storeFirstLoginData();
    this._getAdditionalQuestion();
    this._getRemoteConfigData();
  }

  _getRemoteConfigData = async () => {
    await remoteConfig().fetch(0); // Dont cache
    await remoteConfig().activate();

    const remoteConfigs = remoteConfig().getAll();

    const isNewPollingFeatureActive =
      remoteConfigs.is_new_polling_feature_active._value === 'true' ? true : false;

    this.setState({
      isNewPollingFeatureActive: Boolean(isNewPollingFeatureActive),
    });
  };

  _storeFirstLoginData = async () => {
    try {
      await popCall.get('/dashboard?loginPlace=participant');
    } catch (err) {
      console.error(err);
    }
  };

  _fetchData = async () => {
    const { isNewPollingFeatureActive } = this.state;
    const getStudies = this.props.fetchStudies;
    try {
      await getStudies();
    } catch (error) {
      console.error('We got an error', error);
    } finally {
      this.setState({ loading: false });
    }
    if (!isNewPollingFeatureActive) {
      this._fetchDailyQuestion();
    }
  };

  _getAdditionalQuestion = async () => {
    let additionalQuestions = [];
    let pipeAdditionalQuestions = [];
    try {
      const response = await popCall.get('/participant/popcheck');
      if (
        response &&
        response.data &&
        response.data.questions &&
        response.data.questions.length > 0
      ) {
        response.data.questions.forEach((question) => {
          if (!question.hasOwnProperty('conditions')) {
            additionalQuestions.push(question);
          } else {
            pipeAdditionalQuestions.push(question);
          }
        });
        this.setState({
          additionalQuestion: additionalQuestions,
          pipeAdditionalQuestion: pipeAdditionalQuestions,
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      this._fetchData();
    }
  };

  _resetAditionalQuestion = () => {
    this.setState({ additionalQuestion: [], pipeAdditionalQuestion: [] });
    this._fetchData();
  };

  _navigateToDetail = (studyId) => {
    const { profile, history, featureFlag } = this.props;
    const userDob = moment(
      profile.criteria && profile.criteria.DateOfBirth && profile.criteria.DateOfBirth[0],
      'DD-MM-YYYY',
    );
    const userAge = moment().diff(userDob, 'years');
    if (
      featureFlag &&
      featureFlag.ktpValidation &&
      featureFlag.ktpValidation.isActive &&
      userAge >= 17
    ) {
      this._ktpValidation(studyId);
    } else {
      history.push(`/study/detail/${studyId}`);
    }
  };

  _ktpValidation = (studyId) => {
    const { history, profile } = this.props;
    const { phone_number, ktp_status } = profile;
    let message = '';
    if (!phone_number && ktp_status !== 'APPROVED') {
      message = 'Kamu perlu verifikasi KTP dan No. HP untuk mengambil studi';
    } else if (phone_number && ktp_status !== 'APPROVED') {
      message = 'Kamu perlu verifikasi KTP untuk mengambil studi';
    } else {
      history.push(`/study/detail/${studyId}`);
    }
    this.setState({
      modalKtpValidation: {
        isOpen: true,
        message,
      },
    });
  };

  _renderStudyLoading = () => {
    return (
      <div>
        <StudyItem loading={true} />
        <StudyItem loading={true} />
        <StudyItem loading={true} />
      </div>
    );
  };

  _renderEmpty = () => {
    return (
      <div className='study-list__empty-container'>
        <img className='img-3-dots' src={dot} alt='dot' />
        <h6 className='text-uppercase'>Saat ini, belum ada studi yang tersedia bagi kamu</h6>
        {/** TODO move this to scss */}
        <div
          className='study-list__empty-image-container'
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'center',
          }}
        >
          <img className='img-fluid mt-4' src={noDataIcon} alt='swim' style={{ width: '65%' }} />
        </div>
      </div>
    );
  };

  _fetchDailyQuestion = async () => {
    const path = `/dailyQuestions/todayQuestion/v2`;
    try {
      const response = await popCall.get(path);
      const dailyQuestion = response.filter((val) => val.type !== 'TEXT');
      this.setState({
        dailyQuestion,
      });
    } catch (error) {
    } finally {
      this.setState({ loading: false });
    }
  };

  _postDailyQuestion = debounce(async (id, answer) => {
    this.setState({ isPosting: true });
    const path = `/dailyQuestions/${id}/answer`;
    const dataBody = {
      answer: answer,
    };
    try {
      const response = await popCall.post(path, dataBody);
      this._fetchDailyQuestion();
    } catch (error) {
    } finally {
      this.setState({ isPosting: false });
    }
  }, 500);

  _fetchStudyByCode = (code) => {
    const path = `/participant/studies/code/${code}`;
    popCall
      .get(path)
      .then((res) => {
        const { data } = res;
        const status = data.checkStatus;

        this.setState({
          studyByCode: { studyId: data.id, ...data },
          studyByCodeStatus: status || 'OK',
        });
      })
      .catch((err) => {
        const responseStatus = err.response.status;
        this.setState({
          studyByCode: null,
          studyByCodeStatus: responseStatus === 404 ? 'NOT_FOUND' : 'ERROR',
        });
      });
  };

  _renderBannedParticipant = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            flexWrap: 'wrap',
          }}
        >
          <img
            className='img-fluid mt-4'
            src={popcornUfo}
            alt='sad-popcorn'
            style={{ width: '30%', margin: '1em auto' }}
          />
          <h4 style={{ fontWeight: 'bold', textAlign: 'center' }}>Akun kamu telah di block!</h4>
          <h4 style={{ textAlign: 'center' }}>
            Sistem kami mendeteksi adanya kecurangan yang kamu lakukan di dalam survey kami. Mohon
            hubungi support kami untuk langkah selanjutnya
          </h4>
        </div>
      </div>
    );
  };

  _toggleAdditionalQuestionModal = () => {
    this.setState({ isShowModalAdditionalQuestion: !this.state.isShowModalAdditionalQuestion });
  };

  _hideModalKtpValidation = () => {
    this.setState({ modalKtpValidation: { isOpen: false, message: '' } });
  };

  render() {
    const {
      loading,
      dailyQuestion,
      isPosting,
      studyByCode,
      studyByCodeStatus,
      additionalQuestion,
      modalKtpValidation,
      pipeAdditionalQuestion,
      isNewPollingFeatureActive,
    } = this.state;
    const { studies, featureFlag, profile, history } = this.props;
    const userGrade = profile && profile.credibilityGroup;
    const fflagCredibilityScore =
      featureFlag && featureFlag.credibilityScore && featureFlag.credibilityScore.isActive;

    // check if user is Bad Participant
    if (profile && profile.isBadParticipant) {
      return this._renderBannedParticipant();
    }

    if (
      featureFlag &&
      featureFlag.additionalQuestion &&
      featureFlag.additionalQuestion.isActive &&
      additionalQuestion.length > 0
    ) {
      return (
        <RenderAdditionalQuestion
          additionalQuestionData={additionalQuestion}
          pipeAdditionalQuestionData={pipeAdditionalQuestion}
          fnResetStudy={this._resetAditionalQuestion}
          profile={profile}
        />
      );
    }

    return (
      <div>
        {loading && this._renderStudyLoading()}
        {!isNewPollingFeatureActive &&
          !loading &&
          dailyQuestion &&
          Object.keys(dailyQuestion).length !== 0 && (
            <DailyQuestion
              data={dailyQuestion}
              postAnswerFunction={this._postDailyQuestion}
              isPosting={isPosting}
            />
          )}
        {featureFlag && featureFlag.studyCode && featureFlag.studyCode.isActive && !loading && (
          <SearchByCode
            onCheckStudy={this._fetchStudyByCode}
            navigateToDetail={(studyId, code) => {
              history.push(`/study/detail/${studyId}?code=${code}`);
            }}
            study={studyByCode}
            status={studyByCodeStatus}
          />
        )}
        {!loading &&
          Array.isArray(studies) &&
          studies.map((studyItem, key) => (
            <StudyItem
              key={key}
              data={studyItem}
              buttonFunction={this._navigateToDetail}
              userGrade={userGrade}
              fflag={fflagCredibilityScore}
            />
          ))}
        {!loading && Array.isArray(studies) && studies.length === 0 && this._renderEmpty()}
        {!isNewPollingFeatureActive &&
          !loading &&
          Array.isArray(studies) &&
          studies.length === 0 &&
          dailyQuestion &&
          Object.keys(dailyQuestion).length === 0 &&
          this._renderEmpty()}
        {modalKtpValidation && modalKtpValidation.isOpen && (
          <Transition
            native
            items={modalKtpValidation.isOpen}
            from={{ transform: 'translate(0, -100%)' }}
            enter={{ transform: 'translate(0, 0)' }}
            leave={{ transform: 'translate(0, -100%)' }}
          >
            {(openModalKtpValidation) => (style) =>
              openModalKtpValidation && (
                <Modal classname='modal' show={openModalKtpValidation} styles={style} animatedModal>
                  <div className='modal-header border-bottom py-4'>
                    <img src={populixLogo} style={{ height: 32, width: 110 }} alt='populix' />
                    <button type='button' className='close' onClick={this._hideModalKtpValidation}>
                      <span>&times;</span>
                    </button>
                  </div>
                  <div>
                    <div className='modal-body'>
                      <div className='my-2'>
                        <h3 className='mb-3'>{'Maaf, Kamu belum bisa mengambil studi ini'}</h3>
                        <h4 className='mb-3'>{modalKtpValidation.message}</h4>
                      </div>
                    </div>
                    <div className='modal-footer modal-footer-type-2 float-right'>
                      <button
                        type='button'
                        className='btn btn-transparent btn--text text--light-blue modal-footer-btn'
                        onClick={() => history.push('/settings/profile')}
                      >
                        Verifikasi
                      </button>
                    </div>
                  </div>
                </Modal>
              )}
          </Transition>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    studies: state.study.studies,
    studyByCode: state.study,
    profile: state.profile.userProfile,
    featureFlag: state.featureFlag,
  };
};
export default connect(mapStateToProps, { fetchStudies })(StudyItemLayout);
