import React from 'react';
import { Badge } from '../../../components';
import study_icon from 'assets/img/screening-tab-icons/survey-icon.png';
import { parseStudyHistoryStatus } from '../../../helpers/generalHelpers';
export const StudyHistory = ({ historyData }) => {
  let statusColor;
  switch (historyData.status) {
    case 'Butuh Review':
      statusColor = 'WARN';
      break;
    case 'Disetujui':
      statusColor = 'OK';
      break;
    case 'Ditolak':
      statusColor = 'NOT_OK';
      break;
    default:
      statusColor = 'WARN';
      break;
  }

  return (
    <div className='d-flex flex-row study-item justify-content-between'>
      <div className='d-flex flex-row' style={{ width: '75%' }}>
        <img alt='study' className='study-icon mb-0' src={study_icon} />
        <p
          className='f-16 ml-5 mb-0 font-bold text-color--base align-self-center'
          style={{ lineHeight: 1.8 }}
        >
          Kamu menyelesaikan studi&nbsp;
          <span className='text-color--greyedout font-semibold'>
            {historyData && historyData.title}
          </span>
        </p>
      </div>
      <div className='d-flex flex-column align-items-center'>
        <p className='mb-0'>{historyData && historyData.participatedDate}</p>
        <Badge
          status={statusColor}
          label={historyData && historyData.status && parseStudyHistoryStatus(historyData.status)}
        />
      </div>
    </div>
  );
};
