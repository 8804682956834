import {
  STUDY_STARTED,
  STUDY_STOPPED,
  PROTECTED_BTN_PRESSED,
  CLOSE_MODAL_PROMPT,
  RESET_ACTIVE_STUDY_STATE,
  PURGE,
} from '../actions/actionTypes';

const initialState = {
  is_study_active: false,
  protected_btn_pressed: false,
  nav_target_url: '',
};

export default function reducers(state = initialState, actions) {
  const { payload, type } = actions;
  switch (type) {
    case STUDY_STARTED:
      return {
        ...state,
        is_study_active: true,
        protected_btn_pressed: false,
        nav_target_url: '',
      };
    case STUDY_STOPPED:
      return {
        ...state,
        is_study_active: false,
        protected_btn_pressed: false,
      };
    case PROTECTED_BTN_PRESSED:
      return { ...state, protected_btn_pressed: true, nav_target_url: payload };
    case CLOSE_MODAL_PROMPT:
      return { ...state, protected_btn_pressed: false, nav_target_url: '' };
    case RESET_ACTIVE_STUDY_STATE:
      return {
        ...state,
        is_study_active: false,
        protected_btn_pressed: false,
        nav_target_url: '',
      };
    case PURGE:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
