import React, { Component } from 'react';
import { connect } from 'react-redux';
import { animated, Transition } from 'react-spring/renderprops';
import axios from 'axios';
import { FormSkeletons, ModalPrompt, ModalFormRekening } from '../../components';
import {
  hideNotification,
  showSuccessNotification,
  showFailedNotification,
} from '../../store/actions/profileActions';
import { getBankInformation, getBankData } from '../../store/actions/saldoActions';
import PopCall from '../../services/PopCall';
import { BankListCard } from '../../components';

import popmoney from 'assets/img/popmoney.png';

const popCall = new PopCall();
class SettingBankAccount extends Component {
  state = {
    showPassword: false,
    loading: false,
    formData: {
      accountName: '',
      bank: '',
      accountNumber: '',
    },
    markedEditIndex: null,
    // data: [],
    error: null,
    first_time_setup: true,
    postingData: false,
    showModalPrompt: false,
    isNewRekening: true,
    showDeletePrompt: false,
    formValidation: {
      accountNameForm_invalid: false,
      bankForm_invalid: false,
      accountNumberForm_invalid: false,
    },
    bankList: [],
    isAdmin: false,
  };

  async componentDidMount() {
    const { profile } = this.props;
    if (profile.groups && Array.isArray(profile.groups) && profile.groups.includes('admin')) {
      this.setState({
        isAdmin: true,
      });
    }

    const getBankData = this.props.getBankData;
    this.setState({
      loading: true,
    });

    try {
      await getBankData();
      await this.getBankList();
    } catch (error) {
      this.setState({
        error,
      });
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  getBankList = async () => {
    const { featureFlag } = this.props;
    try {
      const response = await axios.get(`${process.env.REACT_APP_CDN}/bank-list.json`);
      if (response && response.data) {
        let bankList = [];
        response.data.forEach((bank) => {
          if (
            featureFlag &&
            featureFlag.payoutOptions &&
            featureFlag.payoutOptions.bankList[bank.value]
          ) {
            bankList.push({ label: bank.label, value: bank.value, url: bank.url });
          }
        });
        this.setState({
          bankList,
        });
      }
    } catch (err) {
      console.error('error fetch bank list', err);
    }
  };

  showModal = () => {
    this.setState({
      showModalPrompt: true,
      isNewRekening: true,
      formValidation: {
        accountNameForm_invalid: false,
        bankForm_invalid: false,
        accountNumberForm_invalid: false,
      },
    });
  };

  closeModal = () => {
    this.setState({
      showModalPrompt: false,
      formData: {
        accountName: '',
        bank: '',
        accountNumber: '',
      },
    });
  };

  _toggleShowPassword = () => {
    this.setState((prevState) => {
      return {
        showPassword: !prevState.showPassword,
      };
    });
  };

  _onDefaultChange = () => {
    this.setState({
      formData: {
        default: true,
      },
    });
  };

  _onChangeForm = (e) => {
    if (e.target) {
      const { target } = e;
      const { value, name } = target;

      if (name === 'accountNumber') {
        const numberRegex = /^[0-9\b]+$/;

        if (value === '' || numberRegex.test(value)) {
          this.setState((prevState) => ({
            formData: {
              ...prevState.formData,
              [name]: value,
            },
          }));
        }
      } else {
        this.setState((prevState) => ({
          formData: {
            ...prevState.formData,
            [name]: value,
          },
        }));
      }
    } else {
      const { value } = e;
      this.setState(
        (prevState) => ({
          formData: {
            ...prevState.formData,
            bank: value,
          },
        }),
        // ,
        // () => {
        //   console.log('New state', this.state);
        // },
      );
    }
  };

  _validateForm = async () => {
    const { formData, formValidation } = this.state;
    let listOfInvalid = [];
    for (const form of Object.keys(formData)) {
      if (form !== 'default' && form !== 'id' && formData[form].trim() === '') {
        await this.setState((prevState) => ({
          formValidation: {
            ...prevState.formValidation,
            [`${form}Form_invalid`]: true,
          },
        }));
        listOfInvalid.push(form);
        setTimeout(() => {
          this.setState((prevState) => ({
            formValidation: {
              ...prevState.formValidation,
              [`${form}Form_invalid`]: false,
            },
          }));
        }, 3000);
      }
    }
    return listOfInvalid;
  };

  //move somewhere else
  _onSubmitBankAccount = async () => {
    const getBankData = this.props.getBankData;
    const showSuccessNotif = this.props.showSuccessNotification;
    const showFailedNotif = this.props.showFailedNotification;
    const hideNotif = this.props.hideNotification;

    const { formData } = this.state;
    //#region Validation
    const invalidForms = await this._validateForm();

    if (invalidForms.length > 0) {
      return;
    }

    //#endregion
    this.setState({ postingData: true, loading: true });
    try {
      const postPath = `/paymentinfo`;
      await popCall.post(postPath, formData);
      showSuccessNotif();
      await getBankData();
      setTimeout(() => {
        hideNotif();
      }, 2500);
    } catch (error) {
      const { response } = error;
      this.setState({ error });
      showFailedNotif((response && response.data && response.data.message) || undefined);
      setTimeout(() => {
        hideNotif();
      }, 2500);
    } finally {
      // Do something
      this.setState({
        postingData: false,
        showModalPrompt: false,
        loading: false,
        formData: {
          accountName: '',
          bank: '',
          accountNumber: '',
        },
      });
    }
  };

  // REFACTOR
  _onSubmitEdit = async () => {
    const getBankData = this.props.getBankData;
    const showSuccessNotif = this.props.showSuccessNotification;
    const showFailedNotif = this.props.showFailedNotification;
    const hideNotif = this.props.hideNotification;
    const { formData } = this.state;

    //#region Validation
    const invalidFormsEdit = await this._validateForm();

    if (invalidFormsEdit.length > 0) {
      return;
    }

    //#endregion
    this.setState({ loading: true });
    try {
      const putPath = `/paymentinfo/${formData.id}`;
      await popCall.put(putPath, formData);
      showSuccessNotif();
      await getBankData();
      setTimeout(() => {
        hideNotif();
      }, 2500);
    } catch (error) {
      const { response } = error;
      this.setState({ error });
      showFailedNotif((response && response.data && response.data.message) || undefined);
      setTimeout(() => {
        hideNotif();
      }, 2500);
    } finally {
      // Do something
      this.setState({
        postingData: false,
        showModalPrompt: false,
        loading: false,
        formData: {
          accountName: '',
          bank: '',
          accountNumber: '',
        },
      });
    }
  };

  _toggleDeletePrompt = async (data) => {
    await this.setState((prevState) => ({
      showDeletePrompt: !prevState.showDeletePrompt,
      formData: prevState.showDeletePrompt ? {} : data,
    }));
  };
  _renderLoading = () => {
    return (
      <FormSkeletons native>
        {(props) => (
          <div className='skeletons form-skeleton__container d-flex flex-column'>
            <animated.div
              className='skeletons form-skeleton__children'
              style={{ ...props, height: 30 }}
            />
            <animated.div
              className='skeletons form-skeleton__children'
              style={{ ...props, height: 30 }}
            />
            <animated.div
              className='skeletons form-skeleton__children'
              style={{ ...props, height: 30 }}
            />
            <animated.div
              className='skeletons form-skeleton__children'
              style={{ ...props, height: 30 }}
            />
          </div>
        )}
      </FormSkeletons>
    );
  };

  _onEditRekeningBank = (rekeningIdx) => {
    const { bankData } = this.props;
    this.setState((prevState) => ({
      showModalPrompt: !prevState.showModalPrompt,
      formData: {
        accountName: bankData[rekeningIdx].accountName,
        accountNumber: bankData[rekeningIdx].accountNumber,
        bank: bankData[rekeningIdx].bank,
        id: bankData[rekeningIdx].id,
      },
      isNewRekening: false,
    }));
  };

  _onDeleteRekeningBank = async () => {
    const getBankData = this.props.getBankData;
    const showSuccessNotif = this.props.showSuccessNotification;
    const showFailedNotif = this.props.showFailedNotification;
    const hideNotif = this.props.hideNotification;
    const { formData } = this.state;
    this.setState({ loading: true });
    try {
      await popCall.delete(`/paymentinfo/${formData.id}`);
      showSuccessNotif();
      await getBankData();
      setTimeout(() => {
        hideNotif();
      }, 2500);
    } catch (error) {
      this.setState({ error });
      showFailedNotif();
      setTimeout(() => {
        hideNotif();
      }, 2500);
    } finally {
      this.setState({
        showDeletePrompt: false,
        loading: false,
        formData: {
          accountName: '',
          bank: '',
          accountNumber: '',
        },
      });
    }
  };

  // REFACTOR
  _onChangeDefaultRekening = async (index, rekeningId) => {
    const { bankData } = this.props;
    const { accountName, accountNumber, bank } = bankData[index];
    const getBankData = this.props.getBankData;
    const showSuccessNotif = this.props.showSuccessNotification;
    const showFailedNotif = this.props.showFailedNotification;
    const hideNotif = this.props.hideNotification;
    this.setState({ loading: true });
    const formData = { accountName, accountNumber, bank, default: true };
    try {
      await popCall.put(`/paymentinfo/${rekeningId}`, formData);
      showSuccessNotif();
      await getBankData();
      setTimeout(() => {
        hideNotif();
      }, 2500);
    } catch (error) {
      this.setState({ error });
      showFailedNotif();
      setTimeout(() => {
        hideNotif();
      }, 2500);
    } finally {
      this.setState({
        postingData: false,
        showModalPrompt: false,
        loading: false,
        formData: {
          accountNumber: '',
          bank: '',
          accountName: '',
        },
      });
    }
  };

  _temporarySetDefaultRekening = async (bankData, handleClickSelect) => {
    const { accountNumber, bank, id } = bankData[0];
    handleClickSelect && handleClickSelect(id, accountNumber, bank);
  };

  render() {
    const { bankData, fromPayoutRequest, handleClickSelect, selectedBank } = this.props;
    const {
      loading,
      formData,
      showModalPrompt,
      isNewRekening,
      formValidation,
      bankList,
      isAdmin,
    } = this.state;
    const { accountName, accountNumber, bank } = formData;
    if (loading) {
      return this._renderLoading();
    }
    if (Array.isArray(bankData) && bankData.length === 1 && !selectedBank) {
      this._temporarySetDefaultRekening(bankData, handleClickSelect);
    }
    return (
      <div
        className='tab-pane fade show active'
        id='bank-account'
        role='tabpanel'
        aria-labelledby='nav-tab-bank-account'
      >
        <div className='bank-account-settings'>
          {/* <div className='settings-header desktop-disabled pb-3'>
            <h3>Rekening bank</h3>
          </div> */}
          {/* Bank Account */}
          <div className='bank-account-settings-form'>
            {Array.isArray(bankData) && bankData.length === 0 && !isAdmin && (
              <>
                <div className='bank-account-settings-form__img-wrapper'>
                  <img className='img-fluid' src={popmoney} alt='no-bank-acc' />
                </div>
                <h3 className='font-bold my-3 text-center'>Rekening Bank Kamu masih kosong</h3>
                <h5 className='font-light text-center'>
                  Tambahkan Rekening Bank Kamu terlebih dahulu sebelum penarikan dana
                </h5>
                <button type='button' className='btn btn-primary mt-3' onClick={this.showModal}>
                  Tambah Rekening
                </button>
              </>
            )}

            {Array.isArray(bankData) &&
              bankData.map((data, key) => {
                const bankData =
                  Array.isArray(bankList) &&
                  bankList.length > 0 &&
                  bankList.find((bank) => bank.value === data.bank);
                console.log('TCL: render -> bankData', bankData);

                const bankText = !bankData ? data.bank : bankData.label;
                const bankThumbnailUrl = !bankData ? '' : bankData.url;
                return (
                  <BankListCard
                    key={key}
                    name={data.accountName}
                    id={data.id}
                    user={data.accountName}
                    accountNumber={data.accountNumber}
                    bank={bankText}
                    isDefault={!fromPayoutRequest && data.default}
                    labelText={
                      data.id === selectedBank && fromPayoutRequest
                        ? 'Terpilih'
                        : !fromPayoutRequest && data.default
                        ? 'Utama'
                        : fromPayoutRequest
                        ? 'Pilih'
                        : 'Jadikan Utama'
                    }
                    handleClickEdit={() => this._onEditRekeningBank(key)}
                    handleClickDelete={() => this._toggleDeletePrompt(data)}
                    handleClickDefault={() => this._onChangeDefaultRekening(key, data.id)}
                    hideEditDelete={fromPayoutRequest}
                    {...(fromPayoutRequest
                      ? {
                          handleClickSelect: () =>
                            handleClickSelect(data.id, data.accountNumber, data.bank),
                        }
                      : undefined)}
                    isSelected={fromPayoutRequest && data.id === selectedBank}
                    thumbnailUrl={bankThumbnailUrl}
                  />
                );
              })}

            {Array.isArray(bankData) && bankData.length > 0 && !isAdmin ? (
              <h4 className='font-light text-center'>
                Kamu hanya bisa menambah satu Rekening Bank
              </h4>
            ) : (
              isAdmin && (
                <button type='button' className='btn btn-primary mt-3' onClick={this.showModal}>
                  Tambah Rekening
                </button>
              )
            )}

            {/* Modal */}
            <Transition
              native
              items={showModalPrompt}
              from={{ transform: 'translate(0, -100%)' }}
              enter={{ transform: 'translate(0, 0%)' }}
              leave={{ transform: 'translate(0, -100%)' }}
            >
              {(showConfirmationModal) => (modalStyle) =>
                showConfirmationModal && (
                  <ModalFormRekening
                    animatedModal
                    handleClose={this.closeModal}
                    handleSubmitForm={
                      isNewRekening ? this._onSubmitBankAccount : this._onSubmitEdit
                    }
                    handleChangeForm={this._onChangeForm}
                    style={modalStyle}
                    isNewRekening={isNewRekening}
                    accountName={accountName}
                    accountNumber={accountNumber}
                    bank={bank}
                    formValidation={formValidation}
                    bankList={bankList}
                  />
                )}
            </Transition>
            <ModalPrompt
              handleClose={this._toggleDeletePrompt}
              handleConfirmation={this._onDeleteRekeningBank}
              processingData={loading}
              mainPromptMsg={'Apakah kamu yakin untuk menghapus rekening bank ini?'}
              show={this.state.showDeletePrompt}
              mainLoadingMsg='Memproses penghapusan rekening'
              extraLoadingMsg='Mohon menunggu'
            />
            <div id='modal-backdrop' className='modal-backdrop-transparent modal-transition' />
          </div>
        </div>
      </div>
    );
  }
}

const Modal = ({
  handleClose,
  show,
  showPassword,
  togglePasswordFunction,
  handleSubmit,
  posting,
}) => {
  const showHideClassName = show ? 'modal display-block' : 'modal display-none';

  return (
    <div className={showHideClassName}>
      <section className='modal-main'>
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header align-items-center'>
              <h2 className='font-bold'>Kamu yakin mau merubah data rekening?</h2>
              <button type='button' className='close' onClick={handleClose}>
                <span>&times;</span>
              </button>
            </div>
            <div>
              <div className='modal-body'>{/* For Password */}</div>
              <div className='modal-footer modal-footer-type-2 align-items-center'>
                <button
                  type='button'
                  className='btn btn-primary modal-footer-btn modal-footer-btn--line-height-0'
                  onClick={handleSubmit}
                  disabled={posting}
                >
                  Ya
                </button>
                <Transition
                  native
                  items={posting}
                  from={{
                    transform: 'translate(0, 15px)',
                    opacity: 0,
                    position: 'absolute',
                  }}
                  enter={{
                    transform: 'translate(0, 0)',
                    opacity: 1,
                    position: 'relative',
                  }}
                  leave={{
                    transform: 'translate(0, -15px)',
                    opacity: 0,
                    position: 'absolute',
                  }}
                >
                  {(posting) => (style) =>
                    !posting ? (
                      <animated.button
                        onClick={handleClose}
                        type='button'
                        className='btn btn-transparent btn--text text--light-blue modal-footer-btn pr-0'
                        style={{ ...style }}
                      >
                        Tidak
                      </animated.button>
                    ) : (
                      <animated.p
                        style={{ ...style }}
                        className='text-color--warning font-bold mb-0'
                      >
                        Memproses
                      </animated.p>
                    )}
                </Transition>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.profile.userProfile,
    bankInformation: state.wallet.bankObject,
    bankData: state.wallet.bankData ? state.wallet.bankData : state.wallet.bankObject,
    featureFlag: state.featureFlag,
  };
};

export default connect(mapStateToProps, {
  hideNotification,
  showFailedNotification,
  showSuccessNotification,
  getBankInformation,
  getBankData,
})(SettingBankAccount);
