import { createStore, applyMiddleware } from 'redux';

// middleware
import { persistStore, persistReducer } from 'redux-persist';
import { createFilter, createBlacklistFilter } from 'redux-persist-transform-filter';
import storage from 'redux-persist/lib/storage';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';

import reducer from './reducers/index';

// TODO Need to check the redux persist again, there are some anomalies that needs fixing !!
const profileBlacklist = createBlacklistFilter('profile', [
  'show_success_notification',
  'show_error_notification',
]);
const questionBlacklist = createBlacklistFilter('question', ['is_loaded']);
const walletBlackList = createBlacklistFilter('wallet', ['error']);
const persistConfig = {
  key: 'populix',
  storage,
  transforms: [profileBlacklist, walletBlackList, questionBlacklist],
};

const middleWares = [thunk];

if (process.env.NODE_ENV === 'development') {
  middleWares.push(logger);
}

const persistRdx = persistReducer(persistConfig, reducer);
// applying middleware to redux
const middleware = applyMiddleware(...middleWares);
const store = createStore(persistRdx, middleware);
const persistor = persistStore(store);

export { store, persistor };
