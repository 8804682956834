import React, { Component, Fragment } from 'react';
import { Transition } from 'react-spring/renderprops';
import { ModalInput } from '../../components';

class DeleteAccountModal extends Component {
  handleShowModal = (e, label, value) => {
    e.preventDefault();
    this.setState({ [label]: value });
  };

  render() {
    const { showModalDeleteAccount, handleAccountDeletion, handleClose, disabled } = this.props;
    return (
      <Fragment>
        <Transition
          native
          items={showModalDeleteAccount}
          from={{ transform: 'translate(0, -100%)' }}
          enter={{ transform: 'translate(0, 0)' }}
          leave={{ transform: 'translate(0, -100%)' }}
        >
          {(showModalDeleteAccount) => (modalStyle) =>
            showModalDeleteAccount && (
              <ModalInput
                animatedModal
                type='deleteAccount'
                style={modalStyle}
                title='Apakah Anda yakin untuk menghapus akun Anda?'
                dangerLabel='Yakin'
                cancelLabel='Batal'
                handleSubmitForm={handleAccountDeletion}
                handleCancelForm={handleClose}
                handleClose={handleClose}
                disabled={disabled}
              />
            )}
        </Transition>
      </Fragment>
    );
  }
}

export default DeleteAccountModal;
