import axios from 'axios';
import Auth from '@aws-amplify/auth';

const methods = ['get', 'post', 'put', 'patch', 'delete'];
class PopCall {
  constructor(isExternal, externalUrl) {
    this.apiclient = axios.create({
      baseURL: isExternal ? externalUrl : `${process.env.REACT_APP_BASE_URL}`, // use env
      timeout: 60000,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    this.defineInterceptor();
    methods.forEach((method) => {
      this[method] = (targetUrl, body) => {
        return new Promise((resolve, reject) => {
          this.apiclient[method](targetUrl, body)
            .then((res) => {
              resolve(res.data); // Note, might want to adjust the resolved data
            })
            .catch((error) => {
              reject(error);
            });
        });
      };
    });
  }

  defineInterceptor() {
    // We're using interceptor to inject the requests with the idToken from amplify

    const search = window.location.search;
    const url = new URLSearchParams(search);

    this.apiclient.interceptors.request.use(async (config) => {
      const cxToken = localStorage.getItem('cxToken');
      const mobileAccessToken = url.get('mobileAccessToken');
      // if (config === process.env.REACT_APP_BASE_URL) {
      //   // check base URL, to prevent sending credentials to third party url
      // }
      if (Auth.user) {
        try {
          const currentSession = await Auth.currentSession();
          config.headers.Authorization = `${currentSession.idToken.jwtToken}`;
          console.log('Request Email : ', currentSession.idToken.payload.email || '');
          return config;
        } catch (error) {
          console.error('axios error', { error });
          return Promise.reject(error);
        }
      } else if (cxToken) {
        config.headers.Authorization = `${cxToken}`;
        return config;
      } else if (mobileAccessToken) {
        config.headers.Authorization = `${mobileAccessToken}`;
        return config;
      } else {
        // Prevent cancelToken undefineds
        // If you're not logged in, return the default config
        return config;
      }
    });
  }
}

export default PopCall;
