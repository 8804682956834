import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const OK = 'OK';
const WARN = 'WARN';
const NOT_OK = 'NOT_OK';
export const Badge = (props) => {
  return (
    <span
      className={classNames('pop-badge', {
        'pop-badge--success': props.status === OK,
        'pop-badge--rejected': props.status === NOT_OK,
        'pop-badge--pending': props.status === WARN,
      })}
      style={{ ...props.style }}
    >
      {props.label}
    </span>
  );
};

Badge.propTypes = {
  label: PropTypes.string.isRequired,
  status: PropTypes.string,
};
