import React from 'react';
import { Keyframes } from 'react-spring/renderprops';

export const FormSkeletons = Keyframes.Spring(async (next) => {
  while (true) {
    await next({
      from: { background: '#c9ccce' },
      to: { background: '#edeff2' },
    });
    await next({
      from: { background: '#edeff2' },
      to: { background: '#c9ccce' },
      reset: true,
    });
  }
});
