import {
  GET_STUDIES_SUCCESS,
  GET_STUDIES_FAILED,
  GET_STUDY_SUCCESS,
  GET_STUDY_FAILED,
} from './actionTypes';
import PopCall from '../../services/PopCall';

const popCall = new PopCall();

export function fetchStudies() {
  const path = `/participant/studies`;

  return (dispatch) => {
    return new Promise((resolve, reject) => {
      popCall
        .get(path)
        .then((res) => {
          const { data } = res;
          dispatch({ type: GET_STUDIES_SUCCESS, payload: data.studies });
          resolve();
        })
        .catch((err) => {
          dispatch({ type: GET_STUDIES_FAILED, payload: err });
          reject();
        });
    });
  };
}

export function fetchStudy(id) {
  const path = `/participant/studies/${id}`;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      popCall
        .get(path)
        .then((res) => {
          const { data } = res;
          dispatch({ type: GET_STUDY_SUCCESS, payload: data });
          resolve();
        })
        .catch((err) => {
          dispatch({ type: GET_STUDY_FAILED, payload: err });
          reject(err);
        });
    });
  };
}

export function fetchStudyByCode(code) {
  const path = `/participant/studies/code/${code}`;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      popCall
        .get(path)
        .then((res) => {
          const { data } = res;
          dispatch({ type: GET_STUDY_SUCCESS, payload: data });
          resolve();
        })
        .catch((err) => {
          dispatch({ type: GET_STUDY_FAILED, payload: err });
          reject(err);
        });
    });
  };
}

export function fetchStudyHistories(email) {
  const path = `/participant/studyhistories`;
  return new Promise((resolve, reject) => {
    popCall
      .get(path)
      .then((res) => {
        const { data } = res;
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
