import React, { Component } from 'react';
import Auth from '@aws-amplify/auth';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import GoogleIcon from 'assets/icons/google-icon.svg';
import { PopButton } from 'components';
import { loginToAgate } from '../../helpers/authHelpers';

class SocialLoginButtons extends Component {
  constructor(props) {
    super(props);
    this.googleLogin = this.googleLogin.bind(this);
    this.facebookLogin = this.facebookLogin.bind(this);
  }

  // checkAuthFederated() {
  //   Auth.currentAuthenticatedUser().then((user) => {
  //     const { changeLoginStatus, loginStateHandler } = this.props;
  //     const { email } = user;
  //     const path = `/users/${email}`;
  //     const myInit = {
  //       headers: {},
  //     };
  //     API.get(userDataAPI, path, myInit)
  //       .then((response) => {
  //         this.props.dispatch(fetchUserProfileSuccess(response.data));
  //         const { userProfile } = response.data;
  //         const { new_member } = userProfile;
  //         changeLoginStatus(false);
  //         if (new_member) {
  //           this.props.history.push('/pre-screening');
  //         } else {
  //           this.props.history.push('/dashboard');
  //         }
  //       })
  //       .catch((error) => {
  //         loginStateHandler(false, true);
  //         // setTimeout(() => {
  //         //   loginStateHandler(false, false);
  //         // }, 3500);
  //         console.error(error.response);
  //       });
  //   });
  // }

  // responseFacebook(response) {
  //   const { changeLoginStatus, loginStateHandler } = this.props;
  //   const { name, accessToken, expiresIn, email } = response;
  //   const token = accessToken;
  //   const expires = expiresIn;
  //   const arrName = name && name.split(' ');
  //   let first_name = '';
  //   let last_name = '';
  //   // ¯\_(ツ)_/¯
  //   if (response && !response.email) {
  //     return loginStateHandler(false, true);
  //   }
  //   Array.isArray(arrName) &&
  //     arrName.map((currElement, index) => {
  //       if (index === 0) {
  //         first_name = currElement;
  //       } else {
  //         last_name = currElement;
  //       }
  //       if (index > 1) {
  //         last_name += ' ' + currElement;
  //       }
  //     });
  //   changeLoginStatus(true);
  //   Auth.federatedSignIn(
  //     'facebook',
  //     {
  //       token,
  //       expires_at: expires * 1000 + new Date().getTime(),
  //     },
  //     response,
  //   )
  //     .then((credentials) => {
  //       const { IdentityId } = credentials.data;
  //       let insertProfileObj = {
  //         user_pool_id: 'from_federated', // Payload need to be updated
  //         identity_pool_id: IdentityId,
  //         email: email,
  //         login_provider: 'FACEBOOK',
  //         first_name: first_name,
  //         last_name: last_name,
  //         role: 'PARTICIPANT',
  //       };

  //       const path = `/users`;
  //       const myInit = {
  //         body: insertProfileObj,
  //         headers: {},
  //       };
  //       API.post(userDataAPI, path, myInit)
  //         .then((response) => {
  //           this.checkAuthFederated();
  //         })
  //         .catch((error) => {
  //           loginStateHandler(false, true);
  //           // setTimeout(() => {
  //           //   loginStateHandler(false, false);
  //           // }, 3500);
  //           console.error(error.response);
  //         });
  //     })
  //     .catch((e) => {
  //       // flag 2
  //       loginStateHandler(false, true);
  //       // setTimeout(() => {
  //       //   loginStateHandler(false, false);
  //       // }, 3500);
  //       console.error(e);
  //     });
  // }

  // checkAuthFederatedGoogle() {
  //   Auth.currentAuthenticatedUser().then((user) => {
  //     const { changeLoginStatus, loginStateHandler } = this.props;
  //     const { email } = user.profileObj;

  //     const path = `/users/${email}`;
  //     const myInit = {
  //       headers: {},
  //     };
  //     API.get(userDataAPI, path, myInit)
  //       .then((response) => {
  //         this.props.dispatch(fetchUserProfileSuccess(response.data));
  //         const { userProfile } = response.data;
  //         const { new_member } = userProfile;
  //         changeLoginStatus(false);
  //         if (new_member) {
  //           this.props.history.push('/pre-screening');
  //         } else {
  //           this.props.history.push('/dashboard');
  //         }
  //       })
  //       .catch((error) => {
  //         loginStateHandler(false, true);
  //         // setTimeout(() => {
  //         //   loginStateHandler(false, false);
  //         // }, 3500);
  //         console.error(error.response);
  //       });
  //   });
  // }

  // responseGoogle(response) {
  //   const { changeLoginStatus, loginStateHandler } = this.props;
  //   const { tokenId } = response;
  //   const { email, givenName, familyName } = response.profileObj;

  //   const { expires_at } = response.tokenObj;
  //   const token = tokenId;
  //   const expires = expires_at;
  //   changeLoginStatus(true);
  //   Auth.federatedSignIn('google', { token, expires_at: expires }, response)
  //     .then((credentials) => {
  //       const { IdentityId } = credentials.data;

  //       let insertProfileObj = {
  //         user_pool_id: 'from_federated', // payload need to be updated
  //         identity_pool_id: IdentityId,
  //         email: email,
  //         login_provider: 'GOOGLE',
  //         first_name: givenName,
  //         last_name: familyName,
  //         role: 'PARTICIPANT',
  //       };

  //       const path = `/users`;
  //       const myInit = {
  //         body: insertProfileObj,
  //         headers: {},
  //       };

  //       API.post(userDataAPI, path, myInit)
  //         .then((response) => {
  //           this.checkAuthFederatedGoogle();
  //         })
  //         .catch((error) => {
  //           loginStateHandler(false, true);
  //           // setTimeout(() => {
  //           //   loginStateHandler(false, false);
  //           // }, 3500);
  //           console.error(error.response);
  //         });
  //     })
  //     .catch((e) => {
  //       //flag 1
  //       loginStateHandler(false, true);
  //       // setTimeout(() => {
  //       //   loginStateHandler(false, false);
  //       // }, 3500);
  //       console.error(e);
  //     });
  // }

  async facebookLogin() {
    const { featureFlag } = this.props;
    const urlAltFacebook =
      featureFlag && featureFlag.facebookLogin && featureFlag.facebookLogin.url;
    if (featureFlag && featureFlag.facebookLogin && featureFlag.facebookLogin.isActive) {
      await Auth.federatedSignIn({ provider: 'Facebook' })
        .then((res) => {
          loginToAgate();
          Auth.currentAuthenticatedUser()
            .then((user) => {
              // this.setState({ user });
            })
            .catch(() => console.error('Not signed in facebook account'));
        })
        .catch((err) => console.error(err));
    } else {
      window.open(urlAltFacebook);
    }
  }
  googleLogin() {
    Auth.federatedSignIn({ provider: 'Google' })
      .then((res) => {
        loginToAgate();
        Auth.currentAuthenticatedUser()
          .then((user) => {
            // this.setState({ user });
          })
          .catch((err) => console.error('Not signed in google account'));
      })
      .catch((err) => console.error(err));
  }
  render() {
    const { loggingIn, featureFlag } = this.props;
    return (
      <React.Fragment>
        <PopButton
          id='login_facebook'
          color='inherit'
          size='large'
          variant='contained'
          disabled={loggingIn}
          onClick={this.facebookLogin}
          style={{ width: '100%', marginBottom: 20 }}
        >
          <i className='fa fa-facebook-f' style={{ marginRight: 12 }} /> Facebook
        </PopButton>

        <PopButton
          id='login_google'
          color='white'
          size='large'
          variant='outlined'
          disabled={loggingIn}
          onClick={this.googleLogin}
          style={{ width: '100%' }}
        >
          <img
            src={GoogleIcon}
            alt='error'
            width='14px'
            height='14px'
            style={{ marginRight: 12 }}
          />{' '}
          Google
        </PopButton>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ featureFlag }) => ({
  featureFlag,
});

// export default withRouter(SocialLoginButtons);
export default withRouter(connect(mapStateToProps)(SocialLoginButtons));
