import React from 'react';
import PropTypes from 'prop-types';
import ReactPinField from 'react-pin-field';
import clsx from 'clsx';

const CodeInput = ({
  fieldLength,
  onComplete,
  pinContainerStyle,
  pinFieldStyle,
  isSubmittingCode,
}) => (
  <div className={clsx('pin-container', pinContainerStyle)}>
    <ReactPinField
      className={clsx('pin-field', pinFieldStyle)}
      validate='0123456789'
      length={fieldLength}
      onComplete={(code) => onComplete(code)}
      disabled={isSubmittingCode}
    />
  </div>
);

CodeInput.propTypes = {
  fieldLength: PropTypes.number,
  onComplete: PropTypes.func,
  pinContainerStyle: PropTypes.string,
  pinFieldStyle: PropTypes.string,
  isSubmittingCode: PropTypes.bool,
};

CodeInput.defaultProps = {
  fieldLength: 6,
  onComplete: () => {},
  pinContainerStyle: {},
  pinFieldStyle: {},
};

export default CodeInput;
