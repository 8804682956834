import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Transition } from 'react-spring/renderprops';
import Countdown from 'react-countdown-now';
import moment from 'moment';
import PopCall from '../../services/PopCall';
import { Badge, ModalInput, PinConfirmation, Modal } from '../../components';
import { fetchUserProfileSuccess } from '../../store/actions/profileActions';

const popCall = new PopCall();

class PhoneVerification extends Component {
  state = {
    showModalHp: false,
    userProfile: this.props.profile,
    showModalVerification: false,
    postingVerification: false,
    validationFailed: false,
    validationMessage: '',
    reachLimitTime: null,
    changePhoneNumber: false,
    skipPhoneVerification: false,
  };

  componentDidMount = async () => {
    const { profile } = this.props;
    if (profile.groups && Array.isArray(profile.groups) && profile.groups.includes('admin')) {
      this.setState({
        skipPhoneVerification: true,
      });
    }
    const limitTime = await JSON.parse(sessionStorage.getItem('reachMaxAttempt'));
    if (limitTime) {
      this.setState({ reachLimitTime: limitTime });
    }
  };

  handleShowModal = (e, label, value) => {
    e.preventDefault();
    this.setState({ [label]: value });
  };

  _handlePhoneChange = (value) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        userProfile: {
          ...prevState.userProfile,
          phone_number: value && value.toString(),
        },
      };
    });
  };

  _handleVerifikasi = async (event) => {
    const { profile } = this.props;
    const userAge = moment().diff(
      moment(
        profile &&
          profile.criteria &&
          profile.criteria.DateOfBirth &&
          profile.criteria.DateOfBirth[0],
        'DD-MM-YYYY',
      ),
      'years',
    );
    event.preventDefault();
    await this.setState({
      postingVerification: true,
      validationMessage: '',
      validationFailed: false,
    });
    const { userProfile } = this.state;
    let phone_number =
      userProfile && userProfile.phone_number && userProfile.phone_number.substring(0, 3) === '+62'
        ? userProfile.phone_number
        : `+62${userProfile.phone_number}`;
    if (phone_number && phone_number.length > 8 && phone_number.length < 15) {
      const body = {
        phone_number,
      };
      const url = '/otp/send';
      try {
        if (userAge < 17) {
          await popCall.post(url, body);
          this.setState({
            showModalHp: false,
            showModalVerification: true,
          });
          return true;
        } else {
          const putResponse = await popCall.put('/profile/participant', body);
          if (putResponse && putResponse.data) {
            this.props.dispatch(
              fetchUserProfileSuccess({
                ...profile,
                phone_number: putResponse && putResponse.data && putResponse.data.phone_number,
              }),
            );
            this.setState({ showModalHp: false });
          }
        }
      } catch (error) {
        const { response } = error;
        console.error(error);
        if (response.status === 429) {
          this.setState({
            showModalHp: false,
          });
        } else {
          this.setState({
            validationMessage: response.data.message,
            validationFailed: true,
          });
        }
        return false;
      } finally {
        this.setState({
          postingVerification: false,
        });
      }
    } else {
      this.setState({
        postingVerification: false,
        validationMessage: 'Nomor HP tidak valid',
        validationFailed: true,
      });
    }
  };

  submitCode = async (code) => {
    const { userProfile } = this.state;
    const url = '/otp/verify';
    let phone_number =
      userProfile && userProfile.phone_number && userProfile.phone_number.substring(0, 3) === '+62'
        ? userProfile.phone_number
        : `+62${userProfile.phone_number}`;
    const body = {
      code,
      phone_number,
    };
    try {
      const responseVerif = await popCall.post(url, body);
      if (responseVerif && responseVerif.data) {
        this.props.dispatch(fetchUserProfileSuccess(responseVerif.data));
      }
      return responseVerif;
    } catch (error) {
      const { response } = error;
      console.error(error);
      const maxAttempt = {
        message: 'Kamu salah memasukkan kode verifikasi sebanyak 5x',
      };
      if (response.status === 429) {
        await sessionStorage.setItem('reachMaxAttempt', JSON.stringify(Date.now()));
        this.setState({
          reachLimitTime: Date.now(),
          showModalVerification: false,
        });
      }
      return (response && response.data) || maxAttempt;
    }
  };

  clearReachLimit = async () => {
    this.setState({ reachLimitTime: null });
    await sessionStorage.removeItem('reachMaxAttempt');
  };

  render() {
    const { profile } = this.props;
    const {
      showModalHp,
      userProfile,
      showModalVerification,
      postingVerification,
      validationFailed,
      validationMessage,
      reachLimitTime,
      skipPhoneVerification,
    } = this.state;

    let displayPhone = userProfile.phone_number || '';
    if (displayPhone && displayPhone.substring(0, 3) === '+62') {
      displayPhone = displayPhone.replace('+62', '');
    }

    return (
      <Fragment>
        <div className='phone-verification'>
          {profile.phone_number ? (
            <p className='f-13 mr-3 pt-1 phone-verification--mb0'>{profile.phone_number}</p>
          ) : (
            <button
              className='f-13 btn btn-link btn-sm p-0 mr-2'
              onClick={(e) => this.handleShowModal(e, 'showModalHp', true)}
            >
              {reachLimitTime ? (
                <Countdown
                  date={reachLimitTime + 600000}
                  renderer={(props) => (
                    <div>{`Batas maksimum telah tercapai (${props.minutes}:${props.seconds})`}</div>
                  )}
                  onComplete={() => this.clearReachLimit()}
                />
              ) : (
                'Masukkan Nomor HP'
              )}
            </button>
          )}
          {profile.phone_number && (
            <Fragment>
              <Badge
                status={'OK'}
                label={'Terverifikasi'}
                style={{ marginLeft: '0.2rem', marginTop: '0.1rem', height: 'min-content' }}
              />
              <p
                className='f-13 btn btn-link btn-sm ml-2 phone-verification--mb0 phone-verification__link-button'
                onClick={(e) =>
                  reachLimitTime
                    ? {}
                    : (this.handleShowModal(e, 'showModalHp', true),
                      this.setState({ changePhoneNumber: true }))
                }
                style={reachLimitTime ? { color: 'red' } : {}}
              >
                {reachLimitTime ? (
                  <Countdown
                    date={reachLimitTime + 600000}
                    renderer={(props) => (
                      <div>{`Batas maksimum telah tercapai (${props.minutes}:${props.seconds})`}</div>
                    )}
                    onComplete={() => this.clearReachLimit()}
                  />
                ) : (
                  'Ubah'
                )}
              </p>
            </Fragment>
          )}
        </div>

        <Transition
          native
          items={showModalHp}
          from={{ transform: 'translate(0, -100%)' }}
          enter={{ transform: 'translate(0, 0)' }}
          leave={{ transform: 'translate(0, -100%)' }}
        >
          {(ShowInputHp) => (modalStyle) =>
            ShowInputHp && (
              <ModalInput
                animatedModal
                type='phone'
                style={modalStyle}
                title='Nomor HP'
                formLabelMain='Masukkan nomor HP Anda'
                submitLabel='Verifikasi'
                cancelLabel='Batal'
                value={displayPhone}
                disabled={postingVerification}
                formValidationMsg={validationMessage || ''}
                formValidationFailed={validationFailed}
                //untuk sementara pake funx _savingCriteria, nanti bedain fungsi aja untuk validasi
                // handleSubmitForm={this._savingCriteria}
                handleSubmitForm={this._handleVerifikasi}
                handleCancelForm={() => this.setState({ showModalHp: false })}
                handleFormChange={this._handlePhoneChange}
                handleClose={() => this.setState({ showModalHp: false })}
                changePhoneNumber={skipPhoneVerification ? false : this.state.changePhoneNumber}
              />
            )}
        </Transition>

        <Transition
          native
          items={showModalVerification}
          from={{ transform: 'translate(0, -100%)' }}
          enter={{ transform: 'translate(0, 0)' }}
          leave={{ transform: 'translate(0, -100%)' }}
        >
          {(showPinVerif) => (style) =>
            showPinVerif && (
              <Modal classname='modal' show={showPinVerif} styles={style} animatedModal>
                {/* {this._renderModal()} */}
                <div className='modal-header'>
                  {/* <p>Pilih Rekening bank anda</p> */}
                  <button
                    type='button'
                    className='close'
                    onClick={() => this.setState({ showModalVerification: false })}
                  >
                    <span>&times;</span>
                  </button>
                </div>
                {/**NOTE We convert the ModalFormRekening to hooks or do this... */}
                <PinConfirmation
                  submitCode={this.submitCode}
                  resendCode={this._handleVerifikasi}
                  closeModal={() => this.setState({ showModalVerification: false })}
                />
              </Modal>
            )}
        </Transition>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile.userProfile,
  };
};
export default connect(mapStateToProps)(PhoneVerification);
