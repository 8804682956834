import React from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import DoneIcon from 'assets/icons/done-icon.svg';
import CloseIcon from 'assets/icons/close-icon.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    top: 80,
    left: '50%',
    padding: 24,
    borderRadius: 16,
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'row',
    gap: 12,
    alignItem: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      left: '50%',
      width: '75%',
    },
  },
  title: {
    fontSize: 16,
    fontWeight: '600',
    color: '#FFFFFF',
  },
  greenBackground: {
    backgroundColor: '#4CAF50',
  },
  redBackground: {
    backgroundColor: '#E57373',
  },
}));

export const Toast = (props) => {
  const classes = useStyles();

  const getBackgroundColor = () => {
    if (props.category === 'success') {
      return classes.greenBackground;
    }
    return classes.redBackground;
  };

  return (
    <>
      {props.show && (
        <div className={`${classes.container} ${getBackgroundColor()}`}>
          <img src={DoneIcon} alt='done-icon' />
          <span className={classes.title}>{props.title}</span>
          <img src={CloseIcon} alt='close-icon' onClick={() => props.onClose()} />
        </div>
      )}
    </>
  );
};

Toast.propTypes = {
  title: PropTypes.string,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  category: PropTypes.oneOf(['success', 'error']),
};
