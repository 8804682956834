import Auth from '@aws-amplify/auth';
import PopCall from '../services/PopCall';
import { store } from 'store/store';

const popCall = new PopCall();

/**
 * Check user authentication status
 * and get cognito attributes
 * @param {Object} options
 * you can pass options such as bypassCache
 * to Auth.currentAuthenticatedUser.
 * @returns {Object}
 */
export function checkAuthStatus(options) {
  return new Promise((resolve) => {
    Auth.currentAuthenticatedUser(options)
      .then((user) => {
        resolve(user);
      })
      .catch(() => {
        // Not authenticated
        resolve(null);
      });
  });
}

export const checkAuthDb = async () => {
  try {
    await Auth.currentAuthenticatedUser();
    const profile = await popCall.get('/profile');
    return profile.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export function logOut() {
  return new Promise((resolve, reject) => {
    Auth.signOut()
      .then((user) => {
        if (process.env.NODE_ENV === 'production') {
          window.dataLayer.push({
            userId: 0,
          });
        }
        resolve(user);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const loginToAgate = async () => {
  const path = '/auth/register';
  const url = `${process.env.REACT_APP_AGATE_URL}${path}`;
  try {
    let body = null;

    if (process.env.REACT_APP_ENV === 'staging') {
      const currentState = store.getState();
      const userSub = currentState.profile.userProfile.sub;
      body = {
        PopulixID: userSub,
      };
    }

    const tokenAgate = await popCall.post(url, body);
    return tokenAgate;
  } catch (error) {
    return null;
  }
};
