import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateRdxPhoneNumber } from '../../store/actions/profileActions';
import { showFailedNotification, hideNotification } from '../../store/actions/profileActions';

import PopCall from '../../services/PopCall';

const popCall = new PopCall();

class SettingsAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      changeEmailBtn: false,
      changeMobileBtn: false,
      showPassword: false,
      postPhoneNumber: false,
      phoneNumber: '',
    };

    //Email Toggle
    this.toggleEmail = this.toggleEmail.bind(this);

    //Mobile Toggle
    this.toggleMobile = this.toggleMobile.bind(this);
  }

  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
  };

  //Toggle Email Function
  toggleEmail() {
    this.setState({
      changeEmailBtn: !this.state.changeEmailBtn,
    });
  }

  _toggleShowPassword = () => {
    this.setState((prevState) => {
      return {
        showPassword: !prevState.showPassword,
      };
    });
  };

  //Toggle Mobile Function
  toggleMobile() {
    this.setState({
      changeMobileBtn: !this.state.changeMobileBtn,
    });
  }

  _onFormSubmit = async (event) => {
    event.preventDefault();
    const { profile, dispatch } = this.props;
    const { phoneNumber } = this.state;
    const body = {
      phone_number: phoneNumber,
    };
    this.setState({ postPhoneNumber: true });
    try {
      await popCall.patch('/participant/phone', body);
      dispatch(updateRdxPhoneNumber(phoneNumber));
    } catch (error) {
      if (error.response.data.message === 'Invalid phone number format.') {
        dispatch(
          showFailedNotification(
            'Format nomor telepon harus menggunakan tanda (+) dan tidak boleh ada karakter lain',
          ),
        );
        setTimeout(() => {
          dispatch(hideNotification());
        }, 3800);
      }
      console.error('error posting phone number', { error });
    } finally {
      this.setState({ postPhoneNumber: false, changeMobileBtn: false });
    }
  };

  _onChangeForm = (e) => {
    const { target } = e;
    const { value, name } = target;
    this.setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  render() {
    const { profile } = this.props;
    const { showPassword, postPhoneNumber } = this.state;
    const phone_number = profile.phone_number;

    return (
      <div
        className='tab-pane fade show active'
        id='account'
        role='tabpanel'
        aria-labelledby='nav-tab-account'
      >
        <div className='settings-grid'>
          {/* <div className='settings-header content-header desktop-disabled'>
            <h3>Kontak</h3>
          </div> */}
          {/* Account Settings Form */}
          {/** Email hidden until further notice */}
          <div className='account-settings-form'>
            {/* No Handphone */}
            <div className='account-settings-item'>
              <div>
                <p className='font-bold'>No. handphone</p>
                {phone_number && phone_number !== '' ? (
                  <p>
                    Nomor handphone yang kamu gunakan saat ini adalah{' '}
                    <span className='font-bold'>{phone_number}</span>
                  </p>
                ) : (
                  <p>Kamu belum menambahkan nomor handphone mu</p>
                )}
              </div>
              <button
                className='btn btn-secondary'
                style={{ fontWeight: 400, fontSize: 14 }}
                onClick={this.toggleMobile}
              >
                {this.state.changeMobileBtn === true ? 'Batal' : 'Ubah'}
              </button>
              {/* Change Mobile Phone Form */}
              <div
                className={
                  this.state.changeMobileBtn === true ? 'collapsed show' : 'collapsed collapse'
                }
              >
                <form onSubmit={this._onFormSubmit}>
                  <div className='form-group'>
                    {/* Password */}
                    {/* <label className="settings__question font-bold">
											Kata sandi saat ini
										</label> */}
                    {/* <div className="input-group mb-3">
											<input
												type={showPassword ? 'text' : 'password'}
												className="form-control form-password settings__input account-settings--password"
											/>
											<div className="input-group-append">
												<span className="input-group-text">
												  <i
												    onClick={this._toggleShowPassword}
													className={`form-control-feedback fas ${
														showPassword ? 'fa-eye' : 'fa-eye-slash'
													} form-icon-eye`}
												  />
												</span>
											</div>
										</div> */}

                    {/* Mobile Phone */}
                    <label className='font-bold settings__question'>No. handphone baru</label>
                    <input
                      type='tel'
                      // pattern="+[0-9]{2}" TODO LATER
                      className='form-control settings__input account-settings--phone'
                      placeholder='e.g. +62812xxxxx'
                      onChange={this._onChangeForm}
                      name='phoneNumber'
                    />
                  </div>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    style={{ fontWeight: 400, fontSize: 14 }}
                    disabled={postPhoneNumber}
                  >
                    {postPhoneNumber ? 'Menyimpan' : 'Simpan'}
                  </button>
                </form>
              </div>
            </div>
            {/* Delete Account - disabled until further notice */}
          </div>
        </div>
      </div>
    );
  }
}

const Modal = ({ handleClose, show }) => {
  const showHideClassName = show ? 'modal display-block' : 'modal display-none';

  return (
    <div className={showHideClassName}>
      <section className='modal-main'>
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <button type='button' className='close' onClick={handleClose}>
                <span>&times;</span>
              </button>
            </div>
            <form>
              <div className='modal-body'>
                <h4 className='font-bold'>Kamu yakin mau menghapus akun Populix?</h4>
                <div className='del-acc--form'>
                  <div className='form-group'>
                    {/* Label for Password */}
                    <label htmlFor='del-acc--password' className='font-bold del-acc__question'>
                      Kata sandi
                    </label>

                    {/* Password Wrapper */}
                    <div className='input-group mb-3'>
                      {/* Input Password */}
                      <input
                        type='password'
                        className='form-control form-password settings__input'
                        id='del-acc--password'
                        placeholder='Masukkan kata sandi'
                      />

                      {/* Eye Icon Toggle*/}
                      <div className='input-group-append'>
                        <span className='input-group-text'>
                          <i className='form-control-feedback fas fa-eye form-icon-eye' />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='modal-footer modal-footer-type-2'>
                <button type='submit' className='btn btn-primary modal-footer-btn'>
                  Konfirmasi
                </button>
                <a
                  onClick={handleClose}
                  role='button'
                  href='#'
                  className='btn btn--text text--light-blue modal-footer-btn pr-0'
                >
                  Batal
                </a>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.profile.userProfile,
  };
};
export default connect(mapStateToProps)(SettingsAccount);

/* Delete Account */
// disabled until further notice
/* <div className="account-settings-item">
	<div>
		<p className="font-bold">Hapus akun Populix</p>
		<p>Semua data kamu akan terhapus dan tidak bisa dikembalikan</p>
		<p className="f-12">
			Note: kamu tidak perlu menghapus akun Populix yang saat ini
									kamu gunakan, jika kamu hanya ingin{' '}
			<span className="text--light-blue" onClick={this.toggleEmail}>
				mengganti alamat email
									</span>
		</p>
	</div>


	<button
		type="button"
		className="btn btn-secondary"
		style={{ fontWeight: 400, fontSize: 14 }}
		onClick={this.showModal}
	>
		Hapus akun
							</button>

	<Modal
		className="modal fade"
		id="del-acc--modal"
		tabIndex="-1"
		role="dialog"
		show={this.state.show}
		handleClose={this.hideModal}
	/>

	<div
		id="modal-backdrop"
		className="modal-backdrop-transparent modal-transition"
	/>
</div>
*/
/*
<div className="account-settings-item">
  <div>
    <p className="font-bold">Email</p>
    <p>Email yang kamu gunakan saat ini adalah <span
        className="font-bold">juinalwilliam@alva.com</span></p>
  </div>
  <button className="btn btn-secondary collapsed" type="button" style={{fontWeight: 400, fontSize: 14}}
          onClick={this.toggleEmail}>{(this.state.changeEmailBtn === true) ? 'Batal' : 'Ubah'}</button>
  <div
      className={(this.state.changeEmailBtn === true) ? 'collapsed show' : 'collapsed collapse'}>
    <form>
      <div className="form-group">
        <label className="settings__question font-bold">Kata sandi saat
          ini</label>
        <div className="input-group mb-3">
          <input type="password"
                 className="form-control form-password settings__input account-settings--password"/>
          <div className="input-group-append">
                                  <span className="input-group-text">
                                      <i className="form-control-feedback fas fa-eye form-icon-eye"/>
                                      </span>
          </div>
        </div>
        <label className="font-bold settings__question">Email baru</label>
        <input type="text"
               className="form-control settings__input account-settings--email"
               placeholder="someone@something.com"/>
      </div>
    </form>
  </div>
</div> */
