import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';

export const Button = (props) => {
  const Tag = props.asNav && props.navLink ? 'a' : 'button';
  return (
    <Tag
      id={props.id}
      {...(props.navLink ? { href: props.navLink } : {})}
      style={{ ...props.style }}
      className={classnames(
        'btn',
        {
          'btn-default': !props.type || props.type === 'default',
          'btn-primary': props.type === 'primary',
          'btn-danger': props.type === 'danger',
          'btn--orange': props.type === 'warning',
          'nav-link': props.asNav,
          'btn-block': props.block,
          'text--light-blue font-bold': props.asNav && props.type === 'primary',
        },
        `${props.classNames}`,
      )}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.loader ? <CircularProgress disableShrink size={20} /> : props.label}
    </Tag>
  );
};

Button.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  onClick: PropTypes.func,
  asNav: PropTypes.bool,
  navLink: PropTypes.string,
  // Maybe we should consider to parse class from props
  // so that users can have more flexibility
  block: PropTypes.bool,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  loader: PropTypes.bool,
};
