import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { fetchUserProfileSuccess, fetchUserScreening } from '../../store/actions/profileActions';
import { setQuestion } from '../../store/actions/questionActions';
import { animated } from 'react-spring';
import { FormSkeletons } from '../Skeletons/FormSkeletons';
import {
  showFailedNotification,
  showSuccessNotification,
  hideNotification,
} from '../../store/actions/profileActions';
import {
  ScreeningTypeDate,
  ScreeningTypeSelect,
  AvatarImageCropperWrapper,
  AvatarImageCropper,
  PhoneVerification,
  DeleteAccountModal,
  UploaderKTP,
} from '../../components';
import {
  DateFormatter,
  ValidateFileSize,
  ValidateBirthYear,
  StringToDate,
  getQuestion,
} from '../../helpers/generalHelpers';
import { checkAuthDb } from '../../helpers/authHelpers';
import { ScreeningTypeSearchableSelect } from '../ScreeningTypes/ScreeningTypes';
import PopCall from '../../services/PopCall';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

const popCall = new PopCall();
const defaultProfileImage = require('assets/img/default-img-02.png');
const iconKTP = require('assets/img/ktp-icon.png');
const uploadIcon = require('assets/img/upload.png');

class SettingsProfile extends Component {
  static defaultProps = {
    minAllowedYear: 10,
  };

  state = {
    screenReady: false,
    screeningData: [],
    screeningDataToPost: [],
    userProfileData: {
      first_name: '',
      last_name: '',
      address: '',
      profile_picture_url_state: null,
    },
    updatingProfile: false,
    selectedBirthDate: null,
    error: null,
    ktp_obj: {},
    cities: [],
    cityName: '',
    invalidDOB: false,
    userProfileUpdated: false,
    temp_profile_picture_state: '',
    userScreening: {},
    questionBasicProfile: [],
    userProfile: this.props.profile,
    changeProfilePict: false,
    showModalHp: false,
    showModalVerification: false,
    postingVerification: false,
    countDownStart: false,
    validationFailed: false,
    validationMessage: '',
    isDeletingAccount: false, //show feedback options
    otherFeedbackSelected: false, //used only when the user click on feedback input
    otherFeedback: '', //actual text input
    selectedFeedback: 0, //non custom input
    showDeleteAccountPrompt: false, //show delete modal
    feedbacks: [], //feedback options
    disabledOnSubmit: false, //disable submit button on delete account,
    isAdmin: false,
    showModalKTP: false,
    isValidatingKTP: false,
    isValidKTP: false,
    errorKTP: '',
  };

  componentDidMount() {
    //set feedbacks options
    this.setState({
      feedbacks: [
        'Saya tidak pernah mendapatkan studi',
        'Hadiah yang Populix tawarkan tidak/kurang menarik',
        'Saya tidak mempunyai waktu untuk mengisi studi',
        'Saya terlalu banyak mendapatkan email dari Populix',
        'Saya memiliki isu dengan privasi',
      ],
    });
    this.fetchUserScreening();
  }

  componentDidUpdate(prevProps, prevState) {
    // check After Profile Updated
    if (prevProps.profile !== this.props.profile) {
      this.setState({ userProfile: this.props.profile });
    }
  }

  fetchUserScreening = async () => {
    try {
      const participantScreening = await checkAuthDb();
      this.props.dispatch(fetchUserScreening(participantScreening.criteria));
      this.setState({ userScreening: participantScreening.criteria });
      this.fetchQuestion();
    } catch (error) {
      console.error('err', error);
      this.setState({ error: error });
    } finally {
      // when all success, set screen to ready
      this.setState({ screenReady: true });
    }
  };

  fetchQuestion = async () => {
    const { is_loaded, question } = this.props.question;
    if (!is_loaded) {
      const response = await getQuestion();
      if (response) {
        const questionBasicProfile = response.filter((data) => data.tags === 'BasicProfile');
        this.setState({ questionBasicProfile });
        //store question to redux
        this.props.dispatch(setQuestion(response));
      }
    } else {
      const questionBasicProfile = question.filter((data) => data.tags === 'BasicProfile');
      this.setState({ questionBasicProfile });
    }
    // fetch question successed, then fetch cities.
    this.getCities();
  };

  getCities = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_CDN}/cities.json`);
      if (response && response.data) {
        this.setState({
          cities: response.data.cities.map((city) => {
            return { label: city, value: city };
          }),
        });
      }
    } catch (err) {
      console.warn('error fetch city', err);
    }
  };

  _selectForm = (target) => {
    const { minAllowedYear } = this.props;
    const { invalidDOB, questionBasicProfile, userScreening } = this.state;
    const data =
      Array.isArray(questionBasicProfile) &&
      questionBasicProfile.filter((item) => {
        return item.label === target;
      });
    if (data.length === 0) {
      // if there's no result, return a message
      return <p>No data available</p>;
    }
    switch (data[0].optionType) {
      case 1:
        // option type `: input text
        return this._renderTypeText(data[0]);
      case 'INPUT_DATE':
        return (
          <ScreeningTypeDate
            dateState={StringToDate(userScreening[target] && userScreening[target][0])}
            className={`form-control settings__input ${
              invalidDOB ? 'settings__input-border--danger' : ''
            }`}
            questionData={data[0]}
            maxDate={new Date(`December 31, ${new Date().getFullYear() - minAllowedYear}`)}
            onChangeFunction={this._onChangeScreeningDataDate}
          />
        );
      case 'OPTION_GENDER':
        // option type 3: radio select
        return (
          <ScreeningTypeSelect
            className='form-control settings__option'
            questionData={data[0]}
            value={userScreening[target]}
            onChangeFunction={this._onChangeScreeningData}
          />
        );
      case 'OPTION_SELECT':
        // option type 2: optopn select
        return (
          <ScreeningTypeSelect
            className='form-control settings__option'
            questionData={data[0]}
            value={userScreening[target]}
            onChangeFunction={this._onChangeScreeningData}
          />
        );
      case 7:
        return (
          <ScreeningTypeSelect
            className='form-control settings__option'
            questionData={data[0]}
            onChangeFunction={this._onChangeScreeningData}
          />
        );
      case 'DETAILED_ADDRESS':
        return (
          <ScreeningTypeSearchableSelect
            questionData={data[0]}
            defaultData={this.state.cities}
            value={userScreening[target]}
            onChangeFunction={this._onChangeCityValue}
            singleValueColor='hsl(0, 0%, 20%)'
            classNameParent='form-control settings__option'
            classNameComponent={{
              dropDownClassName: 'setting-asyncoptions__dropdown-indicator',
            }}
          />
        );
      default:
        return this._renderTypeText(data[0]);
    }
  };

  _onChangeUserProfileForm = (e) => {
    const { target } = e;
    const { name, value } = target;
    this.setState((prevState) => {
      return {
        ...prevState,
        userProfile: {
          ...prevState.userProfile,
          [name]: value,
        },
        userProfileUpdated: true,
      };
    });
  };

  _errorHandler = (type) => {
    switch (type) {
      case 'maxsize':
        this._toggleErrorNotification('Ukuran file tidak boleh melebihi 10 MB', 3000);
        break;
      case 'not_image':
        this._toggleErrorNotification('Format gambar harus jpeg atau png', 3000);
        break;
      default:
    }
  };

  _toggleErrorNotification = (message, timeoutDuration) => {
    const { dispatch } = this.props;
    dispatch(showFailedNotification(message));
    setTimeout(() => {
      dispatch(hideNotification());
    }, timeoutDuration);
  };

  _onUploadKtp = async (e) => {
    const { dispatch } = this.props;
    const { target } = e;
    const { files } = target;
    const reader = new FileReader();
    try {
      await ValidateFileSize(files[0]);
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        this.setState((prevState) => {
          return {
            ...prevState,
            ktp_obj: {
              image_base_64: reader.result,
            },
            userProfileUpdated: true,
          };
        });
      };
    } catch (error) {
      dispatch(showFailedNotification('Ukuran file tidak boleh melebihi 10 MB'));
      setTimeout(() => {
        dispatch(hideNotification());
      }, 3000);
    }
  };

  _onChangeCityValue = (e, label) => {
    const { value } = e;
    this.setState((prevState) => {
      return {
        userScreening: {
          ...prevState.userScreening,
          [label]: [value],
        },
        userProfileUpdated: true,
      };
    });
  };

  _onChangeScreeningData = (e, label) => {
    const { target } = e;
    const { value } = target;
    this.setState((prevState) => {
      return {
        userScreening: {
          ...prevState.userScreening,
          [label]: [value],
        },
        userProfileUpdated: true,
      };
    });
  };

  _handleProfilePicture = (image) => {
    this.setState((prevState) => {
      return {
        userProfile: {
          ...prevState.userProfile,
          picture: image,
        },
        changeProfilePict: true,
        userProfileUpdated: true,
      };
    });
  };

  _onChangeScreeningDataDate = async (dateValue, label) => {
    const formattedDate = DateFormatter(dateValue);
    try {
      await ValidateBirthYear(formattedDate);
    } catch (error) {
      return this.setState({ invalidDOB: true }, () => {
        setTimeout(() => {
          this.setState({ invalidDOB: false });
        }, 3000);
      });
    }
    this.setState((prevState) => {
      return {
        userScreening: {
          ...prevState.userScreening,
          [label]: [formattedDate],
        },
        userProfileUpdated: true,
      };
    });
  };

  _savingCriteria = async () => {
    this.setState({
      showModalHp: false, // ini untuk sementara biar bisa dipake
      updatingProfile: true,
    });
    const { dispatch } = this.props;
    const { ktp_obj, changeProfilePict, userProfile } = this.state;
    const isKtpUploaded = Object.keys(ktp_obj).length !== 0;
    let body = {};
    if (isKtpUploaded && ktp_obj.image_base_64) {
      body = {
        ...body,
        ktpBase64: ktp_obj.image_base_64,
      };
    }

    if (userProfile && userProfile.picture && changeProfilePict) {
      body = {
        ...body,
        profilePictureBase64: userProfile.picture,
      };
    }

    if (userProfile && userProfile.name) {
      body = {
        ...body,
        name: userProfile.name,
      };
    }

    if (userProfile && userProfile.family_name) {
      body = {
        ...body,
        family_name: userProfile.family_name,
      };
    }

    if (userProfile && userProfile.address) {
      body = {
        ...body,
        address: userProfile.address,
      };
    }

    const url = '/profile/participant';
    try {
      const response = await popCall.put(url, body);
      if (response && response.data) {
        dispatch(fetchUserProfileSuccess(response.data));

        dispatch(showSuccessNotification());
        this.setState((prevState) => {
          return {
            ...prevState,
            updatingProfile: false,
            changeProfilePict: false,
          };
        });
        setTimeout(() => {
          dispatch(hideNotification());
        }, 2500);
      }
    } catch (error) {
      console.error('error saving Criteria', error);
      this.setState({ updatingProfile: false });
      dispatch(showFailedNotification());
      setTimeout(() => {
        dispatch(hideNotification());
      }, 2500);
    }
  };

  _renderTypeText = () => {
    return <input type='text' className='form-control settings__input' />;
  };

  _renderError = () => {
    return (
      <h3>
        Terjadi kesalahan saat mengambil data
        <span role='img' aria-label='sadface'>
          😟
        </span>
        , silahkan coba lagi
      </h3>
    );
  };

  _renderLoading = () => {
    return (
      <FormSkeletons native>
        {(props) => (
          <div className='skeletons form-skeleton__container d-flex flex-column'>
            <div className='d-flex flex-row align-items-center'>
              <animated.div
                className='skeletons form-skeleton__profile-picture'
                style={{ ...props }}
              />
              <animated.div
                className='skeletons form-skeleton__children mx-2'
                style={{ ...props, width: '20%', height: 10 }}
              />
            </div>
            <div className='d-flex col-12 p-0 justify-content-between'>
              <animated.div className='skeletons form-skeleton__children' style={{ ...props }} />
              <animated.div
                className='skeletons form-skeleton__children ml-2'
                style={{ ...props }}
              />
            </div>
            <div className='d-flex col-12 p-0 justify-content-between'>
              <animated.div className='skeletons form-skeleton__children' style={{ ...props }} />
              <animated.div
                className='skeletons form-skeleton__children ml-2'
                style={{ ...props }}
              />
            </div>
            <div className='d-flex col-12 p-0 justify-content-between'>
              <animated.div className='skeletons form-skeleton__children' style={{ ...props }} />
              <animated.div
                className='skeletons form-skeleton__children ml-2'
                style={{ ...props }}
              />
            </div>
            <animated.div className='skeletons form-skeleton__children' style={{ ...props }} />
            <animated.div className='skeletons form-skeleton__children' style={{ ...props }} />
            <animated.div
              className='skeletons form-skeleton__children'
              style={{ ...props, width: '50%' }}
            />
            <animated.div
              className='skeletons form-skeleton__children'
              style={{ ...props, width: '80%' }}
            />
          </div>
        )}
      </FormSkeletons>
    );
  };

  handleOpenModalKTP = () => {
    this.setState({ showModalKTP: true });
  };

  handleCloseModalKTP = () => {
    const { isValidKTP, ktp_obj } = this.state;
    this.setState({
      ktp_obj,
      showModalKTP: false,
      errorKTP: '',
    });
  };

  _validateKTP = async (idCard) => {
    const { isAdmin, userProfile } = this.state;
    const { DateOfBirth, Gender } = userProfile.criteria;
    try {
      this.setState({
        isValidatingKTP: true,
        ktp_obj: {
          image_base_64: idCard,
        },
        errorKTP: '',
      });
      const result = await popCall.post('/profile/validateIDCard', {
        idCard,
      });

      const checkIsValid = isAdmin
        ? true
        : moment(result && result.data && result.data.dateOfBirth).isSame(
            moment(DateOfBirth && DateOfBirth[0], 'DD-MM-YYYY'),
          ) && (result && result.data && result.data.gender) === (Gender && Gender[0]);

      if (!checkIsValid) {
        this.setState({
          errorKTP:
            'Data pada KTP tidak sama dengan data yang Anda masukkan. Silahkan upload kembali.',
        });
      } else {
        this.setState({
          isValidKTP: true,
        });
      }
    } catch (error) {
      let defaultMessage =
        'Data KTP tidak terbaca oleh sistem. Jangan khawatir, tekan ‘Simpan KTP’ agar admin kami dapat memvalidasi KTP anda lebih lanjut.';
      this.setState({
        errorKTP:
          error &&
          error.response &&
          error.response.data &&
          error.response.data.status === 'DUPLICATE'
            ? 'Maaf KTP ini sudah digunakan akun lain.'
            : defaultMessage,
      });
    } finally {
      this.setState({
        isValidatingKTP: false,
      });
    }
  };

  _renderUploadKtpInput = () => {
    const { ktp_obj } = this.state;
    const isKtpSelected = Object.keys(ktp_obj).length !== 0;
    return (
      <div className='profile-settings-id-card'>
        {isKtpSelected && (
          <img
            alt='ktp'
            className='form-control profile-settings-id-card__upload-icon'
            src={iconKTP}
          />
        )}
        <div
          className={classNames({
            'd-flex flex-row align-items-center': !isKtpSelected,
          })}
        >
          <span
            id='profile-id-card'
            className='btn btn-file text--light-blue'
            onClick={this.handleOpenModalKTP}
          >
            <img
              alt='ktp-nt-uploaded'
              src={uploadIcon}
              style={{
                position: 'relative',
                height: 20,
                top: -5,
              }} // TODO Move to scss
              className='mr-2'
            />
            {isKtpSelected ? 'Ganti file' : 'Pilih file'}
          </span>
          <p className={classNames('f-12', { 'ml-0': isKtpSelected })}>
            Maksimal file <strong>10 MB</strong>
          </p>
        </div>
      </div>
    );
    // }
  };

  _renderKTPVerified = () => {
    return (
      <Fragment>
        <div className='profile-settings-id-card'>
          <img
            alt='ktp'
            className='form-control profile-settings-id-card__upload-icon'
            src={iconKTP}
          />
          <span style={{ position: 'relative', left: '-2.15rem', bottom: '-.6rem' }}>
            <FontAwesomeIcon icon={faCheckCircle} color='#1f9161' size='lg' />
          </span>
        </div>
        <p className='f-12 mb-0 font-bold text-color--success'>Ktp anda berhasil di verifikasi.</p>
      </Fragment>
    );
  };

  _checkFeedbackOption = () => {
    const { otherFeedbackSelected, otherFeedback } = this.state;
    //check if the user select other feedback and must fill in the text input
    if (otherFeedbackSelected && otherFeedback === '') {
      this._toggleErrorNotification('Alasan tidak boleh kosong', 3000);
    } else {
      this.setState({ showDeleteAccountPrompt: true });
    }
  };

  //render question and feedback options
  _renderFeedback = () => {
    const { otherFeedbackSelected, otherFeedback, selectedFeedback, feedbacks } = this.state;

    return (
      <Fragment>
        <p>Kami sedih melihat kamu harus pergi dari Populix.</p>
        <p>Mohon pilih alasan kenapa kamu ingin menutup akunmu pada kolom dibawah ini:</p>
        {feedbacks.map((feedback, key) => {
          return (
            <div className='my-1 mx-3' key={key}>
              <input
                type='radio'
                name='feedback'
                className='mx-2'
                checked={key === selectedFeedback}
                onChange={() => {
                  this.setState({ selectedFeedback: key, otherFeedbackSelected: false });
                }}
              />
              {feedback}
            </div>
          );
        })}
        <div className='my-1 mx-3'>
          <input
            type='radio'
            name='feedback'
            className='mx-2'
            checked={otherFeedbackSelected}
            onChange={async () => {
              //if other feedback is selected and set generated feedback to not selected
              await this.setState({ otherFeedbackSelected: true, selectedFeedback: '' });
            }}
          />
          <input
            type='text'
            value={otherFeedback}
            onChange={(feedback) => {
              this.setState({ otherFeedback: feedback.target.value });
            }}
            onFocus={() => {
              this.setState({ otherFeedbackSelected: true });
            }}
            placeholder='Alasan lain'
          />
        </div>
        <a
          className='text-danger'
          style={{ fontSize: '14px' }}
          onClick={async () => {
            this._checkFeedbackOption();
          }}
        >
          <strong>Hapus</strong>
        </a>
      </Fragment>
    );
  };

  // show prompt delete
  _showDeleteAccountModal = (e) => {
    e.preventDefault();
    this.setState({ showDeleteAccountPrompt: true });
  };

  _clearImage = () => {
    this.setState((prevState) => {
      return {
        userProfile: {
          ...prevState.userProfile,
          picture: '',
        },
        temp_profile_picture_state: prevState.userProfileData.picture,
      };
    });
  };

  _cancel = () => {
    this.setState((prevState) => {
      return {
        userProfile: {
          ...prevState.userProfile,
          picture: prevState.temp_profile_picture_state,
        },
        temp_profile_picture_state: '',
      };
    });
  };

  _apply = (dataURL) => {
    this._handleProfilePicture(dataURL);
  };

  showModal = (e) => {
    e.preventDefault();
    this.setState({ showModalHp: true });
  };

  _handlePhoneChange = (value) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        userProfile: {
          ...prevState.userProfile,
          phone_number: value && value.toString(),
        },
      };
    });
  };

  _handleCodeChange = async (value) => {
    const { userProfile } = this.state;

    let phone_number =
      userProfile && userProfile.phone_number && userProfile.phone_number.substring(0, 3) === '+62'
        ? userProfile.phone_number
        : `+62${userProfile.phone_number}`;

    if (value && value.length === 6) {
      //make it disabled
      await this.setState({
        postingVerification: true,
        validationMessage: '',
        validationFailed: false,
      });
      const url = '/otp/verify';
      const body = {
        code: value,
        phone_number,
      };
      try {
        const responseVerif = await popCall.post(url, body);
        if (responseVerif && responseVerif.data) {
          this.props.dispatch(fetchUserProfileSuccess(responseVerif.data));
        }
        await this.setState({
          showModalVerification: false,
          postingVerification: false,
          countDownStart: true,
          screenReady: false,
        });
        this.setState({ screenReady: true });
      } catch (error) {
        const { response } = error;
        console.error(error);
        this.setState({
          postingVerification: false,
          countDownStart: true,
          validationMessage: response.data.message,
          validationFailed: true,
        });
      }
    }
  };

  _handleCountDown = () => {
    this.setState({ countDownStart: false });
  };

  _handleRemarks = () => {
    const { otherFeedbackSelected, otherFeedback, selectedFeedback, feedbacks } = this.state;
    //checking custom feedback then returned it as is
    if (otherFeedbackSelected && otherFeedback) {
      return otherFeedback;
    }
    //proper selected options checking(other than custom feedback). default selected option is 0
    else if (selectedFeedback !== null) {
      return feedbacks[selectedFeedback];
    }
  };

  //handle account deletion
  _handleAccountDeletion = async (e) => {
    e.preventDefault();
    const deactivateUrl = '/account/deactivate';
    const params = {
      disabledAt: new Date(),
      feedback: this._handleRemarks(), //text
    };
    try {
      this.setState({ disabledOnSubmit: true });
      //update participant data with above params
      // disable account in cognito
      await popCall.put(`${deactivateUrl}`, params);
      //force reload to logged user out
      window.location.reload();
    } catch (error) {
      this._toggleErrorNotification(error, 3000);
      console.error(error);
    } finally {
      this.setState({ disabledOnSubmit: false });
    }
  };

  handleClickOpen = () => {
    this.setState({ showDeleteAccountPrompt: true });
  };

  handleClose = () => {
    this.setState({ showDeleteAccountPrompt: false });
  };

  _renderStatusKTP = () => {
    const { userProfile } = this.state;
    if (userProfile.ktp_status === 'NEED_CONFIRMATION' || userProfile.ktp_status === 'EMPTY') {
      return (
        <label htmlFor='profile-id-card' className='text--dark-gray'>
          Menunggu Konfirmasi
        </label>
      );
    }
    if (userProfile.ktp_status === 'REJECTED') {
      return (
        <label htmlFor='profile-id-card' className='text-danger'>
          Ditolak. Coba Upload Lagi
        </label>
      );
    }
  };

  render() {
    const {
      screenReady,
      updatingProfile,
      error,
      userProfile,
      isDeletingAccount,
      disabledOnSubmit,
      feedbacks,
      selectedFeedback,
      otherFeedback,
      otherFeedbackSelected,
      ktp_obj,
      isValidatingKTP,
      isValidKTP,
      // isExistKTP,
      errorKTP,
    } = this.state;

    const { fullScreen } = this.props;

    // Using composition to avoid redundancy while passing props to the deepest component.
    // We can also use context to handle this kind of problem. But, I personally think that
    // using context in this particular problem will/can/might introduce another set of unnecessary complexity (do CMIIW)
    // since we need to add provider, context objects, and such.
    // So I opt to use component composition instead because it has a simpler and more direct approach.
    const imageCropperComposition = (
      <AvatarImageCropper
        errorHandler={this._errorHandler}
        apply={this._apply}
        isBack
        maxSize={1000000}
        clear={this._clearImage}
        cancel={this._cancel}
        style={{
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
        }}
      />
    );

    if (!screenReady) {
      return this._renderLoading();
    }
    if (error) {
      return this._renderError();
    }

    let displayPicture = defaultProfileImage;
    if (userProfile && userProfile.picture) {
      if (userProfile.picture.includes('data:image')) {
        displayPicture = userProfile.picture;
      } else {
        displayPicture = `https://${userProfile.picture}`;
      }
    }

    let displayPhone = userProfile.phone_number || '';
    if (displayPhone && displayPhone.substring(0, 3) === '+62') {
      displayPhone = displayPhone.replace('+62', '');
    }
    const userDob = moment(
      userProfile &&
        userProfile.criteria &&
        userProfile.criteria.DateOfBirth &&
        userProfile.criteria.DateOfBirth.length &&
        userProfile.criteria.DateOfBirth[0],
      'DD-MM-YYYY',
    );
    const userAge = moment().diff(userDob, 'years');
    return (
      <div className='tab-pane fade show active' id='edit-profile' role='tabpanel'>
        <div className='settings-grid'>
          {/* Profile Settings Form */}
          <div className='profile-settings-form'>
            {/* Profile Picture */}
            <h4 className='font-bold'>Foto Profil</h4>
            <div className='profile-settings-picture align-items-center'>
              <div className='img-avatar__wrapper'>
                <AvatarImageCropperWrapper
                  key='profile-picture'
                  className='img-responsive img__md--rounded img-thumbnail--custom'
                  imageSrc={displayPicture}
                  clear={this._clearImage}
                  rendercomponent={imageCropperComposition}
                  style={{
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                  }}
                  // disabled={isLoading}
                />
                {/* <img
                  id="profile-avatar"
                  className="img-avatar"
                  src={profile_picture_url_state || defaultProfileImage}
                  alt="profil-pic"
                /> */}
              </div>
            </div>
            <form>
              <div className='form-group'>
                <div className='form-row'>
                  {/* First Name */}
                  <div className='col-lg'>
                    <label htmlFor='profile-name-first' className='settings__question'>
                      Nama depan
                    </label>
                    <input
                      name='name'
                      type='text'
                      className='form-control settings__input'
                      id='profile-name'
                      value={userProfile.name}
                      onChange={this._onChangeUserProfileForm}
                    />
                  </div>
                  {/* Last Name */}
                  <div className='col-lg'>
                    <label htmlFor='profile-name-last' className='settings__question'>
                      Nama belakang
                    </label>
                    <input
                      name='family_name'
                      type='text'
                      className='form-control settings__input'
                      id='profile-family_name'
                      value={userProfile.family_name}
                      onChange={this._onChangeUserProfileForm}
                    />
                  </div>
                </div>

                {/* Address */}
                <label htmlFor='profile-address' className='settings__question'>
                  Alamat
                </label>
                <input
                  name='address'
                  type='text'
                  className='form-control settings__input'
                  id='address'
                  value={userProfile.address || ''}
                  onChange={this._onChangeUserProfileForm}
                />

                {/** No Handphone */}
                <label htmlFor='profile-profession' className='settings__question'>
                  Nomor HP
                </label>
                <PhoneVerification />
                {userAge && userAge >= 17 && (
                  <Fragment>
                    <div className='d-flex justify-content-between'>
                      <label htmlFor='profile-id-card' className='settings__question'>
                        Informasi KTP
                      </label>
                      {(!userProfile.ktp_status || userProfile.ktp_status !== 'APPROVED') &&
                        this._renderStatusKTP()}
                    </div>
                    <br />
                    {(!userProfile.ktp_status ||
                      (userProfile.ktp_status !== 'APPROVED' &&
                        userProfile.ktp_status !== 'NEED_CONFIRMATION' &&
                        userProfile.ktp_status !== 'EMPTY')) &&
                      this._renderUploadKtpInput()}
                    {userProfile.ktp_status &&
                      userProfile.ktp_status === 'APPROVED' &&
                      this._renderKTPVerified()}
                    {(!userProfile.ktp_status ||
                      (userProfile.ktp_status !== 'APPROVED' &&
                        userProfile.ktp_status !== 'NEED_CONFIRMATION' &&
                        userProfile.ktp_status !== 'EMPTY')) && (
                      <Fragment>
                        <p className='f-12'>
                          Diharapkan agar file KTP yang diupload memiliki kualitas yang baik dan
                          jelas.
                        </p>
                      </Fragment>
                    )}
                    <p className='f-12'>
                      Jika status KTP anda “menunggu konfirmasi”, harap hubungi support kami untuk
                      diverifikasi lebih lanjut.
                    </p>
                    <p className='f-12 mb-0'>
                      {/* NOTE: Might wanna make a helper file for this */}
                      *Populix memerlukan KTP Anda untuk melakukan validasi data dan kami jamin
                      kerahasiaan Anda akan terjaga.
                    </p>
                  </Fragment>
                )}
              </div>
            </form>
            {/* Settings Footer */}
            <div className='row'>
              {/* Button Save */}
              <button
                onClick={this._savingCriteria}
                disabled={updatingProfile}
                className='btn btn-primary float-left font-bold my-3'
              >
                {updatingProfile ? 'Memproses' : 'Simpan'}
              </button>
            </div>
            <div>
              {/* Button Delete */}
              {/* <div>
              <a
                className='text-danger'
                style={{ fontSize: '14px' }}
                onClick={() => this.setState({ isDeletingAccount: !isDeletingAccount })}
              >
                <strong>{!isDeletingAccount ? 'Hapus Akun' : 'Batal'}</strong>
              </a>
            </div> */}
              <div>
                <a
                  className='text-danger'
                  style={{ fontWeight: 400, fontSize: 14 }}
                  onClick={this.handleClickOpen}
                >
                  <strong>Hapus akun</strong>
                </a>
              </div>
              <Dialog fullWidth open={this.state.showModalKTP} onClose={this.handleCloseModalKTP}>
                <DialogTitle>Upload KTP</DialogTitle>
                <DialogContent>
                  <UploaderKTP
                    loading={isValidatingKTP}
                    onUpload={this._validateKTP}
                    srcKTP={ktp_obj && ktp_obj.image_base_64}
                    name={userProfile.name}
                    ktp_status={userProfile && userProfile.ktp_status}
                  />
                  {errorKTP && <p style={{ textAlign: 'center', color: 'red' }}>{errorKTP}</p>}
                  {userProfile &&
                    userProfile.ktp_status &&
                    userProfile.ktp_status === 'REJECTED' &&
                    !errorKTP && (
                      <p style={{ textAlign: 'center', color: 'red' }}>
                        Ada data dari KTP kalian yang tidak sesuai, harap hubungin support kami
                        untuk divalidasi lebih lanjut.
                      </p>
                    )}
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleCloseModalKTP}>Batal</Button>
                  <Button
                    variant='contained'
                    style={{
                      backgroundColor: isValidKTP ? '#3d9be9' : 'grey',
                      color: '#ffffff',
                    }}
                    onClick={this.handleCloseModalKTP}
                  >
                    Simpan KTP
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                fullScreen={fullScreen}
                open={this.state.showDeleteAccountPrompt}
                onClose={this.handleClose}
                aria-labelledby='responsive-dialog-title'
              >
                <DialogTitle id='responsive-dialog-title'>
                  {'Apakah Anda yakin untuk menghapus akun Anda?'}
                </DialogTitle>
                <DialogContent dividers>
                  <p>Kami sedih melihat kamu harus pergi dari Populix.</p>
                  <p>Mohon pilih alasan kenapa kamu ingin menutup akunmu pada kolom dibawah ini:</p>
                  {feedbacks.map((feedback, key) => {
                    return (
                      <div className='my-1 mx-3 small' key={key}>
                        <input
                          type='radio'
                          name='feedback'
                          className='mx-2 small'
                          checked={key === selectedFeedback}
                          onChange={() => {
                            this.setState({
                              selectedFeedback: key,
                              otherFeedbackSelected: false,
                            });
                          }}
                        />
                        {feedback}
                      </div>
                    );
                  })}
                  <div className='my-1 mx-3 small mb-3'>
                    <input
                      type='radio'
                      name='feedback'
                      className='mx-2 small'
                      checked={otherFeedbackSelected}
                      onChange={async () => {
                        //if other feedback is selected and set generated feedback to not selected
                        await this.setState({
                          otherFeedbackSelected: true,
                          selectedFeedback: '',
                        });
                      }}
                    />
                    <input
                      type='text'
                      value={otherFeedback}
                      onChange={async (feedback) => {
                        await this.setState({ otherFeedback: feedback.target.value });
                      }}
                      onFocus={() => {
                        this.setState({ otherFeedbackSelected: true });
                      }}
                      placeholder='Alasan lain'
                    />
                  </div>
                  <div>
                    <p>
                      Menghapus akun berarti menghilangkan semua akses dan kesempatanmu untuk
                      berpartisipasi di Populix secara permanen.
                    </p>
                    <p>
                      Jika Anda menghapus akun, maka email dan nomor handphone yang terdaftar untuk
                      akun ini tidak dapat kembali digunakan.
                    </p>
                    <p className='mb-0'>
                      Setelah kamu menghapus akun ini, kamu tidak diperkenankan untuk membuat akun
                      lain atas data diri yang sama. Lanjut?
                    </p>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleClose} variant='outlined'>
                    Tutup
                  </Button>
                  <Button onClick={this._handleAccountDeletion} color='secondary'>
                    Hapus Akun
                  </Button>
                </DialogActions>
              </Dialog>
            </div>

            {/* <div>
              <a
                className='text-danger'
                style={{ fontSize: '14px' }}
                onClick={() => this.setState({ isDeletingAccount: !isDeletingAccount })}
              >
                <strong>{!isDeletingAccount ? 'Hapus Akun' : 'Batal'}</strong>
              </a>
            </div>

            {isDeletingAccount && this._renderFeedback()}
             */}

            {/* delete account modal */}
            {/* <DeleteAccountModal
              showModalDeleteAccount={this.state.showDeleteAccountPrompt}
              handleClose={() => this.setState({ showDeleteAccountPrompt: false })}
              handleAccountDeletion={(e) => this._handleAccountDeletion(e)}
              disabled={disabledOnSubmit}
            /> */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile.userProfile,
    justProfile: state.profile,
    userScreening: state.profile.userScreening,
    question: state.question,
  };
};
export default connect(mapStateToProps)(SettingsProfile);
