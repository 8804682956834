import {
  UPDATE_PROFILE,
  DELETE_PROFILE,
  // FETCH_USER_PROFILE,
  UPDATE_NEW_MEMBER_STATUS,
  FETCH_USER_PROFILE_SUCCESS,
  // FETCH_USER_PROFILE_FAILED,
  PURGE_PROFILE,
  SHOW_SETTINGS_SUCCESS_NOTIFICATION,
  SHOW_SETTINGS_FAILED_NOTIFICATION,
  HIDE_SETTINGS_NOTIFICATION,
  UPDATE_NOTIFICATION_STATUS,
  UPDATE_PHONE_NUMBER,
  UPDATE_KTP_MEMBER_STATUS,
  PURGE,
} from '../actions/actionTypes';

const initialState = {
  show_success_notification: false,
  show_error_notification: false,
  error_notification_message: '',
  userProfile: {
    id: 0,
    code: '',
    user_pool_id: '',
    identity_pool_id: '',
    email: '',
    login_provider: '',
    name: '',
    family_name: '',
    role: '',
    phone_number: '',
    profile_picture_url: '',
    // ktp_picture_url: '',
    is_first_login: true,
    new_member: true,
    ktp_upload_status: false,
    ktp_approved: false,
    address: '',
  },
  // userSettings: {
  //   email_new_info: false,
  //   email_new_study: false,
  //   sms_new_study: false
  // }
  userSettings: {
    emailNewStudyNotification: true,
  },
  userScreening: null,
};

export default function profleReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_PROFILE:
      return Object.assign({}, state, action.profile);
    case DELETE_PROFILE:
      return null;
    case FETCH_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: { ...state.userProfile, ...action.convertedUserData },
        // userSettings: action.userData.userSettings,
      };
    case UPDATE_NEW_MEMBER_STATUS:
      return {
        ...state,
        userProfile: { ...state.userProfile, new_member: action.payload },
      };
    case UPDATE_NOTIFICATION_STATUS:
      return {
        ...state,
        userSettings: {
          ...state.userSettings,
          [action.fieldName]: action.payload,
        },
      };
    case UPDATE_KTP_MEMBER_STATUS:
      return {
        ...state,
        userProfile: { ...state.userProfile, ktp_upload_status: action.payload },
      };
    case PURGE:
      return { ...state, ...initialState };
    case UPDATE_PHONE_NUMBER:
      return {
        ...state,
        userProfile: { ...state.userProfile, phone_number: action.payload },
      };
    case SHOW_SETTINGS_SUCCESS_NOTIFICATION:
      return { ...state, updating: false, show_success_notification: true };
    case SHOW_SETTINGS_FAILED_NOTIFICATION:
      return {
        ...state,
        updating: false,
        show_error_notification: true,
        error_notification_message: action.payload,
      };
    case HIDE_SETTINGS_NOTIFICATION:
      return {
        ...state,
        show_success_notification: false,
        show_error_notification: false,
      };
    case 'FETCH_USER_SETTINGS_SUCCESS':
      return {
        ...state,
        userSettings: action.userSettings,
      };
    case 'FETCH_USER_SCREENING_SUCCESS':
      return {
        ...state,
        userScreening: action.userScreening,
      };
    default:
      return state;
  }
}
