import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import questionLogo from 'assets/img/pop-logo.png';
import { LinearProgress } from '@material-ui/core';
import { animated } from 'react-spring';
import { Button, FormSkeletons } from '../';

const styles = {
  colorPrimary: {
    backgroundColor: '#d9eff9',
  },
  barColorPrimary: {
    backgroundColor: '#007bff66',
  },
  barColorPop: {
    backgroundColor: '#FAAF40',
  },
  main: {
    height: 20,
    marginBottom: 16,
  },
};

class DailyQuestion extends Component {
  state = {
    showDailyQuestion: false,
    idDailyQuestion: '',
  };

  _renderDailyQuestion = (isAnswered, polling) => {
    return (
      <React.Fragment>
        {isAnswered
          ? this._renderDailyQuestionAnswer(polling)
          : this._renderDailyQuestionAnalytics(polling)}
      </React.Fragment>
    );
  };

  _renderDailyQuestionAnswer = (polling) => {
    const { postAnswerFunction, isPosting } = this.props;
    const options = polling.options;
    return options.map((dqOption, key) => (
      <label key={key} className='radio-container mb-3'>
        <input
          type='radio'
          name='dailyquestion__answer'
          value={dqOption}
          onClick={() => postAnswerFunction(polling.id, dqOption)}
          disabled={isPosting}
        />
        <p className='dailyquestion__answer'>{dqOption}</p>
        <span className='checkmark'></span>
      </label>
    ));
  };

  _renderDailyQuestionAnalytics = (polling) => {
    const { classes } = this.props;
    return (
      polling &&
      polling.surveyResult.map((result, idx) => (
        <React.Fragment key={idx}>
          <p
            className='dailyquestion__result-label'
            style={{ fontWeight: polling.ownAnswer === result.key ? 'bold' : '' }}
          >
            {result.key}
          </p>
          <div>
            <LinearProgress
              className={classes.main}
              classes={{
                colorPrimary: classes.colorPrimary,
                barColorPrimary:
                  polling.ownAnswer === result.key ? classes.barColorPop : classes.barColorPrimary,
              }}
              variant='determinate'
              color='primary'
              value={result.value}
            />
            <span className='dailyquestion__progressbar-label'>{result.value}%</span>
          </div>
        </React.Fragment>
      ))
    );
  };

  _renderLoading = () => {
    return (
      <FormSkeletons native>
        {(props) => (
          <div className='study-item'>
            <div style={{ width: '45px' }}>
              <animated.div className='form-skeleton__children' style={{ ...props, height: 40 }} />
            </div>
            <div>
              <animated.div
                className='form-skeleton__children'
                style={{ ...props, width: '50%', height: 18 }}
              />
              <animated.div
                className='form-skeleton__children mt-3'
                style={{ ...props, width: '40%', height: 18 }}
              />
              <animated.div
                className='form-skeleton__children mt-3'
                style={{ ...props, width: '20%', height: 18 }}
              />
              <animated.div
                className='form-skeleton__children mt-3'
                style={{ ...props, width: '20%', height: 18 }}
              />
            </div>
          </div>
        )}
      </FormSkeletons>
    );
  };

  _onShowDailyQuestion = (idx) => {
    this.setState({ showDailyQuestion: true, idDailyQuestion: idx });
  };

  _handleCloseAnalytics = () => [this.setState({ showDailyQuestion: false })];

  render() {
    const { showDailyQuestion, idDailyQuestion } = this.state;
    const { data, isPosting } = this.props;
    return (
      <div>
        {data && !isPosting && (
          <div className='study-item'>
            <img className='study-icon' src={questionLogo} />
            <div>
              <div className='study-header mb-3 d-flex justify-content-between'>
                <div>
                  <h4 className='font-bold'>Polling</h4>
                  <h5 className='font-regular' style={{ color: 'grey' }}>
                    Cari tahu pendapat dari para populixers
                  </h5>
                </div>
              </div>

              {data.map((polling, idx) => (
                <div key={idx}>
                  <div
                    style={{ paddingTop: '15px' }}
                    className='study-header mb-3 d-flex justify-content-between'
                  >
                    <p className='dailyquestion__question'>{polling.question}</p>
                    <div>
                      {!showDailyQuestion && polling ? (
                        <button
                          className='btn btn-primary btn-block'
                          style={{
                            minWidth: 'unset',
                            width: '120px',
                            align: 'center',
                            marginTop: 'unset',
                          }}
                          type='button'
                          onClick={() => this._onShowDailyQuestion(idx)}
                        >
                          {!('surveyResult' in polling) ? 'Jawab' : 'Lihat Hasil'}
                        </button>
                      ) : idDailyQuestion === idx ? (
                        ''
                      ) : (
                        <button
                          className='btn btn-primary btn-block'
                          style={{
                            minWidth: 'unset',
                            width: '120px',
                            align: 'center',
                            marginTop: 'unset',
                          }}
                          type='button'
                          disabled={!showDailyQuestion ? false : true}
                        >
                          {!('surveyResult' in polling) ? 'Jawab' : 'Lihat Hasil'}
                        </button>
                      )}
                    </div>
                  </div>
                  <div style={{ height: '30px' }} className='pull-right'>
                    {showDailyQuestion && idDailyQuestion === idx
                      ? [
                          <Button
                            style={{
                              margin: '0px',
                              padding: '0px',
                              width: '25px',
                              minWidth: 'unset',
                            }}
                            label='X'
                            type='warning'
                            onClick={this._handleCloseAnalytics}
                            key={idx}
                          />,
                        ]
                      : ''}
                  </div>
                  <br />
                  <div>
                    {showDailyQuestion && idDailyQuestion === idx
                      ? this._renderDailyQuestion(!('surveyResult' in polling), polling)
                      : ''}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {isPosting && this._renderLoading()}
      </div>
    );
  }
}

DailyQuestion.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DailyQuestion);
