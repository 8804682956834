import React, { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
// initialize firebase
import './services/FirebaseService';
import DonateCompleted from './views/Donate/DonateCompleted';

import { Route, Switch, Redirect } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faGhost } from '@fortawesome/free-solid-svg-icons';
import { PrivateRoute, IntercomWidget } from './components';
import Success from './views/Unsubscribe/Success/Success';

const PreScreeningView = lazy(() => import('./views/PreScreening/PreScreeningMain'));
const PayoutCompletedView = lazy(() => import('./views/PayoutSuccess/PayoutSuccess'));
const ThankyouView = lazy(() => import('./views/Thankyou/Thankyou'));
const RegisterView = lazy(() => import('./views/Registration/Registration'));
const LoginView = lazy(() => import('./views/Login/Login'));
const ForgotPassView = lazy(() => import('./views/ForgotPassword/ForgotPassword'));
const NewPassView = lazy(() => import('./views/NewPassword/NewPassword'));
const ConfirmationCodeView = lazy(() => import('./views/ConfirmationCode/ConfirmationCode'));
const ChangePasswordView = lazy(() => import('./views/ChangePassword/ChangePassword'));

const TransactionsView = lazy(() => import('./containers/TransactionLayout/TransactionLayout'));
const StudyListView = lazy(() => import('./containers/StudyLayout/StudyLayout'));
const StudyRedirectionPage = lazy(() =>
  import('./views/StudyRedirectionPage/StudyRedirectionPage'),
);
const SettingsView = lazy(() => import('./containers/SettingsLayout/SettingsLayout'));
const PayoutRequestView = lazy(() =>
  import('./containers/PayoutRequestLayout/PayoutRequestLayout'),
);

const ActiveStudyView = lazy(() => import('./components/Study/StudyDetail/ActiveStudy'));
const NotFoundView = lazy(() => import('./components/NotFound/NotFound'));

const GuestLandingView = lazy(() => import('./views/Guest/GuestLandingPage'));
const GuestPrescreeningView = lazy(() => import('./views/Guest/GuestPreScreening'));
const GuestThankyouView = lazy(() => import('./views/Guest/GuestThankyouPage'));
const GuestThankyouStudy = lazy(() => import('./views/Guest/GuestThankyouStudy'));
const GuestOTPConfirmation = lazy(() => import('./views/Guest/GuestOTPConfirmation'));

const GuestLandingPageCx = lazy(() => import('./views/GuestCx/LandingPageCx/LandingPageCx'));
const GuestOnlineSurveyCx = lazy(() => import('./views/GuestCx/OnlineSurveyCx/OnlineSurveyCx'));

const UnderMaintenance = lazy(() => import('./views/UnderMaintenance'));
const OnlineSurvey = lazy(() => import('./views/OnlineSurvey'));

const EmailUnsubscription = lazy(() =>
  import('./views/Unsubscribe/EmailUnsubscription/EmailUnsubscription'),
);

const SuspenseComponent = (Component) => {
  return (props) => (
    // NOTE: must change Loading here to something awesome loading page
    <Suspense fallback={<div>Loading...</div>}>
      <Component {...props} />
    </Suspense>
  );
};

library.add(faGhost);

class RedirectToLandingPage extends Component {
  componentDidMount() {
    console.log('reactapp env', process.env.REACT_APP_ENV);
    if (process.env.REACT_APP_ENV === 'production') {
      window.location.assign('https://info.populix.co');
    } else {
      this.props.history.push('/login');
    }
  }

  render() {
    return <React.Fragment />;
  }
}

class App extends Component {
  componentDidMount() {
    // display show banner download app on load/refresh
    localStorage.setItem('showPromoBanner', 'true');
    localStorage.setItem('showAnnounceBanner', 'true');
  }

  render() {
    const { featureFlag, isAuthenticated, authProvider } = this.props;

    return (
      <div className='App'>
        {featureFlag && featureFlag.intercom && featureFlag.intercom.isActive && <IntercomWidget />}
        <Switch>
          <Route path='/under-maintenance' component={SuspenseComponent(UnderMaintenance)} />
          {featureFlag && featureFlag.underMaintenance && featureFlag.underMaintenance.webApp && (
            <Redirect to='/under-maintenance' />
          )}
          <Route exact path='/' component={RedirectToLandingPage} />
          <Route path='/register' component={SuspenseComponent(RegisterView)} />
          <Route path='/login' component={SuspenseComponent(LoginView)} />
          <Route path='/forgot-password' component={SuspenseComponent(ForgotPassView)} />
          <Route path='/new-password' component={SuspenseComponent(NewPassView)} />
          <Route path='/confirm' component={SuspenseComponent(ConfirmationCodeView)} />
          <Route path='/change-password' component={SuspenseComponent(ChangePasswordView)} />
          <Route
            exact
            path='/unsubscribe-email'
            component={SuspenseComponent(EmailUnsubscription)}
          />
          <Route exact path='/unsubscribe-email/success' component={Success} />

          <Route
            path='/guest/:surveyType/study'
            component={SuspenseComponent(GuestLandingPageCx)}
          />
          <Route
            path='/guest/:surveyType/submission/:SurveyId/:uniqueCode'
            component={SuspenseComponent(GuestOnlineSurveyCx)}
          />

          <Route path='/guest/study/result/' component={SuspenseComponent(GuestThankyouView)} />
          <Route path='/guest/study/redirect/' component={SuspenseComponent(GuestThankyouStudy)} />
          <Route
            path='/guest/study/prescreening'
            component={SuspenseComponent(GuestPrescreeningView)}
          />
          <Route
            path='/guest/otp-confirmation'
            component={SuspenseComponent(GuestOTPConfirmation)}
          />
          <Route path='/guest/study' component={SuspenseComponent(GuestLandingView)} />

          <PrivateRoute path='/transactions/all' component={SuspenseComponent(TransactionsView)} />
          <PrivateRoute
            path='/pre-screening/:screeningtag'
            component={SuspenseComponent(PreScreeningView)}
          />
          <PrivateRoute path='/study' component={SuspenseComponent(StudyListView)} />
          {isAuthenticated || authProvider ? (
            <PrivateRoute
              path='/redirect-link/study-group/:id/:status'
              component={SuspenseComponent(StudyRedirectionPage)}
            />
          ) : (
            <Route
              path='/redirect-link/study-group/:id/:status'
              component={SuspenseComponent(StudyRedirectionPage)}
            />
          )}
          <PrivateRoute
            exact
            path='/activestudy/:id'
            component={SuspenseComponent(ActiveStudyView)}
          />
          <PrivateRoute path='/transactions' component={SuspenseComponent(TransactionsView)} />
          <PrivateRoute path='/settings' component={SuspenseComponent(SettingsView)} />
          <PrivateRoute path='/thankyou/' component={SuspenseComponent(ThankyouView)} />
          <PrivateRoute path='/payoutrequest' component={SuspenseComponent(PayoutRequestView)} />
          <PrivateRoute
            path='/payoutcompleted'
            component={SuspenseComponent(PayoutCompletedView)}
          />
          <PrivateRoute path='/donateCompleted' component={DonateCompleted} />
          <PrivateRoute path='/online-survey/:id' component={SuspenseComponent(OnlineSurvey)} />
          <Route path='/online-survey-mobile-app/:id' component={SuspenseComponent(OnlineSurvey)} />
          <Route path='/thankyou-poplite-mobile-app' component={SuspenseComponent(ThankyouView)} />
          <Route
            path='/thankyou-poplite-mobile-app/error/:errorType'
            component={SuspenseComponent(ThankyouView)}
          />
          <Route component={SuspenseComponent(NotFoundView)} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  authProvider: state.auth.authProvider,
  featureFlag: state.featureFlag,
});

export default connect(mapStateToProps, {})(App);
