import {
  UPDATE_AUTH,
  REGISTER_SUCCESS,
  REGISTER_FAILED,
  UNCONFIRMED_USER,
  LOGIN_SUCCESS,
  USER_CONFIRMATION_SUCCESS,
  SEND_EMAIL_FORGOT_PASSWORD_SUCCESS,
  PURGE_PROFILE,
  FETCH_USER_PROFILE_SUCCESS,
  CLEAR_AUTH,
  PURGE,
} from '../actions/actionTypes';

const initialState = {
  isAuthenticated: false,
  userData: {
    email: '',
  },
  isConfirmed: false,
  authProvider: '',
  // userProfile: {
  //     id: 0,
  //     code: '',
  //     user_pool_id: '',
  //     identity_pool_id: '',
  //     email: '',
  //     login_provider: '',
  //     first_name: '',
  //     last_name: '',
  //     role: '',
  //     phone_number: '',
  //     profile_picture_url: '',
  //     ktp_picture_url: '',
  //     is_first_login: false
  // }
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_AUTH:
      return {
        ...state,
        userData: {
          email: '',
        },
        isAuthenticated: action.isAuthenticated,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        userData: {
          ...state.userData,
          email: action.username,
        },
      };
    case REGISTER_FAILED:
      return {
        ...state,
        userData: {
          ...state.userData,
          email: action.username,
        },
      };
    case UNCONFIRMED_USER:
      return {
        ...state,
        isAuthenticated: true,
        userData: {
          ...state.userData,
          email: action.username,
        },
      };
    case USER_CONFIRMATION_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        userData: {
          ...state.userData,
          email: '',
        },
      };
    case SEND_EMAIL_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        userData: {
          ...state.userData,
          email: action.username,
        },
      };
    case FETCH_USER_PROFILE_SUCCESS:
      return {
        ...state,
        authProvider: action.authProvider,
      };
    case PURGE_PROFILE:
      return {
        isAuthenticated: false,
        userData: { ...initialState.userData },
        isConfirmed: false,
        // ...state,
        // ...initialState,
      };
    case PURGE:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
