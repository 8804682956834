import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';

// your Cognito Hosted UI configuration
const oauth = {
  domain: `${process.env.REACT_APP_FB_DOMAIN}`,
  scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
  redirectSignIn: `${process.env.REACT_APP_FB_REDIRECT_BASE_URL}/study?callback`,
  redirectSignOut: `${process.env.REACT_APP_FB_REDIRECT_BASE_URL}/login`,
  responseType: 'token', // or 'token', note that REFRESH token will only be generated when the responseType is code
};

export default Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
  },
});

Auth.configure({ oauth });
