import React, { Component, Fragment } from 'react';
import { animated } from 'react-spring';
import ReactCodeInput from 'react-code-input';
import Countdown from 'react-countdown-now';
import { FormSkeletons } from '../../components';

export default class PinConfirmation extends Component {
  state = {
    countDownStart: true,
    formValidationFailed: false,
    formValidationMsg: '',
    isPosting: false,
    code: '',
  };

  handleCountDown = (condition) => {
    this.setState({
      countDownStart: condition,
    });
  };

  handleFormChange = async (value) => {
    // this.setState({ code: '' });
    if (value && value.length === 6) {
      await this.setState({
        isPosting: true,
        code: null,
        formValidationFailed: false,
        formValidationMsg: '',
      });
      const response = await this.props.submitCode(value);
      if (response) {
        if (response.data) {
          this.props.closeModal();
        } else {
          this.setState({ formValidationFailed: true, formValidationMsg: response.message });
        }
      }
      this.setState({ isPosting: false, countDownStart: true });

      // if (response && response.data) {
      //   this.props.closeModal();
      // } else {
      //   this.setState({ formValidationFailed: true, formValidationMsg: response.message });
      // }
      // this.setState({ isPosting: false, countDownStart: true });
    }
  };

  handleSubmitForm = () => {
    this.handleCountDown(true);
  };

  handleKirimUlang = async (e) => {
    this.setState({ isPosting: true });
    await this.props.resendCode(e);
    this.setState({ isPosting: false, countDownStart: true });
  };

  _renderSkeleton = () => {
    return (
      <FormSkeletons native>
        {(props) => (
          <div className='d-flex flex-column justify-content-center align-items-center mt-2 mb-3'>
            <animated.div
              className='skeletons form_skeleton__children'
              style={{ ...props, width: '50%', height: 35 }}
            />
          </div>
        )}
      </FormSkeletons>
    );
  };

  render() {
    const { countDownStart, isPosting, formValidationFailed, formValidationMsg } = this.state;
    return (
      <div className='pl-3 pr-3'>
        <label htmlFor='cash-withdraw--input' className='font-bold cash-withdraw__question'>
          Kami telah mengirimkan 6 digit kode konfirmasi via sms ke nomor HP yang kamu daftarkan.
        </label>
        {!isPosting ? (
          <ReactCodeInput
            className='code-input mb-3 mt-2'
            type='number'
            disabled={isPosting}
            isValid={!formValidationFailed}
            inputStyleInvalid={{ border: '1px solid red' }}
            fields={6}
            onChange={(values) => this.handleFormChange(values)}
          />
        ) : (
          this._renderSkeleton()
        )}
        <div style={{ width: '100%', textAlign: 'center', marginBottom: '40px' }}>
          {this.props.isPayoutVerification && (
            <p style={{ color: '#f14336' }}>{this.props.otpFalseMessage}</p>
          )}
          {countDownStart ? (
            <small
              style={{
                color: formValidationFailed ? '#f14336' : '#0d2433',
              }}
            >
              <Countdown
                date={Date.now() + 30000}
                renderer={(props) => (
                  <div>{`Mohon menunggu ${props.seconds} detik untuk mengirim ulang`}</div>
                )}
                onComplete={() => this.handleCountDown(false)}
              />
            </small>
          ) : (
            <React.Fragment>
              <p>Tidak menerima Kode? </p>
              <button
                type='button'
                onClick={this.handleKirimUlang}
                className='btn btn-primary f-13 m-0'
                disabled={isPosting}
              >
                Kirim Ulang
              </button>
            </React.Fragment>
          )}
          <small
            style={{
              color: formValidationFailed ? '#f14336' : '#0d2433',
            }}
          >
            {formValidationMsg}
          </small>
        </div>
      </div>
    );
  }
}
