import {
  STUDY_STARTED,
  STUDY_STOPPED,
  PROTECTED_BTN_PRESSED,
  RESET_ACTIVE_STUDY_STATE,
  CLOSE_MODAL_PROMPT
} from './actionTypes';

export const startStudy = () => {
  return dispatch => {
    dispatch({ type: STUDY_STARTED });
  };
};

export const stopStudy = () => {
  return dispatch => {
    dispatch({ type: STUDY_STOPPED });
  };
};

export const protectedBtnPressed = navUrl => {
  return dispatch => {
    dispatch({ type: PROTECTED_BTN_PRESSED, payload: navUrl });
  };
};

export const resetActiveStudyState = () => {
  return dispatch => {
    dispatch({ type: RESET_ACTIVE_STUDY_STATE });
  };
};

export const closeModalPrompt = () => {
  return dispatch => {
    dispatch({ type: CLOSE_MODAL_PROMPT });
  };
};
