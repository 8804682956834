// import Firebase from 'firebase';
import Firebase from 'firebase/app';
import { store } from 'store/store';
import { setFeatureFlag } from 'store/actions/featureFlagActions';
import { featureFlagLocal } from './featureFlag.local';
import 'firebase/performance';
import 'firebase/database';
import 'firebase/storage';

const config = {
  clientId: '492166602200-h8eidtlbtqrundl8e1us1dh11os8tmgq.apps.googleusercontent.com',
  appId: '1:492166602200:web:952d6590ce9e0881b326ad',
  apiKey: 'AIzaSyCUWtB4Zo8jRTgh39vHJ5kd1cn5voDZ2Xk',
  messagingSenderId: '492166602200',
  projectId: 'populix-android-app',
  persistence: true,
  databaseURL: 'https://populix-android-app.firebaseio.com/',
};
Firebase.initializeApp(config);

if (['production', 'staging'].includes(process.env.REACT_APP_ENV)) {
  Firebase.database()
    .ref('/')
    .on('value', (snapshot) => {
      const payload = snapshot.val();
      if (payload) {
        if (process.env.REACT_APP_ENV === 'production') {
          store.dispatch(
            setFeatureFlag({ ...payload.production, cxService: payload.saasClientProd.cxService }),
          );
        } else {
          store.dispatch(
            setFeatureFlag({ ...payload.staging, cxService: payload.saasClientStaging.cxService }),
          );
        }
      }
    });
} else {
  store.dispatch(setFeatureFlag(featureFlagLocal));
}

export const getFirebaseShortQuestionExcludedCriterias = () => {
  return new Promise((resolve, reject) => {
    Firebase.database()
      .ref('/')
      .on('value', (snapshot) => {
        const payload = snapshot.val();
        if (payload) {
          if (process.env.REACT_APP_ENV === 'production') {
            if (payload.BEProd) resolve(payload.BEProd.shortQuestionExcludedCriterias);
          } else if (process.env.REACT_APP_ENV === 'staging') {
            resolve(payload.BEStaging.shortQuestionExcludedCriterias);
          } else {
            resolve(['SocialEconomicStatusV2']);
          }
        }
      });
  });
};

// Initialize Performance Monitoring and get a reference to the service
const perf = Firebase.performance();
