import React from 'react';
import PropTypes from 'prop-types';

import popcornHappy from 'assets/img/popcorn-happy.png';
import popcornSleep from 'assets/img/popcorn-sleep.png';

export const StaticTemplateSuccess = (props) => {
  return (
    <div id='home--hero-headings'>
      <img className='img-fluid mb-2 thankyou__popcorn-image' src={props.mainImage} alt='swim' />
      <div>
        <h1 className='lp-h1 header--title'>{props.mainText}</h1>
        <p className='header--subtitle mb-5'>{props.subText}</p>
        <div class='mt-3'>
          <a
            className='lp-btn btn btn-secondary header--btn header--btn--w250'
            href={props.redirectURL}
          >
            {props.buttonText}
          </a>
        </div>
      </div>
    </div>
  );
};

export const StaticTemplateFailed = (props) => {
  return (
    <div id='home--hero-headings' className='mt-5'>
      <img
        className='img-fluid thankyou__popcorn-image thankyou__popcorn-image--mb-negative'
        src={popcornSleep}
        alt='sleep'
      />
      <div>
        <h1 className='lp-h1 header--title'>{props.mainText}</h1>
        <p className='header--subtitle mb-5'>{props.subText}</p>
        <div class='mt-3'>
          <a
            className='lp-btn btn btn-secondary header--btn header--btn--w250'
            href={props.redirectURL}
          >
            {props.buttonText}
          </a>
        </div>
      </div>
    </div>
  );
};

StaticTemplateSuccess.propTypes = {
  mainImage: PropTypes.string.isRequired,
  mainText: PropTypes.string.isRequired,
  subText: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  redirectURL: PropTypes.string.isRequired,
};

StaticTemplateFailed.propTypes = {
  mainImage: PropTypes.string.isRequired,
  mainText: PropTypes.string.isRequired,
  subText: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  redirectURL: PropTypes.string.isRequired,
};
