import React, { Component } from 'react';
import { BrowserRouter as Router, Route, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { SettingsPassword, SettingsProfile } from '../index';

class Settings2 extends Component {
  render() {
    const { profile, authProvider } = this.props;
    return (
      <Router>
        <div>
          <nav className='tab mobile-disabled'>
            <div
              className='tab-nav nav nav-tabs justify-content-left'
              id='content-tab'
              role='tablist'
            >
              <NavLink exact={true} className='nav-item nav-link' to='/profile'>
                Edit profil
              </NavLink>
              {authProvider !== 'Facebook' && (
                <NavLink className='nav-item nav-link' to='/profile/password'>
                  Kata sandi
                </NavLink>
              )}
            </div>
          </nav>
          {/* Body without Floating Card */}
          <div className='body1'>
            <div className='tab-content'>
              <Route exact path='/profile' component={SettingsProfile} />
              {authProvider !== 'Facebook' && (
                  <Route path='/profile/password' component={SettingsPassword} />
              )}
            </div>
          </div>
        </div>
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile.userProfile,
    authProvider: state.auth.authProvider,
  };
};

export default connect(mapStateToProps)(Settings2);
