import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Prompt } from 'react-router-dom';
import classNames from 'classnames';
import { Transition, animated } from 'react-spring/renderprops';

import { TopNav, SidebarNav, ModalError, ModalSuccess, ModalPrompt } from '../../../components';
import { PrettifyTimer } from '../../../helpers/generalHelpers';
import { startStudy, stopStudy, closeModalPrompt } from '../../../store/actions/activeStudyActions';
import BrowserNavigator from '../../../helpers/BrowserNavigator';
import { protectedBtnPressed } from '../../../store/actions/activeStudyActions';
import PopCall from '../../../services/PopCall';

// const clock_image = require('assets/screening-tab-icons/jam-icon.png');
const copy_icon = require('assets/img/embed-icon.png');
const alarm_icon = require('assets/img/alarm-clock@2x.png');
const thumbnail_congratulate = require('assets/img/selesai-studi.svg');
const populixLogo = require('assets/img/logo.png');
const popCall = new PopCall();

class ActiveStudy extends Component {
  state = {
    intervalId: null,
    second: 0,
    minute: 0,
    studyStarted: false,
    studyStopped: false,
    copied_id: false,
    promptConfirmation: false,
    showCongratulationModal: false,
    showErrorModal: false,
    errorMessage: '',
    errorSubMessage: '',
    startDate: null,
    error: null,
    submission_id: null,
    processingStudy: false,
  };

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    const { activeStudy } = this.props;
    const { studyStopped } = this.state;
    if (
      activeStudy.protected_btn_pressed === true &&
      activeStudy.protected_btn_pressed !== prevProps.activeStudy.protected_btn_pressed
    ) {
      this.setState({ promptConfirmation: !prevState.promptConfirmation });
    }
    // if the study is stopped, then remove the event listener
    if (!prevState.studyStopped && studyStopped) {
      window.removeEventListener('beforeunload', this._beforeUnloadFunction);
    }
  }

  componentWillUnmount() {
    const { intervalId } = this.state;
    clearInterval(intervalId);
    window.removeEventListener('beforeunload', this._beforeUnloadFunction);
  }

  // NOTE The reason of using external named function instead of anonymous function is because
  // removeEventListener() need the reference to the function that was called in addEventListener.
  // And, if we're using anonymous function -- since by nature anonymous functions are dynamically declared at runtime and as the name stated,
  // they aren't given any names, the removeEventListener() will not be able to get that reference CMIIW.
  // That is why when user head back to homepage and hit refresh, they were still prompted by that annoying popup.
  _beforeUnloadFunction = (e) => {
    e.preventDefault();
    // NOTE Actually, most browser these days doesn't support custom value message within their
    // 'beforeunload' prompt. But I'll just put it here just in case.
    e.returnValue = 'Study anda akan hilang';
  };

  _startWatch = async () => {
    const { profile, study } = this.props;
    const startStudyRdx = this.props.startStudy;
    const { email } = profile;
    const { participantReward, id, maxAllowedTime, studyUrl } = study;
    const browserNavigator = new BrowserNavigator();
    // const study_id = params.id;
    // const iFrameTarget = document.getElementById("quiz_frame");
    // const iFrameContent = iFrameTarget.contentDocument || iFrameTarget.contentWindow.document;
    if (browserNavigator.operatingSystem === 'IOS') {
      const newTab = window.open(studyUrl);
      setTimeout(() => {
        newTab.close();
      }, 6500); // NOTE This is a temporary solution.
      // The right solution requires us to extend the limesurvey theme. Thus, we might lose the existing survey respondents.
      // So in the meantime this'll be our hotfix
    }
    const path = `/submission/create`;
    const dataBody = {
      studyId: id,
      participantEmail: email,
    };
    this.setState({ processingStudy: true });
    try {
      const createStudy = await popCall.post(path, dataBody);
      const { data } = createStudy;
      const intervalId = setInterval(this._tick, 1000);
      this.setState({
        intervalId,
        studyStarted: true,
        submission_id: data.id,
        processingStudy: false,
      });
      window.addEventListener('beforeunload', this._beforeUnloadFunction);
      startStudyRdx();
    } catch (error) {
      const { response } = error;
      const { data } = response;
      // NOTEtemporary temporary message and error handling.
      // error status_code 500 has different return message, so for this one, we're gonna check if the string
      // indicates that the user have taken this study or else
      if (data.code === 500) {
        // if study is full
        return this.setState({
          showErrorModal: true,
          errorMessage: data.message,
          errorSubMessage: 'Silahkan ambil studi lain dan dapatkan rewardnya!',
        });
      } else if (data.code === 403 && data.message === 'Email Already Exist') {
        return this.setState({
          showErrorModal: true,
          errorMessage: data.message,
          errorSubMessage: 'Anda sudah mengikuti studi ini, Silahkan ambil studi lain!',
        });
      } else {
        return this.setState({
          showErrorModal: true,
          errorMessage: data.message,
          errorSubMessage: 'Silahkan coba beberapa saat lagi!',
        });
      }
    } finally {
      this.setState({ processingStudy: false });
    }
  };

  _tick = () => {
    const { second } = this.state;
    this.setState(
      (prevState) => {
        return {
          second: prevState.second + 1,
        };
      },
      () => {
        if (second >= 59) {
          this.setState((prevState) => {
            return {
              ...prevState,
              second: 0,
              minute: prevState.minute + 1,
            };
          });
        }
      },
    );
  };

  _stopStudy = async () => {
    const { intervalId, minute, second, submission_id } = this.state;
    const { location, match, activeStudy, history } = this.props;
    const stopStudyRdx = this.props.stopStudy;
    const { params } = match;
    const study_id = params.id;
    const API_PATH = `${study_id}/submission/submit/${submission_id}`;
    clearInterval(intervalId);
    const totalMinute = parseFloat(`${minute}.${second}`);
    const finishedDate = new Date();

    this.setState({ processingStudy: true });
    const dataToSend = {
      finish_time: finishedDate,
      participant_finished_time: totalMinute,
    };
    this.setState({ processingStudy: true });
    try {
      await popCall.patch(API_PATH, dataToSend);
      this.setState({
        showCongratulationModal: true,
        promptConfirmation: false,
      });
      stopStudyRdx();
      if (activeStudy && activeStudy.protected_btn_pressed) {
        history.push(activeStudy.nav_target_url);
      }
    } catch (error) {
      this.setState({ error: error });
    } finally {
      this.setState({
        studyStarted: false,
        studyStopped: true,
        processingStudy: false,
      });
    }
  };

  _togglePromptModal = async () => {
    const closeModalRdx = this.props.closeModalPrompt;
    const { promptConfirmation } = this.state;
    await this.setState((prevState) => {
      // if (prevState.promptConfirmation !== promptConfirmation) {
      //   closeModalRdx();
      // }
      return {
        promptConfirmation: !prevState.promptConfirmation,
      };
    });
    closeModalRdx();
  };

  _onClickCopy = () => {
    const element = document.getElementById('participant-code');
    const range = document.createRange();
    window.getSelection().removeAllRanges();
    range.selectNode(element);
    window.getSelection().addRange(range);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
    this.setState({ copied_id: true });
    setTimeout(() => {
      this.setState({ copied_id: false });
    }, 3000);
  };

  _renderNotification = (styleProps) => {
    return (
      <animated.div
        className='d-flex justify-content-center align-items-center notification_box notification_box--success px-2 text-center'
        style={{ ...styleProps, fontSize: '0.8rem', fontWeight: 600 }}
      >
        ID Tersalin
      </animated.div>
    );
  };

  _handleModalClose = () => {
    this.setState({ showCongratulationModal: false });
  };

  _handleErrorModalClose = () => {
    this.setState({ showErrorModal: false });
  };

  _handleModalNavigation = () => {
    const { history } = this.props;
    history.push('/study');
  };

  // NOTE Use this for custom modal prompt. But it's still not working.
  // _handleBackButton = async location => {
  //   const { studyStopped, studyStarted } = this.state;
  //   const protected_btn_pressed = this.props.protectedBtnPressed;
  //   await this.setState(
  //     {
  //       promptConfirmation: true
  //     },
  //     () => {
  //       protected_btn_pressed(location.pathname);
  //       if (studyStarted) return false;
  //       if (!studyStarted && studyStopped) return true;
  //     }
  //   );
  // };

  render() {
    const { profile, study } = this.props;
    const {
      second,
      minute,
      studyStarted,
      studyStopped,
      copied_id,
      showCongratulationModal,
      promptConfirmation,
      processingStudy,
      showErrorModal,
      errorMessage,
      errorSubMessage,
    } = this.state;
    const { name, family_name, code } = profile;
    const { studyUrl } = study;
    const fullName = `${name} ${family_name}`;
    const width = window.innerWidth;
    return (
      <div className='container dashboard mx-0 active-study'>
        <Prompt
          when={studyStarted}
          message='Yakin ingin meninggalkan studi? Data yang anda masukan akan hilang.'
        />
        <Transition
          native
          items={copied_id}
          from={
            width > 768 ? { transform: 'translate(0, -50px)' } : { transform: 'translate(0, 50px)' }
          }
          enter={{ transform: 'translate(0, 0)' }}
          leave={
            width > 768 ? { transform: 'translate(0, -50px)' } : { transform: 'translate(0, 50px)' }
          }
        >
          {(showNotification) => (styleProps) =>
            showNotification && this._renderNotification(styleProps)}
        </Transition>
        {/* TODO: */}
        <TopNav textToShow='Studi' />
        <SidebarNav />
        <div className='active-study__body pb-3'>
          <div className='d-flex flex-row align-items-center justify-content-between mb-3'>
            <div className='d-flex flex-row align-items-center'>
              <p className='mb-0'>
                Populix ID:{' '}
                <span id='participant-code' style={{ fontWeight: '600' }}>
                  {code}
                </span>
              </p>
              <img
                src={copy_icon}
                alt='copy'
                className='smaller-icon ml-2'
                onClick={this._onClickCopy}
              />
            </div>
            <div className='d-flex flex-row align-items-center'>
              <img className='smaller-icon' alt='clock' src={alarm_icon} />
              <p className='m-0 ml-2 mr-3' style={{ fontWeight: '600' }}>
                {PrettifyTimer(minute, second)}
              </p>
            </div>
          </div>
          <div
            className={classNames('d-flex flex-row align-items-center mb-3', {
              'justify-content-end': studyStarted,
            })}
          >
            {!studyStarted && !studyStopped && (
              <h4 className='mb-0'>Klik tombol untuk memulai studi</h4>
            )}
            {!studyStarted && studyStopped && (
              <h4 className='mb-0 font-bold text-color--danger'>Studi telah berakhir</h4>
            )}
            {studyStarted && (
              <button
                onClick={this._togglePromptModal}
                className='btn btn-danger btn-small'
                disabled={studyStopped || processingStudy}
              >
                Akhiri Studi
              </button>
            )}
          </div>
          {studyStarted && (
            <iframe
              id='quiz_frame'
              title='Active study'
              className='active-study__iframe mb-2'
              src={studyUrl}
            />
          )}
          {!studyStarted && (
            <button
              onClick={this._startWatch}
              className='btn btn-primary btn-block'
              disabled={studyStopped || processingStudy}
            >
              {processingStudy ? 'Memproses Studi' : 'Mulai Studi'}
            </button>
          )}
        </div>
        <Transition
          native
          items={promptConfirmation}
          from={{ transform: 'translate(0, -100%)' }}
          enter={{ transform: 'translate(0, 0)' }}
          leave={{ transform: 'translate(0, -100%)' }}
        >
          {(showModalPrompt) => (modalStyle) =>
            showModalPrompt && (
              <ModalPrompt
                animatedModal
                handleConfirmation={this._stopStudy}
                handleClose={this._togglePromptModal}
                style={modalStyle}
                processingData={processingStudy}
                mainPromptMsg='Apakah kamu yakin ingin keluar dari studi?'
                extraPromptMsg='Studi ini tidak bisa diambil lagi'
                mainLoadingMsg='Memproses Hasil Studi'
                extraLoadingMsg='Harap tunggu, hasil studi kamu sedang di proses...'
              />
            )}
        </Transition>
        <Transition
          native
          items={showCongratulationModal}
          from={{ transform: 'translate(0, -100%)' }}
          enter={{ transform: 'translate(0, 0)' }}
          leave={{ transform: 'translate(0, -100%)' }}
        >
          {(showModal) => (modalStyle) =>
            showModal && (
              <ModalSuccess
                animatedModal
                successMsgHead={`Terima kasih, ${fullName}`}
                successMsgDetail='Hasil studi telah kami terima dan reward kamu akan dikirim ke akun setelah jawabanmu divalidasi.'
                handleClose={this._handleModalClose}
                handleContinue={this._handleModalNavigation}
                mainImage={thumbnail_congratulate}
                style={modalStyle}
              />
            )}
        </Transition>
        <Transition
          native
          items={showErrorModal}
          from={{ transform: 'translate(0, -100%)' }}
          enter={{ transform: 'translate(0, 0)' }}
          leave={{ transform: 'translate(0, -100%)' }}
        >
          {(showErrorModal) => (modalStyle) =>
            showErrorModal && (
              <ModalError
                handleCloseFunction={this._handleErrorModalClose}
                errorMsgHead={errorMessage}
                errorMsgDetail={errorSubMessage}
                dataObjects={{
                  name: fullName,
                  role: 'dialog',
                  tabIndex: '-1',
                }}
                animatedModal
                style={modalStyle}
                backButtonText='Tutup'
              />
            )}
        </Transition>
        <div id='modal-backdrop' className='modal-backdrop-transparent modal-transition' />
        {/*<SidebarNavMobile/>*/}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    study: state.study.studyObj,
    profile: state.profile.userProfile,
    activeStudy: state.activeStudy,
  };
};

export default withRouter(
  connect(mapStateToProps, { startStudy, stopStudy, closeModalPrompt, protectedBtnPressed })(
    ActiveStudy,
  ),
);
