export const errorMessage = {
  PARTICIPANT_EXIST:
    'Nomor handphone sudah terdaftar sebagai partisipan. Silahkan masuk melalui halaman login.',
  STUDY_NOT_FOUND: 'Studi tidak ditemukan.',
  STUDY_FULL: 'Studi sudah penuh',
  STUDY_QUOTA_FULL: 'Kuota untuk kriteria ini sudah terpenuhi.',
  DUPLICATE_SUBMISSION:
    'Nomor handphone sudah digunakan untuk mengikuti studi ini. Silahkan menggunakan nomor handphone lain.',
  DUPLICATE_EMAIL_FOR_SUBMISSION:
    'Email sudah digunakan untuk mengikuti studi ini. Silahkan gunakan email lain.',
  DUPLICATE_PHONE_NUMBER_FOR_SUBMISSION:
    'Nomor telepon sudah digunakan untuk mengikuti studi ini. Silahkan gunakan nomor telepon yang lain.',
  NOT_ELIGIBLE: 'Anda tidak memenuhi kriteria studi ini.',
  SUBMISSION_NOT_FOUND: 'Jawaban Anda tidak ditemukan.',
  SUBMISSION_HAS_BEEN_APPROVED: 'Jawaban Anda sudah pernah disetujui.',
  SUBMISSION_HAS_BEEN_REJECTED:
    'Menurut jawaban yang anda beri, anda tidak termasuk dalam kriteria yang kami cari untuk study ini.',
  SUBMISSION_ON_REVIEW: 'Jawaban Anda sedang direview.',
  DEFAULT: 'Terjadi kesalahan saat mengirimkan data. Harap coba kembali.',
  REJECTED_ON_PRESCREENING: 'Email atau nomor telepon sudah mengikuti studi ini.',
  INVALID_INPUT_OTP: 'Nomor handphone tidak terdaftar',
};

export const errorPopup = {
  PARTICIPANT_EXIST: {
    title: 'Nomor sudah terdaftar dalam aplikasi Populix',
    description:
      'Survei ini hanya tersedia untuk pengguna yang belum terdaftar. Silakan masuk ke aplikasi Populix dan temukan survei lainnya!',
    redirect: false,
  },
  STUDY_NOT_FOUND: {
    title: 'Survei tidak ditemukan',
    description: 'Tenang, kamu masih bisa isi survei lainnya di Populix. Download sekarang!',
    redirect: true,
  },
  STUDY_FULL: {
    title: 'Kuota survei habis',
    description: 'Tenang, kamu masih bisa isi survei lainnya di Populix. Download sekarang!',
    redirect: true,
  },
  STUDY_QUOTA_FULL: {
    title: 'Kuota untuk kriteria ini sudah terpenuhi',
    description: 'Maaf, kuota untuk kriteria ini dalam studi ini sudah terpenuhi.',
    redirect: true,
  },
  DUPLICATE_SUBMISSION: {
    title: 'Kamu sudah mengisi survei ini',
    description: 'Silakan masuk ke aplikasi Populix dan temukan survei lainnya!',
    redirect: false,
  },
  DUPLICATE_EMAIL_FOR_SUBMISSION: {
    title: 'Email sudah digunakan untuk mengikuti studi ini',
    description: 'Silakan gunakan email lain untuk mengikuti studi ini.',
    redirect: false,
  },
  DUPLICATE_PHONE_NUMBER_FOR_SUBMISSION: {
    title: 'Nomor telepon sudah digunakan untuk mengikuti studi ini',
    description: 'Silakan gunakan nomor telepon yang lain untuk mengikuti studi ini.',
    redirect: false,
  },
  NOT_ELIGIBLE: {
    title: 'Mohon maaf',
    description:
      'Kamu tidak memenuhi kriteria survei ini. Tenang, masih bisa isi survei lainnya di aplikasi Populix. Download sekarang!',
    redirect: true,
  },
  SUBMISSION_NOT_FOUND: {
    title: 'Jawaban Anda tidak ditemukan',
    description: 'Maaf, jawaban Anda tidak dapat ditemukan dalam sistem kami.',
    redirect: false,
  },
  SUBMISSION_HAS_BEEN_APPROVED: {
    title: 'Jawaban Anda sudah pernah disetujui',
    description: 'Maaf, jawaban Anda sudah pernah disetujui untuk studi ini.',
    redirect: false,
  },
  SUBMISSION_HAS_BEEN_REJECTED: {
    title: 'Mohon maaf',
    description:
      'Kamu tidak memenuhi kriteria survei ini. Tenang, masih bisa isi survei lainnya di aplikasi Populix. Download sekarang!',
    redirect: true,
  },
  DISQUALIFIED: {
    title: 'Mohon maaf',
    description:
      'Kamu tidak memenuhi kriteria survei ini. Tenang, masih bisa isi survei lainnya di aplikasi Populix. Download sekarang!',
    redirect: true,
  },
  SUBMISSION_ON_REVIEW: {
    title: 'Terima kasih telah mengisi survei ini!',
    description: 'Jawaban survei kamu telah kami terima.',
    redirect: true,
  },
  DEFAULT: {
    title: 'Kesalahan sistem',
    description:
      'Maaf terjadi kesalahan di sistem kami. Harap coba kembali atau hubungi customer support.',
    redirect: false,
  },
  REJECTED_ON_PRESCREENING: {
    title: 'Email atau nomor telepon sudah mengikuti studi ini',
    description: 'Maaf, email atau nomor telepon Anda sudah digunakan untuk mengikuti studi ini.',
    redirect: false,
  },
  INVALID_INPUT_OTP: {
    title: 'Kode OTP salah',
    description:
      'Maaf, OTP yang kamu masukkan salah. Silakan masukkan kembali kode OTP yang diterima melalui WA/SMS.',
    redirect: false,
  },
};
