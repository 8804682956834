import { SET_QUESTION, PURGE } from '../actions/actionTypes';

const initialState = {
  is_loaded: false,
  question: null,
};

export default function reducers(state = initialState, actions) {
  const { payload, type } = actions;
  switch (type) {
    case SET_QUESTION:
      return { is_loaded: true, question: payload };
    case PURGE:
      return { ...state, ...initialState };
    default:
      return state;
  }
}
