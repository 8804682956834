import React, { Component, Fragment } from 'react';
import { slide as Menu } from 'react-burger-menu';
import Popup from 'reactjs-popup';
import { withRouter, Link } from 'react-router-dom';
import { Transition } from 'react-spring/renderprops';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { ModalInput, ModalError, ModalPrompt, ProtectedBtn } from '../../components';
import populixLogo from 'assets/img/logo.png';
import { updateAuth } from '../../store/actions/authActions';
import { withdrawSaldo, getBankInformation } from '../../store/actions/saldoActions';
import { persistor } from '../../store/store';
import { RupiahParser } from '../../helpers/generalHelpers';
import { logOut } from '../../helpers/authHelpers';
import Auth from '@aws-amplify/auth';

const defaultImage = require('../../assets/img/default-img-02.png');
class TopNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTarikSaldoModal: false,
      showConfirmationModal: false,
      showCongratulationModal: false,
      showErrorModal: false,
      showNoBankAccountModal: false,
      payoutNominal: 0,
      validationMessage: '',
      formValidationFailed: false,
      uiStates: {
        postingPayout: false,
      },
      error: null,
      errorGetSaldo: null,
    };
  }

  componentDidMount() {
    const { profile } = this.props;
    const { email } = profile;
    const GetBankInformation = this.props.getBankInformation;
    GetBankInformation(email);
  }

  logout = async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      const cognitoGroups = currentUser.signInUserSession.idToken.payload['cognito:groups'];

      persistor.purge();
      localStorage.clear();
      sessionStorage.clear();
      if (
        cognitoGroups &&
        !cognitoGroups
          .toString()
          .toLowerCase()
          .includes('facebook')
      ) {
        this.props.history.push('/login');
      }
      await logOut();
    } catch (error) {
      console.error('error logging out', error);
    }
  };

  _navigateToPayoutRequest = () => {
    this.props.history.push('/payoutrequest');
  };

  _handleOnPressTarikDana = () => {
    const { wallet } = this.props;
    const { payoutNominal } = this.state;
    const { saldo } = wallet;
    if (payoutNominal < 50000) {
      this.setState(
        {
          validationMessage: 'Minimum jumlah penarikan adalah Rp50.000',
          formValidationFailed: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              validationMessage: '',
              formValidationFailed: false,
            });
          }, 2500);
        },
      );
    } else if (payoutNominal > saldo) {
      this.setState(
        {
          formValidationFailed: true,
          validationMessage: 'Saldo tidak cukup!',
        },
        () => {
          setTimeout(() => {
            this.setState({
              formValidationFailed: false,
              validationMessage: '',
            });
          }, 3000);
        },
      );
    } else {
      this.setState({
        showTarikSaldoModal: false,
        showConfirmationModal: true,
      });
    }
  };

  _handleFormChange = (e) => {
    const { target } = e;
    const { name, value } = target;
    this.setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  _onSubmitTarikSaldo = async () => {
    const { profile, wallet } = this.props;
    const { bankData } = wallet;
    const withdraw = this.props.withdrawSaldo;
    const { payoutNominal } = this.state;
    const { email } = profile;
    const defaultBankData = bankData.filter((bank) => bank.default === true);
    if (defaultBankData.length === 0) {
      return this.setState({ showNoBankAccountModal: true });
    }
    this.setState((prevState) => {
      return {
        uiStates: {
          ...prevState.uiStates,
          postingPayout: true,
        },
      };
    });
    try {
      await withdraw(payoutNominal, email, defaultBankData[0] && defaultBankData[0].id);
      this.setState({
        showCongratulationModal: true,
      });
    } catch (error) {
      // Do something
      this.setState({ showErrorModal: true });
    } finally {
      this.setState((prevState) => {
        return {
          showConfirmationModal: false,
          uiStates: {
            ...prevState.uiStates,
            postingPayout: false,
          },
        };
      });
      // Do something
    }
  };

  _hideModal = () => {
    this.setState({
      showTarikSaldoModal: false,
      showConfirmationModal: false,
      showCongratulationModal: false,
      showNoBankAccountModal: false,
      showErrorModal: false,
    });
  };

  _renderHomeScreenHeader = (name, family_name) => {
    return (
      <Fragment>
        <h3>
          Hi, {name}&nbsp;{family_name}.
        </h3>
        <h3>Selamat Datang. 😃</h3>
      </Fragment>
    );
  };

  render() {
    const { isLoading, wallet, profile, isHomeScreen, textToShow } = this.props;
    const { uiStates } = this.state;
    const { postingPayout } = uiStates;
    const { name, family_name, picture } = profile;
    const { saldo } = wallet;
    const getSaldoError = wallet.error;
    const popoverProfile = (
      <button id='topnav-btn-avatar' className='p-0'>
        <img
          src={picture ? `https://${picture}?${Math.random()}` : defaultImage} // TODO Add default image for email login
          className='topnav-notif__avatar--size topnav-notif__avatar--radius-100'
          alt='prof-img'
        />
      </button>
    );
    return (
      <Fragment>
        <nav id='app-navbar' className='topnav navbar-expand-lg navbar-light'>
          {/* For mobile devices */}
          <Menu className='desktop-disabled'>
            <Link to='/study'>
              <img className='img-fluid' id='populix-logo' src={populixLogo} alt='populix logo' />
            </Link>

            <Link className='menu-item' to='/study'>
              Lihat Studi
            </Link>
            {/* <a className='menu-item' data-toggle='dropdown' role='button' href='/screening/profesi'>
              Screening
            </a>
            <a className='menu-item' href='/screening/profesi'>
              <img className='icon' src={profesiIcon} alt='profession' />
              <span>Profesi</span>
            </a>
            <a className='menu-item' href='/screening/education'>
              <img className='icon' src={pendidikanIcon} alt='education' />
              <span>Pendidikan</span>
            </a> */}
            {/* <a className="menu-item" href="/screening/politics">
              <img
                className="icon"
                src={opiniPolitikIcon}
                alt="policical-opinion"
              />
              <span>Opini Politik</span>
            </a> */}
            {/* <a className='menu-item' href='/screening/family'>
              <img className='icon' src={hubunganKeluargaIcon} alt='family-relation' />
              <span>Hubungan Keluarga</span>
            </a>
            <a className='menu-item' href='/screening/social'>
              <img className='icon' src={statusSosialIcon} alt='social-status' />
              <span>Status Sosial</span>
            </a>
            <a className='menu-item' href='/screening/others'>
              <img className='icon' src={hobiIcon} alt='others' />
              <span>Lainnya</span>
            </a> */}
            {/* <a className="menu-item" href="/screening/study">
              <img
                className="icon"
                src={studiKualitatifIcon}
                alt="qualitative-study"
              />
              <span>Studi Kualitatif</span>
            </a> */}
          </Menu>

          <span className='mobile-disabled' />

          <div className='mobile-disabled'>
            {isLoading ? (
              <div style={{ height: 10, width: 100, backgroundColor: 'grey' }} />
            ) : isHomeScreen ? (
              this._renderHomeScreenHeader(name, family_name)
            ) : (
              <h3>{textToShow}</h3>
            )}
          </div>

          {/* Topnav and avatar */}
          <div className='topnav-notif-avatar'>
            <Popup trigger={popoverProfile} position='bottom right'>
              <div className='popover-body f-13'>
                <ul>
                  {/* <ProtectedBtn linkTo='/profile'>
                    <li>Profil Saya</li>
                  </ProtectedBtn>
                  <li>
                    <strong>{code}</strong>
                  </li> */}
                  <li className='popover-body--no-effect'>
                    <strong>
                      {name}
                      &nbsp;
                      {family_name}
                    </strong>
                  </li>
                  <li className='desktop-disabled'>
                    {!getSaldoError && <strong>{RupiahParser(saldo)}</strong>}
                    {getSaldoError && (
                      <span className='font-bold'>
                        Terjadi kesalahan dalam mengambil data
                        <span role='img' aria-label='sadface'>
                          😟
                        </span>
                        , mohon coba lagi
                      </span>
                    )}
                  </li>
                  <li className='nav-item desktop-disabled'>
                    <button
                      type='button'
                      className='btn btn-transparent btn-block btn--text text--light-blue f-13 pl-0 text-left'
                      onClick={this._navigateToPayoutRequest}
                      style={{ width: '100%' }}
                    >
                      Tarik Dana
                    </button>
                  </li>
                  <hr />
                  <ProtectedBtn linkTo='/transactions/all'>
                    <li>Riwayat Saldo</li>
                  </ProtectedBtn>
                  <ProtectedBtn linkTo='/settings/profile'>
                    <li>Settings</li>
                  </ProtectedBtn>
                  {/* {authProvider !== 'Facebook' && (
                    <ProtectedBtn linkTo='/profile/password' className='desktop-disabled'>
                      <li>Kata Sandi</li>
                    </ProtectedBtn>
                  )} */}
                  {/* <ProtectedBtn linkTo='/settings/notification' className='desktop-disabled'>
                    <li>Notifikasi</li>
                  </ProtectedBtn>
                  <ProtectedBtn linkTo='/settings/bank-account' className='desktop-disabled'>
                    <li>Rekening Bank</li>
                  </ProtectedBtn>
                  <ProtectedBtn linkTo='/settings/account' className='desktop-disabled'>
                    <li>Akun</li>
                  </ProtectedBtn> */}
                  <hr />
                  <li>
                    <a href='javascript:void(0)' onClick={this.logout}>
                      Log out
                    </a>
                  </li>
                </ul>
              </div>
            </Popup>
          </div>
        </nav>
        {/* <Modal
          className="modal fade"
          id="cash-widthdraw--modal"
          tabIndex="-1"
          role="dialog"
          show={this.state.showTarikSaldoModal}
          handleClose={this._hideModal}
          handleOnButtonPress={this._handleOnPressTarikDana}
          handleFormChange={this._handleFormChange}
          validationMessage={this.state.validationMessage}
          validationFailed={this.state.formValidationFailed}
        /> */}
        <Transition
          native
          items={this.state.showTarikSaldoModal}
          from={{ transform: 'translate(0, -100%)' }}
          enter={{ transform: 'translate(0, 0)' }}
          leave={{ transform: 'translate(0, -100%)' }}
        >
          {(showWithdrawalModal) => (modalStyle) =>
            showWithdrawalModal && (
              <ModalInput
                animatedModal
                style={modalStyle}
                formLabelMain='Masukkan Jumlah Penarikan (Rp)'
                formValidationMsg={
                  this.state.validationMessage || 'Minimum jumlah penarikan adalah Rp 50.000'
                }
                formValidationFailed={this.state.formValidationFailed}
                handleSubmitForm={this._handleOnPressTarikDana}
                submitLabel='Tarik dana'
                handleFormChange={this._handleFormChange}
                handleClose={this._hideModal}
              />
            )}
        </Transition>
        <ModalPrompt
          handleClose={this._hideModal}
          handleConfirmation={this._onSubmitTarikSaldo}
          processingData={postingPayout}
          mainPromptMsg={`Kamu melakukan penarikan dana sebesar ${RupiahParser(
            this.state.payoutNominal,
          )} ?`}
          extraPromptMsg='Konfirmasi penarikan dapat memakan waktu 1 sampai 3 hari'
          show={this.state.showConfirmationModal}
          mainLoadingMsg='Memproses Penarikan Saldo'
          extraLoadingMsg='Harap tunggu, penarikan saldo kamu sedang kami proses'
        />
        <ModalSuccess
          amountToWithdraw={this.state.payoutNominal}
          name={`${name} ${family_name}`}
          show={this.state.showCongratulationModal}
          handleBackFunction={this._handleMoreWithdrawal}
          handleCloseFunction={this._hideModal}
        />
        <Transition
          native
          items={this.state.showErrorModal}
          from={{ transform: 'translate(0, -100%)' }}
          enter={{ transform: 'translate(0, 0)' }}
          leave={{ transform: 'translate(0, -100%)' }}
        >
          {(showErrorModal) => (styleProps) =>
            showErrorModal && (
              <ModalError
                animatedModal
                dataObjects={{ name: `${name} ${family_name}` }}
                handleCloseFunction={this._hideModal}
                style={styleProps}
                errorMsgHead='Telah terjadi kesalahan saat melakukan penarikan'
                errorMsgDetail='Silahkan coba lagi atau hubungi customer service kami'
              />
            )}
        </Transition>
        <Transition
          native
          items={this.state.showNoBankAccountModal}
          from={{ transform: 'translate(0, -100%)' }}
          enter={{ transform: 'translate(0, 0)' }}
          leave={{ transform: 'translate(0, -100%)' }}
        >
          {(showNoBankAccountModal) => (styleProps) =>
            showNoBankAccountModal && (
              <ModalError
                animatedModal
                dataObjects={{ name: `${name} ${family_name}` }}
                handleCloseFunction={this._hideModal}
                style={styleProps}
                errorMsgHead='Kamu belum mengisi nomor rekening kamu'
                errorMsgDetail='Mohon mengisi informasi bank kamu pada menu settings'
              />
            )}
        </Transition>
        {/* <ModalError
          name={`${first_name} ${last_name}`}
          show={this.state.showErrorModal}
          handleBackFunction={this._handleMoreWithdrawal}
          handleCloseFunction={this._hideModal}
        /> */}
        <div id='modal-backdrop' className='modal-backdrop-transparent modal-transition' />
      </Fragment>
    );
  }
}

const ModalSuccess = ({
  name,
  amountToWithdraw,
  show,
  handleBackFunction,
  handleCloseFunction,
}) => {
  return (
    <div className={`${show ? 'modal display-block' : 'modal display-none'}`}>
      <section className='modal-main'>
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header border-bottom py-4'>
              <img src={populixLogo} style={{ height: 32, width: 110 }} alt='populix' />
            </div>
            <div>
              <div className='modal-body'>
                <div className='d-flex justify-content-between align-items-center'>
                  <h4 className='m-0'>Selamat, {name} 👋</h4>
                  <span>
                    <FontAwesomeIcon icon={faCheckCircle} color='#FAAF40' size='3x' />
                  </span>
                </div>
                <div className='my-2'>
                  <h3 className='mb-3'>Penarikan Sukses !</h3>
                  <h4 className='mb-3'>
                    Dana sebesar{' '}
                    <span style={{ fontWeight: 'bold' }}>{RupiahParser(amountToWithdraw)}</span>{' '}
                    akan segera di transfer ke rekening kamu dalam 3 hari kerja
                  </h4>
                </div>
              </div>
              <div className='modal-footer modal-footer-type-2 float-right'>
                {/* <button
                  type="button"
                  className="btn btn-primary modal-footer-btn"
                  onClick={handleBackFunction}
                >
                  Tarik lagi
                </button> */}
                <button
                  type='button'
                  className='btn btn-transparent btn--text text--light-blue modal-footer-btn'
                  onClick={handleCloseFunction}
                >
                  Tutup
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.profile.userProfile,
    wallet: state.wallet,
    authProvider: state.auth.authProvider,
  };
};

export default withRouter(
  connect(mapStateToProps, { withdrawSaldo, getBankInformation, updateAuth })(TopNav),
);
